import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import ReactGA from "react-ga4";

const measurementIdForDev = process.env.REACT_APP_MEASUREMENT_ID_FOR_DEV;
const measurementIdForProd = process.env.REACT_APP_MEASUREMENT_ID_FOR_PROD;
const mixPanelToken = process.env.REACT_APP_MIX_PANEL_TOKEN;
const sourceUrl = process.env.REACT_APP_SOURCE_URL;

export const initializeGA = () => {
  if (sourceUrl === "https://api.dev.modalx.ai/api") {
    ReactGA.initialize(measurementIdForDev); // Replace with your GA4 Measurement ID
    console.log("Analytics for dev is triggered")
  } else if (sourceUrl === "https://api.modalx.ai/api") {
    ReactGA.initialize(measurementIdForProd);
    console.log("Analytics for prod is triggered")
  }
};

export const trackPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};



export const trackButtonEvent = (eventName, parameters) => {
  ReactGA.event(eventName, { ...parameters });
  // console.log(`Event Tracked: ${eventName}`, parameters);
};


export const trackFileUpload = (eventName, fileType, fileCount, uploadMethod) => {
  ReactGA.event(eventName, {
    file_count: fileCount,
    file_type: fileType,
    upload_method: uploadMethod,
  });

  // console.log(`Event Tracked: file_upload`, { file_type: fileType, file_count: fileCount, upload_method: uploadMethod });
};

export const trackLinkPaste = (linkUrl) => {
  ReactGA.event("link_paste", {
    link_url: linkUrl

  });
  // console.log('Event Tracked: link_paste', { link_url: linkUrl });
};

export const trackModalOpen = (modalName) => {
  ReactGA.event('modal_open', {
    modal_name: modalName,
  });
  // console.log(`Event Tracked: modal_open, Modal Name: ${modalName}`);
};

export const trackContentGeneration = (contentType, generationMethod) => {
  ReactGA.event('content_generation', {
    content_type: contentType,
    generation_method: generationMethod,
  });

  // console.log(`Event Tracked: content_generation, Content Type: ${contentType}, Generation Method: ${generationMethod}`);
};

export const trackSearchInteraction = (searchQuery) => {
  ReactGA.event('search_interaction', {
    search_query: searchQuery,
  });
  // console.log(`Event Tracked: search_interaction, Search Query: ${searchQuery}`);
};

export const trackNavigation = (fromPage, toPage) => {
  ReactGA.event("navigation", {
    navigation_from: fromPage,
    navigation_to: toPage
  });

  // console.log(`Navigation Event: ${fromPage} -> ${toPage}`);
};

export const trackProjectInteraction = (projectName, interactionType) => {
  ReactGA.event('project_interaction', {
    project_name: projectName,
    interaction_type: interactionType,
  });

  // console.log(`Project Interaction: ${projectName}, Type: ${interactionType}`);
};


export const initializeMixPanel = () => {
  if (sourceUrl === "https://api.dev.modalx.ai/api") {
    ReactGA.initialize(measurementIdForDev); // Replace with your GA4 Measurement ID
    mixpanel.init(mixPanelToken, {
      track_pageview: true,
      // debug: true,
      persistence: "localStorage",
    });
    console.log("Analytics for dev is triggered")
  } else if (sourceUrl === "https://api.modalx.ai/api") {
    mixpanel.init(mixPanelToken, {
      track_pageview: true,
      persistence: "localStorage",
    });
    // console.log("Analytics for prod is triggered")
  }
};


//  Track a custom event when the button is clicked
export const ButtonClick = (eventName,buttonName) => {mixpanel.track(eventName, {
  buttonName: buttonName || null,
  timestamp: new Date().toLocaleString()
});
}


export const PromptInputEvent = (eventName, buttonName,requestBody, responseBody) =>{
  mixpanel.track(eventName, {
    buttonName:buttonName,
    requestBody: requestBody,
    responseBody:responseBody
  });
}

export const SignUpEvent = (eventName, signup_method) =>{
  mixpanel.track(eventName, {
    signup_method:signup_method,
    timestamp: new Date().toLocaleString()
  });
}

export const SessionEvent = (eventName) =>{
  mixpanel.track(eventName, {
    timestamp: new Date().toLocaleString()
  });
}

export const LoginEvent = (eventName,buttonName,login_method) =>{
  mixpanel.track(eventName, {
    buttonName : buttonName,
    login_method:login_method,
    timestamp: new Date().toLocaleString()
  });
}

// export const LogoutEvent = (eventName,buttonName) =>{
//   mixpanel.track(eventName, {
//     buttonName : buttonName,
//     timestamp: new Date().toLocaleString()
//   });
// }

export const NavigationClick = (item_name) =>{
  mixpanel.track("sidebar_navigation_click", {
    item_name:item_name,
    timestamp: new Date().toLocaleString()
  });
}

export const ExpandCollapseSidebar = (state) =>{
  mixpanel.track("sidebar_toggle", {
    state:state,
    timestamp: new Date().toLocaleString()
  });
}

export const CreateNewProjectConfirmation = (project_id) =>{
  mixpanel.track("new_project_confirm", {
    project_id:project_id,
    timestamp: new Date().toLocaleString()
  });
}

export const SuggestedActionClick = (action_name) =>{
  mixpanel.track("suggested_action_click", {
    action_name:action_name,
    timestamp: new Date().toLocaleString()
  });
}


// export const GenerateContentButtonClick = () =>{
//   mixpanel.track("generate_content_click", {
//     timestamp: new Date().toLocaleString()
//   });
// }

export const SubmitContentGenerationRequest =(input_text,content_type) =>{
  mixpanel.track("submit_content_generation_request", {
    input_text:input_text,
    content_type:content_type,
    timestamp: new Date().toLocaleString()
  });
}

export const FileUpload = (file_type,file_count) =>{
  mixpanel.track("file_upload", {
    file_type:file_type,
    // file_size:file_size,
    file_count:file_count,
    timestamp: new Date().toLocaleString()
  });
}

export const LinkPaste = (link_url) =>{
  mixpanel.track("link_paste", {
    link_url:link_url,
    timestamp: new Date().toLocaleString()
  });
}

export const SubscribeToProClick = () =>{
  mixpanel.track("subscribe_to_pro_click", {
    timestamp: new Date().toLocaleString()
  });
}

export const AccountInfoClick = () =>{
  mixpanel.track("account_info_click", {
    timestamp: new Date().toLocaleString()
  });
}

// export const DeleteAllProjectsConfirmation = () =>{
//   mixpanel.track("delete_all_projects", {
//     timestamp: new Date().toLocaleString()
//   });
// }

export const ErrorEvent = (error_code,error_message) =>{
  mixpanel.track("error_event", {
    error_code:error_code || null,
    error_message:error_message || null,
    timestamp: new Date().toLocaleString()
  });
}

// export const TooltipHover = (tooltip_name) =>{
//   mixpanel.track("tooltip_hover", {
//     tooltip_name:tooltip_name,
//     timestamp: new Date().toLocaleString()
//   });
// }

export const SettingsAdjustment = (setting_name, new_value) =>{
  mixpanel.track("settings_adjustment", {
    setting_name:setting_name,
    new_value:new_value,
    timestamp: new Date().toLocaleString()
  });
}

export const LikeDislikeEvent = (eventName,buttonName,requestBody,responseBody) =>{
  mixpanel.track(eventName, {
    buttonName : buttonName,
    requestBody : requestBody,
    responseBody : responseBody
  });
}

