import React from "react";
import PageLayout from "../components/navFoot/PageLayout";
import {
  Box,
  Typography,
  CardMedia,
  Button,
  useMediaQuery,
} from "@mui/material";
import RequestDemoNearFooter from "../components/LandingPage/RequestDemoNearFooter";
import Logo from "../assets/LandingPage_Items/CaseStudies/Logo.png";
import image02 from "../assets/LandingPage_Items/CaseStudies/casestudy02.png";
import image03 from "../assets/LandingPage_Items/CaseStudies/casestudy03.png";
import arrow from "../assets/LandingPage_Items/CaseStudies/UpArrow_Icon.png";

function CaseStudy() {
  const mobileView = useMediaQuery("(max-width:991px)");
  const Datas = [
    { id: 1, arrow: arrow, pre: "80%", title: "Web Presence" },
    { id: 2, arrow: arrow, pre: "50%", title: "Time Saved Per Post" },
    { id: 3, arrow: arrow, pre: "20%", title: "Boost in Sales" },
  ];

  const Datas2 = [
    {
      id: 1,
      title1: `ModalX's text-to-image and text-to-video capabilities enabled the
          creation of eye-catching social media posts and promotional videos,
          resulting in a 80% increase in website traffic and a 20% boost in
          sales`,
      title2: "Problems",
    },
    {
      id: 2,
      title1: `ModalX's text-to-image and text-to-video capabilities enabled the
          creation of eye-catching social media posts and promotional videos,
          resulting in a 80% increase in website traffic and a 20% boost in
          sales`,
      title2: "Solutions",
    },
    {
      id: 3,
      title1: `ModalX's text-to-image and text-to-video capabilities enabled the
          creation of eye-catching social media posts and promotional videos,
          resulting in a 80% increase in website traffic and a 20% boost in
          sales`,
      title2: "Outcomes",
    },
  ];

  return (
    <PageLayout>
      <Box
        sx={{
          marginTop: mobileView ? "155px" : "261px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: mobileView ? "12px" : "16px",
            fontWeight: 600,
            lineHeight: "24px",
            textAlign: "center",
            color: "#F6BE6B",
          }}
        >
          Case Study
        </Typography>
      </Box>

      <Typography
        sx={{
          marginTop: mobileView ? "24px" : "48px",
          padding: "0px 50px 0px 50px",
          fontFamily: "Inter",
          fontSize: mobileView ? "28px" : "48px",
          fontWeight: 500,
          lineHeight: "57.6px",
          textAlign: "center",
          letterSpacing: "-0.32px",
          color: "#FFFFFF",
          opacity: "80%",
        }}
      >
        How &lt;Retail Business&gt; Increased their Web Presence by 80%
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          marginTop: mobileView ? "60px" : "100px",
          minWidth: "350px",
          margin: "0px 4% 0px 4%",
     
        }}
      >
        <Box
          sx={{
            // width: "990px",
            display: "flex",
            margin: "auto",
            gap: "50px",

            margin: "0px 20px 0px 20px",
            "@media (max-width : 991px)": {
              display: "grid",
              width: "100%",
              // gridTemplateColumns : 'repeat (1 , 1fr)',
              gap: "40px",

              justifyContent: "center",
              margin: "auto",
            },
          }}
        >
          <Box
            sx={{
              width: "238px",
              // minHeight: "180px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: mobileView ? "20px" : "24px",
                  fontWeight: 600,
                  textAlign: "left",
                  color: "#FFFFFF",
                  opacity: "80%",
                }}
              >
                Customer
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: mobileView ? "24px" : "30px",
              }}
            >
              <Box
                sx={{
                  width: "24px",
                  height: "24px",
                  marginTop: "5px",
                }}
              >
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={Logo}
                  alt="image02"
                />
              </Box>

              <Typography
                sx={{
                  fontFamily: "JetBrains Mono",
                  marginLeft: "6px",
                  fontSize: "20px",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                ShareWise
              </Typography>
            </Box>

            <Box>
              <Typography
                sx={{
                  marginTop: "12px",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 400,
                  textAlign: "left",
                }}
              >
                ShareWise is an electronics retailer based in New York City
                since 1990
              </Typography>
            </Box>
          </Box>

          <Box
            sx={
              {
                // width: "100%",
              }
            }
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: mobileView ? "20px" : "24px",
                fontWeight: 600,
                lineHeight: "36px",
                textAlign: "left",
              }}
            >
              Use Cases
            </Typography>
            <Box
              sx={{
                marginTop: mobileView ? "24px" : "30px",
                width: "130px",
              }}
            >
              <Button
                sx={{
                  textTransform: "none",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  height: "32px",
                  bgcolor: "#1F233E",
                  color: "#CAC4D0",
                }}
              >
                Text-to-video
              </Button>
              <Button
                sx={{
                  textTransform: "none",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  marginTop: "12px",
                  height: "32px",
                  bgcolor: "#1F233E",
                  color: "#CAC4D0",
                }}
              >
                Text-to-image
              </Button>
              <Button
                sx={{
                  textTransform: "none",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  marginTop: "12px",
                  height: "32px",
                  bgcolor: "#1F233E",
                  color: "#CAC4D0",
                }}
              >
                Blog Generation
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              // minWidth: "501px",
              minHeight: "180px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: mobileView ? "20px" : "24px",
                textAlign: "left",
              }}
            >
              Key Results
            </Typography>
            <Box
              sx={{
                marginTop: mobileView ? "24px" : "30px",
                width: mobileView ? "350px" : "501px",
                height: "68px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {Datas.map((e) => (
                <Box
                  key={e.id}
                  sx={{
                    minWidth: "123px",
                    height: "68px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "6px",
                      //   justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        width: "18px",
                        // height : '12px',
                        marginLeft: "6px",
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={e.arrow}
                        alt="image02"
                      />
                    </Box>
                    <Box
                      sx={{
                        width: "89px",
                        height: "40px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: mobileView ? "28px" : "40px",
                          lineHeight: "40px",
                          textAlign: "left",
                          fontWeight: 700,
                          //   color: "#F6BE6B",
                          color: "#FFFFFF",
                          opacity: "80%",
                          background:
                            "linear-gradient(90deg, #F6BE6B 0%, rgba(246, 190, 107, 0.60) 100%)",
                          backgroundClip: "text",
                          WebkitBackgroundClip: "text", // For Safari support
                          WebkitTextFillColor: "transparent", // For Safari support
                        }}
                      >
                        {e.pre}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: mobileView ? "14px" : "16px",
                        fontWeight: 600,
                        lineHeight: mobileView ? "14px" : "16px",
                        textAlign: "center",
                        color: "#FFFFFF",
                        opacity: "80%",
                        background:
                          "linear-gradient(90deg, #F6BE6B 0%, rgba(246, 190, 107, 0.60) 100%)",
                        backgroundClip: "text",
                        WebkitBackgroundClip: "text", // For Safari support
                        WebkitTextFillColor: "transparent", // For Safari support
                      }}
                    >
                      {e.title}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            {/* <CardMedia component="img" image={image02} alt="image02" /> */}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          maxWidth: "900px",
          margin: "auto",
        }}
      >
        <Typography
          sx={{
            marginTop: "100px",
            fontFamily: "Inter",
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "27px",
            textAlign: "left",
            letterSpacing: "-0.32px",
            color: "#FFFFFF",
            opacity: "90%",
            padding: "0px 4% 0px 4%",
          }}
        >
          ModalX's text-to-image and text-to-video capabilities enabled the
          creation of eye-catching social media posts and promotional videos,
          resulting in a 80% increase in website traffic and a 20% boost in
          sales
        </Typography>
      </Box>

      <Box
        sx={{
          maxWidth: "386px",
          maxHeight: "262px",
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          marginTop: "80px",
          padding: "0px 4% 0px 4%",
        }}
      >
        <CardMedia component="img" image={image03} alt="image" />
      </Box>
      {Datas2.map((e) => (
        <Box
          key={e.id}
          sx={{
            padding: "0px 4% 0px 4%",
            display: "flex",
            justifyContent: "center",
            maxWidth: "900px",
            margin: "auto",
            marginTop: "80px",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: mobileView ? "20px" : "24px",
              fontWeight: 600,
              lineHeight: "36px",
              textAlign: "left",
              color: "#FFFFFF",
              opacity: "80%",
            }}
          >
            {e.title2}
          </Typography>

          <Typography
            sx={{
              marginTop: "30px",
              fontFamily: "Inter",
              fontSize: mobileView ? "14px" : "18px",
              fontWeight: 400,
              lineHeight: "27px",
              textAlign: "left",
              letterSpacing: "-0.32px",
              color: "#FFFFFF",
              opacity: "90%",
            }}
          >
            {e.title1}
          </Typography>
        </Box>
      ))}

      <Box
        sx={{
          marginTop: "1031px",
        }}
      >
        <RequestDemoNearFooter />
      </Box>
    </PageLayout>
  );
}

export default CaseStudy;
