import React from "react";
import {
  Row,
  Col,
  Container,
  ProgressBar,
  DropdownButton,
  Dropdown,
  Button,
  Card,
  Form,
} from "react-bootstrap";
import userPreferences from "../constants/userPreferences.json";
import ThemeToggle from "../components/ThemeToggle";

import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const UserJourney = () => {
  const navigate = useNavigate();
  const { setUser } = useAuth();

  const [userData, setUserData] = React.useState({
    firstName: "",
    sourceOfConversion: "Select an option",
    userProfile: "",
    intendedWork: "",
  });

  const [currPage, setCurrPage] = React.useState(0);
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    let progressCurr = 0;
    if (userData.firstName !== "") {
      progressCurr += 25;
    }
    if (userData.sourceOfConversion !== "Select an option") {
      progressCurr += 25;
    }
    if (userData.userProfile !== "") {
      progressCurr += 25;
    }
    if (userData.intendedWork !== "") {
      progressCurr += 25;
    }
    setProgress(progressCurr);
  }, [userData]);

  async function postData() {
    try {
      const response = await fetch("/profile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
        credentials: "include",
      });
      const responseData = await response.json();
      const user = await responseData.result;

      if (response.ok) {
        console.log("User preference setup successful for user: ", user);
        setUser(user);
        navigate("/workspace");
      } else {
        console.error("Error in setting user preference:", response.statusText);
      }
    } catch (error) {
      console.error("Error performing request:", error);
    }
  }

  function HandleDropdown({ data }) {
    return (
      <DropdownButton
        id={data.uid + "-dropdown"}
        title={userData[data.uid]}
        variant="outline-secondary"
      >
        {data.answer.map((opt, index) => (
          <Dropdown.Item
            key={index}
            onClick={() =>
              setUserData((prevUserData) => ({
                ...prevUserData,
                [data.uid]: opt,
              }))
            }
          >
            {opt}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    );
  }

  function HandleCard({ data }) {
    return (
      <Row className="mt-4">
        {data.answer.map((category, index) => (
          <Col key={index} xs={6} className="py-2">
            <Card
              style={{
                height: "100%",
                cursor: "pointer",
                border:
                  userData[data.uid] === category.title ? "2px solid gray" : "",
              }}
              onClick={() =>
                setUserData((prevUserData) => ({
                  ...prevUserData,
                  [data.uid]: category.title,
                }))
              }
            >
              <Card.Body>
                <Row>
                  <Col xs={2}>
                    <i
                      className={`bi bi-${category.bootstrapIconName} fs-4`}
                    ></i>
                  </Col>
                  <Col>
                    <Card.Title>{category.title}</Card.Title>
                    <Card.Text>{category.description}</Card.Text>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    );
  }

  function NavSubmitButtons() {
    return (
      <Row className="p-0 m-0">
        {currPage === 1 && (
          <Col className="d-flex justify-content-start p-0 m-0">
            <Button
              className="mt-4"
              onClick={() => {
                setCurrPage(currPage - 1);
                setUserData((prevUserData) => ({
                  ...prevUserData,
                  userProfile: "",
                  intendedWork: "",
                }));
              }}
            >
              <span>
                <i className={`bi bi-arrow-left fs-6`}></i>
              </span>{" "}
              Back
            </Button>
          </Col>
        )}
        {currPage === 0 && (
          <Col className="d-flex justify-content-end p-0 m-0">
            <Button
              className="mt-4"
              onClick={() => setCurrPage(currPage + 1)}
              disabled={
                userData.sourceOfConversion === "Select an option" ||
                userData.firstName === ""
              }
            >
              Next{" "}
              <span>
                <i className={`bi bi-arrow-right fs-6`}></i>
              </span>
            </Button>
          </Col>
        )}
        {currPage === 1 && (
          <Col className="d-flex justify-content-end p-0 m-0">
            <Button
              varient="success"
              className="mt-4"
              onClick={() => postData()}
              disabled={progress !== 100}
            >
              Submit
              <span>
                <i className={`bi bi-arrow-right fs-6`}></i>
              </span>
            </Button>
          </Col>
        )}
      </Row>
    );
  }

  return (
    <Row className="mx-0" style={{ height: "100vh", width: "100vw" }}>
      <Col className="mx-0" xs={12} sm={12} md={9} lg={8} xl={7} xxl={6}>
        <Container className="px-5 my-5">
          <h1>
            Modal{" "}
            <span
              style={{
                fontWeight: "bold",
                color: "#ff4f00",
                fontSize: "1.2em",
              }}
            >
              X
            </span>
          </h1>
          <ThemeToggle show={false} />
          <ProgressBar
            now={progress}
            style={{ width: "100%" }}
            className="mt-4"
          />
          <Container className="ps-0 pe-5">
            {currPage === 0 && (
              <>
                <h3 className="mt-4">{userPreferences[0].question}</h3>
                <Form.Control
                  type="text"
                  placeholder={userPreferences[0].placeholder}
                  value={userData.firstName}
                  onChange={(e) =>
                    setUserData((prevUserData) => ({
                      ...prevUserData,
                      firstName: e.target.value,
                    }))
                  }
                  style={{ width: "100%" }}
                  className="px-2"
                />
                <h3 className="mt-4">{userPreferences[1].question}</h3>
                <HandleDropdown data={userPreferences[1]} />
              </>
            )}
            {currPage === 1 && (
              <>
                <h3 className="mt-4">{userPreferences[2].question}</h3>
                <HandleCard data={userPreferences[2]} />
                {userData.userProfile !== "" && (
                  <>
                    <h3 className="mt-4">
                      {
                        userPreferences[2].answer.find(
                          (item) => item.title === userData.userProfile
                        ).question
                      }
                    </h3>
                    <HandleCard
                      data={userPreferences[2].answer.find(
                        (item) => item.title === userData.userProfile
                      )}
                    />
                  </>
                )}
              </>
            )}
          </Container>
          <NavSubmitButtons />
        </Container>
      </Col>
      <Col
        className="mx-0 d-none d-md-block"
        xs={0}
        sm={0}
        md={3}
        lg={4}
        xl={5}
        xxl={6}
        style={{ backgroundColor: "gray" }}
      ></Col>
    </Row>
  );
};

export default UserJourney;
