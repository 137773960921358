import React, { useState } from "react";
import Icon_1 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image_1.png";
import Icon_2 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image_2.png";
import Icon_3 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image_3.png";
import Icon_4 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image_4.png";
import Icon_5 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image_5.png";
import Icon_6 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image_6.png";
import Icon_7 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image_7.png";
import Icon_8 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image_8.png";
import Image1 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image1.png";
import Image2 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image2.png";
import Image3 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image3.png";
import Image4 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image4.png";
import Image5 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image5.png";
import Image6 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image6.png";
import Image7 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image7.png";
import Image8 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image8.png";
import { SelectedChat_bg_color } from "../../Provider/Color_Provider";
import { selected_chat_bg_color_dm } from "../../constants/colors";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, IconButton, Typography, CardMedia, Button } from "@mui/material";

const Actions = [
  {
    id: "1",
    icon: Icon_1,
    title: "Marketing Agencies and Freelancers",
    content: "Create high quality content quickly and affordably",
    image: Image1,
  },
  {
    id: "2",
    icon: Icon_2,
    title: "E-Commerce Businesses",
    content: "Create high quality content quickly and affordably",
    image: Image2,
  },
  {
    id: "3",
    icon: Icon_3,
    title: "Immigration Consultant",
    content: "Create high quality content quickly and affordably",
    image: Image3,
  },
  {
    id: "4",
    icon: Icon_4,
    title: "Educational Institutions and Course Creators",
    content: "Create high quality content quickly and affordably",
    image: Image4,
  },
  {
    id: "5",
    icon: Icon_5,
    title: "Nonprofits and NGOs",
    content: "Create high quality content quickly and affordably",
    image: Image5,
  },
  {
    id: "6",
    icon: Icon_6,
    title: "Healthcare Providers",
    content: "Create high quality content quickly and affordably",
    image: Image6,
  },
  {
    id: "7",
    icon: Icon_7,
    title: "Real Estate Agents and Brokers",
    content: "Create high quality content quickly and affordably",
    image: Image7,
  },
  {
    id: "8",
    icon: Icon_8,
    title: "Small Manufacturing and Product Businesses",
    content: "Create high quality content quickly and affordably",
    image: Image8,
  },
];

const ModalXInAction = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalBoxes = 10; // Total number of boxes in the carousel
  const visibleBoxes = 2; // Number of boxes visible at once
  const boxWidth = 301; // Width of each box in pixels (including margin)

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < totalBoxes - visibleBoxes) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };
  return (
    <div style={{ marginTop: "80px",  }}>
      {/* <p style={{ textAlign: "center", color: "#F6BE6B" }}>ModalX in Action</p> */}
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: "16px",
          fontWeight: 600,
          lineHeight: "24px",
          textAlign: "center",
          color: "#F6BE6B",
          "@media (max-width: 991px)": {
            fontSize: "12px",
          },
        }}
      >
        ModalX in Action
      </Typography>
      <div
        style={{
          maxWidth: "1072px",
          margin: "auto",
          textAlign: "center",
          marginTop: "48px",
        }}
      >
        {/* <p style={{ fontSize: "48px", marginTop: "80px" }}>
          The AI toolkit for every business need
        </p> */}

        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "48px",
            fontWeight: 500,
            lineHeight: "57.6px",
            textAlign: "center",
            letterSpacing: "-0.32",
            color: "#FFFFFF",
            opacity: "80%",
            "@media (max-width: 991px)": {
              fontFamily: "Inter",
              fontSize: "28px",
              fontWeight: 500,
              lineHeight: "33.6px",
              textAlign: "center",
              letterSpacing: "-0.32",
              color: "#FFFFFF",
              opacity: "80%",
              minWidth: "358px",
              margin: "auto",
              padding : '0px 10px 0px 10px'
            },
          }}
        >
          The AI toolkit for every business need
        </Typography>
      </div>
      {/* <Box
        sx={{
          "@media (min-width: 991px)": {
            maxWidth: "1324px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            // justifyContent: 'space-evenly',
            margin: "84px auto",
            gap: "40px",
          },

          "@media (max-width: 991px)": {
            display: "flex",
            margin: "84px auto",
            gap: "40px",
          },
        }}
      >
        {Actions.map((item, i) => (
          <Box
            key={item.id}
            sx={{
              padding: "16px",
              borderRadius: "12px",
              backgroundColor: selected_chat_bg_color_dm,
              flexGrow: 1,
              flexBasis: "calc(25% - 40px)", // Adjust width based on container and gap
              maxWidth: "301px", // Optional max-width for consistent card size
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "362px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={item.icon}
                style={{ width: "40px", height: "40px" }}
                alt={item.title}
              />
              <p
                style={{ color: "#FFFFFF", margin: "auto", marginLeft: "10px" }}
              >
                {item.title}
              </p>
            </div>
            <img
              src={item.image}
              style={{ height: "188px", maxWidth: "258px" }}
            />
            <p style={{ color: "rgba(255,255,255,0.6)" }}>{item.content}</p>
          </Box>
        ))}
      </Box> */}
      <Box
        // overflow="hidden"
        // width={`${visibleBoxes * boxWidth + 162}px`}
        // display="flex"
        // position="relative"
        sx={{
          "@media (max-width: 991px)": {
            overflow: "hidden",
            width: "visibleBoxes * boxWidthpx",
            display: "flex",
            position: "relative",
            marginLeft: "16px",
          },
        }}
      >
        <Box
          display="flex"
          transition="transform 0.5s ease-in-out" // Changed the transition to ease-in-out
          sx={{
            transform: `translateX(-${currentIndex * boxWidth}px)`,
            transitionProperty: "transform",
            transitionDuration: "0.5s",
            transitionTimingFunction: "ease-in-out", // Smooth transition
          }}
        >
          <Box
            sx={{
              "@media (min-width: 991px)": {
                maxWidth: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",

                // justifyContent: 'space-evenly',
                margin: "84px auto",
                gap: "40px",
              },

              "@media (max-width: 991px)": {
                display: "flex",
                margin: "84px auto",
                gap: "40px",
                width: "100px",
              },
            }}
          >
            {Actions.map((item, i) => (
              <Box
                key={item.id}
                sx={{
                  padding: "24px",
                  borderRadius: "12px",
                  backgroundColor: selected_chat_bg_color_dm,
                  flexGrow: 1,
                  flexBasis: "calc(25% - 40px)", // Adjust width based on container and gap
                  maxWidth: "306px", // Optional max-width for consistent card size
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "362px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={item.icon}
                    style={{ width: "40px", height: "40px" }}
                    alt={item.title}
                  />
                  {/* <p
                    style={{
                      color: "#FFFFFF",
                      margin: "auto",
                      marginLeft: "10px",
                    }}
                  >
                    {item.title}
                  </p> */}

                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "18.56px",
                      textAlign: "left",
                      marginLeft: "12px",
                      color: "#FFFFFF",
                    }}
                  >
                    {item.title}
                  </Typography>
                </div>
                <img
                  src={item.image}
                  style={{ margin: "auto", height: "188px", maxWidth: "258px" }}
                />
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "18.56px",
                    textAlign: "left",
                    // marginLeft : '12px',
                    color: "#FFFFFF",
                    opacity: "80%",
                  }}
                >
                  {item.content}
                </Typography>
                {/* <p style={{ color: "rgba(255,255,255,0.6)" }}>{item.content}</p> */}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        sx={{
          maxWidth: "112px",
          height: "48px",
          display: "flex",
          justifyContent: "space-between",
          margin: "48px auto",
        }}
      >
        <IconButton
          onClick={handlePrevClick}
          disabled={currentIndex === 0}
          disableRipple
          sx={{
            "@media (min-width: 991px)": {
              display: "none",
            },
            bgcolor: "#1F233E",
            color: "white",
            "&:hover": {
              backgroundColor: "#1B1E36",
            },
            "&:disabled": {
              bgcolor: "#1F233E",
              color: "grey",
            },
            width: "48px",
            height: "49px",
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          onClick={handleNextClick}
          disabled={currentIndex >= totalBoxes - visibleBoxes}
          disableRipple
          sx={{
            "@media (min-width: 991px)": {
              display: "none",
            },
            bgcolor: "#1F233E",
            color: "white",
            "&:hover": {
              backgroundColor: "#1B1E36",
            },
            "&:disabled": {
              bgcolor: "#1F233E",
              color: "grey",
            },
            width: "48px",
            height: "49px",
          }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    </div>
  );
};

export default ModalXInAction;

// import React from 'react';
// import { Box, Typography } from '@mui/material';
// import Icon_1 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image_1.png";
// import Icon_2 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image_2.png";
// import Icon_3 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image_3.png";
// import Icon_4 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image_4.png";
// import Icon_5 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image_5.png";
// import Icon_6 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image_6.png";
// import Icon_7 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image_7.png";
// import Icon_8 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image_8.png";
// import Image1 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image1.png";
// import Image2 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image2.png";
// import Image3 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image3.png";
// import Image4 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image4.png";
// import Image5 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image5.png";
// import Image6 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image6.png";
// import Image7 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image7.png";
// import Image8 from "../../assets/LandingPage_Items/ModalxInAction_Icons/Image8.png";
// import { selected_chat_bg_color_dm } from '../../constants/colors';

// const actions = [
//   {
//     id: '1',
//     icon: Icon_1,
//     title: 'Marketing Agencies and Freelancers',
//     content: 'Create high quality content quickly and affordably',
//     image:Image1
//   },
//   {
//     id: '2',
//     icon: Icon_2,
//     title: 'E-Commerce Businesses',
//     content: 'Create high quality content quickly and affordably',
//     image:Image2
//   },
//   {
//     id: '3',
//     icon: Icon_3,
//     title: 'Professional Service Providers',
//     content: 'Create high quality content quickly and affordably',
//     image:Image3
//   },
//   {
//     id: '4',
//     icon: Icon_4,
//     title: 'Educational Institutions and Course Creators',
//     content: 'Create high quality content quickly and affordably',
//     image:Image4
//   },
//   {
//     id: '5',
//     icon: Icon_5,
//     title: 'Nonprofits and NGOs',
//     content: 'Create high quality content quickly and affordably',
//     image:Image5
//   },
//   {
//     id: '6',
//     icon: Icon_6,
//     title: 'Healthcare Providers',
//     content: 'Create high quality content quickly and affordably',
//     image:Image6
//   },
//   {
//     id: '7',
//     icon: Icon_7,
//     title: 'Real Estate Agents and Brokers',
//     content: 'Create high quality content quickly and affordably',
//     image:Image7
//   },
//   {
//     id: '8',
//     icon: Icon_8,
//     title: 'Small Manufacturing and Product Businesses',
//     content: 'Create high quality content quickly and affordably',
//     image:Image8
//   },
// ];

// const ModalXInAction = () => {
//   return (
//     <Box sx={{ marginTop: '80px' }}>
//       <Typography
//         variant="h6"
//         sx={{ textAlign: 'center', color: '#F6BE6B' }}
//       >
//         ModalX in Action
//       </Typography>
//       <Box sx={{ maxWidth: '1072px', margin: 'auto', textAlign: 'center' }}>
//         <Typography
//           variant="h3"
//           sx={{ fontSize: '48px', marginTop: '80px' }}
//         >
//           The AI toolkit for every business need
//         </Typography>
//       </Box>
//       <Box
//         sx={{
//           maxWidth: '1324px',
//           display: 'flex',
//           flexWrap: 'wrap',
//           justifyContent: 'space-between',
//           margin: '84px auto',
//           gap: '40px',
//         }}
//       >
//         {actions.map((item) => (
//           <Box
//             key={item.id}
//             sx={{
//               padding: '32px 28px',
//               borderRadius: '12px',
//               backgroundColor: selected_chat_bg_color_dm,
//               flexGrow: 1,
//               flexBasis: 'calc(25% - 40px)',
//               maxWidth: '301px',
//               display: 'flex',
//               flexDirection: 'column',
//               justifyContent: 'space-between',
//               height: '244px',
//             }}
//           >
//             <Box
//               component="img"
//               src={item.icon}
//               alt={item.title}
//               sx={{ width: '60px', height: '60px' }}
//             />
//             <Typography
//               sx={{ color: '#FFFFFF' }}
//             >
//               {item.title}
//             </Typography>
//             <Typography
//               sx={{ color: 'rgba(255,255,255,0.6)' }}
//             >
//               {item.content}
//             </Typography>
//           </Box>
//         ))}
//       </Box>
//     </Box>
//   );
// };

// export default ModalXInAction;
