import React, { useState, useEffect } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import {
  ImageSlider_Active_Dod,
  ImageSlider_Inactive_Dod_bg,
} from "../../constants/colors";
import Image1 from "../../assets/signup/Group 174.png";
import Image2 from "../../assets/signup/Frame 142.png";

const SignupLeftSide = () => {
  const [currentIndex, setCurrentIndex] = useState(1);

  let images = [
    {
      image: Image1,
      title: "Personalized Solutions, Custom Outputs",
      disc: "Get a custom AI playbook designed for your needs, not a one-size-fits-all solution. Generate customized solutions for all your business goals.",
    },
    {
      image: Image2,
      title: "Simplified ",
      disc: "We cut through the AI clutter so you can focus on what matters - growing your business",
    },
    {
      image: Image1,
      title: "Personalized Solutions, Custom Outputs",
      disc: "Get a custom AI playbook designed for your needs, not a one-size-fits-all solution. Generate customized solutions for all your business goals.",
    },
    {
      image: Image1,
      title: "Personalized Solutions, Custom Outputs",
      disc: "Get a custom AI playbook designed for your needs, not a one-size-fits-all solution. Generate customized solutions for all your business goals.",
    },
    {
      image: Image2,
      title: "Simplified ",
      disc: "We cut through the AI clutter so you can focus on what matters - growing your business",
    },
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(intervalId);
  }, [images.length]);

  const isBelow1300px = useMediaQuery("(max-width: 1300px)")

  return (
    <>
      <Box>
        <Box sx={{ margin: "110px 40px 0px 40px" }}>
          <Box
            style={{
              maxWidth: "520px",
              height: "260px",
            }}
          >
            <img width="520px" height="260px" style={isBelow1300px ? {width:"390px", height:"195px"} : {width:"520px", height:"260px"}} src={images[currentIndex].image} alt="img" />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            maxWidth: "599px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              fontFamily: "Inter",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 600,
              letterSpacing: "-0.32px",
              lineHeight: "20px",
              color: "#FFFFFF",
              alignSelf: "center",
              whiteSpace: "nowrap",
              marginTop: "100px",
              opacity: 0.8,
            }}
          >
            {images[currentIndex].title}
          </Typography>
          <Typography
            sx={{
              display: "flex",
              maxWidth: "520px",
              justifyContent: "center",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "24px",
              letterSpacing: 0.1,
              color: "rgba(255, 255, 255,0.8)",
              alignSelf: "center",
              marginTop: "16px",
              textAlign: "center",
            }}
          >
            {images[currentIndex].disc}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" mt={2}>
        {images.map((_, index) => (
          <Box
            key={index}
            // onClick={() => goToSlide(index)}
            sx={{
              width: "10px",
              height: "10px",
              backgroundColor:
                currentIndex === index
                  ? ImageSlider_Active_Dod
                  : ImageSlider_Inactive_Dod_bg,
              borderRadius: "50%",
              margin: "0 5px",
              cursor: "pointer",
            }}
          />
        ))}
      </Box>
    </>
  );
};

export default SignupLeftSide;
