// import { Box, Button } from '@mui/material'
// import React from 'react'
// import { darkTheme_bg_color } from '../../constants/colors'
// import Checked_icon from "../../assets/LandingPage_Items/How It Works/Checked.png";
// import SearchUser_icon from "../../assets/LandingPage_Items/How It Works/Search_User.png";
// import EditUser_icon from "../../assets/LandingPage_Items/How It Works/User_Edit.png";
// import VideoImage from "../../assets/LandingPage_Items/How It Works/video output Image for How it works.png";
// import { HoveredChat_bg_color, SelectedChat_bg_color } from '../../Provider/Color_Provider';

// const HowItWorks = () => {
//   return (
//     <Box
//       sx={{
//         textAlign: 'center',
//         // background: `linear-gradient(to bottom, #1e223d, ${darkTheme_bg_color})`,
//         padding: '50px 0 150px 0', // Adjust padding as needed
//         maxWidth:'1200px',
//         margin:'auto',
//         marginBottom:'100px'
//       }}
//     >
//       <Box
//         sx={{
//           height: '182px',
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'space-between',
//           alignItems: 'center',
//           marginBottom: '100px',

//         }}
//       >
//         <p style={{color: '#F6BE6B'}}>How it Works</p>
//         <p style={{ fontSize: '48px', color: 'inherit' }}>
//             Your AI-Powered Growth Engine, Simplified
//         </p>
//         <p style={{ color: 'inherit',color:'rgba(255, 255, 255, 0.6)' }}>Discovery, Personalization, Action</p>
//       </Box>
//       <div style={{height:'341px',margin:'auto',display:'flex',justifyContent:'space-between'}}>
//         <div style={{maxWidth:'630px',height:'100%',display:'flex',flexDirection:'column',justifyContent:'space-evenly'}}>
//             <Button sx={{height:'60px',bgcolor:HoveredChat_bg_color,textTransform:'none',fontSize:'20px',padding:'16px 12px',borderRadius:'12px'}}>
//                 <img style={{width:'28px',height:'28px',marginTop:'10px'}} src={SearchUser_icon}/>
//                 <p style={{margin:'auto auto auto 10px'}}>1. ModalX understands your context and challenges</p>
//             </Button>
//             <Button sx={{height:'60px',bgcolor:SelectedChat_bg_color,textTransform:'none',fontSize:'20px',padding:'16px 12px',borderRadius:'12px'}}>
//                 <img style={{width:'28px',height:'28px',marginTop:'10px'}} src={EditUser_icon}/>
//                 <p style={{margin:'auto auto auto 10px'}}>2. ModalX suggests personalized solutions for your role</p>
//             </Button>
//             <Button sx={{height:'60px',bgcolor:SelectedChat_bg_color,textTransform:'none',fontSize:'20px',padding:'16px 12px',borderRadius:'12px'}}>
//                 <img style={{width:'28px',height:'28px',marginTop:'10px'}} src={Checked_icon}/>
//                 <p style={{margin:'auto auto auto 10px'}}>3. Generate your content, tweak it till you’re satisfied </p>
//             </Button>
//         </div>
//         <img src={VideoImage} style={{maxWidth:'480px',height:'100%'}}/>
//       </div>
//     </Box>
//   )
// }

// export default HowItWorks

import React from "react";
import { Box, Button, CardMedia, Typography } from "@mui/material";
import { darkTheme_bg_color } from "../../constants/colors";
import Checked_icon from "../../assets/LandingPage_Items/How It Works/Checked.png";
import SearchUser_icon from "../../assets/LandingPage_Items/How It Works/Search_User.png";
import EditUser_icon from "../../assets/LandingPage_Items/How It Works/User_Edit.png";
import VideoImage from "../../assets/LandingPage_Items/How It Works/video output Image for How it works.png";
import image_blow_990 from "../../assets/LandingPage_Items/How It Works/how_works_image_below_990.png";
import {
  HoveredChat_bg_color,
  SelectedChat_bg_color,
} from "../../Provider/Color_Provider";

const HowItWorks = () => {
  return (
    <Box
      sx={{
        textAlign: "center",
        // background: `linear-gradient(to bottom, #1e223d, ${darkTheme_bg_color})`,
        padding: "50px 0 150px 0", // Adjust padding as needed
        maxWidth: "1200px",
        margin: "auto",
        marginBottom: "100px",
        "@media (max-width : 991px)": {
          maxHeight: "100%",
          marginBottom: "0px",
        },
      }}
    >
      <Box
        sx={{
          // height: "182px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "100px",
          "@media (max-width : 991px)": {
            // height: "150px",
            marginBottom: "63px",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "24px",
            textAlign: "center",
            color: "#F6BE6B",
            "@media (max-width : 991px)": {
              fontSize: "12px",
            },
          }}
        >
          How it Works
        </Typography>

        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "48px",
            fontWeight: 500,
            letterSpacing: "-0.32",
            textAlign: "center",
            color: "#FFFFFF",
            opacity: "80%",
            marginTop : '48px',
            "@media (max-width : 991px)": {
              fontSize: "28px",
              marginTop : '24px',
              
              // maxWidth: "358px",
              padding : '0px 7.5% 0px 7.5%',
              
            },
          }}
        >
          Your AI-Powered Growth Engine, Simplified
        </Typography>

        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "24px",
            letterSpacing: "0.1",
            textAlign: "center",
            color: "#FFFFFF",
            opacity: "80%",
            color: "#FFFFFF",
            opacity: "80%",
            marginTop : '28px',
            "@media (max-width : 991px)": {
              fontSize: "14px",
              marginTop : '16px',
            },
          }}
        >
          Discovery, Personalization, Action
        </Typography>
      </Box>
      <Box
        sx={{
          height: "341px",
          margin: "auto",
          display: "flex",
          justifyContent: "space-between",
          "@media (max-width : 991px)": {
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            height: "100%",
          },
        }}
      >
        <Box
          sx={{
            maxWidth: "630px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            marginTop: "0px",
            "@media (max-width : 991px)": {
              width: "100%",
              padding : '0px 7.5% 0px 7.5%'

            },
          }}
        >
          <Button
            sx={{
              minHeight: "60px",
              bgcolor: HoveredChat_bg_color,
              textTransform: "none",

              padding: "16px",
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              color: "rgba(255,255,255,0.8)",
              "@media (max-width : 991px)": {
                width: "100%",
                margin: "auto",
                minHeight: "72px",

              },
            }}
          >
            <CardMedia
              component="img"
              sx={{
                width: "28px",
                height: "28px",
                "@media (max-width : 991px)": {
                  width: "17px",
                  height: "17px",
                  
                },
              }}
              image={SearchUser_icon}
              alt="Search User"
            />

            <Typography
              sx={{
                margin: "auto auto auto 10px",
                fontSize: "20px",
                "@media (max-width : 991px)": {
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "0.1px",
                  textAlign: "left",
                },
              }}
            >
              1. ModalX understands your context and challenges
            </Typography>
          </Button>

          {/* <Box
            component="img"
            src={image_blow_990}
            sx={{
              maxWidth: "480px",
              height: "100%",
              "@media (max-width : 991px)": {
                display: "block",
                margin: "auto",
                maxWidth: "338px",
                height: "240px",
                marginTop: "37px",
                marginBottom: "60px",
              },

              "@media (min-width : 991px)": {
                display: "none",
              },
            }}
            alt="image_blow_990"
          ></Box> */}

          <CardMedia
            component="img"
            image={image_blow_990}
            alt="Your Image Description"
            sx={{
              maxWidth: "100%",
              marginBottom: "10px",
              "@media (max-width : 991px)": {
                display: "block",
                margin: "auto",
                marginTop: "37px",
                marginBottom: "37px",
  
              },

              "@media (min-width : 991px)": {
                display: "none",
              },
            }}
          />

          <Button
            sx={{
              minHeight: "60px",
              bgcolor: SelectedChat_bg_color,
              textTransform: "none",
              fontSize: "20px",
              padding: "16px",
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              color: "rgba(255,255,255,0.8)",
              "@media (max-width : 991px)": {
                width: "100%",
                margin: "auto",
                minHeight: "72px",
              },
            }}
          >
            <CardMedia
              component="img"
              sx={{
                width: "28px",
                height: "28px",
                "@media (max-width : 991px)": {
                  width: "17px",
                  height: "17px",
                },
              }}
              image={EditUser_icon}
              alt="Search User"
            />
            <Typography
              sx={{
                margin: "auto auto auto 10px",
                fontSize: "20px",
                "@media (max-width : 991px)": {
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "0.1px",
                  textAlign: "left",
                },
              }}
            >
              2. ModalX suggests personalized solutions for your role
            </Typography>
          </Button>
          {/* <Box
            component="img"
            src={image_blow_990}
            sx={{
              maxWidth: "480px",
              height: "100%",
              "@media (max-width : 991px)": {
                display: "block",
                margin: "auto",
                maxWidth: "338px",
                height: "240px",
                marginTop: "37px",
                marginBottom: "60px",
              },

              "@media (min-width : 991px)": {
                display: "none",
              },
            }}
            alt="image_blow_990"
          ></Box> */}
          <CardMedia
            component="img"
            image={image_blow_990}
            alt="Your Image Description"
            sx={{
              maxWidth: "100%",
              marginBottom: "10px",
              "@media (max-width : 991px)": {
                display: "block",
                margin: "auto",
                marginTop: "37px",
                marginBottom: "37px",

              },

              "@media (min-width : 991px)": {
                display: "none",
              },
            }}
          />

          <Button
            sx={{
              minHeight: "60px",
              bgcolor: SelectedChat_bg_color,
              textTransform: "none",
              fontSize: "20px",

              padding: "16px",
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              color: "rgba(255,255,255,0.8)",
              "@media (max-width : 991px)": {
                width: "100%",
                margin: "auto",
                minHeight: "72px",
              },
            }}
          >
            <CardMedia
              component="img"
              sx={{
                width: "28px",
                height: "28px",
                "@media (max-width : 991px)": {
                  width: "17px",
                  height: "17px",
                },
              }}
              image={Checked_icon}
              alt="Search User"
            />

            <Typography
              sx={{
                margin: "auto auto auto 10px",
                fontSize: "20px",
                "@media (max-width : 991px)": {
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "0.1px",
                  textAlign: "left",
                },
              }}
            >
              3. Generate your content, tweak it till you’re satisfied
            </Typography>
          </Button>

          {/* <Box
            component="img"
            src={image_blow_990}
            sx={{
              maxWidth: "480px",
              height: "100%",
              "@media (max-width : 991px)": {
                display: "block",
                margin: "auto",
                maxWidth: "338px",
                height: "240px",
                marginTop: "37px",
                marginBottom: "60px",
              },

              "@media (min-width : 991px)": {
                display: "none",
              },
            }}
            alt="image_blow_990"
          ></Box> */}
          <CardMedia
            component="img"
            image={image_blow_990}
            alt="Your Image Description"
            sx={{
              maxWidth: "100%",
              marginBottom: "10px",
              "@media (max-width : 991px)": {
                display: "block",
                margin: "auto",
                marginTop: "37px",
                marginBottom: "37px",

              },

              "@media (min-width : 991px)": {
                display: "none",
              },
            }}
          />
        </Box>

        <Box
          component="img"
          src={VideoImage}
          sx={{
            maxWidth: "480px",
            height: "100%",
            "@media (max-width : 991px)": {
              display: "none",
            },
          }}
          alt="Video Illustration"
        />
      </Box>
    </Box>
  );
};

export default HowItWorks;
