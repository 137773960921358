import { Box, CardMedia, Typography } from "@mui/material";
import React from "react";
// import deleteIcon from '../../../assets/DeleteIcon.png'
import deleteIcon from "../../../assets/DeleteIcon.png";

function RemoveFileModal() {
  return (
    <Box
      sx={{
        position: "absolute",
        marginTop: "20px",
        marginLeft: "50px",
      }}
    >
      <Box
        sx={{
          width: "200px",
          height: "64px",
          padding: "8px",
          borderRadius: "4px",
          bgcolor: "#282D51",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "16px",
            height: "16px",
            marginLeft: "12px",
          }}
        >
          <CardMedia component="img" image={deleteIcon} alt="delete icon" />
        </Box>

        <Box
          sx={{
            width: "132px",
            height: "20px",

            marginLeft: "12px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 400,
              letterSpacing: "0.1",
              textAlign: "left",
            }}
          >
            Remove file
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default RemoveFileModal;
