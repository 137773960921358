import { useUI } from "../contexts/UIContext";
// import ModalXDarkSVG from "../assets/modalX_Dark.svg";
// import ModalXLightSVG from "../assets/modalX_Light.svg";

const GetLogo = () => {
  const { currentTheme } = useUI(); // Get the current theme from context
  const ModalXLightSVG = require("../assets/ModalX_-_Black_Gradient-removebg.png")
  const ModalXDarkSVG = require("../assets/modalx_logo_for_landingpage_dm.png")
  // Determine the SVG source based on the current theme
  const theme = localStorage.getItem('theme');
  const svgSrc = theme === "dark" ? ModalXDarkSVG : ModalXLightSVG;

  return svgSrc;
};

export default GetLogo;
