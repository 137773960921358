import React,{useState,useEffect}  from 'react'
import MessageCard from './workspace/message/MessageCard'
import { useWorkspace } from "../contexts/WorkspaceContext";
import { Button, Col, Row } from 'react-bootstrap';
import he from 'he';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import MessageActions from './workspace/message/MessageActions';
import black_logo from "../assets/modalX_robot_icon_black.png";
import white_logo from "../assets/modalX_robot_icon_white.png";


// const data = [
//   {
//       "user": "generate the summary of the attached file",
//       "agent": "It appears that the text file you have attached may not contain coherent information, making it challenging to summarize effectively. However, if you provide more context or related documents, I''d be happy to assist you better! Also, it seems you mentioned an image but it''s not visible here. Please re-upload the image if you need help with it too."
//   },
//   {
//       "user": "hii",
//       "agent": "Hello! It seems the text provided is not forming a coherent narrative or context, which makes it difficult to summarize meaningfully.\n\nHere's an abstract summary based on the provided text: \nThe text seems to contain fragmented sentences and disconnected phrases mentioning various topics such as \"local guard,\" \"American,\" \"Environment House,\" \"Isaac John,\" and a few personal references (\"my dad\").\n\nIf you need assistance with something specific related to this text or if you have additional context or another document, please let me know!"
//   },
//   {
//       "user": "hello",
//       "agent": "Hello! The text you provided contains fragments and appears disjointed, making it challenging to extract a coherent summary. Let me try to extract some key points from the given text:\n\n1. **Local Guard**: There is a mention of a local guard.\n2. **American and Names**: References to \"American, bro,\" \"Isaac John,\" and \"Jones American.\"\n3. **Locations and Contexts**: Mentions of \"Environment House\" and \"M House.\"\n4. **Personal References**: Repeated mentions of \"my dad\" and a phrase \"Hi, Rob happened. No. Y.\"\n\nOverall, based on the fragments, it seems like there are elements of conversation or notes, potentially involving multiple topics ranging from locations, personal relationships, and actions, but without a clear, coherent narrative.\n\nIf you have additional contextual information or more structured text, it would help in providing a more accurate summary."
//   },
//   {
//       "user": "hii",
//       "agent": "Hi! It appears that the provided text is some form of fragmented or disjointed string of words and phrases. Without additional context, it's challenging to extract a coherent summary. Here's an attempt at breaking down the key points from the text:\n\n1. **Entities and People:**\n   - Local guard\n   - American references\n   - Names mentioned: Jones, Isaac John, Rob, Dion in Isa\n\n2. **Locations or Institutions:**\n   - Environment House\n   - M House\n\n3. **Miscellaneous Phrases:**\n   - \"The ba and in the I\"\n   - \"The last chance minimum 50,083\"\n   - \"Playing taps on end\"\n   - Repeated phrases about not needing money again\n\nThe text includes fragmented sentences and thoughts, mixing various entities, personal references, and locations without a clear, unifying context or theme. \n\nIf you could provide more info or possibly structured text, I'd be better able to give you a coherent summary."
//   },
//   {
//       "user": "hii",
//       "agent": "Hello! The text you have provided seems to be a collection of fragmented and disjointed sentences. \n\nHere is an attempt to identify and summarize the key elements present in the text:\n\n1. **Entities:**\n   - Local Guard\n   - American\n   - Names mentioned: Jones, Isaac John, Rob, Dion\n\n2. **Locations or Buildings:**\n   - Environment House\n   - M House\n\n3. **Miscellaneous Phrases:**\n   - \"The ba and in the I\"\n   - \"The last chance minimum 50,083\"\n   - \"Playing taps on end\"\n   - Repeated phrases such as \"my dad\" and \"I don't need this again again\"\n\nFrom these elements, we can infer that the text might be referencing some people (like folks named Jones, Isaac John), buildings (like Environment House, M House), and possibly a guard. \n\nThe exact meaning behind these fragments is unclear due to the lack of coherence in the sentences. If you have any additional context or a more structured document, it would help provide a more concise summary."
//   },
//   {
//       "user": "hii",
//       "agent": "Hello! The text still appears fragmented and doesn't convey a clear message. However, I now understand that by \"image,\" you are referring to the text provided.\n\nHere’s an attempt to summarize the key elements and phrases:\n\n1. **Entities and People:**\n   - Local guard\n   - American references\n   - Names: Jones, Isaac John, Rob, Dion, and \"my dad\"\n\n2. **Locations or Institutions:**\n   - Environment House\n   - M House\n\n3. **Miscellaneous Phrases:**\n   - \"The ba and in the I\"\n   - \"The last chance minimum 50,083\"\n   - \"Playing taps on end\"\n   - Fragments about not needing money again\n\nSummary:\nThe text mentions a mix of entities like a local guard, various American references, and names such as Jones, Isaac John, Rob, and Dion. It involves locations like Environment House and M House. The content is interspersed with random phrases and seems to include personal references to \"my dad.\"\n\nGiven that the text you provided is disjointed, if more context is available, providing it would help create a more accurate summary."
//   },
//   {
//       "user": "hii",
//       "agent": "Thank you for clarifying. From what I understand, the text provided is intended to be treated as image data for processing. Even though the text is fragmented and disjointed, here’s an attempt to generate a summarized version that makes as much sense as possible:\n\n---\n\nThe document mentions several entities and locations, and includes fragmented statements:\n\n- **Entities and People:** \n   - Local guard\n   - Jones\n   - American Isaac John\n   - Rob\n   - Dion\n   - \"my dad\"\n\n- **Locations:**\n   - Environment House\n   - M House\n\n- **Miscellaneous Details:**\n   - Reference to an unspecified \"last chance minimum\" with the value 50,083\n   - Expressions like \"Hm. You on there?\" and “Playing taps on end”\n   - Statements about money and personal concerns\n\nSummary:\nThe text references various people like Isaac John and Rob, alongside institutions like Environment House and M House. There are personal remarks about someone's father and concerns about money, interspersed with random disconnected phrases.\n\n---\n\nThe content is noticeably fragmented. If you need this information in a different format such as audio or video, please let me know!"
//   },
//   {
//       "user": "hii",
//       "agent": "Thank you for the clarification. Given that the text provided is to be treated as \"image data,\" I will process it considering that context.\n\nThe text fragment seems to be a mix of phrases, entities, and conversations with no clear structure. Here’s an attempt to extract key data in a structured form:\n\n### Extracted Information:\n\n**People:**\n- **Local guard**\n- **Jones**\n- **Isaac John**\n- **Rob**\n- **Dion**\n- **\"My dad\"**\n\n**References:**\n- **Environment House**\n- **M House**\n\n**Miscellaneous Phrases:**\n- \"The ba and in the I\"\n- \"The last chance minimum 50,083\"\n- \"Hm. You on there?\"\n- \"American, bro.\"\n- \"Hey M Environment House.\"\n- \"Playing taps on end.\"\n- \"Uh, you know, I care and then up how you don't know money.\"\n- \"I don't need this again again.\"\n- \"All right, Dion in Isa.\"\n\n### Summary:\nThe text mentions various individuals such as Jones, Isaac John, Rob, Dion, and \"my dad.\" It references locations like Environment House and M House. The content includes fragmented, seemingly conversational phrases that address different topics, such as money, personal relationships, and unspecified actions or events. \n\nIf you need to convert this text into another format, like an audio file or a PowerPoint file, just let me know!"
//   },
//   {
//       "user": "hello",
//       "agent": "Hello! It sounds like you want the provided text to be treated as image data. Since it's not an actual image but text data, I'll considering this as the content to be processed as if it were an image reference.\n\n### Extracted Information:\n\n**Entities and People:**\n- **Local guard**\n- **Jones**\n- **Isaac John**\n- **Rob**\n- **Dion**\n- **\"My dad\"**\n\n**Locations/Buildings:**\n- **Environment House**\n- **M House**\n\n**Miscellaneous Phrases:**\n- \"The ba and in the I\"\n- \"The last chance minimum 50,083\"\n- \"Hm. You on there?\"\n- \"American, bro.\"\n- \"Hey M Environment House.\"\n- \"Playing taps on end.\"\n- \"Uh, you know, I care and then up how you don't know money.\"\n- \"I don't need this again again.\"\n- \"All right, Dion in Isa.\"\n\n### Summary:\nThe text appears to mention various individuals (e.g., Jones, Isaac John, Rob, Dion) and locations (e.g., Environment House, M House). It includes fragmented phrases and sentences that may imply different contexts but are not completely coherent when read together. Some statements mention personal concerns, money, and events involving these individuals. \n\nIf you would like to convert this information into another format like an audio file, a video, or PowerPoint slides, I can assist with that. Just let me know your preference!"
//   }
// ]

const data = [
  {
      "user": "generate an image of a raccoon playing basketball",
      "agent": "Here''s the image of a raccoon playing basketball: \\n\\n![Raccoon Playing Basketball](https://static.modalx.ai/output/files/9be44ff8-f8a8-4c3e-9e6d-86e6a2f00e63.jpeg)"
  }
]

const PublicLinkView = () => {
  const [threadData, setThreadData] = useState([]);
  const [fileStatus, setFileStatus] = useState(null);
  const [theme, setTheme] = useState(localStorage.getItem('theme'));
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setThreadData(data);
  }, []);

  const handleDownload = (fileUrl) => {
    // Implement download logic here
    console.log('Downloading file:', fileUrl);
  };

  const handlePreview = (fileUrl) => {
    // Implement preview logic here
    console.log('Previewing file:', fileUrl);
  };

  const findUrl = (msg) => {
    const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
    const match = urlRegex.exec(msg);
    return match ? match[1] : null;
  };


  const decodeUnicode = (str) => {
    return str.replace(/\\u[\dA-F]{4}/gi, (match) => {
      return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
    });
  };

  const decodeContent = (content) => {
    return decodeUnicode(he.decode(content));
  };

  const renderers = {
    a: ({ href, children }) => (
      <div style={{ display: 'inline-flex', alignItems: 'center', wordBreak: 'break-word' }}>
        <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>
        <i
          className="bi bi-copy"
          style={{ cursor: 'pointer', marginLeft: '20px' }}
          onClick={() => handleCopyToClipboard(href)}
          title="Copy link"
        />
      </div>
    ),
  };

  const removeUrlsAndBrackets = (msg) => {
    return msg.replace(/\(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt)\)/g, '');
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Link copied to clipboard:', text);
      })
      .catch((error) => {
        console.error('Error copying link to clipboard:', error);
      });
  };

  const getFileFormat = (url) => {
    // Extract the file extension from the URL
    const parts = url.split('.');
    if (parts.length > 1) {
      return parts[parts.length - 1]; // Return the last part as the file format
    } else {
      return ''; // Return empty string if no file extension found
    }
  };

  const handleRedirect = () => {
    window.open('https://modalx.ai/', '_blank');
  };


  return (
    <div>
      <div style={{ width: '60%', height: '100vh', margin: 'auto', padding: '30px' }}>
        <h1>Public Conversation View</h1>
        <div style={{ display: 'flex', gap: '10px', padding: '10px' }}>
          <i className="bi bi-exclamation-circle"></i>
          <p>This conversation may reflect the link creator’s personalized data, which isn’t shared and can meaningfully change how the model responds.</p>
        </div>
        <hr style={{ border: '1px solid' }}></hr>
        {threadData.map((message, index) => (
        <Row  className="pb-3">
        <Col xs={11} className="text-start" style={{ marginBottom: '20px', paddingRight: '70px' }}>
          <Row>
            <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0">
              <i className="bi bi-person fs-4" />
            </Col>
            <Col xs={11} style={{ margin: 'auto' }}>
              {message.user && typeof message.user === 'string' && message.user.split("\n").map((line, index) => (
                <div key={index} style={{ wordBreak: "break-word" }}>
                  {decodeContent(line)}
                </div>
              ))}
            </Col>
          </Row>
        </Col>
        <Row className="justify-content-end">
          <Col xs={11} className="text-end">
            <Row>
              <Col xs={11} style={{ margin: 'auto', paddingLeft: '40px', marginBottom: '40px' }} className={message.agent.length > 55 ? "text-start" : 'text-end'}>
                <Row>
                  {message.agent &&
                    typeof message.agent === 'string' &&
                    message.agent.split("\\n").map((paragraph, index) => {
                      let updatedParagraph = paragraph;
                      if (findUrl(message.agent) && findUrl(message.agent).includes('dailylimitexceeded')) {
                        // updatedParagraph = 'Your daily '+ getFileFormat(findUrl(message.agent))+' generation limit was exceeded. Consider upgrading to our premium plan for unlimited access to features.' ;
                        updatedParagraph = `Hi there,\n\nIt looks like you’ve reached your daily limit for generating `+ getFileFormat(findUrl(message.agent))+` files. We’re really sorry about that! We know how important uninterrupted access is for you.\n\nTo keep enjoying all the great benefits and have a seamless experience, we’d love for you to consider upgrading to our premium plan.\n\nThank you so much for your understanding and support. We truly value having you with us and are excited to continue providing you with the best service possible.\n\nWarm regards,\n\nThe ModalX Team
                        ` ;
                        return (
                          <div
                            key={index}
                            style={{ wordBreak: "break-word" }}
                          >
                            <ReactMarkdown children={decodeContent(updatedParagraph)} remarkPlugins={[remarkGfm]} components={renderers} />
                          </div>
                        );
                      }
                      const cleanedParagraph = findUrl(message.agent) ? removeUrlsAndBrackets(paragraph) : paragraph;
                      return (
                        <div
                          key={index}
                          style={{ wordBreak: "break-word" }}
                        >
                          <ReactMarkdown children={decodeContent(cleanedParagraph)} remarkPlugins={[remarkGfm]} components={renderers} />
                        </div>
                      );
                    })}
                </Row>
                <Row>
                  {
                    findUrl(message.agent) &&
                    <Row>
                      <MessageActions
                        message={{
                          file: { url: findUrl(message.agent), mode: 'from_history', status: fileStatus }
                        }}
                        handleDownload={handleDownload}
                        handlePreview={handlePreview}
                      />
                    </Row>
                  }
                </Row>
              </Col>
              <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0">
                <img style={{ width: '25px', height: '25px' }} src={theme === 'dark' ? black_logo : white_logo} alt="modalx_logo" />
              </Col>
            </Row>
            {message.file && (
              <Row>
                <MessageActions
                  message={message}
                  handleDownload={handleDownload}
                  handlePreview={handlePreview}
                />
              </Row>
            )}
          </Col>
        </Row>
      </Row>
        ))}
      </div>
      <button
        style={{
          ...fixedButtonStyle,
          backgroundColor: isHovered ? 'blue' : '', // Change background color based on hover state
        }}
        onMouseEnter={() => setIsHovered(true)} // Set hover state to true on mouse enter
        onMouseLeave={() => setIsHovered(false)} // Set hover state to false on mouse leave
        onClick={handleRedirect}
      >
        Continue with ModalX!
      </button>
    </div>
  );
}

const fixedButtonStyle = {
  position: 'fixed',
  bottom: '20px',
  left: '50%',
  transform: 'translateX(-50%)',
  padding: '10px 20px',
  fontSize: '16px',
  backgroundColor: '',
  color: 'white',
  border: 'blue 1px solid',
  borderRadius: '10px',
  cursor: 'pointer',
  width:'400px'
};

export default PublicLinkView;