// import { Box } from '@mui/material'
// import React from 'react'
// import Image from "../../assets/LandingPage_Items/Landingpage_Modalx_FlowDiagram.png";
// import { darkTheme_bg_color } from '../../constants/colors';

// const HowModalX_Works = () => {
//   return (
//     <div style={{textAlign:'center'}}>
//       <div style={{height:'182px',display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center',marginBottom:'100px'}}>
//         <p style={{textAlign:'center',color:'#F6BE6B'}}>How ModalX Works</p>
//         <p style={{fontSize:'48px'}}>Your AI-Powered Growth Engine, Simplified</p>
//         <p>Discovery, Personalization, Action</p>
//       </div>
//       <div style={{ height: '638px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
//         <p style={{ margin: 0 }}>Give ModalX context by uploading multiple files or links at once</p>
//         <img src={Image} alt="ModalX" style={{ maxHeight: '100%', objectFit: 'contain' }} />
//         <p style={{ margin: 0 }}>ModalX generates outputs in one click, in multiple possible formats</p>
//       </div>
      
//     </div>
//   )
// }

// export default HowModalX_Works


import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import Image from '../../assets/LandingPage_Items/Landingpage_Modalx_FlowDiagram.png';
import { darkTheme_bg_color } from '../../constants/colors';

const Capabilities = () => {
  const isBelow991 = useMediaQuery("(max-width:991px)");
  return (
    <Box
      sx={{
        textAlign: 'center',
        background: `linear-gradient(to bottom, #1e223d, ${darkTheme_bg_color})`,
        padding: '50px 0 150px 0', // Adjust padding as needed
      }}
    >
      <Box
        sx={{
          height: '182px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: isBelow991?'60px':'100px',
          
        }}
      >
        <p style={{color: '#F6BE6B',fontSize: isBelow991?'12px':"16px",}}>Capabilities</p>
        <p style={{ fontSize: isBelow991?'28px':'48px', color: 'inherit' }}>
        Multiple Outputs for Any Input
        </p>
        <p style={{ color: 'inherit',color:'rgba(255, 255, 255, 0.6)',fontSize:isBelow991?'14px':'' }}>ModalX’s Multimodal capabilities </p>
      </Box>
      <Box
        sx={{
          height: isBelow991?'343px':'638px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p style={{ margin: 0,fontSize:isBelow991?'12px':'' }}>
          Give ModalX context by uploading multiple files or links at once
        </p>
        <img
          src={Image}
          alt="ModalX"
          style={{ maxHeight: '100%', objectFit: 'contain',width:'80%' }}
        />
        <p style={{ margin: 0,fontSize:isBelow991?'12px':'' }}>
          ModalX generates outputs in one click, in multiple possible formats
        </p>
      </Box>
    </Box>
  );
};

export default Capabilities;
