import React, { useEffect, useState } from "react";
import { Col, Row, Container, Image, Button } from "react-bootstrap";
import { BrowserRouter as Router, Route, Switch, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import black_logo from "../assets/ModalX_-orange_Icon.png";
import white_logo from "../assets/modalX_robot_icon_white.png";
import MDEditor from '@uiw/react-md-editor';

const ShareProject = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [theme, setTheme] = useState(localStorage.getItem('theme'));
    const { token } = useParams();
    const navigate = useNavigate();

// Function to fetch data with dynamic token
  const fetchData = async (token) => {
    console.log(token)
    const url = `https://api.dev.modalx.ai/api/share/${token}/chat`;  // Insert token into the URL

    try {
      const response = await axios.get(url);
      if (response.status){
        setData(response.data.data.history);  
      }else{
        navigate("/")
      }
    } catch (err) {
      console.log(err.message); 
      navigate("/")
    }
  };

    useEffect(()=>{  
    setTheme(localStorage.getItem('theme'));
    if (token) {
            fetchData(token); 
    }
    },[token,])

      const findUrl = (msg) => {
        const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
        const match = urlRegex.exec(msg);
        return match ? match[1] : null;
      };

      const removeUrlsAndBrackets = (msg) => {
        return msg.replace(/\(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt)\)/g, '');
      };
    
    const decodeUnicode = (str) => {
        // const stringWithoutDoubleBackslashes = str.split("\\\")
        const stringNewLine = str.split("\\n").join("\n")
        let stringNewLineWithAnchorTag = stringNewLine.replace(/\\u[\dA-F]{4}|u[\dA-F]{4}/gi, (match) => {
          const hexCode = match.replace(/\\?u/g, ''); // Remove '\u' or 'u'
          return String.fromCharCode(parseInt(hexCode, 16)); // Convert hex code to character
        });
        const finalString = stringNewLineWithAnchorTag.replace(/\\/g, '');
        return finalString
      };
    
    // Function to decode HTML entities
    const decodeContent = (content) => {
      return decodeUnicode((content));
    };

    const getFileFormat = (url) => {
        // Extract the file extension from the URL
        const parts = url.split('.');
        if (parts.length > 1) {
          return parts[parts.length - 1]; // Return the last part as the file format
        } else {
          return ''; // Return empty string if no file extension found
        }
      };

    function renderResponse(response){  
          return <div style={{ wordBreak: "break-word",  }}>
            <MDEditor.Markdown source={decodeUnicode(response)} style={{ backgroundColor:"#171A2D", color:"white"}}  className="custom-md-editor"/>
          </div> 
    }

  return (
    <> 
   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
   <div style={{ maxWidth: "90%", padding: "50px 100px" }}>
    {
      
        data && data.map((message,index)=>{
        return (
          <Row className="pb-3" style={{ alignItems:"center"}}>
          <Col xs={12} className="text-end" style={{ marginBottom: '20px', paddingRight: '30px',paddingLeft:'30px',backgroundColor : ''}}>
            <Row style={{ backgroundColor : ''}}>

              <Col  style={{ margin: 'auto' , backgroundColor : '',display : 'flex', flexDirection:"column",marginRight : '20px',
                  justifyContent : 'end'}}>
                {message.user && typeof message.user === 'string' && message.user.split("\n").map((line, index) => (
                  <div key={index} style={{ wordBreak: "break-word", textAlign : 'start', marginRight : '-13px' 
                    ,backgroundColor : '', maxWidth : '95%'}}>
                    {decodeContent(line)}
                  </div>
                ))}
              </Col>
              <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-1" style={{backgroundColor:'#1F233E',borderRadius:'50%',width:'48px',height:'48px'}}>
                <i className="bi bi-person fs-4" style={{margin:'auto'}}/>
              </Col>
            </Row>
          </Col>
          <Row className="justify-content-end" style={{backgroundColor : ''}}>

            <Col xs={11} className="text-end" style={{ marginBottom: '40px',backgroundColor : ''}}>
              <Row style={{ marginLeft : '-86px',backgroundColor : ''}}>

                  <Col style={{width: '48px', height: '48px', backgroundColor : '#1F233E', marginRight : '', marginLeft : '7px', borderRadius : '24px'}} xs={1} >
                    <img  style={{ width: '24px', height: '24px' , marginTop : '12px'}} src={theme === 'dark' ? black_logo : white_logo} alt="modalx_logo" />
                  </Col>
                <Col xs={11} style={{ margin: 'auto',paddingTop:'10px',
                    backgroundColor : '', marginLeft : '10px', width : '90%'}} className={message.agent.length > 55 ? "text-start" : 'text-start'}>
                  <Row style={{}}>
                    {message.agent &&
                      typeof message.agent === 'string' && renderResponse(message.agent)}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
        )       
        })
    }
    </div>
    </div>
    </>
  )
}

export default ShareProject