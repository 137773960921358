import React, { useState, useEffect } from "react";
import { Box, Button, FormControl, FormHelperText, TextField, Typography, useMediaQuery } from "@mui/material";
import  CustomizedSnackbars  from "./CustomizedSnackbars";
import { New_Project_Button_bg_color } from "../../Provider/Color_Provider";
import axios from "axios";
import sourceUrl from "../../api/Source.api";
import { disposableEmailProviders } from "../../constants/disposableEmailDomains";
import { useAuth } from "../../contexts/AuthContext";

const ResetPassword = ({
  showLoginModal,
  setShowLoginModal,
  setShowSignUpModal,
  setEmail,
  email,
  setMaskedPassword
}) => {
 
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [notifMsg, setNotifMsg] = useState("");
  const [open, setOpen] = React.useState(false);
  const [showNotif, setShowNotif] = useState(false);
  const [notifVariant, setNotifVariant] = useState("");
  const isBelow991 = useMediaQuery("(max-width:991px)");
  const {showResetPasswordModal,setShowResetPasswordModal,showResendModal, setShowResendModal} =useAuth()

  const customTextFieldStylesForInvalid = (isInvalid) => {
    return ( {"& .MuiInputLabel-root": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.5px",
      color: isInvalid ? "#F2B8B5" : "rgba(255, 255, 255, 0.80)",
      "&.Mui-focused": {
        color: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80)",
      },
    },
    "& .MuiInputBase-input": {
      color: "#FFFFFF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: isInvalid ? "#F2B8B5" : "rgba(255, 255, 255, 0.80)",
      },
      "&:hover fieldset": {
        borderColor: isInvalid ? "#F2B8B5" : "rgba(255, 255, 255, 0.80)",
      },
      "&.Mui-focused fieldset": {
        borderColor: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80) ",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiInputLabel-root": {
      color: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80) ",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80) ",}
    })
    }

  const handleOpenSignUpModal = () => {
    setShowSignUpModal(true);
    setShowResetPasswordModal(false);
  };

  const handleOpenLoginModal = () => {
    setEmail("");
    setMaskedPassword("")
    setShowLoginModal(true);
    setShowResetPasswordModal(false);
  };

  const handleEmailSubmit = async (e) => {
    if (isDisposableEmail(email)) {
      setNotifMsg(
        "We do not accept temporary or disposable email addresses. Please use a permanent email."
      );
      setNotifVariant("error");
      setShowNotif(true);
      setOpen(true);
      return;
    }
    if (email === "") {
      setNotifVariant("error");
      setShowNotif(true);
      setOpen(true);
      setNotifMsg("Please enter your email")
      return;
    } else {
        try{
          const response = await axios.post(
            `${sourceUrl}/reset-password`,
            { email:email },
          );
          if(response.data.status === false) {
            setNotifVariant("error");
            setShowNotif(true);
            setOpen(true);
            setNotifMsg(response.data.message)
            throw new Error(response.data.message)
          }else if(response.data.status === true){
            setNotifVariant("success");
            setShowNotif(true);
            setOpen(true);
            setNotifMsg(response.data.message)
            setShowResetPasswordModal(false);
            setShowResendModal(true);
          }
        }catch(e){
          console.log(e)
          setNotifVariant("error");
          setShowNotif(true);
          setOpen(true);
          setNotifMsg(e.response.data.message)
        }
    
    }
  };

  const handleCloseResetPasswordModal = () => {
    setShowResetPasswordModal(false);
  };

  const isDisposableEmail = (email) => {
    const emailDomain = email.split("@")[1];
    return disposableEmailProviders.includes(emailDomain);
  };

  const handleEmailInputChange = (e) =>{
    const newEmail = e.target.value;
    setEmail(newEmail);
        
        if (isDisposableEmail(newEmail)) {
          setIsInvalidEmail(true);
        } else {
          setIsInvalidEmail(false);
        }
  }

  return (<>
    {showResetPasswordModal && (
      <div
        style={{
          position: "relative",
          zIndex: 70,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: isBelow991 ? "" : "100vw",
          height: isBelow991 ? "" : "100vh",
          left: "0%",
          top: "0%",
        }}
      >
    <div
      onClick={handleCloseResetPasswordModal}
      style={{
        width: "100%",
        height: isBelow991 ? "" : "100%",
        position: isBelow991 ? "static" : "absolute",
        backgroundColor: "rgba(23,26,45,0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        onClick={(event) => event.stopPropagation()}
        sx={{
          backgroundColor:  isBelow991 ? "#171A2D" : "rgba(40, 45, 81, 1)",
          borderRadius: "8px",
          border: isBelow991 ? "" :"1px solid rgba(202, 196, 208, 1)",
          alignItems:"center",
          maxWidth: isBelow991 ? "342px" : "600px",
          padding: isBelow991 ? "" : "60px 150px 40px 150px",
          marginTop:isBelow991 ? "120px" : "",
        }}
      >
        <Box
          sx={{
            width: "100%",
            margin: "0px 0px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "20px",
              alignSelf: "stretch",
              textAlign: "center",
              margin: "0px 0px",
            }}
          >
            Reset your password
          </Typography>
        </Box>

        <Box
          sx={{
            marginTop: "24px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "20px",
              textAlign: "center",
            }}
          >
            Enter your registered email address, and we’ll send you a link to
            reset your password
          </Typography>
        </Box>

        <Box
          sx={{
            marginTop: "48px",
            width: "100%",
          }}
        >
          <FormControl
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              type="email"
              onChange={handleEmailInputChange}
              value={email}
              id="outlined-basic"
              label="Email address*"
              variant="outlined"
              sx={()=>customTextFieldStylesForInvalid(isInvalidEmail)}
              style={{
                width: "100%",
                height: "56px",
                color: "#FFFFFF",
                backgroundColor: "#232746",
                borderRadius: "8px",
                boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                placeholderfontsize: "16px",
              }}
            />
             
              {
                isInvalidEmail ? 
                <FormHelperText
                sx={{
                  color: isInvalidEmail ? "#F2B8B5" : "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "16px",
                  alignSelf: "start",
                  
                  margin: {
                      md:  "4px 0px 14px 0px",
                      lg:  "4px 16px 14px 0px",
                      xl:  "4px 16px 14px 0px",
                    },
                }}
              >
                {isInvalidEmail ? "Email address is invalid" : ""}
              </FormHelperText>
               : <div></div>
              }
          </FormControl>
        </Box>

        <Box
          sx={ isInvalidEmail ? {
            marginTop: "2px",
            width: "100%",
          } : { marginTop: "36px",
                 width: "100%",}}
        >
          <Button
            onClick={handleEmailSubmit}
            variant="contained"
            sx={{
              bgcolor: New_Project_Button_bg_color,
              color: "rgba(23, 24, 44, 1)",
              padding: "10px 24px 10px 24px",
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "20px",
              letterSpacing: "0.1px",
              textAlign: "center",
              width: "100%",
              "&:hover": {
                bgcolor: "#ffd9aa",
              },
            }}
          >
            Continue
          </Button>
        </Box>

        <Box
          onClick={handleOpenLoginModal}
          sx={{
            width: "100%",
            marginTop: "24px",
            cursor: "pointer",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "20px",
              alignSelf: "stretch",
              textAlign: "center",
            }}
          >
            Back to Login
          </Typography>
        </Box>

        <Box
          sx={{
            width: "100%",
            marginTop: "60px",
          }}
        >
          <Typography
            sx={{
              color:
                "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
              fontFamily: "Inter",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "20px",
              whiteSpace: "nowrap",
              margin: "4px 16px 14px 16px",
              textAlign: "center",
            }}
          >
            Don’t have an account yet?{" "}
            <span
              onClick={handleOpenSignUpModal}
              style={{
                textDecorationLine: "underline",
                cursor: "pointer",
              }}
            >
              Sign up
            </span>
          </Typography>
        </Box>
      </Box>

      <CustomizedSnackbars
        open={open}
        setOpen={setOpen}
        notifMsg={notifMsg}
        notifVariant={notifVariant}
        setShowNotif={setShowNotif}
      />
    </div>
    </div>)}
    </>
  );
};

export default ResetPassword;
