import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

const ShareModal = ({ show, handleClose }) => {
  const theme = localStorage.getItem('theme');
  const [link, setLink] = useState('');
  const publicLink = 'https://www.modalx/public_link/thread_122';
  const [copied, setCopied] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false); // State to toggle more options

  useEffect(() => {
    setLink(publicLink)
  }, [])

  const copyToClipboard = () => {
    navigator.clipboard.writeText(publicLink)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Hide the popup after 2 seconds
      })
      .catch(err => {
        console.error('Error copying text to clipboard', err);
      });
  };

  const toggleMoreOptions = () => {
    setShowMoreOptions(!showMoreOptions);
  };

  const shareLinkedIn = () => {
    const publicLink = 'https://www.modalx/public_link/thread_122'; // Update with your actual link
    const encodedLink = encodeURIComponent(publicLink);
    const title = encodeURIComponent('Check out this thread on ModalX');
    const summary = encodeURIComponent('Discussing important topics on ModalX platform. Join the conversation!');
    window.open(`https://www.linkedin.com/feed/?linkOrigin=LI_BADGE&shareActive=true&shareUrl=${encodedLink}&title=${title}&summary=${summary}`);
  };
  
  const shareSlack = () => {
    window.open(`https://slack.com/intl/en-in/`);
  };

  const shareTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(publicLink)}`);
  };
  
  const shareFacebook = () => {
    const encodedLink = encodeURIComponent(publicLink);
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`);
  };
  

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Share public link to chat</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Your name, custom instructions, and any messages you add after sharing stay private. <a style={{ textDecoration: 'underline' }} href="#">Learn more</a></p>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{
            width: '90%',
            height: '40px',
            backgroundColor: theme === 'dark' ? '#1a1a1a' : '#dee2e6',
            borderRadius: '15px',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '20px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}>
            {link}
          </div>
          <div style={{ position: 'relative' }}>
            <button
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                position: 'relative',
                color: 'blue',
                transform: hovered ? 'scale(1.1)' : 'scale(1)',
                transition: 'transform 0.2s ease-in-out'
              }}
              onClick={copyToClipboard}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              <i className="bi bi-copy"></i>
            </button>
            {copied && (
              <div style={{
                position: 'absolute',
                top: '-30px',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: 'black',
                color: 'white',
                padding: '5px 10px',
                borderRadius: '5px',
                whiteSpace: 'nowrap',
                zIndex: 10
              }}>
                Copied to clipboard!
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button style={{ borderRadius: '50%', border: 'none' }} variant="outline-primary" onClick={shareLinkedIn}>
          <i className="bi bi-linkedin"></i>
        </Button>
        <Button style={{ borderRadius: '50%', border: 'none' }} variant="outline-primary" onClick={shareSlack}>
          <i className="bi bi-slack"></i>
        </Button>
        <Button style={{ borderRadius: '50%', border: 'none' }} variant="outline-primary" onClick={shareTwitter}>
          <i className="bi bi-twitter-x"></i>
        </Button>
        {!showMoreOptions && 
        <Button
          style={{ borderRadius: '50%', border: 'none', marginLeft: '10px' }}
          variant="outline-primary"
          onClick={toggleMoreOptions}
        >
          <i className="bi bi-three-dots"></i>
        </Button>}
        {showMoreOptions && (
          <>
            <Button style={{ borderRadius: '50%', border: 'none', marginLeft: '10px' }} variant="outline-primary">
              <i className="bi bi-tiktok"></i>
            </Button>
            <Button style={{ borderRadius: '50%', border: 'none', marginLeft: '10px' }} variant="outline-primary">
              <i className="bi bi-instagram"></i>
            </Button>
            <Button style={{ borderRadius: '50%', border: 'none', marginLeft: '10px' }} variant="outline-primary" onClick={shareFacebook}>
              <i className="bi bi-facebook"></i>
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ShareModal;
