// import React, { useEffect, useState } from 'react'
// import { useWorkspace } from "../../../contexts/WorkspaceContext";
// import axios from "axios";
// import { ListGroup } from 'react-bootstrap';

// const ViewThreadFiles = () => {
//     const {threadId} = useWorkspace()
//     const id = threadId ;
//     const token = localStorage.getItem('token');
//     const [files,setFiles] = useState([]);
//     const fetchHistoryFiles = async() => {
//         try {
//             // const res = await axios.get(`https://modalx.ai/threads/${id}/listFile`,{
//             //     headers:{
//             //         Authorization:`Bearer ${token}`
//             //     }
//             // });
//             const res = await axios.get(`https://modalx.ai/chat/${id}/history`, {
//               headers: {
//                 Authorization: `Bearer ${token}`
//               }
//             });
//             setFiles(res.data.data.input_links);
//             // console.log("Inside_thread_files",res.data.data.input_links);
//         } catch (error) {
//             console.log(error);
//         }
//     }
//     console.log("Inside_thread_files",files);
//     // console.log("Inside_thread_files",id);
//     useEffect(() => {
//         fetchHistoryFiles()
    
//     }, [id])
    
//   return (
//     <div>
//     <p style={{fontSize:'.95rem',textDecoration:'underline'}}>Uploaded Files & Links</p>
//     <ul style={{ listStyleType: 'disc', paddingInlineStart: '1em' }}>
//         {files.map((file, index) => (
//             <li key={index} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={file}>
//                 {file.length > 20 ? `${file.substring(0, 20)}...` : file}
//             </li>
//         ))}
//     </ul>
// </div>
//   )
// }

// export default ViewThreadFiles


import React, { useEffect, useState,useRef } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import sourceUrl from "../../../api/Source.api";
import Youtube_Icon from "../../../assets/File_Icon/youtube_icon.png";
import Xlsx_Icon from "../../../assets/File_Icon/xlsx_icon.png";
import Pdf_Icon from "../../../assets/File_Icon/pdf_icon.png";
import {  IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReplayIcon from '@mui/icons-material/Replay';
import RemoveFileModal from './RemoveFileModal';
import progressIndicator from '../../../assets/progressIndicator.png'
import pngIcon from "../../../assets/File_Icon/pngIcon.png";
import movIcon from "../../../assets/File_Icon/movIcon.png"
import mp4Icon from "../../../assets/File_Icon/mp4Icon.png"
import word_logo from "../../../assets/File_Icon/word_logo.png"
import mp3_logo from "../../../assets/File_Icon/mp3_logo.png"
import powerpoint_logo from "../../../assets/File_Icon/powerpoint_logo.png"
import jpgIcon from "../../../assets/File_Icon/jpgIcon.png"
import CompleteIcon from '@mui/icons-material/CheckCircle';
import completeIcon from '../../../assets/greencheckIcon.png'
import CircularProgress from './ProcessingIndicator/CircularProgress';





const ViewThreadFiles = () => {
    const { threadId, historyFiles, threadFiles, setThreadFiles, InvokeMatrixApi, uploadFiles, setWaitThread,upload_FilesOnly } = useWorkspace();
    const [progressStatusChecking, setProgressStatusChecking] = useState({});
    const token = localStorage.getItem('token');
    const [filesLen, setFilesLen] = useState(0);
    const [toggleRemoveFile, setToggleRemoveFile] = useState(false)
    const [clickIndex , setClickIndex] = useState(null)
      const [completedFiles, setCompletedFiles] = useState({});
    const [showCompleteIcon, setShowCompleteIcon] = useState({});

    const id = threadId && parseInt(threadId.replace("thread-", ""), 10);

    // Ensure both historyFiles and threadFiles are arrays
    const files = [...(threadFiles ? [...threadFiles].reverse() : []), ...(historyFiles || [])];

    const renderIcons = () => {
        
    }

    // useEffect(() => {
    //     const intervals = files.map((file) => {
    //         if (file.status === "uploading" || file.status === "processing") {
    //             setProgressStatusChecking((prev) => ({ ...prev, [file.url]: true }));

    //             const interval = setInterval(async () => {
    //                 try {
    //                     const status_resp = await fetch(`${sourceUrl}/status`, {
    //                         method: "POST",
    //                         headers: {
    //                             "Content-Type": "application/json",
    //                             "Authorization": `Bearer ${token}`
    //                         },
    //                         body: JSON.stringify({ threadId, url: file.url }),
    //                     });
    //                     const responseData = await status_resp.json();

    //                     if (status_resp.status === 200 && responseData.data.status === "completed") {
    //                         InvokeMatrixApi(responseData, status_resp, 'POST', 'status', { threadId, url: file.url });

    //                         setThreadFiles((prevThreadFiles) =>
    //                             prevThreadFiles.map((prevFile) =>
    //                                 prevFile.name === file.name
    //                                     ? { ...prevFile, status: "completed" }
    //                                     : prevFile
    //                             )
    //                         );

    //                         setProgressStatusChecking((prev) => ({ ...prev, [file.url]: false }));
    //                         clearInterval(interval);
    //                     } else if (status_resp.status === 200 && responseData.data.status === "failed") {
    //                         setThreadFiles((prevThreadFiles) =>
    //                             prevThreadFiles.map((prevFile) =>
    //                                 prevFile.name === file.name
    //                                     ? { ...prevFile, status: "failed" }
    //                                     : prevFile
    //                             )
    //                         );

    //                         setProgressStatusChecking((prev) => ({ ...prev, [file.url]: false }));
    //                         clearInterval(interval);
    //                     } else if (status_resp.status === 500) {
    //                         setProgressStatusChecking((prev) => ({ ...prev, [file.url]: false }));
    //                         clearInterval(interval);
    //                     }
    //                 } catch (error) {
    //                     console.error("Error checking file status:", error);
    //                     setProgressStatusChecking((prev) => ({ ...prev, [file.url]: false }));
    //                     clearInterval(interval);
    //                 }
    //             }, 3000);

    //             return interval;
    //         }

    //         return null;
    //     });

    //     // Cleanup intervals on unmount
    //     return () => {
    //         intervals.forEach((interval) => {
    //             if (interval) clearInterval(interval);
    //         });
    //     };
    // }, [files, threadId, token, setThreadFiles, InvokeMatrixApi]);
 
    useEffect(() => {
        const intervals = files.map((file) => {
            if (file.status === "uploading" || file.status === "processing") {
                const interval = setInterval(async () => {
                    try {
                        // Validate that threadId and file.url are not undefined or null
                        if (!threadId || !file.url) {
                            console.error("Invalid data: threadId or file URL is missing.");
                            clearInterval(interval); // Stop if the data is invalid
                            return;
                        }
    
                        // Log the request body for debugging
                        const requestBody = JSON.stringify({ threadId, url: file.url });
                        console.log("Request Body:", requestBody);
    
                        // Fetch request to check file status
                        const status_resp = await fetch(`${sourceUrl}/status`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${token}`
                            },
                            body: requestBody,
                        });
    
                        // Handle response
                        if (!status_resp.ok) {
                            console.error(`Failed with status: ${status_resp.status}`);
                            return;
                        }
    
                        const responseData = await status_resp.json();
                        console.log("Response Data:", responseData);
    
                        // Handle different statuses
                        if (responseData.data.status === "completed") {
                            setShowCompleteIcon((prev) => ({ ...prev, [file.url]: true })); // Show complete icon
                            setTimeout(() => {
                                setShowCompleteIcon((prev) => ({ ...prev, [file.url]: false })); // Hide after 2 seconds
                            }, 2000);
    
                            setThreadFiles((prevThreadFiles) =>
                                prevThreadFiles.map((prevFile) =>
                                    prevFile.name === file.name
                                        ? { ...prevFile, status: "completed" }
                                        : prevFile
                                )
                            );
                            clearInterval(interval); // Clear interval after completion
                        } else if (responseData.data.status === "failed") {
                            setThreadFiles((prevThreadFiles) =>
                                prevThreadFiles.map((prevFile) =>
                                    prevFile.name === file.name
                                        ? { ...prevFile, status: "failed" }
                                        : prevFile
                                )
                            );
                            clearInterval(interval); // Clear interval after failure
                        } else {
                            console.error(`Unexpected status: ${responseData.data.status}`);
                        }
                    } catch (error) {
                        console.error("Error checking file status:", error);
                    }
                }, 3000);
    
                return interval;
            }
    
            return null;
        });
    
        // Clear all intervals on cleanup
        return () => {
            intervals.forEach((interval) => {
                if (interval) clearInterval(interval);
            });
        };
    }, [files, threadId, token, setThreadFiles]);
    
    


    useEffect(() => {
        setFilesLen(files.length);
    }, [files]);

    const getFileIcon = (fileName) => {
        if (fileName.includes("youtube")) return Youtube_Icon;
        if (fileName.includes("mp4")) return mp4Icon;
        if (fileName.includes("mov")) return movIcon;
        if (fileName.includes("doc") || fileName.includes("docx"))  return word_logo;    
        if (fileName.includes("pptx") || fileName.includes("pptx") || fileName.includes("pptm"))  return powerpoint_logo;      
        if (fileName.includes("mp3")) return mp3_logo;
        // if (fileName.includes("png")) return pngIcon;
        // if (fileName.includes("jpg")) return jpgIcon;
        if (fileName.endsWith(".pdf")) return Pdf_Icon;
        if (fileName.endsWith(".xlsx") || fileName.endsWith(".xls")) return Xlsx_Icon;
        return null;
    };

    // const handleRetryFile = async (file) => {
    //     // Update only the specific file being retried
    //     setThreadFiles(prevThreadFiles =>
    //         prevThreadFiles.map(prevFile =>
    //             prevFile.link === file.link
    //                 ? { ...prevFile, status: "uploading" }
    //                 : prevFile
    //         )
    //     );
        
    //     // Mark the specific file's progress as true
    //     setProgressStatusChecking(prev => ({ ...prev, [file.url]: true }));
    
    //     setWaitThread(true);
    
    //     try {
    //         const response = await uploadFiles(file);
    
    //         if (response && response.data) {
    //             const { id: fileId, url, status } = response.data;
    //             setThreadFiles(prevThreadFiles =>
    //                 prevThreadFiles.map(prevFile =>
    //                     prevFile.link === file.link
    //                         ? { ...prevFile, status, fileId, url }
    //                         : prevFile
    //                 )
    //             );
    
    //             setProgressStatusChecking(prev => ({ ...prev, [url]: true }));
    
    //             const interval = setInterval(async () => {
    //                 try {
    //                     const status_resp = await fetch(`${sourceUrl}/status`, {
    //                         method: "POST",
    //                         headers: {
    //                             "Content-Type": "application/json",
    //                             "Authorization": `Bearer ${token}`,
    //                         },
    //                         body: JSON.stringify({ url }),
    //                     });
    
    //                     const responseData = await status_resp.json();
    
    //                     if (status_resp.ok && responseData.data) {
    //                         const { status } = responseData.data;
    //                         setThreadFiles(prevThreadFiles =>
    //                             prevThreadFiles.map(prevFile =>
    //                                 prevFile.link === file.link
    //                                     ? { ...prevFile, status, fileId, url }
    //                                     : prevFile
    //                             )
    //                         );
    
    //                         if (status === "completed" || status === "failed") {
    //                             setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
    //                             clearInterval(interval);
    //                         }
    //                     } else {
    //                         setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
    //                         clearInterval(interval);
    //                     }
    //                 } catch (error) {
    //                     console.error('Error fetching status:', error);
    //                     setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
    //                     clearInterval(interval);
    //                 }
    //             }, 3000);
    //         } else {
    //             console.error('Invalid response from uploadFiles:', response);
    //             setThreadFiles(prevThreadFiles =>
    //                 prevThreadFiles.map(prevFile =>
    //                     prevFile.link === file.link
    //                         ? { ...prevFile, status: "failed", fileId: "", url: file.link }
    //                         : prevFile
    //                 )
    //             );
    //         }
    //     } catch (error) {
    //         console.error('Error during file upload:', error);
    //         setThreadFiles(prevThreadFiles =>
    //             prevThreadFiles.map(prevFile =>
    //                 prevFile.link === file.link
    //                     ? { ...prevFile, status: "failed", fileId: "", url: file.link }
    //                     : prevFile
    //             )
    //         );
    //     }
    
    //     setWaitThread(false);
    // };


    const handleRetryFile = async (file) => {
        // Mark the specific file as "uploading"
        setThreadFiles(prevThreadFiles =>
            prevThreadFiles.map(prevFile =>
                prevFile.fileId === file.fileId
                    ? { ...prevFile, status: "uploading" }
                    : prevFile
            )
        );
        
        setProgressStatusChecking(prev => ({ ...prev, [file.url]: true }));
        setWaitThread(true);
    
        try {
            const response = await upload_FilesOnly(file);
            if (response && response.data) {
                const { id: newFileId, url, status } = response.data;
                setThreadFiles(prevThreadFiles =>
                    prevThreadFiles.map(prevFile =>
                        prevFile.fileId === file.fileId
                            ? { ...prevFile, status, fileId: newFileId, url }
                            : prevFile
                    )
                );
    
                const interval = setInterval(async () => {
                    try {
                        const status_resp = await fetch(`${sourceUrl}/status`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${token}`,
                            },
                            body: JSON.stringify({ url }),
                        });
    
                        const responseData = await status_resp.json();
    
                        if (status_resp.ok && responseData.data) {
                            const { status } = responseData.data;
                            setThreadFiles(prevThreadFiles =>
                                prevThreadFiles.map(prevFile =>
                                    prevFile.fileId === newFileId
                                        ? { ...prevFile, status, url }
                                        : prevFile
                                )
                            );
    
                            if (status === "completed" || status === "failed") {
                                setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
                                clearInterval(interval);
                            }
                        } else {
                            setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
                            clearInterval(interval);
                        }
                    } catch (error) {
                        console.error('Error fetching status:', error);
                        setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
                        clearInterval(interval);
                    }
                }, 3000);
            } else {
                console.error('Invalid response from uploadFiles:', response);
                setThreadFiles(prevThreadFiles =>
                    prevThreadFiles.map(prevFile =>
                        prevFile.fileId === file.fileId
                            ? { ...prevFile, status: "failed" }
                            : prevFile
                    )
                );
            }
        } catch (error) {
            console.error('Error during file upload:', error);
            setThreadFiles(prevThreadFiles =>
                prevThreadFiles.map(prevFile =>
                    prevFile.fileId === file.fileId
                        ? { ...prevFile, status: "failed" }
                        : prevFile
                )
            );
        }
    
        setWaitThread(false);
    };

    const toggleRemoveFileFun =(index) =>{
        setToggleRemoveFile(!toggleRemoveFile)
        setClickIndex(index)
        
     

    }
    console.log(toggleRemoveFile  ,'rammmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm')
    const handleClickOutside = () => {
            setToggleRemoveFile(false); // Close the dropdown if clicked outside
 
        
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
    

    return (
        <div>
            <p style={{ fontSize: '14px', paddingLeft : '15px'}}>Your Files ({filesLen})</p>
            {files.length ? (
                <ul style={{ paddingInlineStart: '1em',backgroundColor : '#1F233E' ,padding : '8px 16px 8px 16px'}}>
                    {files.map((file, index) => (
                        <li
                            key={index}
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                listStyleType: 'disc',
                                textAlign: 'start',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '16px',
                                color: file.status === 'failed' ? 'red' : '',
                                padding : '5px 0px 5px 0px'
                            }}
                            title={file.name}
                        >
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '280px'}}>
                                <div>
                                    <img
                                        src={getFileIcon(file.name)}
                                        alt=""
                                        style={{ marginRight: '8px', width: '20px', height: '20px' }}
                                    />
                                    {file.name.length > 14 ? `${file.name.substring(0, 14)}...` : file.name}
                                </div>
                                {/* <div  onClick={() => toggleRemoveFileFun(index)} style={{}}>
                                    {file.status === "uploading" || file.status === "processing" ? (
                                        
                                        <CircularProgress  size={20} style={{ marginLeft: '8px' , color : 'white'}} />
                                    ) : file.status === "failed" ? (
                                        <IconButton onClick={() => handleRetryFile(file)} style={{height:'10px',color:'red',marginLeft:'25px'}}>
                                            <ReplayIcon />
                                        </IconButton>
                                    ) : (
                                        <MoreHorizIcon />
                                    )}
                                </div> */}
                                <div onClick={() => toggleRemoveFileFun(index)}>
                                    {file.status === "uploading" || file.status === "processing" ? (
                                        // <CircularProgress size={20} style={{ marginLeft: '8px', color: 'white' }} />
                                        <CircularProgress/>
                                    
                                    ) : file.status === "failed" ? (
                                        <IconButton onClick={() => handleRetryFile(file)} style={{ height: '10px', color: 'red', marginLeft: '25px' }}>
                                            <ReplayIcon />
                                        </IconButton>
                                    ) : showCompleteIcon[file.url] ? (
                                        // Show the complete icon if the file is completed
                                        // <CompleteIcon style={{ marginLeft: '8px', color: 'green' }} />
                                        <img src={completeIcon} alt=""  width='20px'/>
                                    ) : (
                                        <MoreHorizIcon />
                                    )}
                                </div>
                                
                                {toggleRemoveFile ? clickIndex === index && <RemoveFileModal/> : ''}

                                
                            </div>
                        </li>
                    ))}
                </ul>
            ) : (
                <></>
            )}
        </div>
    );
};

export default ViewThreadFiles;






//=========================== Below one is the updated code on till 29-07-2024 (including handleRetry)===========================================





// import React, { useEffect, useState } from 'react';
// import { ProgressBar } from 'react-bootstrap';
// import { useWorkspace } from "../../../contexts/WorkspaceContext";
// import sourceUrl from "../../../api/Source.api";
// import Youtube_Icon from "../../../assets/File_Icon/youtube_icon.png";
// import Xlsx_Icon from "../../../assets/File_Icon/xlsx_icon.png";
// import Pdf_Icon from "../../../assets/File_Icon/pdf_icon.png";
// import { CircularProgress } from '@mui/material';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


// const ViewThreadFiles = () => {
//     const { threadId, historyFiles, threadFiles, setThreadFiles, InvokeMatrixApi } = useWorkspace();
//     const [progressStatusChecking, setProgressStatusChecking] = useState({});
//     const token = localStorage.getItem('token');
//     const [filesLen, setFilesLen] = useState(0);

//     const id = threadId && parseInt(threadId.replace("thread-", ""), 10);

    
    

//     // Ensure both historyFiles and threadFiles are arrays
//     const files = [...(threadFiles ? [...threadFiles].reverse() : []), ...(historyFiles || [])];

//     useEffect(() => {
//         const intervals = files.map((file) => {
//             if (file.status === "uploading" || file.status === "processing") {
//                 setProgressStatusChecking((prev) => ({ ...prev, [file.url]: true }));

//                 const interval = setInterval(async () => {
//                     try {
//                         const status_resp = await fetch(`${sourceUrl}/status`, {
//                             method: "POST",
//                             headers: {
//                                 "Content-Type": "application/json",
//                                 "Authorization": `Bearer ${token}`
//                             },
//                             body: JSON.stringify({ threadId, url: file.url }),
//                         });
//                         const responseData = await status_resp.json();

//                         if (status_resp.status === 200 && responseData.data.status === "completed") {
//                             InvokeMatrixApi(responseData, status_resp, 'POST', 'status', { threadId, url: file.url });

//                             setThreadFiles((prevThreadFiles) =>
//                                 prevThreadFiles.map((prevFile) =>
//                                     prevFile.name === file.name
//                                         ? { ...prevFile, status: "completed" }
//                                         : prevFile
//                                 )
//                             );

//                             setProgressStatusChecking((prev) => ({ ...prev, [file.url]: false }));
//                             clearInterval(interval);
//                         } else if (status_resp.status === 200 && responseData.data.status === "failed") {
//                             setThreadFiles((prevThreadFiles) =>
//                                 prevThreadFiles.map((prevFile) =>
//                                     prevFile.name === file.name
//                                         ? { ...prevFile, status: "failed" }
//                                         : prevFile
//                                 )
//                             );

//                             setProgressStatusChecking((prev) => ({ ...prev, [file.url]: false }));
//                             clearInterval(interval);
//                         } else if (status_resp.status === 500) {
//                             setProgressStatusChecking((prev) => ({ ...prev, [file.url]: false }));
//                             clearInterval(interval);
//                         }
//                     } catch (error) {
//                         console.error("Error checking file status:", error);
//                         setProgressStatusChecking((prev) => ({ ...prev, [file.url]: false }));
//                         clearInterval(interval);
//                     }
//                 }, 3000);

//                 return interval;
//             }

//             return null;
//         });

//         // Cleanup intervals on unmount
//         return () => {
//             intervals.forEach((interval) => {
//                 if (interval) clearInterval(interval);
//             });
//         };
//     }, [files, threadId, token, setThreadFiles, InvokeMatrixApi]);

//     useEffect(() => {
//     setFilesLen(files.length);
//     }, [files])
    
//     const getFileIcon = (fileName) => {
//         if (fileName.includes("youtube")) return Youtube_Icon;
//         if (fileName.endsWith(".pdf")) return Pdf_Icon;
//         if (fileName.endsWith(".xlsx") || fileName.endsWith(".xls")) return Xlsx_Icon;
//         return null;
//     };

//     return (
//         <div>
//             <p style={{ fontSize: '14px'}}>Your Files ({filesLen})</p>
//             {files.length ? (
//                 <ul style={{ paddingInlineStart: '1em'}}>
//                     {files.map((file, index) => (
//                         <li
//                             key={index}
//                             style={{
//                                 overflow: 'hidden',
//                                 textOverflow: 'ellipsis',
//                                 whiteSpace: 'nowrap',
//                                 listStyleType: 'disc',
//                                 textAlign: 'start',
//                                 cursor: 'pointer',
//                                 display: 'flex',
//                                 alignItems: 'center',
//                                 fontSize:'16px'
//                             }}
//                             title={file.name}
//                         >
//                             <div style={{display:'flex',justifyContent:'space-between',width:'280px',color:file.status === 'failed'?'red':''}}>
//                                 <div>
//                                     <img 
//                                         src={getFileIcon(file.name)} 
//                                         alt="" 
//                                         style={{ marginRight: '8px', width: '20px', height: '20px' }} 
//                                     />
//                                     {file.name.length > 14 ? `${file.name.substring(0, 14)}...` : file.name}
//                                 </div>
//                                 <div>
//                                     {(file.status === "uploading" || file.status === "processing") && progressStatusChecking[file.url] ? (
//                                         <CircularProgress size={20} style={{ marginLeft: '8px' }} />
//                                     ) : (
//                                         <MoreHorizIcon />
//                                     )}
//                                 </div>
//                             </div>
//                         </li>
//                     ))}
//                 </ul>
//             ) : (
//                 // <p style={{ fontSize: '.9rem', color: 'grey', textAlign: 'center' }}>No files uploaded</p>
//                 <></>
//             )}
//         </div>
//     );
// };

// export default ViewThreadFiles;

