
import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle, CircularProgress, Typography,Box,Button,IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useCreditRecharge } from "../contexts/CreditRechageContext";
import sourceUrl from '../api/Source.api';
import { SubscribeToProClick, trackButtonEvent } from "../config/analytics";

const CreditRechargeModal = () => {
  const { showCreditRecharge, setShowCreditRecharge } = useCreditRecharge();
  const [isLoading, setIsLoading] = useState(false); // State for loading modal

  // payment part
  const [paymetSuccess , setPaymentSuccess] = useState(false)
  const [paymentFail, setPaymentFail] = useState(false)

  const handelPaymentSuccess = () =>{

  }

  const handelPaymentFail = () =>{

  }

  const token = localStorage.getItem('token');

  const handleClose = () => setShowCreditRecharge(false);

  const handleAddCredit = async () => {
    trackButtonEvent('button_click_Upgrade_Plan', {
      button_name:  "Upgrade Plan",
      button_position:  "Bottom-left"
    })
    SubscribeToProClick()
    setIsLoading(true); // Show loading modal

    const body = {
      priceId: 'price_1Q51ELLIN2khABjKiCD5gFG2' // Example price ID
    };

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}` // Add your authorization token if required
    };

    try {
      const response = await fetch(`${sourceUrl}/payment/createCheckoutSession`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
      });

      const result = await response.json();
      
      if (response.ok && result.status) {
        const redirectUrl = result.data.url;
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          console.error('Redirect URL not found in the response.');
          setIsLoading(false); // Hide loading modal if an error occurs
        }
      } else {
        console.error('Error creating checkout session:', result.message || 'Unknown error');
        setIsLoading(false); // Hide loading modal if an error occurs
      }
    } catch (error) {
      alert('Create checkout session failed');
      console.error('Error:', error);
      setIsLoading(false); // Hide loading modal if an error occurs
    }
  };

  return (
    <>
  <Dialog open={showCreditRecharge} close={handleClose}>
     
<Box
 sx={{
   
     width: '480px',
     height: '381px',
     top: '322px',
     left: '480px',
    borderRadius: '8px',
     border: '1px solid rgba(202, 196, 208, 1)',
     backgroundColor: 'rgba(40, 45, 81, 1)',
    

 }}
 >
       {/* Section 1 */}
     <Box 
     sx={{
         height : '68px',
         justifyContent : 'space-between',
         padding : '20px',
         border: '1px solid rgba(255, 255, 255, 0.2)',
         borderTop : '8px',
         borderTopRightRadius : '8px',
         borderTopLeftRadius : "8px"
         
     }}
     >
         <Box 
         sx={{width : '440px',
             height : "28px",
             justifyContent : 'space-between',
             display : 'flex',
             margin : 'auto',
             alignItems : "center"
            
            

         }}
         
         >
             <Box sx={{
               

                 width  : '279px',
                 height : '20px',
         
             }}>
                <Typography 
                         sx={{
                             fontFamily: 'Inter, sans-serif',
                             fontWeight: 600, 
                             fontSize: '20px', 
                             lineHeight : '20px',
                             color: 'rgba(255, 255, 255, 1)',
                         }}

                >Upgrade your plan</Typography>
             </Box>
             
             <Box sx={{
                 width  : '28px',
                 height : '28px'

             }}>
                         
    <IconButton
   aria-label="close"
   onClick={handleClose}
   sx={{

    //  position: 'absolute',
    //  right: 8,
    //  top: 8,

     color: 'rgba(255, 255, 255, 1)',
   }}
 >
   <CloseIcon sx={{
    width : '16.33px',
    height : '16.33px'
   }}
    />
 </IconButton>
             </Box>


         </Box>
         
     </Box>

      {/* Section 2 */}

      <Box sx={{
       textAlign: 'center',
         width : '440px',
         height : '140px',
         position: "absolute",
         top : '84px',
         left : '20px',
         gap : "16px",
         // margin : 'auto'
         
      }}>
         <Typography  sx={{
                width: '440px',
                 fontFamily: 'Inter',
                 fontWeight: 400,
                 fontSize: '16px',
                 lineHeight: '20px',
                 color : 'rgba(255, 255, 255, 1)',
                 textAlign: 'start',
                 margin : 'auto'

             
         }} >
         You have reached your daily limit of free access to ModalX features. <br /> <br />

         We know how important it is for you to have uninterrupted access to our features. To keep enjoying a seamless experience and explore the full potential of ModalX, please consider subscribing to our Pro plan.
         </Typography>

      </Box>
       {/* Section 3 */}
       <Box sx={{
         width : '428px',
         height : '20',
         top : '264px',
         left : '20px',
         position : 'absolute',
         textAlign : "center"
       }}
       >
         <Typography
                 sx={{
                   fontFamily: 'Inter',
                   fontSize: '16px',
                   fontWeight: 400,
                   lineHeight: '20px',
                   textAlign: 'center',
                   color: 'rgba(255, 255, 255, 1)'
                 }}
         >Get more from ModalX with Pro today</Typography>

       </Box>
       <Button variant="contained"  sx={{backgroundColor : "rgba(246, 190, 107, 1)", color : 'black',
         width : '440px',
         height : '40px',
         position : "absolute",
         top : '301px',
         left : '20px',
         padding : '10px 24px 10px 24px',
         fontFamily: 'Inter',
         fontSize: '16px',
         fontWeight: 600,
         lineHeight: '20px',
         letterSpacing: '0.1px',
         textAlign: 'center',
         textTransform : 'none',
         "&:hover": {
          bgcolor: "#ffd9aa",
        },
       }}
       onClick={handleAddCredit}
        >
        Subscribe to Pro</Button>
     
    
 </Box>


</Dialog>

{/* for payment success */}




      <Dialog
        open={isLoading}
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{ style: { padding: '20px', textAlign: 'center' } }}
      >
        <DialogTitle>{"Processing..."}</DialogTitle>
        <DialogContent>
          <CircularProgress />
          <Typography variant="h6" style={{ marginTop: '15px' }}>
            Wait!.. Do not refresh the screen.
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreditRechargeModal;


