// import React from 'react'
// import Image_1 from "../assets/Aboutus/Image_1.png";
// import PageLayout from '../components/navFoot/PageLayout';
// import RequestDemoNearFooter from '../components/LandingPage/RequestDemoNearFooter';

// const Aboutus = () => {
//   return (
//     <PageLayout>
//         <div style={{marginTop:'156px'}}>
//             <div style={{maxWidth:'1072px',height:'188px',display:'flex',flexDirection:'column',justifyContent:'space-between',textAlign:'center',margin:'auto'}}>
//                 <p style={{ textAlign: 'center', color: '#F6BE6B' }}>About Us</p>
//                     <p style={{ fontSize: '48px'}}>
//                         World’s best AI Productivity Tool backed by Multimodal Generative AI
//                     </p>
//             </div>
//             <div style={{maxWidth:'900px',height:'60px',margin:'80px auto'}}>
//                 <p style={{fontSize:'20px',color:'rgba(255,255,255,0.8)'}}>ModalX provides adaptability for your generative AI requirements by merging our array of cutting-edge open models with self-hosting advantages.</p>
//             </div>
//             <div style={{maxWidth:'900px',height:'60px',margin:'80px auto'}}>
//                 <p style={{fontSize:'20px',color:'rgba(255,255,255,0.8)'}}>We're not just AI; we're your AI running mate. ModalX is a multimodal platform that understands your business, predicts your challenges, and delivers tailored AI solutions. From strategy to execution, we've got you covered.</p>
//             </div>
//             <div style={{maxWidth:'1072px',maxHeight:'605px',margin:'80px auto'}}>
//                 <img src={Image_1}/>
//             </div>
//             <div>
//                 <p style={{fontSize:'48px',textAlign:'center'}}>About Our Team</p>
//                 <div style={{margin:'80px auto',maxWidth:'380px',height:'128px',display:'flex',justifyContent:'space-between',color:'rgba(255,255,255,0.9)'}}>
//                     <p style={{fontSize:'24px'}}>Founders</p>
//                     <div>
//                         <p>Jeremy Barber</p>
//                         <p>Hanif Joshaghani</p>
//                         <p>Peh Keong Teh</p>
//                         <p>Vikram Shekhawat</p>
//                     </div>
//                 </div>
//             </div>
//             <div>
//                 <RequestDemoNearFooter />
//             </div>
//         </div>
//     </PageLayout>
//   )
// }

// export default Aboutus

import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Image_1 from '../assets/Aboutus/Image_1.png';
import PageLayout from '../components/navFoot/PageLayout';
import RequestDemoNearFooter from '../components/LandingPage/RequestDemoNearFooter';

const Aboutus = () => {
  const isBelow991 = useMediaQuery("(max-width:991px)")
  return (
    <PageLayout>
      <Box sx={{ marginTop: isBelow991?'80px':'156px' }}>
        <Box
          sx={{
            maxWidth: '1072px',
            height: '188px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            textAlign: 'center',
            margin: 'auto',
            width:isBelow991?'90%':'100%'
          }}
        >
          <Typography sx={{ textAlign: 'center', color: '#F6BE6B',fontSize:isBelow991?'12px':'16px' }}>About Us</Typography>
          <Typography sx={{ fontSize: isBelow991?'28px':'48px' }}>
            World’s best AI Productivity Tool backed by Multimodal Generative AI
          </Typography>
        </Box>
        <Box sx={{ maxWidth: '900px', height: '60px', margin: isBelow991?'60px auto':'80px auto',width:isBelow991?'80%':'100%' }}>
          <Typography sx={{ fontSize: isBelow991?'16px':'20px', color: 'rgba(255,255,255,0.8)' }}>
            ModalX provides adaptability for your generative AI requirements by merging our array of
            cutting-edge open models with self-hosting advantages.
          </Typography>
        </Box>
        <Box sx={{ maxWidth: '900px', height: '60px', margin: '80px auto',width:isBelow991?'80%':'100%' }}>
          <Typography sx={{ fontSize: isBelow991?'16px':'20px', color: 'rgba(255,255,255,0.8)' }}>
            We're not just AI; we're your AI running mate. ModalX is a multimodal platform that
            understands your business, predicts your challenges, and delivers tailored AI solutions.
            From strategy to execution, we've got you covered.
          </Typography>
        </Box>
        <Box sx={{ maxWidth: '1072px', maxHeight: '605px', margin: isBelow991?'60px auto':'80px auto',width:isBelow991?'80%':'100%',paddingTop:'60px' }}>
          <img src={Image_1} alt="About Us" style={{ width: '100%', height: 'auto' }} />
        </Box>
        <Box>
          <Typography sx={{ fontSize: isBelow991?'28px':'48px', textAlign: 'center' }}>About Our Team</Typography>
          <Box
            sx={{
              margin: isBelow991?'60px auto 643px auto':'80px auto',
              maxWidth: '380px',
              height: '128px',
              display: 'flex',
              justifyContent: 'space-between',
              color: 'rgba(255,255,255,0.9)',
              width:isBelow991?'80%':''
            }}
          >
            <Typography sx={{ fontSize: isBelow991?'20px':'24px' }}>Founders</Typography>
            <Box>
              <Typography>Jeremy Barber</Typography>
              <Typography>Hanif Joshaghani</Typography>
              <Typography>Peh Keong Teh</Typography>
              <Typography>Vikram Shekhawat</Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <RequestDemoNearFooter />
        </Box>
      </Box>
    </PageLayout>
  );
};

export default Aboutus;
