import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField } from '@mui/material';
import { 
  button_bg_color_for_New_Project, 
  uploadFiles_button_default_bg_color, 
  uploadFiles_button_hovered_bg_color,
  settingsModal_bg_color_dm
} from '../../../../constants/colors';
import sourceUrl from '../../../../api/Source.api';
import { useWorkspace } from "../../../../contexts/WorkspaceContext";

const EditUserDetails = ({ open, onClose, firstName: initialFirstName, lastName: initialLastName, linkedIn: initialLinkedIn, role: initialRole, companyWebsite: initialCompanyWebsite }) => {
  const [firstName, setFirstName] = useState(initialFirstName);
  const [lastName, setLastName] = useState(initialLastName);
  const [linkedIn, setLinkedIn] = useState(initialLinkedIn);
  const [role, setRole] = useState(initialRole);
  const [companyWebsite, setCompanyWebsite] = useState(initialCompanyWebsite);
  const {setWaitThread,setSending,setCurrentThread,sendMessage} = useWorkspace();

  const sendEditMessage = async (prompt) => {
    setWaitThread(true);
    setSending(true);
    try {
      const latestEntry = { sender: "user", content: prompt };
      setCurrentThread((prevThread) => [...prevThread, latestEntry]);

      const entryResponse = await sendMessage(latestEntry,false);
      if (entryResponse) {
        setCurrentThread((prevThread) => [...prevThread, entryResponse]);
      } else {
        setCurrentThread((prevThread) => [
          ...prevThread, 
          { content: "Unable to process your query!", sender: "modalX" }
        ]);
      }
    } catch (error) {
      console.error("Error sending initial message:", error);
    } finally {
      setWaitThread(false);
      setSending(false);
    }
  };

  const handleSave = async () => {
    const userInfo = {
      firstName,
      lastName,
      linkedinProfile: linkedIn,
      role,
      companyWebsite
    };

    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${sourceUrl}/userInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(userInfo),
      });

      if (response.ok) {
        console.log('User details saved successfully');
        sendEditMessage("No, edit details")
        onClose(); // Close the modal after saving
      } else {
        console.error('Failed to save user details');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="edit-details-title"
      aria-describedby="edit-details-description"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        sx={{
          bgcolor: settingsModal_bg_color_dm,
          boxShadow: 24,
          borderRadius: '8px',
          maxWidth: '720px',
          position: 'relative',
          borderBottom: '1px solid #fff',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
            pb: 1,
            borderBottom: '1px solid #fff',
            padding: '20px'
          }}
        >
          <Typography id="edit-details-title" variant="h6" component="h2">
            Edit Personal Details
          </Typography>
          <Button
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: '20px',
              top: '20px',
              padding: '8px',
              minWidth: 'auto',
              color: '#fff',
            }}
          >
            X
          </Button>
        </Box>

        <Box component="form" sx={{ mt: 2, padding: '20px' }}>
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '40px' }}>
            <TextField
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{ sx: { color: '#fff' } }}
              InputProps={{
                sx: {
                  borderColor: '#fff',
                  '& fieldset': {
                    borderColor: '#fff',
                  },
                },
              }}
            />
            <TextField
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{ sx: { color: '#fff' } }}
              InputProps={{
                sx: {
                  borderColor: '#fff',
                  '& fieldset': {
                    borderColor: '#fff',
                  },
                },
              }}
            />
          </Box>
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '40px', mt: 2 }}>
            <TextField
              label="LinkedIn Profile"
              value={linkedIn}
              onChange={(e) => setLinkedIn(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{ sx: { color: '#fff' } }}
              InputProps={{
                sx: {
                  borderColor: '#fff',
                  '& fieldset': {
                    borderColor: '#fff',
                  },
                },
              }}
            />
            <TextField
              label="Role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{ sx: { color: '#fff' } }}
              InputProps={{
                sx: {
                  borderColor: '#fff',
                  '& fieldset': {
                    borderColor: '#fff',
                  },
                },
              }}
            />
          </Box>
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: '40px', mt: 2 }}>
            <TextField
              label="Company Website"
              value={companyWebsite}
              onChange={(e) => setCompanyWebsite(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{ sx: { color: '#fff' } }}
              InputProps={{
                sx: {
                  borderColor: '#fff',
                  '& fieldset': {
                    borderColor: '#fff',
                  },
                },
              }}
            />
          </Box>
        </Box>

        <Box sx={{
          mt: 3,
          display: 'flex',
          justifyContent: 'flex-end',
          borderTop: '1px solid rgba(255, 255, 255, 0.2)',
          padding: '20px'
        }}>
          <Button
            variant="contained"
            onClick={onClose}
            style={{ marginRight: '10px', color: '#17182C', fontSize: '16px', fontWeight: '600', fontFamily: 'inter' }}
            sx={{ textTransform: 'none', bgcolor: uploadFiles_button_default_bg_color,
              '&:hover': {
                bgcolor: uploadFiles_button_hovered_bg_color,
              }
            }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            style={{ marginRight: '10px', color: '#17182C', fontSize: '16px', fontWeight: '600', fontFamily: 'inter' }}
            sx={{ textTransform: 'none', bgcolor: button_bg_color_for_New_Project,
              '&:hover': {
                bgcolor: '#FFD9AA',
              }
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditUserDetails;

// import React, { useState } from "react";
// import axios from "axios";
// import {useWorkspace} from "../../../../contexts/WorkspaceContext";


// const EditUserDetails = ({ closeModal }) => {
//   const [firstName, setFirstName] = useState("");
//   const [lastName, setLastName] = useState("");
//   const [linkedInProfile, setLinkedInProfile] = useState("");
//   const [role, setRole] = useState("");
//   const [companyWebsite, setCompanyWebsite] = useState("");
//   const { sendMessage, setCurrentThread, setWaitThread, setSending } = useWorkspace();

//   const handleSave = async () => {
//     const userDetails = {
//       firstName,
//       lastName,
//       linkedInProfile,
//       role,
//       companyWebsite,
//     };

//     try {
//       const response = await axios.post("/userInfo", userDetails, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });

//       if (response.status === 200) {
//         const message = "No, edit details";
        
//         // Call sendMessage and update setCurrentThread
//         setWaitThread(true);
//         setSending(true);
        
//         const latestEntry = { sender: "user", content: message };
//         setCurrentThread((prevThread) => [...prevThread, latestEntry]);
        
//         const entryResponse = await sendMessage(latestEntry);
//         if (entryResponse) {
//           setCurrentThread((prevThread) => [...prevThread, entryResponse]);
//         } else {
//           setCurrentThread((prevThread) => [
//             ...prevThread, 
//             { content: "Unable to process your query!", sender: "modalX" }
//           ]);
//         }
//       } else {
//         console.error("Error saving user details:", response.statusText);
//       }
//     } catch (error) {
//       console.error("Error saving user details:", error);
//     } finally {
//       setWaitThread(false);
//       setSending(false);
//     }

//     // Close the modal after saving
//     closeModal();
//   };

//   return (
//     <div className="edit-user-details-modal">
//       <h2>Edit Details</h2>
//       <button onClick={closeModal} className="close-button">X</button>
//       <div className="input-row">
//         <input
//           type="text"
//           placeholder="First Name"
//           value={firstName}
//           onChange={(e) => setFirstName(e.target.value)}
//         />
//         <input
//           type="text"
//           placeholder="Last Name"
//           value={lastName}
//           onChange={(e) => setLastName(e.target.value)}
//         />
//       </div>
//       <div className="input-row">
//         <input
//           type="text"
//           placeholder="LinkedIn Profile"
//           value={linkedInProfile}
//           onChange={(e) => setLinkedInProfile(e.target.value)}
//         />
//         <input
//           type="text"
//           placeholder="Role"
//           value={role}
//           onChange={(e) => setRole(e.target.value)}
//         />
//       </div>
//       <div className="input-row">
//         <input
//           type="text"
//           placeholder="Company Website"
//           value={companyWebsite}
//           onChange={(e) => setCompanyWebsite(e.target.value)}
//         />
//       </div>
//       <div className="modal-footer">
//         <button onClick={closeModal}>Close</button>
//         <button onClick={handleSave}>Save</button>
//       </div>
//     </div>
//   );
// };

// export default EditUserDetails;




