import React from "react";
import { Box, Typography, Button } from "@mui/material";
import RequestDemoNearFooter from "../components/LandingPage/RequestDemoNearFooter";
import PageLayout from "../components/navFoot/PageLayout";
function TermsOfService() {
  return (
    <>
      <PageLayout>
        {/* section 1 */}

        <Box
          sx={{
            width: "100%",
            // height: "500px",
            marginTop: "246px",
            gap: "48px",
            opacity: "0px",
            "@media (max-width : 991px)": {
              marginTop: "156px",
  
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "48px",
              fontWeight: "500px",
              lineHeight: "57.6px",
              letterSpacing: "-0.32",
              opacity: "80%",
              textAlign: "center",
              color: "#FFFFFF",
              "@media (max-width : 991px)": {
                fontSize: "28px",
              },
            }}
          >
            Terms of Use
          </Typography>
        </Box>

        {/* section 1 */}

        {/* section 2 */}

        <Box
          sx={{
            width: "100%",
            marginTop: "40px",
            gap: "0px",
            opacity: "0px",
            "@media (max-width : 991px)": {
              marginTop: "36px",

            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: "500px",
              lineHeight: "24px",
              textAlign: "center",
              color: "rgba(255, 255, 255, 1)",
              "@media (max-width : 991px)": {
                fontSize: "14px",
              },
            }}
          >
            Last Updated: July 26, 2024{" "}
          </Typography>
        </Box>

        {/* section 2 */}

        {/* section 3 */}
        <Box
          display={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "100px",
              gap: "20px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                minWidth: "350px",
                marginTop: "60px",
 
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "24px",
                display: "flex",
                "@media (max-width : 991px)": {
                  padding: "0px 4.4% 0px 4.4%",
                  minWidth: "350px",
                  
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "600px",
                  lineHeight: "24px,",
                  textAlign: "left",
                  "@media (max-width : 991px)": {
                    fontSize: "14px",
                  },
                }}
              >
                Effective:{" "}
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "500px",
                    lineHeight: "24px,",
                    textAlign: "left",
                    "@media (max-width : 991px)": {
                      fontSize: "14px",
                    },
                  }}
                  component="span"
                >
                  August 1 2024
                </Typography>{" "}
              </Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
                marginTop: "20px",
                "@media (max-width : 991px)": {
                  minWidth: "350px",
                  padding: "0px 4.4% 0px 4.4%",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "500px",
                  lineHeight: "24px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "14px",
                  },
                }}
              >
                These terms and conditions ("Terms") are an agreement between
                the user and BRIGHTSPOT APP INC.  ("Company"). By accessing or
                using our AI services ("Services"), the user agree to be bound
                by these Terms. If the user does not agree to all of these
                Terms, do not access or use the Services.
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* section 3 */}

        {/* section 4 */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
               
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                "@media (max-width : 991px)": {
                  minWidth: "350px",
                  paddingLeft : '4.4%'
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                1. Acceptance
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                  minWidth: "350px",
                  padding: "0px 4.4% 0px 4.4%",
                },
              }}
            >
              {" "}
              <Box component="ul" sx={{ paddingLeft: 2 }}>
                <Box component="li">
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    By using the Services, the user agrees to comply with and be
                    bound by these Terms. If the user does not agree, please do
                    not use the Services.
                  </Typography>
                </Box>
                <Box component="li">
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    Users are responsible for ensuring their use does not
                    violate any laws, regulations, or third-party rights.
                  </Typography>
                </Box>
                <Box component="li">
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    The Company may deny access, terminate contracts, or report
                    misconduct to authorities if Users violate laws,
                    regulations, or these Terms, harm the Company’s interests,
                    or infringe on third-party rights.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* section 4 */}

        {/* section 5 */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                
                marginTop: "40px",
                minWidth: "350px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                "@media (max-width : 991px)": {
                  minWidth: "350px",
                  padding: "0px 4.4% 0px 4.4%",
                  
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                2. Age Restrictions
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                  minWidth: "350px",
                  padding: "0px 4.4% 0px 4.4%",
                },
              }}
            >
              {" "}
              <Box component="ul" sx={{ paddingLeft: 2 }}>
                <Box component="li">
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    If the user is under 18 or the legal age in the user's
                    country to manage your own modalx.ai account, the user must
                    have his parent's or guardian's permission to use modalx.ai.
                    Please have them review these terms with the user. 
                  </Typography>
                </Box>
                <Box component="li">
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    If the user is a parent or guardian allowing your child to
                    use modalx.ai services, the user agrees to these terms and
                    takes responsibility for their activity.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* section 5 */}

        {/* section 6 */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",

              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                3. User Accounts
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",

                },
              }}
            >
              {" "}
              <Box component="ul" sx={{ paddingLeft: 2 }}>
                <Box component="li">
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    To use certain features, the user must create an account.
                    The user is responsible for safeguarding their account, and
                    the user must notify us immediately of any unauthorized use.
                  </Typography>
                </Box>
                <Box component="li">
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    The user may not create an account using false information
                    or through unauthorized means, such as automated devices,
                    scripts, bots, spiders, or scrapers.
                  </Typography>
                </Box>
                <Box component="li">
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    The Company reserves the right to modify or terminate the
                    user’s account for any reason at any time without notice.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* section 6 */}

        {/* section 7 */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              // marginTop: "120px",
              marginBottom: "320px",
              gap: "0px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                display: "none",
              },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: "500px",
                lineHeight: "24px,",
                textAlign: "left",
              }}
            >
              For any questions regarding these terms, please contact us at:{" "}
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "500px",
                  lineHeight: "24px,",
                  textAlign: "left",
                }}
                component="span"
              >
                support@modalx.ai
              </Typography>{" "}
            </Typography>
          </Box>
        </Box>

        {/* section 7 */}
        <Box>
          <RequestDemoNearFooter />
        </Box>
      </PageLayout>
    </>
  );
}

export default TermsOfService;
