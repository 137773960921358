import React, { useState } from "react";
import { Box, Typography, Button, FilledInput, TextField } from "@mui/material";
import PageLayout from "../components/navFoot/PageLayout";
import RequestDemoNearFooter from "../components/LandingPage/RequestDemoNearFooter";
import sourceUrl from "../api/Source.api";

function Contact() {
  const token = localStorage.getItem("token");
  const [isFirstName, setIsFirstName] = useState(false);
  const [isLastName, setIsLastName] = useState(false);
  const [isEmial, setIsEmail] = useState(false);
  const [isFeedback, setIsFeedback] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      message: message,
    };

    try {
      const response = await fetch(`${sourceUrl}/support/contactus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Passing the token here
        },
        body: JSON.stringify(data),
      });


      const result = await response.json(); // Parse JSON response

      if (response.ok && result.status) {
        alert(result.message);
        setFirstName("");
        setLastName("");
        setEmail("");
        setMessage("");
      }
      else {
        console.log("Failed to submit form");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const customTextFieldStylesForInvalid = (isInvalid) => {
    return {
      "& .MuiInputLabel-root": {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: "0.5px",
        color: isInvalid ? "#F2B8B5" : "rgba(255, 255, 255, 0.80)",
        "&.Mui-focused": {
          color: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80)",
        },
      },
      "& .MuiInputBase-input": {
        color: "#FFFFFF",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: isInvalid ? "#F2B8B5" : "rgba(255, 255, 255, 0.80)",
        },
        "&:hover fieldset": {
          borderColor: isInvalid ? "#F2B8B5" : "rgba(255, 255, 255, 0.80)",
        },
        "&.Mui-focused fieldset": {
          borderColor: isInvalid
            ? "#F2B8B5 !important"
            : "rgba(255, 255, 255, 0.80) ",
        },
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiInputLabel-root": {
        color: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80) ",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: isInvalid
          ? "#F2B8B5 !important"
          : "rgba(255, 255, 255, 0.80) ",
      },
    };
  };
  return (
    <>
      <PageLayout>
        {/* section 1 */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              marginTop: "163px",
              gap: "48px",
              opacity: "0px",
              "@media(max-width : 991px)": {
                marginTop: "155px",
              },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "48px",
                fontWeight: "500px",
                lineHeight: "57.6px",
                letterSpacing: "-0.32",
                opacity: "0.8px",
                textAlign: "center",
                color: "rgba(255, 255, 255, 1)",
                "@media(max-width : 991px)": {
                  fontSize: "28px",
                },
              }}
            >
              Contact Us
            </Typography>
          </Box>
        </Box>

        {/* section 1 */}

        {/* section 2 */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              maxWidth: "731px",
              marginTop: "28px",
              gap: "20px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "16px",
              },
            }}
          >
            {" "}
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "27px",
                textAlign: "center",
                color:'rgba(255, 255, 255, 0.90)',
                "@media (max-width : 991px)": {
                  fontSize: "14px",
                  padding: "0px 20px 0px 20px",
                },
              }}
            >
              Get in touch for a more personalized experience of the platform
              with a one-on-one demo call. Feel free to book a demo call below{" "}
            </Typography>
          </Box>
        </Box>

        {/* section 2 */}

        {/* secont 3 */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "889px",
              marginTop: "152px",
              gap: "200px",
              opacity: "0px",
              display: "flex",

              "@media (max-width : 991px)": {
                display: "grid",
                gridTemplateColumns: "repeat(1, 1fr)",
                // marginTop : '80px',
                minWidth: "350px",
                margin: "80px 4.4% 0px 4.4%",
                gap: "0px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                gap: "40px",
                opacity: "0px",
              }}
            >
              <Box sx={{}}>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "24px",
                    fontWeight: "600px",
                    lineHeight: "36px",
                    textAlign: "left",
                    color: "#FFFFFF",
                    opacity: "0.9",
                    "@media (max-width : 991px)": {
                      fontSize: "20px",
                    },
                  }}
                >
                  {/* Book a demo call */}
                  Send Feedback / Request feature
                </Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    width: "100%",
                    marginTop: "40px",
                    gap: "40px",
                    opacity: "0px",
                    "@media (max-width : 991px)": {
                      marginTop: "36px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "142px",
                      gap: "30px",
                      opacity: "0px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        // height: "56px",
                        gap: "18px",
                        opacity: "0px",
                        display: "flex",

                        "@media (max-width : 991px)": {
                          display: "grid",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          // height: "56px",
                          gap: "18px",
                          opacity: "0px",
                        }}
                      >
                        <Box
                          sx={{
                            width: "210px",
                            height: "56px",
                            gap: "10px",
                            // borderRadius: "4px",
                            // border: "1px solid rgba(255, 255, 255, 0.6)",
                            opacity: "0px",
                            display: "flex",
                            alignItems: "center",
                            "@media (max-width : 991px)": {
                              width: "100%",
                            },
                          }}
                        >
                          <TextField
                            id="outlined-basic"
                            label="First name*"
                            variant="outlined"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            sx={() =>
                              customTextFieldStylesForInvalid(isFirstName)
                            }
                            style={{
                              width: "100%",
                              // maxWidth :  isBelow991 ? "100%" : "300px",
                              // height: "56px",
                              color: "#FFFFFF",
                              // backgroundColor: "#232746",
                              borderRadius: "8px",
                              boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                              placeholderfontsize: "16px",
                            }}
                          />
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          width: "100%",
                          height: "56px",
                          gap: "18px",
                          opacity: "0px",
                          // backgroundColor : 'yellow'
                        }}
                      >
                        <Box
                          sx={{
                            width: "210px",
                            height: "56px",
                            gap: "10px",
                            // borderRadius: "4px",
                            // border: "1px solid rgba(255, 255, 255, 0.6)",
                            opacity: "0px",
                            display: "flex",
                            alignItems: "center",
                            "@media (max-width : 991px)": {
                              width: "100%",
                            },
                          }}
                        >
                          <TextField
                            id="outlined-basic"
                            label="Last name*"
                            variant="outlined"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            sx={() =>
                              customTextFieldStylesForInvalid(isLastName)
                            }
                            style={{
                              width: "100%",
                              // maxWidth :  isBelow991 ? "100%" : "300px",
                              height: "56px",
                              color: "#FFFFFF",
                              // backgroundColor: "#232746",
                              borderRadius: "8px",
                              boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                              placeholderfontsize: "16px",
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        width: "438px",
                        gap: "18px",
                        opacity: "0px",
                        marginTop: "30px",
                        display: "flex",
                        "@media (max-width : 991px)": {
                          width: "100%",
                          marginTop: "20px",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: "438px",
                          height: "56px",
                          gap: "10px",
                          // borderRadius: "4px",
                          // border: "1px solid rgba(255, 255, 255, 0.6)",
                          opacity: "0px",
                          display: "flex",
                          alignItems: "center",
                          "@media (max-width : 991px)": {
                            width: "100%",
                          },
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          label="Work email address*"
                          variant="outlined"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          sx={() => customTextFieldStylesForInvalid(isEmial)}
                          style={{
                            width: "100%",
                            // maxWidth :  isBelow991 ? "100%" : "300px",
                            height: "56px",
                            color: "#FFFFFF",
                            // backgroundColor: "#232746",
                            borderRadius: "8px",
                            boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                            placeholderfontsize: "16px",
                          }}
                        />
                      </Box>
                    </Box>

                    <TextField
                      id="outlined-basic"
                      label="Share your feedback or describe the feature you would like to see on ModalX*"
                      variant="outlined"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      sx={() => customTextFieldStylesForInvalid(isFeedback)}
                      style={{
                        width: "100%",
                        marginTop: "30px",
                        // height: "56px",
                        color: "#FFFFFF",
                        // backgroundColor: "#232746",
                        borderRadius: "8px",
                        boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                        placeholderfontsize: "16px",
                      }}
                      InputProps={{
                        style: {
                          height: "112px", // Ensures the input field fills the container height
                          // padding: "20px 14px", // Adjust padding for better alignment
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          whiteSpace: "normal", // Allows label text to wrap
                          overflow: "visible", // Ensures the entire label is visible
                          fontSize: "14px", // Adjust font size if needed
                        },
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: "89px",
                      marginTop: "172px",
                      gap: "8px",
                      opacity: "0px",
                      "@media (max-width : 991px)": {
                        marginTop: "226px",
                        width: "43%",
                        // marginRight : '100px'
                      },
                    }}
                  >
                    <Button
                      type="submit"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        height: "40px",
                        gap: "0px",
                        borderRadius: "4px",
                        border: "1px solid rgba(247, 190, 105, 1)",
                        opacity: "0px",
                        textAlign: "center",
                        "&:hover": {
                          borderColor: "#F6BE6A",
                          backgroundColor: "transparent", // No border color changes on hover
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: "600px",
                          lineHeight: "20px",
                          textTransform: "none",
                          letterSpacing: "0.1px",
                          textAlign: "center",
                          color: "rgba(246, 190, 106, 1)",
                          "@media (max-width : 991px)": {
                            fontSize: "14px",
                          },
                        }}
                      >
                        Send
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>

            <Box
              sx={{
                width: "251px",
                gap: "36px",
                opacity: "0px",
                "@media (max-width : 991px)": {
                  marginTop: "80px",
                },
              }}
            >
              <Box
                sx={{
                  width: "251px",
                  height: "36px",
                  gap: "0px",
                  opacity: "0px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "24px",
                    fontWeight: "600px",
                    lineHeight: "36px",
                    textAlign: "left",
                    color: "rgba(255, 255, 255, 0.9)",
                    "@media (max-width : 991px)": {
                      fontSize: "20px",
                    },
                  }}
                >
                  Company Information
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "190px",
                  height: "183px",
                  gap: "30px",
                  opacity: "0px",
                  marginTop: "30px",
                }}
              >
                <Box
                  sx={{
                    width: "190px",
                    height: "24px",
                    gap: "0px",
                    opacity: "0px",
                  }}
                >
                  {" "}
                  <Typography
                    sx={{
                      fontFamily: "16px",
                      fontSize: "16px",
                      fontWeight: "600px",
                      lineHeight: "24px",
                      textAlign: "left",
                    }}
                  >
                    ModalX is a project by:
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "190px",
                    height: "129px",
                    gap: "11px",
                    opacity: "0px",
                    marginTop: "30px",
                  }}
                >
                  <Box
                    sx={{
                      width: "190px",
                      height: "24px",
                      gap: "0px",
                      opacity: "0px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: "700px",
                        lineHeight: "24px",
                        textAlign: "left",
                        color: "rgba(255, 255, 255, 1)",
                      }}
                    >
                      BRIGHTSPOT APP INC.
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: "190px",
                      height: "24px",
                      gap: "0px",
                      opacity: "0px",
                      marginTop: "11px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "24px",
                        textAlign: "left",
                        color: "rgba(255, 255, 255, 0.9)",
                      }}
                    >
                      GST/HST ID:
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: "190px",
                      height: "24px",
                      gap: "0px",
                      opacity: "0px",
                      marginTop: "11px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "24px",
                        textAlign: "left",
                        color: "rgba(255, 255, 255, 0.9)",
                      }}
                    >
                      Company Reg Number
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "190px",
                      height: "24px",
                      gap: "0px",
                      opacity: "0px",
                      marginTop: "11px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "24px",
                        textAlign: "left",
                        color: "rgba(255, 255, 255, 0.9)",
                      }}
                    >
                      Address:
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "251px",
                      height: "24px",
                      gap: "0px",
                      opacity: "0px",
                      marginTop: "36px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: "600px",
                        lineHeight: "24px",
                        textAlign: "left",
                        color: "rgba(255, 255, 255, 1)",
                      }}
                    >
                      Email:{" "}
                      <Typography
                        component="span"
                        sx={{
                          textDecoration: "underline",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "24px",
                          textAlign: "left",
                          color: "rgba(255, 255, 255, 1)",
                        }}
                      >
                        support@modalx.ai
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "981px",
            "@media (max-width : 9911px)": {
              marginTop: "798",
            },
          }}
        >
          <RequestDemoNearFooter />
        </Box>
      </PageLayout>
    </>
  );
}

export default Contact;
