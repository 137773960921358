import React from "react";
import { darkTheme_bg_color } from "../../../constants/colors";
import Image_1 from "../../../assets/LandingPage_Items/Personalized_Img_1.png";
import Image_2 from "../../../assets/LandingPage_Items/Personalized_Img_2.png";
import Image_3 from "../../../assets/LandingPage_Items/Personalized_Img_3.png";
import Image_990_1 from "../../../assets/LandingPage_Items/Personalized_Img_1_990.png";
import Image_990_2 from "../../../assets/LandingPage_Items/Personalized_Img_2_990.png";
import Image_990_3 from "../../../assets/LandingPage_Items/Personalized_Img_3_990.png";
import { Typography, Box, CardMedia } from "@mui/material";

const Personalized = () => {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: darkTheme_bg_color,
        textAlign: "center",

      }}
    >
      {/* <p style={{ textAlign: "center", color: "#F6BE6B" }}>Personalized</p> */}
      <div style={{ maxWidth: "1072px", margin: "auto", textAlign: "center" }}>
        <Typography
          sx={{
            fontSize: "48px",
            marginTop: "80px",
            color: "#FFFFFF",
            opacity : '80%',
            "@media (max-width : 991px)": {
              // width: "358px",
              // height: "136px",
              gap: "24px",
              fontFamily: "Inter",
              fontSize: "28px",
              fontWeight: 500,
              lineHeight: "33.6px",
              textAlign: "center",
              color: "#FFFFFF",
              margin : 'auto',
              marginBottom : '40px',
              padding : '0px 10px 0px 10px',
              
            },
          }}
        >
          Get a custom AI playbook designed for your needs, not a
          one-size-fits-all solution
        </Typography>
      </div>
      <Box
        sx={{
          maxWidth: "1142px",
          height: "390px",
          position: "relative",
          margin: "80px auto",
         
          "@media (max-width : 991px)": {
            display: "none",
          },
        }}
      >
        <div style={{ position: "absolute", bottom: "0", zIndex: "999" }}>
          <img src={Image_1} />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "calc(50% - 135px)",
            left: "calc(50% - 240px)",
            zIndex: "888",
          }}
        >
          <img src={Image_2} alt="Descriptive alt text" />
        </div>
        <div style={{ position: "absolute", top: "0", right: "0" }}>
          <img src={Image_3} />
        </div>
      </Box>

      <Box
        sx={{
          "@media (max-width : 991px)": {
            display: "block",
            // bgcolor : 'red',
            // height : '600px'
            marginBottom : '0px'
          },
          "@media (min-width : 991px)": {
            display: "none",
          },
        }}
      >
        <Box
          sx={{
            maxWidth: "990px",
            minHeight: "436px",
            justifyContent: "center",
            display: "flex",
            // bgcolor : 'blue',
  
          }}
        >
          <Box
            sx={{
              minWidth: "50%",
              minHeight: "436px",
              margin :'auto',
              position : "relative"
            }}
          >
            <Box
              sx={{
                minWidth: "278px",
                // maxHeight: "156px",
                position: "relative",
                zIndex: 200,
              }}
            >
              <CardMedia
                component="img"
                image={Image_990_1}
                alt="Sample Image"
              />
            </Box>
            <Box
              sx={{
                minWidth: "278px",
                // maxHeight: "156px",
                position: "relative",
                top: "-16px",
                left: "20px",
                zIndex: 100,
              }}
            >
              <CardMedia
                component="img"
                image={Image_990_2}
                alt="Sample Image"
              />
            </Box>
            <Box
              sx={{
                minWidth: "278px",
                // maxHeight: "156px",
                position: "relative",
                top: "-32px",
                left: "40px",
                
              }}
            >
              <CardMedia
                component="img"
                image={Image_990_3}
                alt="Sample Image"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Personalized;
