import axios from "axios";
import sourceUrl from "../../../api/Source.api"
const Validationtoken = localStorage.getItem('token');

const PostSendOnLinkedin = async (token, ArrayOfAssesetObject, subId, titleOfPost, cors_url) => {
    // console.log('URLs:', ArrayOfAssesetObject);
    if (!Array.isArray(ArrayOfAssesetObject)) {
        alert("Something went wrong");
    }
    const url = `${cors_url}https://api.linkedin.com/v2/ugcPosts`

    const responsMedia = ArrayOfAssesetObject.map((asset) => {
        let imageObj = {
            "status": "READY",
            "description": {
                "text": "This is an image description."
            },
            "media": asset.asset,
            "title": {
                "text": "Image Title"
            }
        }
        return imageObj;
    }
    )

    let body = {
        "author": `urn:li:person:${subId}`,
        "lifecycleState": "PUBLISHED",
        "specificContent": {
            "com.linkedin.ugc.ShareContent": {
                "shareCommentary": {
                    "text": titleOfPost
                },
                "shareMediaCategory": "IMAGE",
                "media": responsMedia
            }
        },
        "visibility": {
            "com.linkedin.ugc.MemberNetworkVisibility": "PUBLIC"
        }
    }

    try {
        const response = await axios.post(url, body, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        console.log('LinkedIn Post response:', response);
        return response?.data?.id;
    } catch (error) {

        console.error('Error posting on LinkedIn:', error);
    }

}

const PutImageOnGivenUrl = async (token, ArraysofUrls, cors_url) => {
    if (!Array.isArray(ArraysofUrls)) {
        alert("Something is wrong");
    }

    const uploadResults = [];

    const uploadPromises = ArraysofUrls?.map(async (object, i) => {
        const { link, uploadUrl, asset } = object;

        // console.log(link, uploadUrl, `=====================Object ${i}====================`)

        const url = `${cors_url}${uploadUrl}`;

        const response = await fetch(`${cors_url}${link}`);
        const blob = await response.blob(); // Convert response to a Blob

        const formData = new FormData();
        formData.append('file', blob, 'image.jpeg'); // Add the Blob to FormData

        try {
            const respon = await axios.put(url, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            console.log('Upload response:', respon);
            if (respon.status === 201) {
                uploadResults.push({ asset });
            }

        } catch (e) {
            alert("Something is wrong");
            console.log(e)
        }

    })

    // Wait for all upload requests to finish
    await Promise.all(uploadPromises);
    // console.log('Final Upload Results AFter Put request completed:', uploadResults);
    return uploadResults; // Return the results array
}

const PostOnLinked = async (token, subId, urls, cors_url, setLoading) => {
    // Log the URLs to check if they're correctly passed
    // console.log('URLs:', urls);

    if (!Array.isArray(urls)) {
        alert("something went wrong");
    }

    const uploadResults = []; // Array to store the results

    const uploadPromises = urls?.map(async (link, i) => {
        const url = `${cors_url}https://api.linkedin.com/v2/assets?action=registerUpload`;
        const body = {
            registerUploadRequest: {
                recipes: [
                    `urn:li:digitalmediaRecipe:feedshare-image`
                ],
                owner: `urn:li:person:${subId}`,
                serviceRelationships: [
                    {
                        relationshipType: "OWNER",
                        identifier: "urn:li:userGeneratedContent"
                    }
                ]
            }
        };

        try {
            const response = await axios.post(url, body, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            // Extracting uploadUrl and asset from the response
            const { uploadUrl } = response?.data?.value?.uploadMechanism["com.linkedin.digitalmedia.uploading.MediaUploadHttpRequest"];
            const asset = response?.data?.value?.asset;
            uploadResults.push({ link, uploadUrl, asset });
        } catch (error) {
            console.error('Error registering upload:', error);
            uploadResults.push({ error: error.message }); // Store error information if needed
            setLoading(false)
        }
    });

    // Wait for all upload requests to finish
    await Promise.all(uploadPromises);

    // console.log('Final Upload Results:', uploadResults);
    return uploadResults; // Return the results array
};

const regenrateTitle = async (token, message) => {
    console.log(token)
    console.log(message)


    try {
        // const reponse = await axios.post(`http://localhost:8000/captions/regenerate`, {
        const reponse = await axios.post(`${sourceUrl}/captions/regenerate`, {
            "caption": message
        },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        if (reponse.status === 200) {
            return reponse.data.data
        }
        console.log(reponse);
    } catch (err) {
        console.log(err);
    }
}

const GetAccessToken = async () => {
    try {
        const response = await axios.get(`${sourceUrl}/getToken`, {
            headers: {
                Authorization: `Bearer ${Validationtoken}`
            }
        });
        return response
    } catch (err) {
        console.log(err)
    }
}

const StoreAccessToken = async (sub_Id, access_token) => {
    let body = {
        "access_token": access_token,
        "sub_id": sub_Id
    }

    try {
        const response = await axios.post(`${sourceUrl}/storeToken`, body, {
            headers: {
                Authorization: `Bearer ${Validationtoken}`
            }
        });
        return response
    } catch (err) {
        console.log(err)
    }
}


export { regenrateTitle, PostSendOnLinkedin, PutImageOnGivenUrl, PostOnLinked, GetAccessToken, StoreAccessToken }