import { Box, Dialog, IconButton, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { ButtonClick } from "../config/analytics";

function LogOut() {
  const { logout, toggleLogout, setToggleLogout } = useAuth();
  const userEmail = localStorage.getItem("userEmail");

  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    localStorage.setItem("isLoggedIn", "");
    localStorage.setItem("token", "");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("current_thread_id");
    localStorage.removeItem("current_history_id");
    // localStorage.clear();
    localStorage.removeItem("hasVisitedWorkspace");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("sub");
    localStorage.removeItem("userId");
    ButtonClick("user_logout", "logout");
    navigate("/");
    setToggleLogout(false);
  };


  const closefun = () => {
    setToggleLogout(false);
  };

  return (
    
      <>
        <Dialog
          open={toggleLogout}
          close={closefun}
          PaperProps={{
            sx: {
              width: "100%", // Increase the width as needed
              borderRadius: "8px", // Adjust if you want to change the border radius
            },
          }}
        >
          <Box
            sx={{
              maxWidth: "600px",
              // height: "200px",
              borderRadius: "8px",
              border: "1px solid rgba(255, 255, 255, 0.2)",
              bgcolor: "#171A2D",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                // justifyContent: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "28px",
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding : '0px 20px 0px 20px'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    textAlign: "left",
                    color : '#FFFFFF'
                  }}
                >
                  Log out?
                </Typography>
                <Box  sx={{
                    color : '#FFFFFF',
                    '&:hover' :{
                      cursor : "pointer"
                    }
                }} onClick={closefun}>
                    <CloseIcon/>
                    </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                padding : '0px 20px 0px 20px',
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                marginTop: "20px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  textAlign: "left",
                  color : '#FFFFFF'
                }}
              >
                You are currently logged in as <Typography component='span'>{userEmail}</Typography> Are you sure
                you want to log out?
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: "20px",
                width: "100%",
                height: "72px",
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Box
                sx={{
                  width: "226px",
                  height: "40px",
                  marginRight: "16px",
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={closefun}
                  sx={{
                    width: "103px",
                    height: "40px",
                    textTransform: "none",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    textAlign: "center",
                    bgcolor: "#FFFFFF",
                    color: "#17182C",
                    '&:hover': {
                        backgroundColor: '#CDCFDF'

                      },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleLogout}
                  sx={{
                    width: "103px",
                    height: "40px",
                    textTransform: "none",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    textAlign: "center",
                    bgcolor: "#DD2025",
                    color: "#FFFFFF",
                    '&:hover': {
                        backgroundColor: '#E6E0E91F'

                      },
                  }}
                >
                  Log out
                </Button>
              </Box>
            </Box>
          </Box>
        </Dialog>
      </>
    
  );
}

export default LogOut;
