import {
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import RequestDemoNearFooter from "../components/LandingPage/RequestDemoNearFooter";
import LadyImage from "../assets/Marketing_Page/ladyImage.png";
import boyImage from "../assets/Marketing_Page/boyImage.png";
import ladyImageSmallScreen from "../assets/Marketing_Page/ladyImageSmallScreen.png";
import boyImageSmallScreen from "../assets/Marketing_Page/boyImageSmallScreen.png";
import post1 from "../assets/Marketing_Page/Post1.png";
import post2 from "../assets/Marketing_Page/Post2.png";
import post3 from "../assets/Marketing_Page/Post3.png";
import post4 from "../assets/Marketing_Page/Post4.png";
import post5 from "../assets/Marketing_Page/Post5.png";
import post6 from "../assets/Marketing_Page/Post6.png";
import Footer from "../components/navFoot/Footer";
import PageLayout from "../components/navFoot/PageLayout";
import { keyframes } from '@mui/system';

const Posts = [
  { id: 1, image: post1, title: "Instagram Post" },
  { id: 2, image: post2, title: "Pastel theme Brand Launch Presentation" },
  { id: 3, image: post3, title: "Simple Infographic Presentation" },
  { id: 4, image: post4, title: "Simple Infographic Presentation" },
  { id: 5, image: post5, title: "Simple Infographic Presentation" },
  { id: 6, image: post6, title: "Simple Infographic Presentation" },
];

let buttonList = [
  "All",
  "Instagram Posts",
  "Pitch Decks",
  "Product Launch Presentations",
  "Market Research Presentations",
  "Portfolio",
  "Marketing Campaign Strategy",
  "Videos",
];

let SecondboxContent = [
  {
    title: "Cost Effective",
    disc: "Save time and resources by automating your content creation",
  },
  {
    title: "Personalized",
    disc: "Deliver value to clients by creating personalized content",
  },
  {
    title: "Greater Engagement",
    disc: "With ModalX, you are not limited to just text or just images. Use ModalX’s multi-format capabilities to drive maximum engagement",
  },
];

const MarketingAgencies = () => {
  const [selected, setSelected] = useState(0);
  const isBelow991 = useMediaQuery("(max-width:991px)");
  const isBelow1370 = useMediaQuery("(max-width:1370px)");

  const scrollLogos = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

  return (
    <>
      <PageLayout>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignSelf: "center",
            marginBottom: "100px",
            padding: "163px 84px 0px 84px",
            "@media (max-width: 991px)": { padding: "120px 16px 0px 16px" },
          }}
        >
          {/* Page Name Heading */}

          <Box>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "24px",
                textAlign: "center",
                color: "#F6BE6B",
                "@media (max-width: 991px)": {
                  fontSize: "12px",
                },
              }}
            >
              ModalX for Marketing Agencies
            </Typography>
          </Box>

          {/* Second Main Heading \  */}
          <Box
            sx={{
              maxWidth: "1072px",
              margin: "auto",
              textAlign: "center",
              marginTop: "48px",
              "@media (max-width: 991px)": { marginTop: "24px" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "48px",
                fontWeight: 500,
                lineHeight: "57.6px",
                textAlign: "center",
                letterSpacing: "-0.32",
                color: "#FFFFFF",
                opacity: "80%",
                "@media (max-width: 991px)": {
                  fontFamily: "Inter",
                  fontSize: "28px",
                  fontWeight: 500,
                  lineHeight: "33.6px",
                  textAlign: "center",
                  letterSpacing: "-0.32",
                  color: "#FFFFFF",
                  opacity: "80%",
                  minWidth: "358px",
                  margin: "auto",
                  whiteSpace: "wrap",
                },
              }}
            >
              Create high quality content quickly and affordably
            </Typography>
          </Box>

          {/* First Box  Code */}
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "center",
                border: "1px solid rgba(255, 255, 255, 0.10)",
                maxWidth: "1272px",
                borderRadius: "8px",
                background: "#1A1E34",
                marginTop: "80px",
                padding: "48px",
                "@media (max-width: 991px)": {
                  padding: "24px",
                  marginTop: "60px",
                },
                "@media (max-width: 390px)": {
                  padding: "24px",
                  maxWidth: "358px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  "@media (max-width: 390px)": { maxWidth: "310px" },
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "48px",
                    fontWeight: 500,
                    lineHeight: "57.6px",
                    textAlign: "left",
                    letterSpacing: "-0.32",
                    color: "#FFFFFF",
                    opacity: "80%",
                    background:
                      "linear-gradient(90deg, #F6BE6B 0%, rgba(246, 190, 107, 0.60) 100%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text", 
                    WebkitTextFillColor: "transparent", 
                    "@media (max-width: 991px)": {
                      fontFamily: "Inter",
                      fontSize: "24px",
                      fontWeight: 500,
                      lineHeight: "33.6px",
                      letterSpacing: "-0.32",
                      opacity: "80%",
                      minWidth: "310px",
                      margin: "auto",
                      textAlign: "left",
                    },
                  }}
                >
                  Personalized AI Marketing Solutions
                </Typography>
                {isBelow991 ? (
                  <Box
                    sx={{
                      minWidth: "310px",
                      marginTop: "16px",
                      height: "281px",
                      display: "flex",
                      alignSelf: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={ladyImageSmallScreen}
                      height={"281px"}
                      minWidth={"310px"}
                      alt="img"
                    />
                  </Box>
                ) : null}
                <Box
                  sx={{
                    marginTop: "30px",
                    maxWidth: "640px",
                    "@media (max-width: 991px)": { minWidth: "310px" },
                  }}
                >
                  <Typography
                    sx={{
                      color: "rgba(255, 255, 255, 0.80)",
                      fontFamily: "Inter",
                      fontSize: "18px",
                      fontWeight: 500,
                      fontStyle: "normal",
                      lineHeight: "150%", // Equivalent to 21px
                      letterSpacing: "-0.32px",
                      opacity: 0.8,
                      fontFeatureSettings: "'liga' off, 'clig' off", // Custom font feature settings
                      "@media (max-width: 991px)": {
                        minWidth: "310px",
                        alignSelf: "center",
                        fontSize: "14px",
                      },
                    }}
                  >
                    In today’s fast-paced digital world, capturing and retaining
                    your audience’s attention is crucial. Marketing
                    professionals face the challenge of creating engaging,
                    cost-effective content that stands out. ModalX is a
                    game-changer for marketing strategies with its
                    groundbreaking personalized AI technology.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: isBelow991 ? "310px" : "370px",
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "48px",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      width: isBelow991 ? "147px" : "161px",
                      height: "40px",
                      padding: isBelow991 ? "14px 18px" : "10px 20px",
                      borderColor: "#F6BE6A",
                      color: "#F6BE6A",
                      textTransform: "none",
                      fontSize: isBelow991 ? "14px" : "16px",
                      "&:hover": {
                        borderColor: "#F6BE6A",
                        backgroundColor: "transparent", // No border color changes on hover
                      },
                      fontFamily: "Inter, sans-serif",
                    }}
                  >
                    Request demo
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      width: isBelow991 ? "147px" : "173px",
                      height: "40px",
                      padding: isBelow991 ? "14px 16px" : "10px 20px",
                      backgroundColor: "#F6BE6A",
                      color: "#17182C",
                      fontWeight: "600",
                      textTransform: "none",
                      fontSize: isBelow991 ? "14px" : "16px",
                      "&:hover": {
                        backgroundColor: "#ffd9aa", // Changes background color on hover
                      },
                      fontFamily: "Inter, sans-serif",
                    }}
                  >
                    Get started free
                  </Button>
                </Box>
              </Box>

              {/* Right Side  */}
              {isBelow991 ? null : (
                <Box>
                  <img src={LadyImage} alt="img" />
                </Box>
              )}
            </Box>
          </Box>

          {/*  Second Box Code */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              border: "1px solid rgba(255, 255, 255, 0.10)",
              justifyContent: "space-between",
              maxWidth: "1272px",
              borderRadius: "8px",
              background: "#1A1E34",
              marginTop: "80px",
              padding: "48px",
              "@media (max-width: 991px)": {
                padding: "24px",
                marginTop: "40px",
              },
              "@media (max-width: 390px)": {
                padding: "24px",
                maxWidth: "358px",
              },
            }}
          >
            {/* Left Side  */}
            <Box sx={{}}>
              {isBelow991 ? null : <img src={boyImage} alt="img" />}{" "}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "40px",
                "@media (max-width: 991px)": { marginLeft: "0px" },
                "@media (max-width: 390px)": { maxWidth: "310px" },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontSize: "48px",
                  fontWeight: 500,
                  lineHeight: "57.6px",
                  textAlign: "left",
                  letterSpacing: "-0.32",
                  color: "#FFFFFF",
                  opacity: "80%",
                  maxWidth: "689px",
                  "@media (max-width: 991px)": {
                    fontFamily: "Inter",
                    fontSize: "28px",
                    fontWeight: 500,
                    lineHeight: "33.6px",
                    letterSpacing: "-0.32",
                    color:
                      "linear-gradient(90deg, #F6BE6B 0%, rgba(246, 190, 107, 0.60) 100%)",
                    opacity: "80%",
                    margin: "auto",
                    textAlign: "left",
                  },
                }}
              >
                Create Content that Converts
              </Typography>

              {isBelow991 ? (
                <Box
                  sx={{
                    minWidth: "310px",
                    marginTop: "16px",
                    height: "281px",
                    display: "flex",
                    alignSelf: "center",
                  }}
                >
                  <img
                    src={boyImageSmallScreen}
                    height={"260px"}
                    Width={"310px"}
                    alt="img"
                  />
                </Box>
              ) : null}
              <Box
                sx={{
                  marginTop: "28px",
                  "@media (max-width: 991px)": { marginTop: "0px" },
                }}
              >
                {SecondboxContent.map((ele, index) => {
                  return (
                    <Box
                      sx={{
                        marginTop: "20px",
                        maxWidth: "640px",
                        "@media (max-width: 991px)": {
                          minWidth: "310px",
                          marginTop: "16px",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#FFF",
                          fontFeatureSettings: "'liga' off, 'clig' off",
                          fontFamily: "Inter",
                          fontSize: "24px",
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "150%",
                          letterSpacing: "-0.32px",
                          opacity: 0.8,
                          "@media (max-width: 991px)": {
                            fontSize: "16px",
                            lineHeight: "150%",
                            letterSpacing: "-0.32px",
                            opacity: 0.8,
                            minWidth: "310px",
                          },
                        }}
                      >
                        {ele.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: "rgba(255, 255, 255, 0.80)",
                          fontFamily: "Inter",
                          fontSize: "20px",
                          fontWeight: 500,
                          fontStyle: "normal",
                          lineHeight: "150%", // Equivalent to 21px
                          letterSpacing: "-0.32px",
                          opacity: 0.8,
                          fontFeatureSettings: "'liga' off, 'clig' off", // Custom font feature settings
                          "@media (max-width: 991px)": {
                            minWidth: "310px",
                            alignSelf: "center",
                            fontSize: "14px",
                            marginTop: "8px",
                          },
                        }}
                      >
                        {ele.disc}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>

          {/*  Third Box Code */}
          <Box
            sx={{
              gap: "48px",
              opacity: "0px",
              top: "246px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              "@media (max-width: 900px)": {
                width: "100%",
              },
              "@media (max-width: 1400px)": {
                width: "100%",
              },
            }}
          >
            <div
              style={{
                maxWidth: "1096pxpx",
                margin: "auto",
                textAlign: "center",
                marginTop: "48px",
                "@media (max-width: 991px)": { marginTop: "24px" },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "48px",
                  fontWeight: 500,
                  lineHeight: "57.6px",
                  textAlign: "center",
                  letterSpacing: "-0.32",
                  color: "#FFFFFF",
                  opacity: "80%",
                  "@media (max-width: 991px)": {
                    fontFamily: "Inter",
                    fontSize: "24px",
                    fontWeight: 500,
                    lineHeight: "33.6px",
                    textAlign: "center",
                    letterSpacing: "-0.32",
                    color: "#FFFFFF",
                    opacity: "80%",
                    minWidth: "358px",
                    margin: "auto",
                  },
                }}
              >
                Generate Content for All your Marketing Needs
              </Typography>
            </div>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "16px",
                  height: "32px",
                  width: "fit-content",
                  scrollbarWidth: "none", 
                  msOverflowStyle: "none", 
                  "&::-webkit-scrollbar": {
                    display: "none", 
                  },
                  scrollBehavior: "smooth", 

                  "@media(max-width: 900px)": {
                    gap: "8px", 
                  },
                  animation: isBelow1370 ? `${scrollLogos} 20s linear infinite` : "none"
                }}
              >
                {buttonList.map((title, index) => (
                  <Button
                    key={index}
                    onClick={() => setSelected(index)}
                    sx={{
                      flex: "0 0 auto", 
                      minWidth: "auto",
                      height: "32px",
                      backgroundColor:
                        selected === index
                          ? "rgba(202, 196, 208, 1)"
                          : "rgba(31, 35, 62, 1)",
                      "&:hover": {
                        backgroundColor:
                          selected === index
                            ? "rgba(202, 196, 208, 1)"
                            : "rgba(31, 35, 62, 1)",
                      },
                      borderRadius: "4px",
                    }}
                    variant="contained"
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter, sans-serif",
                        fontSize: "10px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        textAlign: "center",
                        color:
                          selected === index
                            ? "rgba(23, 24, 44, 1)"
                            : "rgba(202, 196, 208, 1)",
                        whiteSpace: "nowrap", 
                        overflow: "hidden",
                        textOverflow: "ellipsis", 
                      }}
                    >
                      {title}
                    </Typography>
                  </Button>
                ))}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%", 
                  maxWidth: "1400px", 
                  display: "flex",
                  justifyContent: "center",
                  padding: "0 16px", 
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)", 
                    gap: "60px", 
                    "@media(max-width: 1400px)": {
                      gridTemplateColumns: "repeat(2, 1fr)", 
                    },
                    "@media(max-width: 900px)": {
                      gridTemplateColumns: "repeat(1, 1fr)",
                    },
                  }}
                >
                  {Posts.map((ele) => (
                    <Grid item key={ele.id}>
                      <Box
                        sx={{
                          margin: "auto",
                          gap: "17px",
                        }}
                      >
                        <Box
                          sx={{
                            borderRadius: "4px",
                          }}
                        >
                          <Card>
                            <CardMedia
                              component="img"
                              image={ele.image}
                              alt="Example"
                            />
                          </Card>
                        </Box>
                        <Box
                          sx={{
                            height: "21px",
                            marginTop: "17px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Inter",
                              fontSize: "16px",
                              fontWeight: "400px",
                              lineHeight: "20.8px",
                              textAlign: "center",
                            }}
                          >
                            {ele.title}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <RequestDemoNearFooter />
      </PageLayout>
    </>
  );
};

export default MarketingAgencies;
