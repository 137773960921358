import React from "react";
import PageLayout from "../components/navFoot/PageLayout";

const ApiDocs = () => {
  return (
    <PageLayout>
      <h1>API Docs</h1>
      <hr></hr>
      <h4>Coming Soon!...</h4>
    </PageLayout>
  );
};

export default ApiDocs;
