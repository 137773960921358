import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

export const ToggleSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 50, // Increased width
    height: 28, // Increased height
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(22px)', // Increased translation for larger switch
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#4CAF50', // Custom checked color
          opacity: 1,
          border: 0,
          ...theme.applyStyles('dark', {
            backgroundColor: '#388E3C',
          }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
        ...theme.applyStyles('dark', {
          color: theme.palette.grey[600],
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
        ...theme.applyStyles('dark', {
          opacity: 0.3,
        }),
      },
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Adding a subtle hover effect
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 24, // Increased thumb size
      height: 24, // Increased thumb size
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // Added shadow to thumb
      '&:hover': {
        backgroundColor: '#66bb6a', // Hover color for thumb when checked
      },
    },
    '& .MuiSwitch-track': {
      borderRadius: 14, // Rounded track
      backgroundColor: '#E0E0E0', // Unchecked color
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      ...theme.applyStyles('dark', {
        backgroundColor: '#424242',
      }),
    },
  })
);
