// import React, { useState, useEffect } from 'react';
// import defaultIcon from "../../../../assets/Website Tracking/DefaultIconFor_Websites.png";
// import defaultIconWithPlus from "../../../../assets/web-plus.png";
// import axios from 'axios';
// import sourceUrl from '../../../../api/Source.api';
// import { Box, Typography, Button, Tooltip } from '@mui/material';
// import { useWorkspace } from "../../../../contexts/WorkspaceContext";
// import AddIcon from '@mui/icons-material/Add';
// import CircleIcon from '@mui/icons-material/Circle';
// import TrackWebsiteModal from './TrackWebsiteModal';


// const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const options = { month: 'long', day: 'numeric' };
//     return date.toLocaleDateString(undefined, options);
//   };



// const WebsiteListingPage = ({ website }) => {
//   // const [website, setWebSite] = useState(website || []);
//   const token = localStorage.getItem('token');
//   const {setOpenWebsiteTrackModal,setCurrentWebsiteLinkedId,setSelectedWeb} = useWorkspace();
//   // const [addWebClicked, setAddWebClicked] = useState(false);

// //   ==================== start  Default component if there is no websites added yet =================================

//   const DefaultScreenIfNoWebsites = () => {
//     return(
//         <Box sx={{width:'100%',maxWidth:'312px',height:'229px',margin:'auto',display:'flex',flexDirection:'column',justifyContent:'space-between',alignItems:'center'}}>
//             <Box sx={{ width: '60px', height: '60px', display: 'flex', alignItems: 'center' }}>
//                 <img src={defaultIconWithPlus} alt="website" style={{ width: '100%', height: '100%', display: 'block' }} />
//             </Box>
//             <Typography sx={{fontSize:'16px',fontWeight:600}}>Start by adding a website</Typography>
//             <Typography sx={{fontSize:'14px',fontWeight:400}}>You have not added any websites to track yet. Get started by adding a website.</Typography>
//             <Button
//               onClick={() => { setOpenWebsiteTrackModal(true) }}
//               sx={{
//                 backgroundColor: 'white',
//                 border: 'none',
//                 padding: 0,
//                 margin: 0,
//                 textTransform: 'none',
//                 color: '#17182C',
//                 '&:hover': { backgroundColor: 'transparent',color:'rgba(255,255,255,0.8)' },
//                 width:'164px',
//                 height:'40px',
//                 fontWeight:600,
//                 fontFamily:'inter'
//               }}
//             >
//               <AddIcon sx={{width:'20px',height:'20px',marginRight:'8px'}} />
//               Add website
//             </Button>
//         </Box>
//     )
// }

// //   ==================== end  Default component if there is no websites added yet =================================

//   // useEffect(() => {
//   //   fetchWebsites();
//   // }, [addWebClicked]);

//   // const fetchWebsites = async () => {
//   //   try {
//   //     const res = await axios.get(`${sourceUrl}/websiteTracking/listAll`, {
//   //       headers: {
//   //         Authorization: `Bearer ${token}`,
//   //         "Content-Type": "application/json",
//   //       },
//   //     });
//   //     const sortedData = res.data.data.sort((a, b) => {
//   //       if (a.notifications === b.notifications) return 0;
//   //       return a.notifications ? -1 : 1;
//   //     });
//   //     setWebSite(sortedData);
//   //   } catch (error) {
//   //     console.error(error);
//   //   }
//   // };

//   const getSingleWebTrackDetails = async (id) => {
//     setCurrentWebsiteLinkedId(id.linkId)
//     setSelectedWeb(id.displayName);
//     // try {
//     //   const res = await axios.get(`${sourceUrl}/websiteTracking/get/${id}`,{
//     //     headers:{
//     //       "Authorization":`Bearer ${token}`,
//     //       "Content-Type":"application/json"
//     //     }
//     //   })
//     //   const responseData = await res.json();
//     //   return responseData.data ;
//     // } catch (error) {
//     //   console.log(error)
//     // }
//   }

//   // const handleAddWebClicked = () => {
//   //   setAddWebClicked(!addWebClicked);
//   // };


  

//   return (
//     <Box sx={{paddingTop: '60px', 
//         display: 'flex', 
//         justifyContent: 'center', 
//         alignItems: 'center', 
//         textAlign: 'center', 
//         width: '100%'}}>
//       <Box sx={{ fontWeight: 500}}>
//         {/* <Typography variant="h5" sx={{ marginBottom: '48px', fontSize: '24px', textTransform: 'none',gap:'20px'}}>Track sites with ModalX 
//         <Button
//             sx={{
//                 textTransform: 'none',
//                 padding: '4px 8px',
//                 bgcolor: '#E6E0E9',
//                 fontSize: '12px',
//                 fontWeight: '500',
//                 color: '#17182C',
//                 '&:hover': {
//                 bgcolor: '#E6E0E9', // Keeps background color the same on hover
//                 color: '#17182C', // Keeps text color the same on hover
                
//                 },
//             }}
//             >
//             Beta
//         </Button>
//         </Typography> */}
//         <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px', marginBottom: '48px' }}>

//             <Typography 
//              sx={{ 
//               fontFamily : 'Inter',
//               fontSize: '24px',
//               fontWeight: '500',
//               lineHeight: '24px',
//               letterSpacing: '0.10000000149011612px',
//               color : '#FFFFFF',
//                }}>
//             Track sites with ModalX
//             </Typography>
//             <Button
//             sx={{
//                 textTransform: 'none',
//                 padding: '4px 8px',
//                 bgcolor: '#E6E0E9',
//                 fontSize: '12px',
//                 fontWeight: '500',
//                 color: '#17182C',
//                 '&:hover': {
//                 bgcolor: '#E6E0E9', // Keeps background color the same on hover
//                 color: '#17182C',    // Keeps text color the same on hover
//                 },
//             }}
//             >
//             Beta
//             </Button>
//         </Box>

//         <Box sx={{ maxWidth: '688px', minHeight: '88px', fontSize: '16px', textTransform: 'none',margin:'auto',textAlign:'start'}}>
//           <Typography sx={{ marginBottom: '18px',
//              textTransform: 'none',
//              fontFamily : 'Inter',
//              fontWeight  : 400,
//              fontSize : '16px',
//              textAlign : 'center',
//              color : '#FFFFFF',
//               }}>
//             Stay up to date with the latest updates and information on any webpage.
//           </Typography>
//           <Typography
//            sx={{ textTransform: 'none',
//                   fontFamily : 'Inter',
//                   fontWeight  : 400,
//                   fontSize : '16px',
//                   textAlign : 'center',
//                   color : '#FFFFFF',
//            }}>
//             Give ModalX an URL to track, and you will get notified whenever there is an update on the site, along with a summary of the update!
//           </Typography>
//         </Box>
//         <Box sx={{marginBottom: '40px',marginTop: '60px', }}>
//           <Box
//             sx={{
//               width: '688px',
//               height: '40px',
//               display: 'flex',
//               justifyContent: 'space-between',
//               alignItems: 'center',
//               paddingBottom: '20px',
//               borderBottom: '1px solid rgba(255,255,255,0.6)',
              
//               textTransform: 'none',margin:'auto'
//             }}
//           >
//             <Typography sx={{ 
//               margin: 0,
//               textTransform: 'none',
//               fontfontFamily : 'Inter',
//               fontSize : '16px',
//               fontWeight : 400,
//               color : '#FFFFFF',
               
//              }}>Your sites</Typography>
//             <Button
//               onClick={() => { setOpenWebsiteTrackModal(true) }}
//               sx={{
//                 backgroundColor: 'transparent',
//                 border: 'none',
//                 padding: 0,
//                 margin: 0,
//                 textTransform: 'none',
//                 color: 'rgba(255,255,255,1)',
//                 '&:hover': { backgroundColor: 'transparent',color:'rgba(255,255,255,0.8)' },
//               }}
//             >
//               <AddIcon sx={{width:'20px',height:'20px',marginRight:'8px'}} />
//               <Typography sx={{
//                 fontFamily : 'Inter',
//                 fontSize : '16px',
//                 fontWeight : 600,
//                 // color : '#FFFFFF',
//               }}>Add website</Typography>
             
//             </Button>
//           </Box>
//         </Box>
//         <Box sx={{ maxHeight: '300px', overflowY: 'auto',maxWidth: '688px', }}>
//           {website.length === 0 ? <DefaultScreenIfNoWebsites /> : website.map((ele, index) => (
//             <Button
//               key={index}
//               sx={{
//                 width: '100%',
//                 maxWidth: '688px',
//                 height: '75px',
//                 borderRadius: '8px',
//                 border: '1px solid rgba(255,255,255,0.2)',
//                 backgroundColor: 'transparent',
//                 marginBottom: '16px',
//                 textTransform: 'none',
//                 '&:hover': { backgroundColor: 'transparent' }
//               }}
//               onClick={() => getSingleWebTrackDetails(ele)}
//             >
//               <Box sx={{ width: '100%', height: '100%', padding: '16px', display: 'flex',  alignItems: 'center' }}>
//                 <Box sx={{display:"flex", gap:"16px", width:"480px"}}>
//                 <Box sx={{ width: '40px', height: '40px', display: 'flex', alignItems: 'center' }}>
//                   <img src={ele?.websiteLogoUrl?.length > 0 ? ele.websiteLogoUrl : defaultIcon} alt="website" style={{ width: '100%', height: '100%', display: 'block' }} />
//                 </Box>
//                 <Box sx={{ fontSize: '16px', display: 'flex', flexDirection: 'column', height: '43px', textAlign: 'start', textTransform: 'none' }}>
//                     {ele?.displayName?.length > 0 && (
//                         <Tooltip title={ele.displayName} placement="top">
//                             <Typography sx={{ marginBottom: '0px', textTransform: 'none', color: 'rgba(255,255,255,1)' }}>
//                             {ele.displayName.length > 50 ? `${ele.displayName.substring(0, 50)}...` : ele.displayName}
//                             </Typography>
//                         </Tooltip>
//                     )}
//                   {ele?.websiteLink?.length > 0 && (
//                     <Typography sx={{ fontSize: '14px', color: 'rgba(255,255,255,0.8)', textTransform: 'none' }}>
//                       {ele?.websiteLink?.length > 50 ? `${ele?.websiteLink?.substring(0, 50)}...` : ele?.websiteLink}
//                     </Typography>
//                   )}
//                 </Box>
//                 </Box>
//                 <Box
//                     sx={{
//                         maxWidth: '158px',
//                         height: '14px',
//                         fontSize: '12px',
//                         textTransform: 'none',
//                         color: 'rgba(255,255,255,1)',
//                         display: 'flex',
//                         alignItems: 'center', 
//                         textAlign:"start",
//                         justifyContent:"flex-start"
//                     }}
//                     >
//                     <Typography sx={{ textTransform: 'none', fontSize: '12px',paddingRight:ele?.notifications === false && '12px' }}>
//                         {`Updated ${formatDate(ele.updatedAt)}`}
//                     </Typography>
//                     {ele?.notifications === true && <CircleIcon sx={{ color: '#F6BE6B', width: '12px', height: '12px', marginLeft: '4px' }} />}
//                 </Box>
//               </Box>
//             </Button>
//           ))}
//         </Box>
//       </Box>
//       {/* <TrackWebsiteModal
//         websites={website}
//         onAddWebClicked={handleAddWebClicked}
//       /> */}
//     </Box>
//   );
// };

// export default WebsiteListingPage;


//===================================================Shaban Khan Code start here with mobile===================================

import React, { useState, useEffect } from 'react';
import defaultIcon from "../../../../assets/Website Tracking/DefaultIconFor_Websites.png";
import defaultIconWithPlus from "../../../../assets/web-plus.png";
import axios from 'axios';
import sourceUrl from '../../../../api/Source.api';
import { Box, Typography, Button, Tooltip, FormControlLabel } from '@mui/material';
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import AddIcon from '@mui/icons-material/Add';
import CircleIcon from '@mui/icons-material/Circle';
import TrackWebsiteModal from './TrackWebsiteModal';
import { useMediaQuery } from 'react-responsive';
import WarRoomHeaderForMobileResponsive from '../../mobileResponsive/WarRoomHeaderForMobileResponsive';
import { ToggleSwitch } from '../../../Common_Components/ToggleSwitch';


const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };



const WebsiteListingPage = ({ website,updateWebList }) => {
  // const [website, setWebSite] = useState(website || []);
  const isUnder992px = useMediaQuery({ maxWidth:992});
  const token = localStorage.getItem('token');
  const {setOpenWebsiteTrackModal,setCurrentWebsiteLinkedId,setSelectedWeb} = useWorkspace();
  // const [addWebClicked, setAddWebClicked] = useState(false);

//   ==================== start  Default component if there is no websites added yet =================================

  const DefaultScreenIfNoWebsites = () => {
    return(
        <Box sx={{width:'100%',maxWidth:'312px',height:'229px',margin:'auto',display:'flex',flexDirection:'column',justifyContent:'space-between',alignItems:'center'}}>
            <Box sx={{ width: '60px', height: '60px', display: 'flex', alignItems: 'center' }}>
                <img src={defaultIconWithPlus} alt="website" style={{ width: '100%', height: '100%', display: 'block' }} />
            </Box>
            <Typography sx={{fontSize:'16px',fontWeight:600}}>Start by adding a website</Typography>
            <Typography sx={{fontSize:'14px',fontWeight:400}}>You have not added any websites to track yet. Get started by adding a website.</Typography>
            <Button
              onClick={() => { setOpenWebsiteTrackModal(true) }}
              sx={{
                backgroundColor: 'white',
                border: 'none',
                padding: 0,
                margin: 0,
                textTransform: 'none',
                color: '#17182C',
                '&:hover': { backgroundColor: 'transparent',color:'rgba(255,255,255,0.8)' },
                width:'164px',
                height:'40px',
                fontWeight:600,
                fontFamily:'inter'
              }}
            >
              <AddIcon sx={{width:'20px',height:'20px',marginRight:'8px'}} />
              Add website
            </Button>
        </Box>
    )
}

//   ==================== end  Default component if there is no websites added yet =================================

  // useEffect(() => {
  //   fetchWebsites();
  // }, [addWebClicked]);

  // const fetchWebsites = async () => {
  //   try {
  //     const res = await axios.get(`${sourceUrl}/websiteTracking/listAll`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     const sortedData = res.data.data.sort((a, b) => {
  //       if (a.notifications === b.notifications) return 0;
  //       return a.notifications ? -1 : 1;
  //     });
  //     setWebSite(sortedData);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getSingleWebTrackDetails = async (id) => {
    setCurrentWebsiteLinkedId(id.linkId)
    setSelectedWeb(id.displayName);
    // try {
    //   const res = await axios.get(`${sourceUrl}/websiteTracking/get/${id}`,{
    //     headers:{
    //       "Authorization":`Bearer ${token}`,
    //       "Content-Type":"application/json"
    //     }
    //   })
    //   const responseData = await res.json();
    //   return responseData.data ;
    // } catch (error) {
    //   console.log(error)
    // }
  }

  // const handleAddWebClicked = () => {
  //   setAddWebClicked(!addWebClicked);
  // };

  const handleEnable = async (id) => {
    try {
        const res = await axios.post(`${sourceUrl}/websiteTracking/enable/${id}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        
        if (res.status) {
            console.log("Enable API call succeeded.");
        }
    } catch (error) {
        console.error("Failed to update status:", error);
    }
    // finally {
    //   updateWebList();
    // }
};

const handleDisable = async (id) => {
    try {
        const res = await axios.post(`${sourceUrl}/websiteTracking/disable/${id}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        
        if (res.status) {
            console.log("Disable API call succeeded.");
        }
    } catch (error) {
        console.error("Failed to update status:", error);
    }
    // finally {
    //   updateWebList();
    // }
};


  

  return (
    <>  <WarRoomHeaderForMobileResponsive websitetrack={"websitetrack"}/>
    <Box sx={{paddingTop: '60px', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        textAlign: 'center', 
        width: '100%'}}>
      <Box sx={{ fontWeight: 500}}>
        {/* <Typography variant="h5" sx={{ marginBottom: '48px', fontSize: '24px', textTransform: 'none',gap:'20px'}}>Track sites with ModalX 
        <Button
            sx={{
                textTransform: 'none',
                padding: '4px 8px',
                bgcolor: '#E6E0E9',
                fontSize: '12px',
                fontWeight: '500',
                color: '#17182C',
                '&:hover': {
                bgcolor: '#E6E0E9', // Keeps background color the same on hover
                color: '#17182C', // Keeps text color the same on hover
                
                },
            }}
            >
            Beta
        </Button>
        </Typography> */}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px', marginBottom:isUnder992px ? '36px' : '48px',}}>

            <Typography 
             sx={{ 
              fontFamily : 'Inter',
              fontSize:isUnder992px ? '20px' : '24px',
              fontWeight: '500',
              lineHeight: '24px',
              letterSpacing: '0.10000000149011612px',
              color : '#FFFFFF',
               }}>
            Track sites with ModalX
            </Typography>
            <Button
            sx={{
                textTransform: 'none',
                padding:isUnder992px ? '2px 8px' : '4px 8px',
                bgcolor: '#E6E0E9',
                fontSize: '12px',
                fontWeight: '500',
                color: '#17182C',
                '&:hover': {
                bgcolor: '#E6E0E9', // Keeps background color the same on hover
                color: '#17182C',    // Keeps text color the same on hover
                },
            }}
            >
            Beta
            </Button>
        </Box>

        <Box sx={{ maxWidth: isUnder992px ? '90%' : '688px', minHeight: '88px', fontSize: '16px', textTransform: 'none',margin:'auto',textAlign:'start',}}>
          <Typography sx={{ marginBottom: '18px',
             textTransform: 'none',
             fontFamily : 'Inter',
             fontWeight  : 400,
             fontSize : isUnder992px ? '12px' : '16px',
             textAlign : 'center',
             color : '#FFFFFF',
              }}>
            Stay up to date with the latest updates and information on any webpage.
          </Typography>
          <Typography
           sx={{ textTransform: 'none',
                  fontFamily : 'Inter',
                  fontWeight  : 400,
                  fontSize :isUnder992px ? '12px' : '16px',
                  textAlign : 'center',
                  color : '#FFFFFF',
           }}>
            We are tracking the sites below already, but you can also add your own!  Give ModalX an URL to track, and you will get notified whenever there is an update on the site, along with a summary of the update!
          </Typography>
        </Box>
        <Box sx={{marginBottom: '40px',marginTop: '60px' }}>
          <Box
            sx={{
              width:isUnder992px ? '90%' : '688px',
              height: '40px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingBottom: '20px',
              borderBottom: '1px solid rgba(255,255,255,0.6)',
              
              textTransform: 'none',margin:'auto'
            }}
          >
            <Typography sx={{ 
              margin: 0,
              textTransform: 'none',
              fontfontFamily : 'Inter',
              fontSize : '16px',
              fontWeight : 400,
              color : '#FFFFFF',
               
             }}>Tracked sites</Typography>
            <Button
              onClick={() => { setOpenWebsiteTrackModal(true) }}
              sx={{
                backgroundColor: 'transparent',
                border: 'none',
                padding: 0,
                margin: 0,
                textTransform: 'none',
                color: 'rgba(255,255,255,1)',
                '&:hover': { backgroundColor: 'transparent',color:'rgba(255,255,255,0.8)' },
              }}
            >
              <AddIcon sx={{width:'20px',height:'20px',marginRight:'8px'}} />
              <Typography sx={{
                fontFamily : 'Inter',
                fontSize : '16px',
                fontWeight : 600,
                // color : '#FFFFFF',
              }}>Add website</Typography>
             
            </Button>
          </Box>
        </Box>
        <Box sx={{ maxHeight: '300px', overflowY: 'auto',maxWidth:isUnder992px ? '90%' : '688px',margin:'auto'}}>
          {website.length === 0 ? <DefaultScreenIfNoWebsites /> : website.map((ele, index) => (
            <Box
              key={index}
              sx={{
                width: isUnder992px ? '90%' : '100%',
                maxWidth: isUnder992px ? '90%' : '688px',
                height: '75px',
                borderRadius: '8px',
                border: '1px solid rgba(255,255,255,0.2)',
                backgroundColor: 'transparent',
                marginBottom: '16px',
                padding: isUnder992px && '0px',
                textTransform: 'none',
                '&:hover': { backgroundColor: 'transparent' },
              }}
              // onClick={() => getSingleWebTrackDetails(ele)}
            >
              <Box sx={{ width: '100%', height: '100%', padding:  isUnder992px ? '12px' : '16px', display: 'flex',  alignItems: 'center', margin:  isUnder992px ? '0px' : ''}}>
                <Box sx={{display:"flex", gap:"16px", width: isUnder992px ? '100%' :"480px",cursor:'pointer'}} onClick={() => getSingleWebTrackDetails(ele)}>
                <Box sx={{ width: '40px', height: '40px', display: 'flex', alignItems: 'center',position:'relative' }}>
                  <img src={ele?.websiteLogoUrl?.length > 0 ? ele.websiteLogoUrl : defaultIcon} alt="website" style={{ width: '100%', height: '100%', display: 'block' }} />
                  {ele?.notifications === true && <CircleIcon sx={{ color: '#F6BE6B', width: '12px', height: '12px', position:'absolute',top:'0px' }} />}
                </Box>
                <Box sx={{ fontSize: '16px', display: 'flex', flexDirection: 'column', height: '43px', textAlign: 'start', textTransform: 'none' }}>
                    {ele?.displayName?.length > 0 && (
                        <Tooltip title={ele.displayName} placement="top">
                           
                              {
                                isUnder992px ? 
                                <Typography sx={{ marginBottom: '0px', textTransform: 'none', color: 'rgba(255,255,255,1)' }}>
                                {ele.displayName.length > 15 ? `${ele.displayName.substring(0, 15)}...` : ele.displayName}
                                 </Typography>
                                 : 
                                <Typography sx={{ marginBottom: '0px', textTransform: 'none', color: 'rgba(255,255,255,1)' }}>
                                {ele.displayName.length > 50 ? `${ele.displayName.substring(0, 50)}...` : ele.displayName}
                                 </Typography>
                              }
                        </Tooltip>
                    )}
                  {ele?.websiteLink?.length > 0 && (
                    
                      isUnder992px ? 
                      <Typography sx={{ fontSize: '14px', color: 'rgba(255,255,255,0.8)', textTransform: 'none' }}>
                      {ele?.websiteLink?.length > 30 ? `${ele?.websiteLink?.substring(0, 30)}...` : ele?.websiteLink}
                      </Typography>
                        : 
                        <Typography sx={{ fontSize: '14px', color: 'rgba(255,255,255,0.8)', textTransform: 'none' }}>
                      {ele?.websiteLink?.length > 50 ? `${ele?.websiteLink?.substring(0, 50)}...` : ele?.websiteLink}
                        </Typography>
                    
                    
                  )}
                </Box>
                </Box>
                <Box
                    sx={{
                        maxWidth: '175px',
                        height: '14px',
                        fontSize: '12px',
                        textTransform: 'none',
                        color: 'rgba(255,255,255,1)',
                        display: 'flex',
                        alignItems: 'center', 
                        textAlign:"start",
                        justifyContent:"flex-start"
                    }}
                    >
                    {/* <Typography sx={{ textTransform: 'none', fontSize: '12px',paddingRight:ele?.notifications === false && '12px', flexShrink:isUnder992px && "0" }}>
                      {
                          isUnder992px ?  `${formatDate(ele.updatedAt)}`.substring(0, 6) : `Updated ${formatDate(ele.updatedAt)}`
                      }
                        
                    </Typography> */}
                    {/* {ele?.notifications === true && <CircleIcon sx={{ color: '#F6BE6B', width: '12px', height: '12px', marginLeft: '4px' }} />} */}
                    {!isUnder992px && <FormControlLabel
                      control={<ToggleSwitch sx={{ m: 1 }} defaultChecked={ele?.status === "enabled"} onClick={() => ele?.status === "enabled"?handleDisable(ele?.linkId):handleEnable(ele?.linkId)} />}
                      label="Enable tracking"
                      labelPlacement="start"
                      sx={{
                        whiteSpace: 'nowrap',   // Prevents text wrapping
                        overflow: 'hidden',     // Ensures it doesn't overflow container
                        textOverflow: 'ellipsis', // Adds "..." if text is too long for container
                        '& .MuiFormControlLabel-label': { fontSize: '14px' },
                      }}
                    />}

                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      {/* <TrackWebsiteModal
        websites={website}
        onAddWebClicked={handleAddWebClicked}
      /> */}
    </Box></>
  );
};

export default WebsiteListingPage;

