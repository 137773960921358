import React, { createContext, useContext, useState, useEffect } from "react";

const UIContext = createContext(null);

export const useUI = () => {
  return useContext(UIContext);
};

const initializeTheme = () => {
  const setStoredTheme = (theme) => localStorage.setItem("theme", theme);
  const getStoredTheme = () => localStorage.getItem("theme");

  if (!getStoredTheme()) {
    setStoredTheme(
      window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
    );
  }

  return getStoredTheme();
};

const UIProvider = ({ children }) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [currentTheme, setCurrentTheme] = useState(initializeTheme());

  useEffect(() => {
    document.documentElement.setAttribute("data-bs-theme", currentTheme);
  }, [currentTheme]);

  const toggleTheme = () => {
    const newTheme = currentTheme === "dark" ? "light" : "dark";
    setCurrentTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  return (
    <UIContext.Provider
      value={{ showLoginModal, setShowLoginModal, toggleTheme, currentTheme }}
    >
      {children}
    </UIContext.Provider>
  );
};

export default UIProvider;
