import sourceUrl from "../../../../api/Source.api";
import { avatarVoiceLanguages } from '../../../../constants/avatarVoiceLanguages';
const token = localStorage.getItem('token');


const VideoSettingsAPI = (setCategories,setBackgrounds,setSelectedCategory ,setAvatars, setLanguages,setVoices, avatarsloading, setAvatarsLoading, voiceloading, setVoiceLoading  ) =>{

  const fetchAvatars = async () => {
    try {
      const response = await fetch(`${sourceUrl}/video/avatar`, {
        headers: { "Authorization": `Bearer ${token}` }
      });
      if (!response.ok) {
        throw new Error("Failed to fetch avatars");
      }
      const json = await response.json();
      setAvatars(json["data"]["data"]);
    } catch (error) {
      console.error("Error fetching avatars:", error.message);
    }
    setAvatarsLoading(false);
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${sourceUrl}/video/backgroundCategory`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok)
        throw new Error("Failed to fetch background categories");
      const json = await response.json();
      setCategories(json.data);
      setSelectedCategory(json.data[0]);
    } catch (error) {
      console.error("Error fetching background categories:", error.message);
    }
  };


  const fetchBackgrounds = async () => {
    try {
      const response = await fetch(`${sourceUrl}/video/backgroundImage`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) throw new Error("Failed to fetch background images");
      const json = await response.json();
      setBackgrounds(json.data.data);
    } catch (error) {
      console.error("Error fetching background images:", error.message);
    }
  };

 const fetchVoices = async () => {
    try {
      const response = await fetch(`${sourceUrl}/video/avatarVoice`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error("Failed to fetch voices");
      }
      const json = await response.json();
      const voiceData = json.data.data;
      setLanguages(avatarVoiceLanguages);
      setVoices(voiceData);
    } catch (error) {
      console.error("Error fetching voices:", error.message);
    }
    setVoiceLoading(false)
  };

    fetchAvatars()
    fetchCategories()
    fetchBackgrounds()
    fetchVoices()

}

export default VideoSettingsAPI

