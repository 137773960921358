import React from "react";
import {
  Typography,
  Box,
  Button,
  CardMedia,
  Card,
  Grid,
  useMediaQuery,
} from "@mui/material";
import image1 from "../assets/LandingPage_Items/ImageGallery/imageGallery01.png";
import image2 from "../assets/LandingPage_Items/ImageGallery/imageGallery02.png";
import image3 from "../assets/LandingPage_Items/ImageGallery/imageGallery03.png";
import image4 from "../assets/LandingPage_Items/ImageGallery/imageGallery04.png";
import image5 from "../assets/LandingPage_Items/ImageGallery/imageGallery05.png";
import image6 from "../assets/LandingPage_Items/ImageGallery/imageGallery06.png";
import image7 from "../assets/LandingPage_Items/ImageGallery/imageGallery07.png";
import image8 from "../assets/LandingPage_Items/ImageGallery/imageGallery08.png";
import image9 from "../assets/LandingPage_Items/ImageGallery/imageGallery09.png";
import PageLayout from "../components/navFoot/PageLayout";
import RequestDemoNearFooter from "../components/LandingPage/RequestDemoNearFooter";

function ImageGallery() {
  const datas = [
    { id: 1, image: image1, title: "A house in a beautiful hillside meadow" },
    {
      id: 2,
      image: image2,
      title:
        "A surreal setting with an angel creating a portal to earth with her hands",
    },
    {
      id: 3,
      image: image3,
      title: "Three beagle puppies playing with a tennis ball in a park",
    },
    {
      id: 4,
      image: image4,
      title:
        "A man looking over a river valley with wavy multicolored strings on the riverbank",
    },
    {
      id: 5,
      image: image5,
      title:
        "A group of friends with color matching outfits having a laugh together",
    },
    {
      id: 6,
      image: image6,
      title:
        "A snowy mountain with a sharp peak from a lake reflecting the mountain’s image",
    },
    {
      id: 7,
      image: image7,
      title:
        "A stream of water coming from the mountains in a grassy, isolated land",
    },
    {
      id: 8,
      image: image8,
      title:
        "An abstract illustration symbolizing the connection between the mind and perception ",
    },
    {
      id: 9,
      image: image9,
      title:
        "A painting of a cyberpunk theme city in the style of 19th century European art",
    },
  ];

  const buttonData = [
    { id: 1, btn: "All" },
    { id: 2, btn: "3D" },
    { id: 3, btn: "Anime" },
    { id: 4, btn: "Cinematic" },
    { id: 5, btn: "Isometric" },
    { id: 6, btn: "Line art" },
    { id: 7, btn: "Origami" },
    { id: 8, btn: "Photographic" },
    { id: 9, btn: "Neon-punk" },
    { id: 10, btn: "Analog film" },
    { id: 11, btn: "Comic book" },
    { id: 12, btn: "Digital art" },
  ];
  const isBelow1300 = useMediaQuery("(max-width:1300px)");
  const isBelow991 = useMediaQuery("(max-width:1300px)");
  return (
    <>
      <PageLayout>
        {/* section 1 */}

        <Box
          sx={{
            gap: "48px",
            opacity: "0px",
            marginTop: "246px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            "@media (max-width:991px)": {
              marginTop: "156px",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: isBelow991 ? "12px" : '16px',
                fontWeight: "600px",
                lineHeight: "24px",
                textAlign: "center",
                color: "rgba(246, 190, 107, 1)",
              }}
            >
              Image Gallery
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            marginTop: "48px",
            "@media (max-width:991px)": {
              marginTop: "24px",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "48px",
              fontWeight: "500",
              lineHeight: "57.6px",
              textAlign: "center",
              color: "rgba(255, 255, 255, 1)",
              "@media (max-width:991px)": {
                fontSize: "28px",
                padding : '0px 4.4% 0px 4.4%'
              },
            }}
          >
            Generate copyright-free images that effectively communicate your
            ideas
          </Typography>
        </Box>

        {/* section 1 */}

        {/* section 2 button part */}

        <Box
          sx={{
            overflow: "hidden",
            width: "100%",
            maxWidth: "100%",
          }}
        >
          {isBelow1300 ? (
            <Box
              sx={{
                display: "flex",
                // overflow: "hidden",
                padding: "0 20px",
                whiteSpace: "nowrap",
                animation: "scrollLogos 20s linear infinite",
                "@keyframes scrollLogos": {
                  "0%": { transform: "translateX(100%)" },
                  "100%": { transform: "translateX(-270%)" },
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "60px",
                }}
              >
                <Box
                  sx={{
                    width: "1269px",
                    gap: "16px",
                    opacity: "0px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {buttonData.map((e) => (
                    <Box key={e.id}>
                      <Button
                        sx={{
                          // width: "42px",
                          textTransform: "none",
                          height: "32px",
                          backgroundColor: "#1F233E",

                          "&:hover": {
                            backgroundColor: "rgba(202, 196, 208, 1)",
                          },
                          borderRadius: "4px",
                        }}
                        variant="contained"
                      >
                        <Box
                          sx={
                            {
                              //   width: "18px",
                              //   height: "20px",
                            }
                          }
                        >
                          <Typography
                            sx={{
                              fontFamily: "Inter, sans-serif",
                              fontSize: "14px",
                              fontWeight: 600,
                              lineHeight: "20px",
                              letterSpacing: "0.1px",
                              textAlign: "center",
                              color: "#CAC4D0",
                              "&:hover": {
                                color: "#17182C",
                              },
                            }}
                          >
                            {e.btn}
                          </Typography>
                        </Box>
                      </Button>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "72px",
              }}
            >
              <Box
                sx={{
                  // width: "1269px",
                  gap: "16px",
                  opacity: "0px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {buttonData.map((e) => (
                  <Box key={e.id}>
                    <Button
                      sx={{
                        textTransform: "none",
                        // width: "42px",
                        height: "32px",
                        backgroundColor: "#1F233E",

                        "&:hover": {
                          backgroundColor: "rgba(202, 196, 208, 1)",
                        },
                        borderRadius: "4px",
                      }}
                      variant="contained"
                    >
                      <Box
                        sx={{
                          minWidth: "18px",
                          height: "20px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Inter, sans-serif",
                            fontSize: "14px",
                            fontWeight: 600,
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            textAlign: "center",
                            color: "#CAC4D0",
                            "&:hover": {
                              color: "#17182C",
                            },
                          }}
                        >
                          {e.btn}
                        </Typography>
                      </Box>
                    </Button>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
        {/* section 2 button part */}

        {/* section 3 Grid part */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              borderRadius: "12px ",
              "@media (max-width: 991px)": {
                padding: "0px 4.4% 0px 4.4%",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap", // Ensures that the items wrap to the next line
                justifyContent: "space-evenly",
                marginTop: "-8px",
                "@media (max-width : 991px)": {
                  display: "grid",
                  gridTemplateColumns: "repeat(1 , 1fr)",
                  margin: "auto",
                  marginTop: "0px",
                  justifyContent: "center",
                },
              }}
            >
              {datas.map((e) => (
                <Grid item key={e.id}>
                  <Box
                    sx={{
                      margin: "auto",
                      gap: "17px",
                      opacity: "0px",
                      marginTop: "100px",
                      "@media (max-width : 991px)": {
                        marginTop: "60px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        gap: "0px",
                        opacity: "0px",
                        maxWidth: "384px",
                        borderRadius: "4px",
                        "@media (max-width : 991px)": {
                          minWidth: "100%",
                        },
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={e.image}
                        alt="Example"
                      />
                    </Box>
                    <Box
                      sx={{
                        maxWidth: "384px",
                        marginTop: "17px",
                        "@media (max-width : 991px)": {
                          maxWidth: "100%",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: "400px",
                          lineHeight: "20.8px",
                          textAlign: "center",
                        }}
                      >
                        {e.title}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Box>
          </Box>
        </Box>

        {/* section 3 Grid part */}

        <Box
          sx={{
            marginTop: "185px",
          }}
        >
          <RequestDemoNearFooter />
        </Box>
      </PageLayout>
    </>
  );
}

export default ImageGallery;
