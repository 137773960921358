import React, { useState, useEffect } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Dialog, Box, Typography, Button, CardMedia, TextField, IconButton } from "@mui/material";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import { selected_chat_bg_color_dm } from "../../../constants/colors";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RepeatIcon from "@mui/icons-material/Repeat";
import AddIcon from "@mui/icons-material/Add";
import linkedinImage from "../../../assets/shareModalForSocialMedia/linkedin.png";
import ayush from "../../../assets/shareModalForSocialMedia/ayush.png";
import jupiter from "../../../assets/shareModalForSocialMedia/jupiter.png";
import postImage from "../../../assets/shareModalForSocialMedia/postImage1.png";
import completeImage from "../../../assets/shareModalForSocialMedia/completeImage.png";
import facebookPost from "../../../assets/shareModalForSocialMedia/facebookPost.png";
import facebookStories from "../../../assets/shareModalForSocialMedia/facebookStories.png";
import instragram from "../../../assets/shareModalForSocialMedia/instragram.png";
import linkedinProfile from "../../../assets/shareModalForSocialMedia/linkedinProfile.png";
import linkedinPage from "../../../assets/shareModalForSocialMedia/linkedinPage.png";
import x from "../../../assets/shareModalForSocialMedia/x.png";
import printerest from "../../../assets/shareModalForSocialMedia/printerest.png";
import youTube from '../../../assets/shareModalForSocialMedia/youTube.png'
import PreviewMedia from "./PreviewMedia";
import PreviewMediaForSocialMedia from "./PreviewMediaForSocialMedia";
import axios from "axios";
import { PostOnLinked, PostSendOnLinkedin, PutImageOnGivenUrl, regenrateTitle, StoreAccessToken } from "./apicall";
import { client_id, client_secret, cors_url, redirect_url, } from '../../../api/Source.api'
import sourceUrl from '../../../api/Source.api'
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router-dom';



function ShareModalForSocialMedia({ message }) {

  const [currentIndex, setCurrentIndex] = useState(0);
  const totalBoxes = 10; // Total number of boxes in the carousel
  const visibleBoxes = 4; // Number of boxes visible at once
  const boxWidth = 40; // Width of each box in pixels (including margin)

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < totalBoxes - visibleBoxes) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };
  const [urls, setUrls] = useState([]);

  useEffect(() => {
    if (message.content) {
      const extractedUrls = findUrls(message.content);
      setUrls(extractedUrls || []);
    }
    else {
      const extractedUrls = findUrls(message.agent);
      setUrls(extractedUrls || []);

    }
  }, [message]);

  var postImage = urls[0]

  const findUrls = (msg) => {
    const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
    const matches = msg.match(urlRegex); // Use match to find all occurrences
    return matches ? matches : null; // Return all matches or null if no matches
  };


  const imageData = [
    { id: 1, image2: postImage },
    { id: 2, image2: postImage },
    { id: 3, image2: postImage },
    { id: 4, image2: postImage },
  ]

  const { shareModalforSocialMedia, setShareModalforSocialMedia } =
    useWorkspace();
  const [linkedin, setLinkedin] = useState(false);
  const [connectLinkedin, setConnectLinkedin] = useState(false);
  const [linkedinAccount, setLinkedinAccount] = useState(false);
  const [publicPost, setPublicPost] = useState(false);
  const [userAccount, setUserAccount] = useState([])
  const [finalPostId, setFinalPostId] = useState();
  const [titleOfPost, setTitleOfPost] = useState(`Take a look at what I created using ModalX!`);
  const [Loading, setLoading] = useState(false);
  var REDIRECT_URI
  if (sourceUrl === "https://api.dev.modalx.ai/api") {
    REDIRECT_URI = "https://dev.modalx.ai/newpage";
  } else {
    REDIRECT_URI = "https://modalx.ai/newpage";
  }

  const Validationtoken = localStorage.getItem('token');
  const token = localStorage.getItem('accessToken');
  const subId = localStorage.getItem('sub');


  const userInfo = async (token) => {
    // const token = localStorage.getItem('accessToken'); // Replace 'access_token' with your actual key name

    if (token) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const getaccess = await axios.get(`${cors_url}https://api.linkedin.com/v2/userinfo`, config)
        console.log(getaccess.data.sub)
        localStorage.setItem("sub", getaccess?.data?.sub)
        setUserAccount([{ id: 1, image: getaccess?.data?.picture, name: getaccess?.data?.given_name }])
      } catch (err) {
        alert("Something is wrong");
        console.log("Error", err)
      }
    } else {
      console.log('No token found');
    }

  }

  const exchangeAuthorizationCodeForAccessToken = async (authorizationCode) => {
    console.log(cors_url)
    if (authorizationCode) {
      try {
        const response = await axios.post(`${cors_url}https://www.linkedin.com/uas/oauth2/accessToken?client_id=${client_id}&client_secret=${client_secret}&redirect_uri=${REDIRECT_URI}&grant_type=authorization_code&code=${authorizationCode}`);
        console.log(response);
        if (response?.data?.access_token) {
          console.log("Access Token", response.data.access_token);
          localStorage.setItem("accessToken", response.data.access_token);
          userInfo(response.data.access_token);
        }
      } catch (e) {
        setLoading(false)
        console.error("Error during access token exchange:", e);
      }
    } else {
      alert("Authorization process was not completed. Please try again.");
    }
  };

  const TakePermissionFromUsers = async () => {
    try {
      const state = Math.random().toString(36).substring(2);
      const linkedInAuthUrl = `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=${client_id}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&state=${state}&scope=openid%20profile%20email%20w_member_social`;

      const authWindow = window.open(linkedInAuthUrl, '_blank', 'width=500,height=600');

      // Polling the new tab to check if it redirects back to your redirect URI
      const pollTimer = window.setInterval(async function () {
        try {
          if (authWindow.location.href.indexOf(REDIRECT_URI) !== -1) {
            // Stop polling
            window.clearInterval(pollTimer);

            const urlParams = new URLSearchParams(authWindow.location.search);
            const authorizationCode = urlParams.get('code');
            const returnedState = urlParams.get('state');

            authWindow.close();

            if (returnedState !== state) {
              throw new Error("State mismatch. Potential CSRF attack.");
            }

            if (authorizationCode) {
              const accessToken = await exchangeAuthorizationCodeForAccessToken(authorizationCode);
            } else {
              throw new Error("Authorization code not found.");
            }
          }
        } catch (error) {
          if (authWindow.closed) {
            alert("Authorization window closed without completing the process.");
            window.clearInterval(pollTimer);
          } else {
            // Handle other errors
            console.error("Error during polling:", error);
          }
        }
      }, 500);

    } catch (err) {
      setLoading(false)
      alert("Something is wrong");
      console.error("Error during LinkedIn OAuth:", err);
    }
  }

  const handleViewPostRequest = () => {
    if (finalPostId) {
      window.open(`https://www.linkedin.com/feed/update/${finalPostId}/`, '_blank');
      setPublicPost(false)
    } else {
      console.error('No URL provided for redirection.');
    }
  }

  const handleRegenrateCaption = async () => {
    const title = await regenrateTitle(Validationtoken, titleOfPost)
    setTitleOfPost(title)
  }

  const handleLinkAnotherAccount = async () => {
    await TakePermissionFromUsers()
    setConnectLinkedin(true);
    setLinkedin(false);
  }


  const handleClick = () => {
    setShareModalforSocialMedia(false);
  };

  const handleLinkedin = () => {
    setLinkedin(false);
  };

  const handleClickReverseLinkedin = () => {
    setLinkedin(false);
    setShareModalforSocialMedia(true);
  };

  const handleLinkedintrue = () => {
    //Call Backend API for Checking Token Is available or Not Available
    if (token) {
      setLinkedinAccount(true);
      setShareModalforSocialMedia(false);
    } else {
      setLinkedin(true);
      setShareModalforSocialMedia(false);
    }
  };

  const handleConnectLinkedin = () => {
    setConnectLinkedin(false);
  };

  const handleConnectLinkedintrue = async () => {
    console.log("click on no token")
    await TakePermissionFromUsers()
    setConnectLinkedin(true);
    setLinkedin(false);
  };
  const handleClickReverseConnnetLinkedin = () => {
    setConnectLinkedin(false);
    setLinkedin(true);
  };
  const handleLinkedAccount = () => {
    setLinkedinAccount(false);
    // setConnectLinkedin(true);
  };

  const handleLinkedAccountReverse = () => {
    setLinkedinAccount(false);
    setConnectLinkedin(true);
  };

  const handleLinkedAccountTrue = async () => {
    const response = await StoreAccessToken(subId, token)
    // console.log(response);
    setLinkedinAccount(true);
    setConnectLinkedin(false);
  };

  const handlePublicPost = () => {
    setPublicPost(false);
  };

  const handlePublicPostTrue = async () => {
    // write code here to post publish post
    setLoading(true);
    const ArrayOfUrlsObject = await PostOnLinked(token, subId, urls, cors_url, setLoading)
    const ArrayOfAssesetObject = await PutImageOnGivenUrl(token, ArrayOfUrlsObject, cors_url)
    const finalresponse = await PostSendOnLinkedin(token, ArrayOfAssesetObject, subId, titleOfPost, cors_url)
    setFinalPostId(finalresponse)
    if (finalresponse) {
      setPublicPost(true);
      setLinkedinAccount(false);
      setLoading(false)
    }
  };

  const socialMediaData = [
    {
      id: 1,
      bgcolor: "white",
      color: "blue",
      title: "Facebook Post",
      image1: facebookPost
    },
    {
      id: 2,
      bgcolor: "white",
      color: "blue",
      title: "Facebook Story",
      image1: facebookStories
    },
    {
      id: 3,
      bgcolor: "white",
      color: "blue",
      title: "Instagram",
      image1: instragram
    },
    {
      id: 4,
      bgcolor: "white",
      color: "blue",
      title: "Linkedin Profile",
      click: handleLinkedintrue,
      image1: linkedinProfile
    },
    {
      id: 5,
      bgcolor: "white",
      color: "blue",
      title: "Linkedin Page",
      image1: linkedinPage
    },
    {
      id: 6,
      bgcolor: "white",
      color: "blue",
      title: "X (Twitter)",
      image1: x
    },
    {
      id: 7,
      bgcolor: "white",
      color: "blue",
      title: "Pinterest",
      image1: printerest
    },
    {
      id: 8,
      bgcolor: "white",
      color: "blue",
      title: "YouTube",
      image1: youTube
    },
  ];


  const [selectedIndex, setSelectedIndex] = useState(0);
  const [hideImage, setHideImage] = useState(true)

  const handleImageClick = (index) => {
    setSelectedIndex(index);
    setHideImage(false)
  };



  return (
    <>
      <Dialog open={shareModalforSocialMedia} close={handleClick}>
        <Box
          sx={{
            maxWidth: "480px",
            bgcolor: selected_chat_bg_color_dm,
            border: "1px solid #CAC4D0",
          }}
        >
          <Box
            sx={{
              maxWidth: "480px",
              height: "68px",
              // bgcolor : 'red',
              borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "440px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0px 20px 0px 20px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: 600,
                  textAlign: "left",
                  color: "#FFFFFF",
                }}
              >
                Share post
              </Typography>
              <Box
                onClick={handleClick}
                sx={{
                  width: "16px",
                  height: "16px",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                <CloseIcon />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              maxWidth: "440px",
              height: "220px",
              // bgcolor : 'red',
              margin: "40px 20px 0px 20px",
              display: "grid",

              gridTemplateColumns: "repeat(4, 1fr)",
              justifyContent: "space-between",
            }}
          >
            {socialMediaData.map((e) => (
              <Box
                key={e.id}
                onClick={e.click}
                sx={{
                  width: "66px",
                  height: "96px",
                  margin: "auto",
                  cursor: "pointer",
                  // bgcolor : 'green',
                  // display : 'flex',
                  // justifyContent : 'center'
                }}
              >
                <Box
                  sx={{
                    width: "48px",
                    height: "48px",
                    bgcolor: "#2F355F",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    marginLeft: "9px",
                    '&:hover': {
                      bgcolor: '#373E6D'
                    }

                  }}
                >
                  <Box
                    sx={{
                      // bgcolor: e.bgcolor,
                      // color: e.color,
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CardMedia component="img" image={e.image1} alt="loading" />
                  </Box>
                </Box>

                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 400,
                    textAlign: "center",
                    color: "#FFFFFF",
                  }}
                >
                  {e.title}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box sx={{ maxWidth: "400px", margin: "40px 40px 40px 40px" }}>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 400,
                textAlign: "left",
                color: "#FFFFFF",
              }}
            >
              ModalX will automatically generate a relevant caption or write-up
              for your post based on the platform you choose. You can always
              edit or regenerate it before sharing the post.
            </Typography>
          </Box>
        </Box>
      </Dialog>

      <Dialog open={linkedin} close={handleLinkedin}>
        <Box
          sx={{
            maxWidth: "480px",
            bgcolor: selected_chat_bg_color_dm,
            border: "1px solid #CAC4D0",
          }}
        >
          <Box
            sx={{
              maxWidth: "480px",
              height: "68px",
              // bgcolor : 'red',
              borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "440px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0px 20px 0px 20px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: 600,
                  textAlign: "left",
                  color: "#FFFFFF",
                }}
              >
                Share post
              </Typography>
              <Box
                onClick={handleLinkedin}
                sx={{
                  width: "16px",
                  height: "16px",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                <CloseIcon />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "230px",
              height: "24px",
              margin: "20px 0px 0px 20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              onClick={handleClickReverseLinkedin}
              sx={{
                width: "24px",
                height: "20px",
                color: "#FFFFFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <ArrowBackIcon />
            </Box>
            <Box
              sx={{
                width: "200px",
                height: "20px",
                color: "#FFFFFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 400,
                  textAlign: "center",
                }}
              >
                Connect LinkedIn Account
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              width: "48px",
              height: "48px",
              bgcolor: "#2F355F",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              marginLeft: "9px",
              margin: "40px 0px 0px 40px",
              '&:hover': {
                bgcolor: '#373E6D',
                cursor: "pointer"
              }
            }}
          >
            <Box
              sx={{
                width: "24px",
                height: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

              }}
            >
              <CardMedia component="img" image={linkedinProfile} alt="loading" />
            </Box>
          </Box>
          <Box
            sx={{
              margin: "20px 40px 0px 40px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "400px",
                textAlign: "left",
                color: "#FFFFFF",
              }}
            >
              Sign in to one or more of your LinkedIn accounts to connect them
              with ModalX. Once connected, you will be able to publish posts
              seamlessly.
            </Typography>
          </Box>
          <Box
            sx={{
              margin: "20px 40px 40px 40px",
            }}
          >
            <Button
              onClick={handleConnectLinkedintrue}
              variant="contained"
              sx={{
                width: "400px",
                height: "40px",
                bgcolor: "#F6BE6B",
                borderRadius: "4px",
                textTransform: "none",
                fontFamily: "Inter",
                fontSize: "16px",
                textAlign: "center",
                fontWeight: 600,
                color: " #17182C",
                "&:hover": {
                  bgcolor: "#FFD9AA",
                },
              }}
            >
              {" "}
              Connect LinkedIn Account
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Dialog open={connectLinkedin} close={handleConnectLinkedin}>
        <Box
          sx={{
            maxWidth: "480px",
            // height: "500px",
            bgcolor: selected_chat_bg_color_dm,
            border: "1px solid #CAC4D0",
          }}
        >
          <Box
            sx={{
              maxWidth: "480px",
              height: "68px",
              borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "440px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0px 20px 0px 20px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: 600,
                  textAlign: "left",
                  color: "#FFFFFF",
                }}
              >
                Share post
              </Typography>
              <Box
                onClick={handleConnectLinkedin}
                sx={{
                  width: "16px",
                  height: "16px",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                <CloseIcon />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "230px",
              height: "24px",
              margin: "20px 0px 0px 20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              onClick={handleClickReverseConnnetLinkedin}
              sx={{
                width: "24px",
                height: "20px",
                color: "#FFFFFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <ArrowBackIcon />
            </Box>
            <Box
              sx={{
                width: "200px",
                height: "20px",
                color: "#FFFFFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 400,
                  textAlign: "center",
                }}
              >
                Choose Linkedin Account
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: "140px",
              height: "20px",
              margin: "40px 0px 0px 40px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 500,
                textAlign: "left",
                color: "#FFFFFF",
              }}
            >
              Linked Accounts
            </Typography>
            <Box
              sx={{
                width: "16px",
                height: "16px",
              }}
            >
              <CardMedia component="img" image={linkedinImage} alt="loading" />
            </Box>
          </Box>
          {userAccount?.map((e) => (
            <Box key={e.id}>
              <Box
                onClick={handleLinkedAccountTrue}
                sx={{
                  width: "120px",
                  height: "24px",
                  margin: "20px 0px 0px 40px",
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{
                    width: "24px",
                    height: "24px",
                  }}
                >
                  <CardMedia component="img" image={e.image} alt="loading" />
                </Box>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 400,
                    textAlign: "left",
                    color: "#FFFFFF",
                  }}
                >
                  {e.name}
                </Typography>
              </Box>
            </Box>
          ))}

          <Box
            onClick={handleLinkAnotherAccount}
            sx={{
              minWidth: "154px",
              height: "24px",
              margin: "20px 0px 0px 40px",
              display: "flex",
              alignItems: "center",
              gap: "12px",
              marginBottom: "84px",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                width: "24px",
                height: "24px",
                color: "#FFFFFF",
              }}
            >
              <AddIcon />
            </Box>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 400,
                textAlign: "left",
                color: "#FFFFFF",
              }}
            >
              Link another account
            </Typography>
          </Box>
        </Box>
      </Dialog >
      <Dialog
        open={linkedinAccount}
        close={handleLinkedAccount}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            width: "720px",
            height: "615px",
            gap: "0px",
            borderRadius: "8px",
            border: "1px solid #CAC4D0",
            opacity: "0px",
            bgcolor: "#282D51",
          },
        }}
      >
        <Box
          sx={
            {
              // width: "600px",
              // height: "500px",
              // bgcolor: selected_chat_bg_color_dm,
              // border: "1px solid #CAC4D0",
            }
          }
        >
          <Box
            sx={{
              maxWidth: "720px",
              height: "68px",
              borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "680px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0px 20px 0px 20px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: 600,
                  textAlign: "left",
                  color: "#FFFFFF",
                }}
              >
                Share post
              </Typography>
              <Box
                onClick={handleLinkedAccount}
                sx={{
                  width: "16px",
                  height: "16px",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                <CloseIcon />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "130px",
              height: "24px",
              margin: "20px 0px 0px 20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              onClick={handleLinkedAccountReverse}
              sx={{
                width: "24px",
                height: "20px",
                color: "#FFFFFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <ArrowBackIcon />
            </Box>
            <Box
              sx={{
                width: "100px",
                height: "20px",
                color: "#FFFFFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 400,
                  textAlign: "center",
                }}
              >
                Preview post
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{
                // bgcolor: "pink",
                width: "310px",
              }}
            >
              <Box
                sx={{
                  width: "230px",
                  height: "24px",
                  margin: "40px 0px 0px 40px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 500,
                    textAlign: "left",
                    color: "#FFFFFF",
                  }}
                >
                  {message.user}
                </Typography>
              </Box>
              {/* <Box
                sx={{
                  width: "230px",
                  height: "230px",
                  margin: "20px 0px 0px 40px",
                  // bgcolor: "green",
                }}
              >
                <CardMedia component="img" image={postImage} alt="loading" />
              </Box> */}
              <Box
                sx={{
                  width: "230px",
                  height: "230px",
                  margin: "20px 0px 0px 40px",
                  // bgcolor : 'red',
                  // bgcolor: selectedIndex === 0 ? "lightblue" : "transparent", // Change background color
                }}
              >
                <PreviewMediaForSocialMedia url={urls[selectedIndex]} alt="loading" />
              </Box>

            </Box>

            <Box
              sx={{
                width: "410px",
                height: "300px",
              }}
            >
              <Box
                sx={{
                  maxWidth: "100%",
                  height: "24px",
                  // bgcolor: "pink",
                  marginTop: "40px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Box
                  sx={{
                    width: "195px",
                    height: "24px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 500,
                      textAlign: "left",
                      color: "#FFFFFF",
                    }}
                  >
                    Caption
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 500,
                      textAlign: "left",
                      color: "#FFFFFF",
                      opacity: "60%",
                    }}
                  >
                    Suggested by ModalX
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "150px",
                    height: "24px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    onClick={handleRegenrateCaption}
                    sx={{
                      color: "#FFFFFF",
                      cursor: "pointer",
                    }}
                  >
                    <RepeatIcon />
                  </Box>

                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 500,
                      textAlign: "left",
                      color: "#FFFFFF",
                    }}
                  >
                    Regenerate caption
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "370px",
                  height: "280px",
                  bgcolor: "#232746",
                  color: "white",
                  margin: "20px 0px 0px 20px",
                }}
              >

                <TextField
                  sx={{
                    width: "370px",
                    height: "280px",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none', // Hide the border
                      },
                      '& textarea': {
                        whiteSpace: 'normal', // Allow text to wrap (show multiple lines)
                        scrollbarWidth: 'thin',  // For Firefox (thin scrollbar)
                        '-ms-overflow-style': 'none', // For Internet Explorer and Edge
                      },
                      '& textarea::-webkit-scrollbar': {
                        width: '6px', // Optional: Adjust the width of the vertical scrollbar for Chrome, Safari, and Opera
                      },
                      '& textarea::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888', // Optional: Style for the scrollbar thumb
                        borderRadius: '10px', // Optional: Rounded corners for the scrollbar
                      }
                    },
                  }}
                  placeholder="Write something..."
                  multiline
                  value={titleOfPost}
                  onChange={(e) => setTitleOfPost(e.target.value)}
                  id="outlined-basic"
                  variant="outlined"
                  InputProps={{
                    sx: {
                      color: 'white', // Set the text color to white
                    },
                  }}
                />

                {/* <TextField sx={{
                  width: "370px",
                  height: "280px",
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none', // Hide the border
                    },
                    '& textarea': {
                      whiteSpace: 'wrap', // Prevent text wrapping
                      overflowX: 'hidden',
                      overflowY: 'auto', // Enable horizontal scrolling
                      scrollbarWidth: 'none',  // For Firefox
                      '-ms-overflow-style': 'none', // For Internet Explorer and Edge
                    },
                    '& textarea::-webkit-scrollbar': {
                      display: 'none', // For Chrome, Safari, and Opera
                    }
                  }

                }}
                  multiline
                  value={titleOfPost}
                  onChange={(e) => { setTitleOfPost(e.target.value) }}

                  id="outlined-basic" variant="outlined"
                  InputProps={{
                    sx: {
                      color: 'white', // Set the text color to white
                    },
                  }}
                /> */}

                {/* <Typography
                  sx={{
                    padding: "10px",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 400,
                    textAlign: "left",
                    color: "#FFFFFF",
                  }}
                >
                  🚀 Exciting News! 🚀 <br />
                  <br /> We're thrilled to announce a brand-new feature on
                  Jupiter that takes your payment experience to the next level.
                  Now, you can link any of your RuPay credit cards on Jupiter
                  and unlock a world of benefits: <br />
                  <br /> 💳 UPI Payments: Seamlessly use your linked RuPay
                  credit card for UPI transactions. It's never been easier to
                  pay! 📊 Track Your Spends: Keep a close eye on your spending
                  with detailed tracking and insights. 💰 Earn 2% Cashback:
                  Enjoy 2% cashback on every transaction made through UPI using
                  your linked RuPay credit card. At Jupiter, we're all about
                  making finance simple, rewarding, and accessible. This new
                  feature is just another step in that direction. Join us in
                  transforming the way you manage your money! 💼✨ #Jupiter
                  #RuPay #CreditCard #UPI #Cashback #Fintech #DigitalPayments
                  #NewFeature
                </Typography> */}
              </Box>
            </Box>
          </Box>

          <Box sx={{
            width: '230px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            marginTop: '10px',
            marginLeft: '40px'
          }}>
            <IconButton
              onClick={handlePrevClick}
              disabled={currentIndex === 0}
              disableRipple
              sx={{
                bgcolor: "#1F233E",
                color: "white",
                marginRight: '5px',
                "&:hover": {
                  backgroundColor: "#1B1E36",
                },
                "&:disabled": {
                  bgcolor: "#1F233E",
                  color: "grey",
                },
                width: "25px",
                height: "25px",
              }}
            >
              <ChevronLeftIcon />

            </IconButton>
            <Box sx={{
              maxWidth: '170px',
              height: '40px',
              display: "flex"
            }}>

              <Box
                overflow="hidden"
                width={`${visibleBoxes * boxWidth + 100}px`}
                display="flex"
                position="relative"
              >
                <Box
                  display="flex"
                  transition="transform 0.5s ease-in-out" // Changed the transition to ease-in-out
                  sx={{
                    transform: `translateX(-${currentIndex * boxWidth}px)`,
                    transitionProperty: "transform",
                    transitionDuration: "0.5s",
                    transitionTimingFunction: "ease-in-out", // Smooth transition
                    gap: '4px'
                  }}
                >
                  {urls.map((e, index) => (
                    <Box
                      key={index}
                      sx={{
                        width: '40px',
                        // height: '10px',
                        cursor: 'pointer',
                        border: selectedIndex === index ? '1px solid white' : 'none',
                        transition: 'border-color 0.3s',

                      }}
                      onClick={() => handleImageClick(index)} // Set the selected index on click
                    >
                      {/* <CardMedia component="img" image={e} alt="loading" /> */}
                      <PreviewMediaForSocialMedia url={e} />
                    </Box>
                  ))}
                </Box>
              </Box>


            </Box>
            <IconButton
              onClick={handleNextClick}
              disabled={currentIndex >= totalBoxes - visibleBoxes}
              disableRipple
              sx={{
                bgcolor: "#1F233E",
                color: "white",
                marginLeft: '5px',
                "&:hover": {
                  backgroundColor: "#1B1E36",
                },
                "&:disabled": {
                  bgcolor: "#1F233E",
                  color: "grey",
                },
                width: "25px",
                height: "25px",
              }}
            >
              <KeyboardArrowRightIcon />
            </IconButton>

          </Box>
          <Box
            sx={{
              width: "100%",
              height: "50px",
              // bgcolor : 'red',
              display: "flex",
              justifyContent: "end",
              marginTop: "50px",
            }}
          >
            <Box
              sx={{
                width: "268px",
                height: "40px",
                marginRight: "16px",
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={handleLinkedAccount}
                sx={{
                  width: "103px",
                  height: "40px",
                  textTransform: "none",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                  bgcolor: "#FFFFFF",
                  color: "#17182C",
                  "&:hover": {
                    backgroundColor: "#CDCFDF",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handlePublicPostTrue}
                sx={{
                  width: "145px",
                  height: "40px",
                  textTransform: "none",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                  bgcolor: "#F6BE6B",
                  color: "#17182C",
                  "&:hover": {
                    backgroundColor: "#FFD9AA",
                  },
                }}
              >
                {
                  Loading ? <CircularProgress color="secondary" size="30px" style={{ color: "#17182C" }} /> : "Publish post"
                }

              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>

      <Dialog open={publicPost} close={handlePublicPost}>
        <Box
          sx={{
            maxWidth: "480px",
            bgcolor: selected_chat_bg_color_dm,
            border: "1px solid #CAC4D0",
          }}
        >
          <Box
            sx={{
              maxWidth: "480px",
              height: "68px",
              // bgcolor : 'red',
              borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "440px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0px 20px 0px 20px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: 600,
                  textAlign: "left",
                  color: "#FFFFFF",
                }}
              >
                Share post
              </Typography>
              <Box
                onClick={handlePublicPost}
                sx={{
                  width: "16px",
                  height: "16px",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                <CloseIcon />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "40px",
              height: "40px",
              // bgcolor: "#2F355F",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              marginLeft: "9px",
              margin: "40px 0px 0px 40px",
            }}
          ><CardMedia component="img" image={completeImage} alt="loading" />
            {/* <Box
              sx={{
                bgcolor: "white",
                color: "blue",
                width: "17px",
                height: "17px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LinkedInIcon />
            </Box> */}
          </Box>

          <Box sx={{
            width: '200px',
            margin: '20px 0px 0px 40px'
          }} ><Typography
            sx={{
              fontFamily: 'Inter',
              fontSize: '20px',
              fontWeight: 600,
              textAlign: 'left',
              color: '#FFFFFF'
            }}
          >Post Published</Typography>
          </Box>
          <Box
            sx={{
              margin: "20px 40px 0px 40px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "400px",
                textAlign: "left",
                color: "#FFFFFF",
              }}
            >
              Your post has been published from your selected LinkedIn account.
              You can now view it on LinkedIn.
            </Typography>
          </Box>
          <Box
            sx={{
              margin: "20px 40px 40px 40px",
            }}
          >
            <Button
              onClick={handleViewPostRequest}
              variant="contained"
              sx={{
                width: "400px",
                height: "40px",
                bgcolor: "#F6BE6B",
                borderRadius: "4px",
                textTransform: "none",
                fontFamily: "Inter",
                fontSize: "16px",
                textAlign: "center",
                fontWeight: 600,
                color: " #17182C",
                "&:hover": {
                  bgcolor: "#FFD9AA",
                },
              }}
            >
              View post on Linkedin
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default ShareModalForSocialMedia;
