import React, { useState } from "react";
import { Button } from "react-bootstrap";

function ThemeToggle({ show = true }) {
  const setStoredTheme = (theme) => localStorage.setItem("theme", theme);
  const getStoredTheme = () => localStorage.getItem("theme");

  if (!getStoredTheme) {
    setStoredTheme(
      window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
    );
  }

  const [currentTheme, setCurrentTheme] = useState(getStoredTheme);

  document.documentElement.setAttribute("data-bs-theme", currentTheme);

  function toggleTheme() {
    const newTheme = currentTheme === "dark" ? "light" : "dark";
    setCurrentTheme(newTheme);
    setStoredTheme(newTheme);
    document.documentElement.setAttribute("data-bs-theme", currentTheme);
  }

  return (
    <>
      {show === true && (
        <Button
          onClick={toggleTheme}
          className="me-2 mb-2"
          variant={currentTheme === "dark" ? "secondary" : "dark"}
        >
          {currentTheme === "dark" ? "☀️" : "🌙"}
        </Button>
      )}
    </>
  );
}

export default ThemeToggle;

// import React, { useState, useEffect } from "react";
// import { Button } from "react-bootstrap";

// function ThemeToggle({ show = true }) {
//   const setStoredTheme = (theme) => localStorage.setItem("theme", theme);
//   const getStoredTheme = () => localStorage.getItem("theme");

//   const getCurrentTime = () => {
//     const date = new Date();
//     return date.getHours();
//   };

//   const isDaytime = () => {
//     const currentTime = 10;
//     return currentTime >= 6 && currentTime < 18;
//   };

//   const [currentTheme, setCurrentTheme] = useState(() => {
//     const storedTheme = getStoredTheme();
//     if (storedTheme) {
//       return storedTheme;
//     } else {
//       const defaultTheme = isDaytime() ? "light" : "dark";
//       console.log("Default Theme:", defaultTheme); // Debugging
//       setStoredTheme(defaultTheme); // Ensure the default theme is saved
//       return defaultTheme;
//     }
//   });
  

//   const toggleTheme = () => {
//     const newTheme = currentTheme === "dark" ? "light" : "dark";
//     setCurrentTheme(newTheme);
//     setStoredTheme(newTheme);
//     document.documentElement.setAttribute("data-bs-theme", newTheme);
//   };

//   useEffect(() => {
//     document.documentElement.setAttribute("data-bs-theme", currentTheme);
//   }, [currentTheme]);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       const currentTime = getCurrentTime();
//       const newTheme = currentTime >= 6 && currentTime < 18 ? "light" : "dark";
//       setCurrentTheme(newTheme);
//       setStoredTheme(newTheme);
//     }, 60000); // Update every minute
//     return () => clearInterval(intervalId);
//   }, []);

//   useEffect(() => {
//     console.log("Stored Theme:", getStoredTheme()); // Debugging
//   }, []);

//   return (
//     <>
//       {show && (
//         <Button
//           onClick={toggleTheme}
//           className="me-2 mb-2"
//           variant={currentTheme === "dark" ? "secondary" : "dark"}
//         >
//           {currentTheme === "dark" ? "☀️" : "🌙"}
//         </Button>
//       )}
//     </>
//   );
// }

// export default ThemeToggle;
