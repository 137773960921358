//========================Vishnu Last Code for Login Start=====================================

// import React, { useState } from "react";
// import { Modal, Button, Form, Row, Col, CloseButton } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import Notification from "../Notification";
// import {
//   auth,
//   signInWithEmailAndPassword,
//   signInWithPopup,
//   GoogleAuthProvider,
// } from "../../config/firebase.config";
// import { useAuth } from "../../contexts/AuthContext";
// import axios from "axios";
// import sourceUrl from "../../api/Source.api";

// function Login({
//   showLoginModal,
//   setShowLoginModal,
//   setShowSignUpModal,
//   emailSent,
// }) {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [showNotif, setShowNotif] = useState(false);
//   const [notifVariant, setNotifVariant] = useState("");
//   const [notifMsg, setNotifMsg] = useState("");
//   const { setUser } = useAuth();
//   const navigate = useNavigate();

//   const verifyUser = async (email) => {
//     const token = localStorage.getItem("token");
//     try {
//       const response = await axios.post(
//         `${sourceUrl}/login/verify`,
//         { email },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       if (response.data.status === false) {
//         throw new Error("Only invited users will be able to use Modalx.ai");
//       }
//       return true;
//     } catch (error) {
//       setNotifMsg(error.message || "Verification failed");
//       setNotifVariant("danger");
//       setShowNotif(true);
//       return false;
//     }
//   };

//   const handleLogin = async (e) => {
//     e.preventDefault();
//     // const isVerified = await verifyUser(email);
//     // if (!isVerified) return;

//     try {
//       const userCredential = await signInWithEmailAndPassword(
//         auth,
//         email,
//         password
//       );
//       localStorage.setItem("userEmail", userCredential.user.email);
//       localStorage.setItem("current_thread_id", "");
//       localStorage.setItem("current_history_id", "");
//       setUser(userCredential.user);
//       localStorage.setItem("token", userCredential._tokenResponse.idToken);
//       // ==================================
//       const isVerified = await verifyUser(email);
//       if (!isVerified) {
//         setShowSignUpModal(true);
//         await axios.post(
//           `${sourceUrl}/profile`,
//           {},
//           {
//             headers: {
//               Authorization: `Bearer ${userCredential._tokenResponse.idToken}`,
//             },
//           }
//         );
//         return;
//       }
//       // ==================================
//       if (userCredential.user.emailVerified) {
//         setUser(userCredential.user.emailVerified);
//         navigate("/workspace");
//         localStorage.setItem("isLoggedIn", true);
//         setShowLoginModal(false);
//         window.location.reload();
//       } else {
//         setShowLoginModal(true);
//         localStorage.setItem("isLoggedIn", "");
//       }
//     } catch (error) {
//       console.error("Error logging in:", error);
//       // setNotifMsg(`Error logging in: ${error.message}`);
//       let message;
//       switch (error.code) {
//         case "auth/invalid-credential":
//           message = "Invalid Credentials";
//           break;
//         case "auth/user-not-found":
//           message = "User not found.";
//           break;
//         case "auth/wrong-password":
//           message = "Incorrect password.";
//           break;
//         case "auth/too-many-requests":
//           message =
//             "Access to this account has been temporarily disabled due to many failed login attempts.Please try again later";
//           break;
//         default:
//           message = "An error occurred. Please try again.";
//       }

//       setNotifMsg(message);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const handleGoogleSignUp = async () => {
//     try {
//       const provider = new GoogleAuthProvider();
//       const result = await signInWithPopup(auth, provider);

//       // ===================== Invited users check =====================
//       // const isVerified = await verifyUser(result.user.email);
//       // if (!isVerified) return;

//       // ===============================================================
//       if (result.user.emailVerified) {
//         {
//           result.user &&
//             result.user.accessToken &&
//             (await axios.post(
//               `${sourceUrl}/profile`,
//               {},
//               {
//                 headers: {
//                   Authorization: `Bearer ${result.user.accessToken}`,
//                 },
//               }
//             ));
//         }

//         localStorage.setItem("token", result.user.accessToken);
//         const isVerified = await verifyUser(result.user.email);
//         if (!isVerified) {
//           setShowSignUpModal(true);
//           await axios.post(
//             `${sourceUrl}/profile`,
//             {},
//             {
//               headers: {
//                 Authorization: `Bearer ${result.user.accessToken}`,
//               },
//             }
//           );
//           return;
//         }
//         localStorage.setItem("current_thread_id", "");
//         localStorage.setItem("current_history_id", "");
//         setUser(result.user.emailVerified);
//         navigate("/workspace");
//         localStorage.setItem("isLoggedIn", true);
//         localStorage.setItem("userEmail", result.user.email);
//         setShowLoginModal(false);
//         window.location.reload();
//       } else {
//         setShowLoginModal(true);
//         localStorage.setItem("isLoggedIn", "");
//       }
//     } catch (error) {
//       console.error("Error signing up with Google:", error);
//       setNotifMsg(`Error signing up with Google: ${error.message}`);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const handleCloseLoginModal = () => {
//     setShowLoginModal(false);
//   };

//   const handleOpenSignUpModal = () => {
//     setShowSignUpModal(true);
//     setShowLoginModal(false);
//   };

//   return (
//     <>
//       <Notification
//         showNotif={showNotif}
//         setShowNotif={setShowNotif}
//         variant={notifVariant}
//       >
//         {notifMsg}
//       </Notification>

//       <Modal centered show={showLoginModal} onHide={handleCloseLoginModal}>
//         <Modal.Header className="justify-content-center">
//           <Modal.Title>Login</Modal.Title>
//           <CloseButton onClick={handleCloseLoginModal} />
//         </Modal.Header>
//         <Modal.Body className="my-3">
//           {emailSent && (
//             <p className="text-center mt-3" style={{ color: "red" }}>
//               Please verify your email address.
//             </p>
//           )}
//           <Row className="justify-content-center">
//             <Col md={10}>
//               <Form
//                 onSubmit={handleLogin}
//                 style={{ width: "75%", margin: "auto" }}
//               >
//                 <Form.Group controlId="formBasicEmail" className="mb-3">
//                   <Form.Label>Email address</Form.Label>
//                   <Form.Control
//                     type="email"
//                     placeholder="Enter email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     required
//                   />
//                 </Form.Group>
//                 <Form.Group controlId="formBasicPassword" className="mb-3">
//                   <Form.Label>Password</Form.Label>
//                   <Form.Control
//                     type="password"
//                     placeholder="Password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     required
//                   />
//                 </Form.Group>
//                 <div>
//                   <Button variant="primary" type="submit" className="w-100">
//                     Login
//                   </Button>
//                   <Button
//                     variant="info"
//                     className="w-100 mt-3"
//                     onClick={handleGoogleSignUp}
//                     style={{
//                       display: "flex",
//                       gap: "10px",
//                       alignItems: "center",
//                       justifyContent: "center",
//                       backgroundColor: "#d95959",
//                       color: "white",
//                       border: "none",
//                     }}
//                   >
//                     <img
//                       style={{ width: "5%" }}
//                       src={require(`../../assets/transparent-google-logo-google-logo-with-multicolored-g-and-1710875781697.png`)}
//                     />
//                     Sign up with Google
//                   </Button>
//                 </div>
//               </Form>
//             </Col>
//           </Row>
//         </Modal.Body>
//         <Modal.Footer className="justify-content-center">
//           <p>
//             New user?{" "}
//             <Button variant="link" onClick={handleOpenSignUpModal}>
//               Sign up
//             </Button>
//           </p>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }

// export default Login;

//========================Vishnu Last Code for Login End=====================================

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  FormControl,
  TextField,
  FormHelperText,
  useMediaQuery,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Notification from "../Notification";
import {
  auth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "../../config/firebase.config";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import sourceUrl from "../../api/Source.api";
import {
  button_bg_color_for_New_Project_dm,
  uploadFiles_button_default_bg_color,
  uploadFiles_button_hovered_bg_color,
} from "../../constants/colors";
import { New_Project_Button_bg_color } from "../../Provider/Color_Provider";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SignupLeftSide from "./SignupLeftSide";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ResetPassword from "./ResetPassword";
import ResendEmailmodal from "./ResendEmailmodal";
import { ErrorEvent, LoginEvent } from "../../config/analytics";

const Login = ({
  // showLoginModal,
  // setShowLoginModal,
  // showSignUpModal,
  // setShowSignUpModal,
  images,
  currentIndex,
  setCurrentIndex,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [maskedPassword, setMaskedPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showNotif, setShowNotif] = useState(false);
  const [notifVariant, setNotifVariant] = useState("");
  const [notifMsg, setNotifMsg] = useState("");
  const [open, setOpen] = React.useState(false);
  const { setUser } = useAuth();
  const navigate = useNavigate();
  const isBelow991 = useMediaQuery("(max-width:991px)");
  const { showLoginModal, setShowLoginModal, setShowSignUpModal,showResetPasswordModal,setShowResetPasswordModal,showResendModal, setShowResendModal} =useAuth()

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if(!userCredential.user.emailVerified){
        setNotifMsg("User Not Verfied");
        setNotifVariant("error");
        setShowNotif(true);
        setOpen(true);
        return
      }
      localStorage.setItem("userEmail", userCredential.user.email);
      localStorage.setItem("current_thread_id", "");
      localStorage.setItem("current_history_id", "");
      setUser(userCredential.user);
      localStorage.setItem("token", userCredential._tokenResponse.idToken);
      // ================================== 
      const isVerified = await verifyUser(email);
      if ( !isVerified) {
        setShowSignUpModal(true);
        await axios.post(
          `${sourceUrl}/profile`,
          {},
          {
            headers: {
              Authorization: `Bearer ${userCredential._tokenResponse.idToken}`,
            },
          }
        );
        return;
      }
      // ==================================
      if (userCredential.user.emailVerified) {
        setUser(userCredential.user.emailVerified);
        navigate("/workspace");
        localStorage.setItem("isLoggedIn", true);
        setShowLoginModal(false);
        window.location.reload();
      } else {
        setShowLoginModal(true);
        localStorage.setItem("isLoggedIn", "");
      }
      LoginEvent("user_login", "login", "login_with_email")
      clearStates()
    } catch (error) {
      console.error("Error logging in:", error);
      // setNotifMsg(`Error logging in: ${error.message}`);
      let message;
      switch (error.code) {
        case "auth/invalid-credential":
          message = "Invalid Credentials";
          break;
        case "auth/user-not-found":
          message = "User not found.";
          break;
        case "auth/wrong-password":
          message = "Incorrect password.";
          break;
        case "auth/too-many-requests":
          message =
            "Access to this account has been temporarily disabled due to many failed login attempts.Please try again later";
          break;
        default:
          message = "An error occurred. Please try again.";
      }
      ErrorEvent(error ,error.message)
      setNotifMsg(message);
      setNotifVariant("error");
      setShowNotif(true);
      setOpen(true);
    }
  };

  const verifyUser = async (email) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${sourceUrl}/login/verify`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === false) {
        throw new Error("Please Verify your Email address From Your Inbox");
      }
      return true;
    } catch (error) {
      ErrorEvent(error ,error.message)
      setNotifMsg(error.message || "Verification failed");
      setNotifVariant("error");
      setShowNotif(true);
      setOpen(true);
      return false;
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      LoginEvent("user_login", "login", "continue_with_google")

      // ===================== Invited users check =====================
      // const isVerified = await verifyUser(result.user.email);
      // if (!isVerified) return;

      // ===============================================================
      if (result.user.emailVerified) {
        {
          result.user &&
            result.user.accessToken &&
            (await axios.post(
              `${sourceUrl}/profile`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${result.user.accessToken}`,
                },
              }
            ));
        }

        localStorage.setItem("token", result.user.accessToken);
        const isVerified = await verifyUser(result.user.email);
        if (!isVerified) {
          setShowSignUpModal(true);
          await axios.post(
            `${sourceUrl}/profile`,
            {},
            {
              headers: {
                Authorization: `Bearer ${result.user.accessToken}`,
              },
            }
          );
          return;
        }
        localStorage.setItem("current_thread_id", "");
        localStorage.setItem("current_history_id", "");
        setUser(result.user.emailVerified);
        navigate("/workspace");
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("userEmail", result.user.email);
        setShowLoginModal(false);
        window.location.reload();
      } else {
        setShowLoginModal(true);
        localStorage.setItem("isLoggedIn", "");
      }
    } catch (error) {
      ErrorEvent(error ,error.message)
      console.error("Error signing up with Google:", error);
      setNotifMsg(`Error signing up with Google: ${error.message}`);
      setNotifVariant("error");
      setShowNotif(true);
      setOpen(true);
    }
  };

  const handleOpenSignUpModal = () => {
    setShowSignUpModal(true);
    setShowLoginModal(false);
    handleCloseLoginModal();
  };
  const handleCloseLoginModal = () => {
    if(!isBelow991){
      setShowLoginModal(false);
    }
  };

  const handleForgotPassword = () => {
    setShowLoginModal(false);
    setShowSignUpModal(false);
    setShowResetPasswordModal(true);
  };

  const handleToggleVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (event) => {
    const input = event.target.value;
    let updatedPassword;
  
    if (showPassword) {
      updatedPassword = input;
    } else {
      if (input.length < password.length) {
        updatedPassword = password.slice(0, input.length);
      } else {
        updatedPassword = password + input[input.length - 1];
      }
    }
  
    setPassword(updatedPassword);
    setMaskedPassword('*'.repeat(updatedPassword.length));
  };

  const clearStates = () =>{
    setEmail("");
    setPassword("");
    setMaskedPassword("");
    setShowPassword(false);
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  const customTextFieldStyles = {
    "& .MuiInputLabel-root": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.5px",
      color: "rgba(255, 255, 255, 0.80)",
      "&.Mui-focused": {
        color: "rgba(255, 255, 255, 0.80) !important",
      },
    },
    "& .MuiInputBase-input": {
      color: "#FFFFFF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80) !important",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiInputLabel-root": {
      color: "rgba(255, 255, 255, 0.80) !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(255, 255, 255, 0.80) !important",
    },
  };

  // const isBelow1300px = useMediaQuery("(max-width: 1300px)")
  // useEffect(()=>{
  //   if(isBelow1300px){
  //     alert("")
  //   }
  // },[isBelow1300px])

  const escapeToggle = () => {
    setShowLoginModal(false);
  };

  // Function to detect key press (e.g., 'Escape' key to toggle)
  const escapeHandleKeyDown = (event) => {
    if (event.key === 'Escape') {
      escapeToggle();
    }
  };

  useEffect(() => {
    // Add keydown event listener
    window.addEventListener('keydown', escapeHandleKeyDown);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('keydown', escapeHandleKeyDown);
    };
  }, []);
  
 


  return (
    <>
    <div style={{ position: isBelow991 ? "" :"absolute", zIndex:50, top: isBelow991 ? "0%" : "50%", marginTop: isBelow991 ? "0%" :"-40px"  , left:isBelow991 ? "0%" :"50%",transform: isBelow991 ? "" : "translate(-50%, -0%)",  backgroundColor:"rgba(23,26,45,0.8)" }}>
      {
        showLoginModal && (<div
          onClick={handleCloseLoginModal}
        >
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: isBelow991 ? "" : "100vh",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              zIndex: 10,
            }}
          >
            <Box
              onClick={(event) => event.stopPropagation()}
              sx={{
                display: "flex",
                flexDirection: "row",
                width:isBelow991 ? "100%" : "90%",
                maxWidth:isBelow991 ? "342px" : "1200px",
                height: "40.875rem",
                borderRadius: "8px",
                border: isBelow991 ? null : "1px solid #CAC4D0",
              }}
            >
              <Box
                sx={{
                  backgroundColor: isBelow991 ? "#171A2D" : "#1F233E",
                  width: isBelow991 ? "0%": "50%",
                  height: "100%",
                  borderRadius: "8px 0px 0px 8px ",
                }}
              >
              {
                isBelow991 ? null : <SignupLeftSide images={images} currentIndex={currentIndex} /> 
              }
              </Box>
              <Box
                sx={{
                  backgroundColor: isBelow991 ? "#171A2D" : "#282D51",
                  width: isBelow991 ? "100%": "50%",
                  height: "100%",
                  borderRadius: "0px 8px 8px 0px ",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItema: "center",
                  padding: {
                    xs: "0px 0px",
                    sm: "0px 0px",
                    md: "0px 0px",
                    lg: "0px 141px",
                    xl: "0px 141px",
                  },
                }}
              >
                <div style={{ maxWidth:"100%", display:"flex", flexDirection:"column", alignSelf:"center" }}>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      fontFamily: "Inter",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "20px",
                      color: "#FFFFFF",
                      marginBottom: "40px",
                      alignSelf: "start",
                      whiteSpace: "nowrap",
                      textAlign:"left"
                    }}
                  >
                    Log in to your ModalX account
                  </Typography>
                  <FormControl
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      id="outlined-basic"
                      label="Email address*"
                      variant="outlined"
                      sx={customTextFieldStyles}
                      style={{
                        width: "100%", 
                    maxWidth :  isBelow991 ? "342px" : "300px",
                        height: "56px",
                        color: "#FFFFFF",
                        backgroundColor: "#232746",
                        borderRadius: "8px",
                        boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                        placeholderfontsize: "16px",
                        marginBottom: "36px",
                      }}
                    />
                    <TextField
                      type="text"
                      onChange={handlePasswordChange}
                      value={showPassword ? password : maskedPassword} 
                      id="outlined-basic"
                      label="Password*"
                      variant="outlined"
                      sx={customTextFieldStyles}
                      style={{
                        width: "100%", 
                    maxWidth :  isBelow991 ? "342px" : "300px",
                        height: "56px",
                        color: "#FFFFFF",
                        backgroundColor: "#232746",
                        borderRadius: "8px",
                        boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                        placeholderfontsize: "16px",
                        marginBottom: "16px",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleToggleVisibility}
                              edge="end"
                              aria-label="toggle password visibility"
                              
                            >
                              {showPassword ?  <Visibility sx={{color:"#CAC4D0"}}/> : <VisibilityOff  sx={{color:"#CAC4D0"}}/> }
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText
                      onClick={handleForgotPassword}
                      sx={{
                        color:
                          "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textDecorationLine: "underline",
                        alignSelf: "start",
                        cursor: "pointer",
                        margin:  "4px 16px 36px 0px",
                      }}
                    >
                      Forgot password?
                    </FormHelperText>
                    <Box>
                      <Button
                        onClick={handleLogin}
                        variant="outlined"
                        sx={{
                          gap: "8px",
                          padding: "10px 24px 10px 16px",
                          fontSize: "16px",
                          width: "100%", 
                           maxWidth :  isBelow991 ? "342px" : "300px",
                          textTransform: "none",
                          bgcolor: button_bg_color_for_New_Project_dm,
                          color: "black",
                          fontWeight: "600",
                          height: "40px",
                          fontFamily: "Inter, sans-serif",
                          border : 'none',
                          "&:hover": {
                            bgcolor: "#ffd9aa",
                            border : 'none',
                          },
                          
                        }}
                      >
                        Log in
                      </Button>
                      <Typography
                        sx={{
                          display: "flex",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "20px",
                          color: "rgba(255, 255, 255, 0.80);",
                          margin: "12px 0px",
                          padding: "auto auto",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        OR
                      </Typography>
                      <Button
                        onClick={handleGoogleSignUp}
                        variant="outlined"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "8px",
                          padding: "10px 24px 10px 16px",
                          fontSize: "16px",
                          width: "100%", 
                          maxWidth :  isBelow991 ? "342px" : "300px",
                          textTransform: "none",
                          bgcolor: uploadFiles_button_default_bg_color,
                          color: "black",
                          fontWeight: "600",
                          height: "40px",
                          fontFamily: "Inter, sans-serif",
                          marginBottom: "90px",
                          border : 'none',
                          "&:hover": {
                            bgcolor: uploadFiles_button_hovered_bg_color,
                            border : 'none',
                          },
                        }}
                      >
                        <img
                          style={{ width: "5%" }}
                          src={require(`../../assets/transparent-google-logo-google-logo-with-multicolored-g-and-1710875781697.png`)}
                          alt="G"
                        />
                        Continue with Google
                      </Button>
                    </Box>

                    <FormHelperText
                      sx={{
                        width: "100%", 
                        maxWidth :  isBelow991 ? "342px" : "300px",
                        color:
                          "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "20px",
                        margin: "4px 0px 14px 0px",
                        alignSelf: "center",
                        textAlign: "center",
                        whiteSpace:"nowrap",
                      }}
                    >
                      Don’t have an account yet?{" "}
                      <span
                        onClick={handleOpenSignUpModal}
                        style={{
                          textDecorationLine: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Sign up
                      </span>
                    </FormHelperText>
                  </FormControl>
                </div>
              </Box>
            </Box>
          </div>
        </div>
      )}
      <CustomizedSnackbars
        open={open}
        setOpen={setOpen}
        notifMsg={notifMsg}
        notifVariant={notifVariant}
        setShowNotif={setShowNotif}
      />
        {showResetPasswordModal && (
          <ResetPassword
            setShowSignUpModal={setShowSignUpModal}
            showLoginModal={showLoginModal}
            setShowLoginModal={setShowLoginModal}
            showResetPasswordModal={showResetPasswordModal}
            setShowResetPasswordModal={setShowResetPasswordModal}
            setShowResendModal={setShowResendModal}
            setEmail={setEmail}
            email={email}
            setMaskedPassword={setMaskedPassword}
            
          />)}
        {showResendModal && (
          <ResendEmailmodal
            setShowSignUpModal={setShowSignUpModal}
            showLoginModal={showLoginModal}
            setShowLoginModal={setShowLoginModal}
            showResetPasswordModal={showResetPasswordModal}
            setShowResetPasswordModal={setShowResetPasswordModal}
            setShowResendModal={setShowResendModal}
            showResendModal={showResendModal}
            setEmail={setEmail}
            email={email}
          />)} 
    </div>
    </>
  );
};
export default Login;

export function CustomizedSnackbars({
  open,
  setOpen,
  notifMsg,
  setShowNotif,
  notifVariant,
}) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div style={{ position: "relative", zIndex: 60 }}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
        <Alert
          onClose={handleClose}
          severity={notifVariant}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {notifMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
