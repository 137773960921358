import React from "react";
import PageLayout from "../components/navFoot/PageLayout";

const Career = () => {
  return (
    <PageLayout>
      <h1 className="mb-4">Careers</h1>
      <p style={{ color: "gray" }} className="pt-3 border-top">
        Are you a self-driven, hardworking individual with a passion for working
        on cutting-edge technology, including GenAI, LLMs, GPTs, and more?{" "}
        <br /> If you have relevant experience in the field and share a love for
        our product, we'd love to hear from you!
      </p>
      <p style={{ color: "gray" }} className="pt-3 border-top">
        Please share your CV and resume with us at{" "}
        <a href="mailto:career@getbrightspot.com">career@getbrightspot.com</a>.
        Our team will review your application and connect with you as soon as
        possible.
      </p>
    </PageLayout>
  );
};

export default Career;
