import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const NewPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // useEffect(() => {
    //     const param1 = queryParams.get('code');
    //     console.log(param1);

    //     const GetTokenAccess = async () => {

    //         const CLIENT_ID = '77hvhjsd6yyk7j';  // Replace with your LinkedIn App Client ID
    //         const CLIENT_SECRET = 'WPL_AP1.G2cxSDf8JPvIwe9h.XVMMtQ==';
    //         const REDIRECT_URI = 'http://localhost:3000/newpage';  // The callback URL after authentication
    //         const CODE = param1

    //         if (param1) {
    //             try {
    //                 const response = await axios.post(`https://cors-anywhere.herokuapp.com/https://www.linkedin.com/uas/oauth2/accessToken?client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}&redirect_uri=${REDIRECT_URI}&grant_type=authorization_code&code=${CODE}`);
    //                 console.log(response)
    //                 if (response?.data?.access_token) {
    //                     console.log("Access Token", response?.data?.access_token)
    //                     localStorage.setItem("accessToken", response?.data?.access_token)
    //                     // Define the headers
    //                     const config = {
    //                         headers: {
    //                             'Content-Type': 'application/json',  // You can set other headers like Authorization, etc.
    //                             Authorization: `Bearer ${response?.data?.access_token}`,
    //                         },
    //                     };
    //                     const getaccess = await axios.get(`https://cors-anywhere.herokuapp.com/https://api.linkedin.com/v2/userinfo`, config)
    //                     localStorage.setItem("sub", getaccess?.data?.sub)
    //                     window.location.href = "http://localhost:3000/workspace"
    //                 }
    //             } catch (e) {
    //                 console.log("Error", e)
    //             }
    //         }
    //     }
    //     GetTokenAccess()
    // }, [])

    return (
        <div>Access Permisssion</div>
    )
}

export default NewPage