import React, { useState } from "react";
import "./CSS/FAQ.css";
import { Box, Typography, Button } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import RequestDemoNearFooter from "../components/LandingPage/RequestDemoNearFooter";
import PageLayout from "../components/navFoot/PageLayout";

function FAQ() {
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);
  const [toggle4, setToggle4] = useState(false);
  const [toggle5, setToggle5] = useState(false);
  const [toggle6, setToggle6] = useState(false);
  const [toggle7, setToggle7] = useState(false);
  const [toggle8, setToggle8] = useState(false);
  const [toggle9, setToggle9] = useState(false);
  const [toggle10, setToggle10] = useState(false);
  const [toggle11, setToggle11] = useState(false);
  const [toggle12, setToggle12] = useState(false);
  const [toggle13, setToggle13] = useState(false);

  const handleChange1 = () => {
    setToggle1(!toggle1);
  };
  const handleChange2 = () => {
    setToggle2(!toggle2);
  };
  const handleChange3 = () => {
    setToggle3(!toggle3);
  };
  const handleChange4 = () => {
    setToggle4(!toggle4);
  };
  const handleChange5 = () => {
    setToggle5(!toggle5);
  };
  const handleChange6 = () => {
    setToggle6(!toggle6);
  };
  const handleChange7 = () => {
    setToggle7(!toggle7);
  };
  const handleChange8 = () => {
    setToggle8(!toggle8);
  };
  const handleChange9 = () => {
    setToggle9(!toggle9);
  };
  const handleChange10 = () => {
    setToggle10(!toggle10);
  };
  const handleChange11 = () => {
    setToggle11(!toggle11);
  };
  const handleChange12 = () => {
    setToggle12(!toggle12);
  };
  const handleChange13 = () => {
    setToggle13(!toggle13);
  };

  const datas = [
    {
      id: 1,
      title1: "Can the data obtained from ModalX be used commercially?",
      title2:
        "Certainly, commercial utilization of content generated by ModalX is allowed. You're free to use the data for lawful purposes. However, please exercise caution and check the information as ModalX can make mistakes. Refer to our  ",
      span: "Terms of Service",
      toggles: handleChange1,
      toggle: toggle1,
      title3: "for comprehensive information",
    },
    {
      id: 2,
      title1: "Are the generated images eligible for NFTs?",
      title2:
        "Certainly, commercial utilization of content generated by ModalX is allowed. You're free to use the data for lawful purposes. However, please exercise caution and check the information as ModalX can make mistakes. Refer to our ",
      span: "Terms of Service",
      toggles: handleChange2,
      toggle: toggle2,
      title3: "for comprehensive information",
    },
    {
      id: 3,
      title1: "What is the ownership status of the output?",
      title2:
        "Certainly, commercial utilization of content generated by ModalX is allowed. You're free to use the data for lawful purposes. However, please exercise caution and check the information as ModalX can make mistakes. Refer to our  ",
      span: "Terms of Service",
      toggles: handleChange3,
      toggle: toggle3,
      title3: "for comprehensive information",
    },
    {
      id: 4,
      title1: "Does the output have copyright protection?",
      title2:
        "Certainly, commercial utilization of content generated by ModalX is allowed. You're free to use the data for lawful purposes. However, please exercise caution and check the information as ModalX can make mistakes. Refer to our  ",
      span: "Terms of Service",
      toggles: handleChange4,
      toggle: toggle4,
      title3: "for comprehensive information",
    },
    {
      id: 5,
      title1:
        "Is it possible to obtain images of higher resolution or quality?",
      title2:
        "Certainly, commercial utilization of content generated by ModalX is allowed. You're free to use the data for lawful purposes. However, please exercise caution and check the information as ModalX can make mistakes. Refer to our  ",
      span: "Terms of Service",
      toggles: handleChange5,
      toggle: toggle5,
      title3: "for comprehensive information",
    },
    {
      id: 6,
      title1: "Are the images suitable for printing?",
      title2:
        "Certainly, commercial utilization of content generated by ModalX is allowed. You're free to use the data for lawful purposes. However, please exercise caution and check the information as ModalX can make mistakes. Refer to our  ",
      span: "Terms of Service",
      toggles: handleChange6,
      toggle: toggle6,
      title3: "for comprehensive information",
    },
    {
      id: 7,
      title1: "Is the output downloadable and editable?",
      title2:
        "Certainly, commercial utilization of content generated by ModalX is allowed. You're free to use the data for lawful purposes. However, please exercise caution and check the information as ModalX can make mistakes. Refer to our  ",
      span: "Terms of Service",
      toggles: handleChange7,
      toggle: toggle7,
      title3: "for comprehensive information",
    },
    {
      id: 8,
      title1: "How can I register for ModalX?",
      title2:
        "Certainly, commercial utilization of content generated by ModalX is allowed. You're free to use the data for lawful purposes. However, please exercise caution and check the information as ModalX can make mistakes. Refer to our  ",
      span: "Terms of Service",
      toggles: handleChange8,
      toggle: toggle8,
      title3: "for comprehensive information",
    },
    {
      id: 9,
      title1: "Does ModalX offer free presentation creation?",
      title2:
        "Certainly, commercial utilization of content generated by ModalX is allowed. You're free to use the data for lawful purposes. However, please exercise caution and check the information as ModalX can make mistakes. Refer to our  ",
      span: "Terms of Service",
      toggles: handleChange9,
      toggle: toggle9,
      title3: "for comprehensive information",
    },
    {
      id: 10,
      title1:
        "Is it possible to share presentations and make the edits offline?",
      title2:
        "Certainly, commercial utilization of content generated by ModalX is allowed. You're free to use the data for lawful purposes. However, please exercise caution and check the information as ModalX can make mistakes. Refer to our  .",
      span: "Terms of Service",
      toggles: handleChange10,
      toggle: toggle10,
      title3: "for comprehensive information",
    },
    {
      id: 11,
      title1: "Where should I go if I have further inquiries?",
      title2:
        "Certainly, commercial utilization of content generated by ModalX is allowed. You're free to use the data for lawful purposes. However, please exercise caution and check the information as ModalX can make mistakes. Refer to our  ",
      span: "Terms of Service",
      toggles: handleChange11,
      toggle: toggle11,
      title3: "for comprehensive information",
    },
    {
      id: 12,
      title1: "Is ModalX’s AI voice generator the most superior?",
      title2:
        "Certainly, commercial utilization of content generated by ModalX is allowed. You're free to use the data for lawful purposes. However, please exercise caution and check the information as ModalX can make mistakes. Refer to our  ",
      span: "Terms of Service",
      toggles: handleChange12,
      toggle: toggle12,
      title3: "for comprehensive information",
    },
    {
      id: 13,
      title1: "How extensive is ModalX's language support?",
      title2:
        "Certainly, commercial utilization of content generated by ModalX is allowed. You're free to use the data for lawful purposes. However, please exercise caution and check the information as ModalX can make mistakes. Refer to our  ",
      span: "Terms of Service",
      toggles: handleChange13,
      toggle: toggle13,
      title3: "for comprehensive information",
    },
  ];

  var lenData = datas.length;
  return (
    <>
      <PageLayout>
        {/* Main Box */}

        {/* section 1 */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              marginTop: "246px",
              gap: "48px",
              opacity: "0.8px",
              "@media(max-width : 991px)": {
                marginTop: "156px",
              },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "48px",
                fontWeight: 500,
                lineHeight: "57.6px",
                letterSpacing: "-0.32px",
                textAlign: "center",
                color: "rgba(255, 255, 255, 1)",
                "@media(max-width : 991px)": {
                  fontSize: "28px",
                  padding: "0px 20px 0px 20px",
                },
              }}
            >
              Frequently Asked Questions
            </Typography>
          </Box>
        </Box>

        {/* section 1 */}

        {/* section 2 */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "720px",

              marginTop: "120px",
              marginBottom: "120px",
              gap: "48px",
              opacity: "0.8px",
              "@media (max-width : 991px)": {
                marginTop: "60px",
                width: "100%",
                padding: "0px 4.4% 0px 4.4%",
              },
            }}
          >
            <Box
              sx={{
                width: "720px",
                gap: "24px",
                opacity: "0px",
                "@media (max-width : 991px)": {
                  width: "100%",
                },
                // bgcolor : 'green',
              }}
            >
              {datas.map((e) => (
                <Box sx={{}} key={e.id}>
                  <Box
                    sx={{
                      width: "720px",
                      height: "36px",
                      gap: "0px",
                      justifyContent: "space-between",
                      opacity: "0px",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "18px",
                      marginBottom: "18px",
                      "@media (max-width : 991px)": {
                        width: "100%",
                        justifyContent: 'space-between',
                       
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: "565px",
                        gap: "0px",
                        opacity: "0px",
                        "@media (max-width : 991px)": {
                          width: "100%",
                         
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          
                          fontSize: "20px",
                          fontWeight: 500,
                          lineHeight: "30px",
                          textAlign: "left",
                          color: "rgba(255, 255, 255, 1)",
                          "@media (max-width : 991px)": {
                            fontSize: "16px",
                          },
                          
                        }}
                      >
                        {e.title1}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        width: "36px",
                        height: "36px",
                        gap: "0px",
                        opacity: "0px",
                       
                      }}
                    >
                      <Box
                        sx={{
                          width: "21px",
                          height: "21px",
                          paddingTop: "7.5px",
                          paddingLeft: "7.5px",

                          color: "rgba(255, 255, 255, 1)",
                          backgroundColor: "rgba(23, 26, 45, 1)",
                          "&:hover": {
                            backgroundColor: "rgba(23, 26, 45, 1)",
                          },
                        }}
                        onClick={e.toggles}
                      >
                        {e.toggle ? <RemoveIcon /> : <AddIcon />}{" "}
                      </Box>
                    </Box>
                  </Box>
                  {e.toggle ? (
                    <Box
                      sx={{
                        width: "100%",
                        gap: "0px",
                        opacity: "0px",
                        marginBottom: "36px",
                      }}
                    >
                      {" "}
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: 400,
                          lineHeight: "24px",
                          textAlign: "left",
                        }}
                      >
                        {e.title2}{" "}
                        <Typography
                          sx={{ textDecoration: "underline" }}
                          component="span"
                        >
                          {e.span}{" "}
                        </Typography>
                        <Typography
                          component="span"
                          sx={{
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "24px",
                            textAlign: "left",
                          }}
                        >
                          {e.title3}
                        </Typography>
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )}
                  {e.id !== lenData ? (
                    <Box
                      sx={{
                        border: "solid 1px rgba(255, 255, 255, 0.2)",
                      }}
                    ></Box>
                  ) : (
                    ""
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        {/* section 2 */}

        <RequestDemoNearFooter />
      </PageLayout>
    </>
  );
}

export default FAQ;
