//const url = process.env.REACT_APP_SOURCE_URL;

const sourceUrl = process.env.REACT_APP_SOURCE_URL;
const client_id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
const client_secret = process.env.REACT_APP_LINKEDIN_CLIENT_SECRET;
const cors_url = process.env.REACT_APP_CORS_URL;
const redirect_url = process.env.REACT_APP_REDIRECT_URL;

export { client_id, client_secret, cors_url, redirect_url };
export default sourceUrl
