import React, { useEffect, useState, useRef } from 'react'
import { fetchVoices, VideoGenAPISettings } from './VideoGenAPISettings'
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { Box, Grid, Typography, IconButton, } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";


const VoiceSettings = () => {

    const [playingVoice, setPlayingVoice] = useState(null);
    const [audioElement, setAudioElement] = useState(null);
    const modalContentRef = useRef(null);
    const theme = localStorage.getItem("theme");

    const {
        genVideoVoice, setGenVideoVoice,
        genVideoVoicesLoading, setGenVideoVoicesLoading,
        selectedGenVideoVoice, setSelectedGenVideoVoice,
        setGenVideoLanguage, setGenVideoAudioSettingsLoading,
        setCaptionFontForVideoGen
      } = useWorkspace();

    useEffect(()=>{
      if(!setGenVideoVoice){
        fetchVoices(setGenVideoVoice,setGenVideoVoicesLoading)
      }

         // Cleanup audio element when the component unmounts
    // return () => {
    //     if (audioElement) {
    //       audioElement.pause();
    //       // setAudioElement(null);
    //     }
    //   };
    },[])

    useEffect(()=>{
      if(selectedGenVideoVoice !== null){
        setGenVideoVoice((prevVoices)=>{
          const filterVoices = prevVoices?.filter(voice => voice?.id !== selectedGenVideoVoice?.id)
          return [selectedGenVideoVoice,...filterVoices]
        })
      }
    },[selectedGenVideoVoice])

    
    const handleVoiceSelect = (voice) => {
        // console.log(voice, "voice========================")
        // setSelectedGenVideoVoice(voice);
        if(playingVoice === voice?.id){
            audioElement.pause();
            setPlayingVoice(null);  
        }else{
            if(audioElement){
                audioElement.pause();
            }
            const newAudioElement = new Audio(voice.url);
            newAudioElement.play();
            setPlayingVoice(voice?.id);
            setAudioElement(newAudioElement);
        }
    }
    // console.log(genVideoVoice, "genVideoVoice========================")
    const handleVoiceSelectClick = (voice) =>{
      setSelectedGenVideoVoice(voice)
      modalContentRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }

  return (
    <> {
        genVideoVoicesLoading ? 
        <Box sx={{ p: 2 }}>
              <Typography>Loading Voices</Typography>
        </Box>
        :
        <Box sx={{ p: 2 }} ref={modalContentRef}>
          <Typography variant="h6" gutterBottom style={{ fontSize: '16px', fontWeight: '500', width: '143px', height: '20px', marginBottom: '20px' }}>
            Select Voice
          </Typography>
    
            <Grid container spacing={1} justifyContent="flex-start">
              {Array.isArray(genVideoVoice) &&
                genVideoVoice.map((voiceOption) => (
                  <Grid item key={voiceOption.voice_id} xs={12} sm={6}>
                    <Grid container alignItems="center" gap="5px">
                      <Grid item sx={{ flex: '1 1 auto' }}>
                        <Box sx={{ display: 'flex', alignItems: 'start', gap: '5px' }}>
                          {/* Updated IconButton Styling */}
    
    
                          {/* Box acting as button with Typography */}
                          <Box
                            component="button"
                            onClick={() =>handleVoiceSelectClick(voiceOption)}
                            sx={{
                              width: '100%',
                              backgroundColor: selectedGenVideoVoice?.id === voiceOption.id ? '#3a3f69' : '#30355f',
                              color: theme === 'dark' ? 'white' : 'black',
                              '&:hover': {
                                backgroundColor: selectedGenVideoVoice?.id === voiceOption.id ? '#3a3f69' : '',
                              },
                              border: `1px solid ${selectedGenVideoVoice?.id === voiceOption.id ? '#cac4d0' : 'transparent'}`,
                              mx: 0.5,
                              textTransform: 'none',
                              padding: '8px 12px',
                              cursor: 'pointer',
                              borderRadius: '4px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'start',
                            }}
                          >
                            <Box sx={{ textAlign: 'left', width : '100%',  }}>
                              <Typography sx={{fontSize : '14px',}}>Name: {voiceOption.name}</Typography>
                              {/* <Typography sx={{fontSize : '14px'}}>Age: {voiceOption.labels.age}</Typography> */}
                              <Typography sx={{fontSize : '14px'}}>Gender: {voiceOption.gender}</Typography>
                              <Typography sx={{fontSize : '14px'}}>Language: {voiceOption.language}</Typography>
                              <Typography sx={{fontSize : '14px'}}>Country: {voiceOption.country}</Typography>
                              <Box sx={{
                                marginTop : '10px', 
                                width : '100%',
                                // bgcolor : 'red',
                                display   : "flex",
                                // justifyContent : "space-between",
                                alignItems : "center",
                                // padding : '0px 20px 0px 20px',
                              }}>
                                <IconButton
                                  onClick={(e) => {handleVoiceSelect(voiceOption);  e.stopPropagation() }}
                                  sx={{
                                    color: playingVoice === voiceOption.id ? '#9e9e9e' : '#ffffff',
                                    backgroundColor: playingVoice === voiceOption.id ? '#53567A' : '#3B3E5B',
                                    transition: 'background-color 0.3s ease, transform 0.2s ease',
                                    '&:hover': {
                                      backgroundColor: '#282D51',
                                      // transform: 'scale(1.1)', // Subtle zoom on hover
                                    },
                                    width : '30px',
                                    height : '30px',
                                    padding: '12px',
                                    borderRadius: '50%', 
                                  }}
                                >
                                  {playingVoice === voiceOption.id ? <PauseIcon /> : <PlayArrowIcon />}
                                </IconButton>
                                <Typography sx={{fontSize : '14px', marginLeft : '12px'}}>Try this voice</Typography>
    
                              </Box>
    
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
    
    
        </Box>
     
    
        }</>
  )
}

export default VoiceSettings