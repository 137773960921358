import React from "react";
import { darkTheme_bg_color } from "../../../constants/colors";
import round_Icon from "../../../assets/LandingPage_Items/modalx_Round_Icon_dm.png";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Box, Typography, CardMedia } from "@mui/material";

const Simplified = () => {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: darkTheme_bg_color,
        textAlign: "center",
      }}
    >
      {/* <p style={{textAlign:'center',color:'#F6BE6B'}}>Simplified</p> */}
      <div style={{ maxWidth: "1072px", margin: "auto", textAlign: "center" }}>
        <Typography
          sx={{
            fontSize: "48px",
            marginTop: "80px",
            "@media (max-width : 991px)": {
              // width: "370px",
              // height: "136px",
              fontFamily: "Inter",
              fontSize: "28px",
              fontWeight: 500,
              lineHeight: "33.6px",
              letterSpacing: "-0.32",
              textAlign: "center",
              margin: "auto",
              marginTop: "36px",
              padding : '0px 10px 0px 10px'
            },
          }}
        >
          We cut through the AI clutter so you can focus on what matters –
          growing your business
        </Typography>
      </div>
      {/* <div style={{maxWidth:'456px',height:'487px',borderRadius:'20px',border:'1px solid',margin:'80px auto',borderColor:'rgba(255, 255, 255, 0.8)'}}>
            <div style={{maxWidth:'364px',margin:'60px auto'}}>
                <p style={{fontSize:'16px',marginBottom:'36px'}}>Generate an Instagram marketing campaign for Sharewise</p>
                <img src={round_Icon} />
                <div style={{maxWidth:'340px',margin:'20px auto auto auto',display:'grid',gap:'40px'}}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <RadioButtonUncheckedIcon />
                        <p style={{fontSize:'14px'}}>Divide weekly post schedule in two series of posts: testimonials and features</p>
                    </div>
                    <div style={{display:'flex',alignItems:'center',fontSize:'13px',color:'rgba(255, 255, 255, 0.6)',maxWidth:'308px',margin:'auto'}}>
                        <TaskAltIcon />
                        <p style={{}}>Identify your target audience based on 3 parameters: age, location, and interests</p>
                    </div>
                    <div style={{display:'flex',alignItems:'center',fontSize:'10px',color:'rgba(255, 255, 255, 0.4)',maxWidth:'291px',margin:'auto'}}>
                        <TaskAltIcon />
                        <p style={{}}>Download the free HubSpot content guide PDF and choose the type of content you want to publish</p>
                    </div>
                </div>
            </div>
        </div> */}

      <Box
        sx={{
          maxWidth: "456px",
          height: "487px",
          borderRadius: "20px",
          border: "1px solid #FFFFFFCC",
          margin: "auto",
          marginTop: "80px",
          marginBottom: "80px",
          bgcolor: "#1C2038",
          display: "flex",
          justifyContent: "center",
          "@media(max-width : 991px)": {
            width: "358px",
            height: "382px",
            gap: "0px",
            borderRadius: "16px",
            marginTop: "40px",
            marginBottom: "40px",
          },
        }}
      >
        <Box
          sx={{
            width: "368px",
            height: "40px",
            margin: "60px auto",
            "@media (max-width : 991px)": {
              width: "285px",
              height: "32px",
              marginTop: "47px",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "20px",
              textAlign: "center",
              color: "rgba(255, 255, 255, 0.8)",

              "@media (max-width : 991px)": {
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "15.7px",
                textAlign: "center",
              },
            }}
          >
            Generate an Instagram marketing campaign for Sharewise
          </Typography>

          <Box
            sx={{
              width: "40px",
              height: "40px",
              gap: "10px",
              opacity: "0px",
              margin: "auto",
              marginTop: "36px",
              "@media (max-width : 991px)": {
                width: "34px",
                height: "34px",
                marginTop: "27px",
              },
            }}
          >
            <CardMedia
              sx={{
                width: "40px",
                height: "40px",
                "@media (max-width : 991px)": {
                  width: "34px",
                  height: "34px",
                },
              }}
              component="img"
              image={round_Icon}
              alt="round_Icon"
            />
          </Box>

          <Box
            sx={{
              Width: "340px",
              height: "200px",
              margin: "auto",
              display: "grid",
              justifyContent: "center",
              gap: "40px",
              opacity: "0px",
              marginTop: "20px",
              "@media (max-width : 991px)": {
                Width: "266px",
                height: "158px",
                marginTop: "13px",
                gap: "28px",
              },
            }}
          >
            <Box
              sx={{
                width: "340px",
                height: "40px",
                gap: "16px",
                opacity: "0px",
                display: "flex",
                alignItems: "center",
                margin: "auto",
                justifyContent: "space-between",
                "@media (max-width : 991px)": {
                  width: "266px",
                  height: "32px",
                },
              }}
            >
              <Box
                sx={{
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "rgba(255, 255, 255, 0.8)",
                  "@media (max-width : 991px)": {
                    width: "15px",
                    height: "15px",
                  },
                }}
              >
                <RadioButtonUncheckedIcon
                  sx={{
                    width: "20px",
                    height: "20px",
                    "@media (max-width : 991px)": {
                      width: "15px",
                      height: "15px",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "300px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "rgba(255, 255, 255, 0.8)",
                  opacity: "0px",
                  gap: "0px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    textAlign: "left",
                    color: " #FFFFFF 80%",
                    "@media (max-width : 991px)": {
                      fontSize: "11px",
                      lineHeight: "15.7px",
                    },
                  }}
                >
                  Divide weekly post schedule in two series of posts:
                  testimonials and features
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                width: "308px",
                height: "40px",
                gap: "16px",
                opacity: "0px",
                display: "flex",
                alignItems: "center",
                margin: "auto",
                justifyContent: "space-between",
                "@media (max-width : 991px)": {
                  width: "241px",
                  height: "32px",
                  gap: "12px",
                },
              }}
            >
              <Box
                sx={{
                  width: "16.67px",
                  height: "16.67px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "rgba(255, 255, 255, 0.6)",
                  "@media (max-width : 991px)": {
                    width: "13px",
                    height: "13px",
                  },
                }}
              >
                <TaskAltIcon
                  sx={{
                    width: "16.67px",
                    height: "16.67px",
                    "@media (max-width : 991px)": {
                      width: "13px",
                      height: "13px",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "272px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#FFFFFF",
                  opacity: "0px",
                  gap: "0px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    textAlign: "left",
                    color: "rgba(255, 255, 255, 0.6)",
                    "@media (max-width : 991px)": {
                      fontSize: "10.21px",
                      lineHeight: "15.7px",
                    },
                  }}
                >
                  Identify your target audience based on 3 parameters: age,
                  location, and interests
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                width: "291px",
                height: "40px",
                gap: "16px",
                opacity: "0px",
                display: "flex",
                alignItems: "center",
                margin: "auto",
                justifyContent: "space-between",
                "@media (max-width : 991px)": {
                  width: "228px",
                  height: "32px",
                  gap: "12px",
                },
              }}
            >
              <Box
                sx={{
                  width: "13.33px",
                  height: "13.33px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "rgba(255, 255, 255, 0.4)",
                  "@media (max-width : 991px)": {
                    width: "10.5px",
                    height: "10.5px",
                  },
                }}
              >
                <TaskAltIcon
                  sx={{
                    width: "13.33px",
                    height: "13.33px",
                    "@media (max-width : 991px)": {
                      width: "10.5px",
                      height: "10.5px",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "251px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#FFFFFF",
                  opacity: "0px",
                  gap: "0px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "10px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    textAlign: "left",
                    color: " rgba(255, 255, 255, 0.4)",
                    "@media (max-width : 991px)": {
                      fontSize: "8px",
                      lineHeight: "15.7px",
                    },
                  }}
                >
                  Download the free HubSpot content guide PDF and choose the
                  type of content you want to publish
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Simplified;
