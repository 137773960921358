

import React, { useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { IconBase } from "react-icons";
import { BsCopy } from "react-icons/bs";
import ReactAudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";



// Styled-components for custom audio player styles
const CustomAudioPlayer = styled(ReactAudioPlayer)`
  .rhap_container {
    background-color: transparent !important;
    border-radius: 10px;
  }

  .rhap_progress-bar {
    background: #626473;
    border-radius: 10px;
  }

  .rhap_progress-indicator {
    background: #FFFFFF;
    border-radius: 50%;
  }

  .rhap_progress-filled, .rhap_volume-filled {
    background: #FFFFFF !important;
    border-radius: 10px;
  }

  .rhap_time {
    color: #007bff;
  }

  .rhap_controls-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .rhap_repeat-button {
    display: none !important;
  }
  .rhap_progress-section{
      margin:0% 10%
  }
  .rhap_download-progress{
    background-color:#626473
  }
.rhap_main-controls-button, .rhap_volume-button,  .rhap_time{
  color:#FFFFFF
}
.rhap_volume-indicator{
  background-color:#FFFFFF
  }
.rhap_volume-bar{
background:#626473
}
.rhap_volume-bar{
background-color:#FFFFFF
}
`;

const CopyToClipboardButton = ({ url }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(url).then(() => {
      alert("URL copied to clipboard!");
    });
  };

  return (
    <IconBase
      as="button"
      onClick={handleCopy}
      style={{
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        width: "32px",
        height: "32px",
        backgroundColor: "#007bff",
        color: "#fff",
        border: "none",
        padding: "6px",
        outline: "none",
      }}
    >
      <BsCopy size={15} />
    </IconBase>
  );
};

const AudioContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  &:hover .download-button,
  &:hover .large-preview-button,
  &:hover .share-button {
    display: flex;
  }
`;

const ResponsiveDocumentViewerContainer = styled.div`
  position: relative;
  width: 100%;
  &:hover .download-button,
  &:hover .large-preview-button,
  &:hover .share-button {
    display: flex;
  }
`;

const ResponsiveDocumentViewer = styled.iframe`
  width: 100%;
  height: ${({ height }) => height || '100%'};
  border: none;
  
`;

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;

  &:hover .download-button,
  &:hover .large-preview-button,
  &:hover .share-button {
    display: flex;
  }
`;

const ActionButton = styled.button`
  position: absolute;
  top: 10px;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 20px;
  display: none;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
    color: blue;
    background-color: grey;
  }
`;

const DownloadButton = styled.a`
  position: absolute;
  top: 10px;
  right: 5%;
  background-color: grey;
  padding: 2px 8px;
  border-radius: 50%;
  display: none;
  text-decoration: none;
  color: white;
  font-size: 20px;

  &:hover {
    transform: scale(1.1);
    color: blue;
    background-color: grey;
  }
`;

const DownloadButtonInsideModal = styled.a`
  top: 15px;
  right: 7%;
  background-color: blue;
  padding: 2px 8px;
  border-radius: 10px;
  text-decoration: none;
  color: white;
  font-size: 20px;

  &:hover {
    transform: scale(1.05);
    color: white;
    background-color: #3232ff;
  }
`;

const LargePreviewButton = styled(ActionButton)`
  right: 15%;
  background-color: grey;
  padding: 6px 10px;
  border-radius: 50%;
  font-size: 14px;
`;

const ShareButton = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
  display: flex;
  gap: 5px;
  display: none;
`;

const PreviewMediaForSocialMedia = ({ url }) => {
  console.log("Inside previewmedia.jsx",url)
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const shareUrl = url;

  if (url.includes("dailylimitexceeded")) {
    return null;
  }


  const handleImageDownload = async (url) => {
    try {
      // Fetch the image data as a Blob
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
  
      // Create a link element and trigger the download
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = url.split('/').pop(); // Extract filename from URL
      downloadLink.click();
  
      // Revoke the object URL to free memory
      URL.revokeObjectURL(downloadLink.href);
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };
  

  const videoTest = /\.(mp4)$/i;
  const audioTest = /\.(mp3)$/i;
  const pptTest = /\.(ppt|pptx)$/i;
  const imageTest = /\.(jpg|jpeg|png)$/i;

  switch (true) {
    case videoTest.test(url):
      return (
        <div
      style={{
        overflow: "hidden", 
        borderRadius: "8px", 
        width: "100%", 
        height:"100%",
      }}
    >
        <ReactPlayer
          url={url}
          controls
          width= "100%"
          height="100%"
          style={{ borderRadius:"8px"}}
        /></div>
      );
    case audioTest.test(url):
      return (
        <AudioContainer>
          <CustomAudioPlayer
            
            src={url}
            autoPlay={false}
            controls
            style={{ maxWidth: "100%", width: "100%", backgroundColor: 'transparent', borderRadius: '10px',paddingTop:'45px',border:"0.5px solid #66697A"}}
          />
          <DownloadButton
            href={url}
            download
            target="_blank"
            rel="noopener noreferrer"
            className="download-button"
            title="Download"
          >
            <i className="bi bi-cloud-arrow-down"></i>
          </DownloadButton>
          <LargePreviewButton
            as="button"
            onClick={handleShowModal}
            className="large-preview-button"
            title="View"
          >
            <i className="bi bi-arrows-fullscreen"></i>
          </LargePreviewButton>
          <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Audio Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CustomAudioPlayer
                src={url}
                autoPlay={false}
                controls
                style={{ maxWidth: "90%", width: "100%",backgroundColor: 'transparent',borderRadius:'20px'}}
              />
            </Modal.Body>
            <Modal.Footer>
              <DownloadButtonInsideModal
                  href={url}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  className="download-button"
                  title="Download"
                  style={{display:'flex',alignItems:'center'}}
                >
                  <i className="bi bi-cloud-arrow-down"></i><span style={{fontSize:'1rem',marginLeft:'10px'}}> Download</span>
              </DownloadButtonInsideModal>
            </Modal.Footer>
          </Modal>
          {/* ShareButton and other buttons */}
        </AudioContainer>
      );
    case pptTest.test(url):
      return (
        <ResponsiveDocumentViewerContainer>
          <ResponsiveDocumentViewer
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
            allow="autoplay"
            borderRadius="8px"
          />
          {/* <DownloadButton
            href={url}
            download
            target="_blank"
            rel="noopener noreferrer"
            className="download-button"
            title="Download"
          >
            <i className="bi bi-cloud-arrow-down"></i>
          </DownloadButton> */}
          {/* <LargePreviewButton
            as="button"
            onClick={handleShowModal}
            className="large-preview-button"
            title="View"
          >
            <i className="bi bi-arrows-fullscreen"></i>
          </LargePreviewButton> */}
 
          <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>PPT Preview</Modal.Title>
              
            </Modal.Header>
            <Modal.Body>
              <ResponsiveDocumentViewer
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
                allow="autoplay"
                height="500px"
                borderRadius="8px"
              />
              <div style={{
                  width: '100px',
                  height: '4%',
                  backgroundColor: '#444444',
                  position: 'absolute',
                  bottom: '4.1%',
                  right: '3%',
                  overflow:'hidden'
                }}>
                  {/* This i have used to hide the extra options to right bottom of iframe controls */}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <DownloadButtonInsideModal
                  href={url}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  className="download-button"
                  title="Download"
                  style={{display:'flex',alignItems:'center'}}
                >
                  <i className="bi bi-cloud-arrow-down"></i><span style={{fontSize:'1rem',marginLeft:'10px'}}> Download</span>
              </DownloadButtonInsideModal>
            </Modal.Footer>
          </Modal>

        </ResponsiveDocumentViewerContainer>
      );
    case imageTest.test(url):
      return (  
        <ImageContainer>
          <Image src={url} alt="Image Preview" fluid  style={{width:"auto"}}/>
          {/* <DownloadButton
            as="button"
            download
            onClick={() => handleImageDownload(url)}
            className="download-button"
            title="Download"
          >
            <i className="bi bi-cloud-arrow-down"></i>
          </DownloadButton> */}
          {/* <LargePreviewButton
            as="button"
            onClick={handleShowModal}
            className="large-preview-button"
            title="View"
          >
            <i className="bi bi-arrows-fullscreen"></i>
          </LargePreviewButton> */}
          <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Image Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Image src={url} style={{borderRadius:"8px"}} alt="Image Preview" fluid/>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() =>handleImageDownload(url)}>
                <i className="bi bi-cloud-arrow-down"></i><span style={{marginLeft:'10px'}}>Download</span>
              </Button>
            </Modal.Footer>
          </Modal>
 
        </ImageContainer>
      );
    default:
      return null;
  }
};

export default PreviewMediaForSocialMedia;




