import React from "react";
import {
  Box,
  Button,
  Menu,
} from "@mui/material";
import { RgbaColorPicker } from "react-colorful";

const ColorPalette = ({
  anchorEl,
  handleCloseColorPalette,
  color,
  setColor,
  selectedcolor,
}) => {
  const theme = localStorage.getItem("theme");

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleCloseColorPalette}
      PaperProps={{
        style: {
          backgroundColor: "#2F355F",
          color: "white",
          padding: "4px",
          borderRadius: "8px",
          marginTop: "60px",
          marginLeft: "90px",
          border: "1px solid rgba(255, 255, 255, 0.2)",
        },
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right", // Align to the right
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right", // The menu will align to the right
      }}
    >
      <Box sx={{ bgcolor: "#373E6D" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "#2F355F",
            p: 1,
          }}
        >
          {/* <ColorPicker color={color} onChange={setColor} />; */}
          <RgbaColorPicker color={color} onChange={setColor} />
          <Box
            sx={{
              mt: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box
                sx={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
              >
                HEX
              </Box>
              <Button
                // variant="outlined"
                sx={{
                  // backgroundColor: selectedaspectRatioForVideoGen === ratio ? '#3a3f69' : '#30355f',
                  backgroundColor: "#373E6D",
                  // backgroundColor: theme === 'dark'? '#30355f' : '',
                  color: theme === "dark" ? "white" : "black",
                  "&:hover": {
                    backgroundColor: "#373E6D",
                  },
                  borderColor: "#373E6D",
                  padding: "4px 8px",
                  margin: "0px 0px",
                  minWidth: "43px",
                  minHeight: "20px",
                }}
              >
                {selectedcolor}
              </Button>
            </Box>
            <Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: "2px" }}>
                <Box>
                  <Box
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 500,
                    }}
                  >
                    R
                  </Box>
                  <Button
                    // variant="outlined"
                    sx={{
                      // backgroundColor: selectedaspectRatioForVideoGen === ratio ? '#3a3f69' : '#30355f',
                      backgroundColor: "#373E6D",
                      // backgroundColor: theme === 'dark'? '#30355f' : '',
                      color: theme === "dark" ? "white" : "black",
                      "&:hover": {
                        backgroundColor: "#373E6D",
                      },
                      borderColor: "#373E6D",
                      padding: "4px 8px",
                      width: "25px",
                      minHeight: "20px",
                      borderRadius: "4px 0px 0px 4px",
                      minWidth: "30px",
                    }}
                  >
                    {color.r}
                  </Button>
                </Box>
                <Box>
                  <Box
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 500,
                    }}
                  >
                    G
                  </Box>
                  <Button
                    // variant="outlined"
                    sx={{
                      // backgroundColor: selectedaspectRatioForVideoGen === ratio ? '#3a3f69' : '#30355f',
                      backgroundColor: "#373E6D",
                      // backgroundColor: theme === 'dark'? '#30355f' : '',
                      color: theme === "dark" ? "white" : "black",
                      "&:hover": {
                        backgroundColor: "#373E6D",
                      },
                      borderColor: "#373E6D",
                      padding: "4px 8px",
                      margin: "0px 0px",
                      width: "25px",
                      minHeight: "20px",
                      borderRadius: "0px 0px 0px 0px",
                      minWidth: "30px",
                    }}
                  >
                    {color.g}
                  </Button>
                </Box>
                <Box>
                  <Box
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 500,
                    }}
                  >
                    B
                  </Box>
                  <Button
                    // variant="outlined"
                    sx={{
                      // backgroundColor: selectedaspectRatioForVideoGen === ratio ? '#3a3f69' : '#30355f',
                      backgroundColor: "#373E6D",
                      // backgroundColor: theme === 'dark'? '#30355f' : '',
                      color: theme === "dark" ? "white" : "black",
                      "&:hover": {
                        backgroundColor: "#373E6D",
                      },
                      borderColor: "#373E6D",
                      padding: "4px 8px",
                      margin: "0px 0px",
                      width: "25px",
                      minHeight: "20px",
                      borderRadius: "0px 4px 4px 0px",
                      minWidth: "30px",
                    }}
                  >
                    {color.b}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box></Box>
      </Box>
    </Menu>
  );
};

export default ColorPalette;
