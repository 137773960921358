import React from "react";
import ToolSelector from "../components/ToolSelector";
import PageLayout from "../components/navFoot/PageLayout";

const Tools = () => {
  return (
    <PageLayout>
      <h1 className="text-center">Convert anything to anything</h1>
      <div className="mb-3"></div>
      <p>Select desired output format :</p>
      <hr />
      <div className="mb-3"></div>
      <ToolSelector />
    </PageLayout>
  );
};

export default Tools;
