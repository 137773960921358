import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  Modal,
  Badge,
  Spinner,
  Dropdown,
  DropdownButton,
  Form,
} from "react-bootstrap";

function LinkSelector({ selectedLinks, setSelectedLinks }) {
  function handleLinkChange(e, index) {
    const newLinks = [...selectedLinks];
    newLinks[index] = e.target.value;
    setSelectedLinks(newLinks);

    // Check if the last input box is being typed into, and if so, add a new empty input box
    if (index === selectedLinks.length - 1 && e.target.value.trim() !== "") {
      setSelectedLinks([...newLinks, ""]);
    }
  }

  function handleLinkKeyDown(e, index) {
    const { key, selectionStart, selectionEnd } = e;
    const webLink = selectedLinks[index];

    if (key === "Backspace" && webLink.length > 0) {
      let updatedLink;

      if (selectionStart === selectionEnd) {
        // Single character backspace
        updatedLink = webLink.slice(0, -1);
      } else {
        // Delete the selected portion
        updatedLink =
          webLink.substring(0, selectionStart) +
          webLink.substring(selectionEnd);
      }

      const updatedLinks = [...selectedLinks];
      updatedLinks[index] = updatedLink;

      if (updatedLink.length === 0 && selectedLinks.length > 1) {
        updatedLinks.splice(index, 1);
      }

      setSelectedLinks(updatedLinks);
      e.preventDefault(); // Prevent the default behavior of the Backspace key
    }
  }

  return (
    <>
      {selectedLinks.map((link, index) => (
        <div key={index} style={{ marginTop: "10px" }}>
          <Form.Control
            type="text"
            placeholder="Add web link/s"
            value={link}
            onChange={(e) => handleLinkChange(e, index)}
            onKeyDown={(e) => handleLinkKeyDown(e, index)}
            style={{ width: "100%" }}
          />
        </div>
      ))}
    </>
  );
}

function FileSelector({ selectedFiles, setSelectedFiles }) {
  const fileInputRef = useRef(null);

  const filesSelectedText =
    selectedFiles.length === 1
      ? "1 file selected"
      : `${selectedFiles.length} files selected`;

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    setSelectedFiles(Array.from(e.target.files));
  };

  return (
    <div className="mb-3">
      <Button variant="primary" onClick={handleFileSelect}>
        {selectedFiles.length > 0 ? "Re-Select File" : "Select File"}
      </Button>
      <span style={{ color: "gray", marginLeft: "10px" }}>
        {filesSelectedText}
      </span>
      {selectedFiles.length > 0 && (
        <Button
          variant="danger"
          onClick={() => setSelectedFiles([])}
          style={{ marginLeft: "10px" }}
        >
          <i className="bi bi-x"></i>
        </Button>
      )}
      <input
        type="file"
        accept=".txt, .docx, .mp3, .pdf, .mp4, .xlsx, video/*"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
        multiple
      />
    </div>
  );
}

function ToolCard({
  outputContentType,
  bootstrapIconName,
  title,
  description,
  isDisabled,
  availableOptions,
}) {
  const [showModal, setShowModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [webLinks, setWebLinks] = useState([""]);
  const [uploadStatus, setUploadStatus] = useState("Upload");
  const [uploadStatusResult, setUploadStatusResult] = useState(null);
  const [outputLanguage, setOutputLanguage] = useState("English");

  const appendSelectedFilesToForm = (formData) => {
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("file", selectedFiles[i]);
    }
  };

  const appendLinksToForm = (formData) => {
    const uniqueLinks = new Set(webLinks);
    const nonEmptyUniqueLinks = [...uniqueLinks].filter(
      (link) => link.trim() !== ""
    );
    for (let i = 0; i < nonEmptyUniqueLinks.length; i++) {
      formData.append("webLink", nonEmptyUniqueLinks[i]);
    }
  };

  async function fetchStatus(id) {
    try {
      const statusResponse = await fetch(`/status?id=${id}`, {
        method: "POST",
        credentials: "include",
      });
      const statusResult = await statusResponse.json();
      // Update the second modal with the response
      if (statusResult.status === "success") {
        setUploadStatus("Download");
      } else {
        setUploadStatus(statusResult.status);
      }
      setUploadStatusResult(statusResult.result);
    } catch (error) {
      console.error("Error checking upload status:", error);
      setUploadStatus("Error checking upload status");
    }
  }

  const uploadFiles = async (formData, contentType) => {
    let url = `/upload?outputContentType=${contentType}`;

    if (availableOptions) {
      url += `&outputLanguage=${outputLanguage}`;
    }

    return await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include",
    });
  };

  async function handleUploadClick() {
    setShowModal(false);
    setUploadStatus("Uploading..."); // Set initial status

    const formData = new FormData();

    appendSelectedFilesToForm(formData);
    appendLinksToForm(formData);

    try {
      const response = await uploadFiles(formData, outputContentType);
      console.log("-------------------", response);
      const result = await response.json();
      console.log(result); // Process the server response as needed

      setUploadStatus("Files Uploaded!"); // Update status after successful upload

      // Periodically update the second modal with the status
      await fetchStatus(result.result);
      const intervalId = setInterval(async () => {
        await fetchStatus(result.result);
      }, 5000); // Update every 5 seconds

      // Save the interval ID to clear it later
      setUploadIntervalId(intervalId);
    } catch (error) {
      console.error("Error uploading files:", error);
      setUploadStatus("Error uploading files"); // Update status in case of an error
    }
  }

  // Add state for the interval ID
  const [uploadIntervalId, setUploadIntervalId] = useState(null);

  // Add useEffect to clear the interval when the component unmounts
  useEffect(() => {
    if (uploadIntervalId && uploadStatus === "Download") {
      clearInterval(uploadIntervalId);
    }
  }, [uploadIntervalId, uploadStatus]);

  const resetUploader = () => {
    setUploadStatus("Upload");
    setUploadStatusResult(null);
    setWebLinks([""]);
    setShowModal(false);
    setSelectedFiles([]);
    setOutputLanguage("English");
  };

  const handleRedirect = (link) => {
    console.log("Redirecting to:", link);
    window.open(link, "_blank");
    resetUploader();
  };

  const DropdownLanguageSelector = () => {
    const handleOptionSelect = (option) => {
      setOutputLanguage(option);
    };

    return (
      <DropdownButton id="dropdown-basic-button" title={outputLanguage}>
        {availableOptions.options.map((opt, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => handleOptionSelect(Object.values(opt)[0])}
          >
            {Object.values(opt)[0]}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    );
  };

  function handleCardButton() {
    if (uploadStatus === "Upload") {
      resetUploader();
      setShowModal(true);
    } else if (uploadStatus === "Download") {
      handleRedirect(uploadStatusResult);
    }
  }

  function UploadModal() {
    return (
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <div className="mb-3"></div>
        <Modal.Body>
          {FileSelector({
            selectedFiles: selectedFiles,
            setSelectedFiles: setSelectedFiles,
          })}
          {LinkSelector({
            selectedLinks: webLinks,
            setSelectedLinks: setWebLinks,
          })}
        </Modal.Body>
        <div className="mb-3"></div>
        <Modal.Footer>
          {" "}
          {availableOptions && <DropdownLanguageSelector />}
          <Button
            variant="primary"
            onClick={handleUploadClick}
            disabled={
              selectedFiles.length === 0 &&
              webLinks.every((link) => link.trim() === "")
            }
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <Card
        variant="link"
        style={{
          height: "100%",
          opacity: isDisabled ? "0.5" : "1",
          filter: isDisabled ? "grayscale(100%)" : "none",
        }}
      >
        <Card.Body style={{ textAlign: "left" }} className="mb-5">
          <i className={`bi bi-${bootstrapIconName} fs-2`}></i>
          <Card.Title>
            <h5>{title}</h5>
          </Card.Title>
          <Card.Text style={{ color: "gray" }}>{description}</Card.Text>

          {(uploadStatus === "Upload" ||
            uploadStatus === "Download" ||
            uploadStatus.includes("error")) && (
            <Button
              variant={
                uploadStatus === "Upload" || uploadStatus.includes("error")
                  ? "primary"
                  : "success"
              }
              onClick={handleCardButton}
              style={{ position: "absolute", bottom: "14px", left: "14px" }}
              disabled={isDisabled}
            >
              {isDisabled
                ? "Soon..."
                : uploadStatus === "Download"
                ? "Download"
                : "Upload"}
            </Button>
          )}

          {/* {uploadStatus.includes("error") && (
            <Badge
              bg="danger"
              style={{ position: "absolute", bottom: "14px", left: "14px" }}
            >
              {uploadStatus}
            </Badge>
          )} */}

          {(uploadStatus === "waiting" || uploadStatus === "Uploading...") && (
            <Badge
              bg="secondary"
              style={{ position: "absolute", bottom: "14px", left: "14px" }}
            >
              {uploadStatus === "waiting" ? "Processsing..." : "Uploading..."}
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </Badge>
          )}
        </Card.Body>
      </Card>

      {UploadModal()}
    </>
  );
}

export default ToolCard;

// function LinkSelector({ selectedLinks, setSelectedLinks }) {
//   function handleLinkChange(e, index) {
//     const newLinks = [...selectedLinks];
//     newLinks[index] = e.target.value;
//     setSelectedLinks(newLinks);

//     // Check if the last input box is being typed into, and if so, add a new empty input box
//     if (index === selectedLinks.length - 1 && e.target.value.trim() !== "") {
//       setSelectedLinks([...newLinks, ""]);
//     }
//   }

//   function handleLinkKeyDown(e, index) {
//     const { key, selectionStart, selectionEnd } = e;
//     const webLink = selectedLinks[index];

//     if (key === "Backspace" && webLink.length > 0) {
//       let updatedLink;

//       if (selectionStart === selectionEnd) {
//         // Single character backspace
//         updatedLink = webLink.slice(0, -1);
//       } else {
//         // Delete the selected portion
//         updatedLink =
//           webLink.substring(0, selectionStart) + webLink.substring(selectionEnd);
//       }

//       const updatedLinks = [...selectedLinks];
//       updatedLinks[index] = updatedLink;

//       if (updatedLink.length === 0 && selectedLinks.length > 1) {
//         updatedLinks.splice(index, 1);
//       }

//       setSelectedLinks(updatedLinks);
//       e.preventDefault(); // Prevent the default behavior of the Backspace key
//     }
//   }

//   return (
//     <>
//       {selectedLinks.map((link, index) => (
//         <div key={index} style={{ marginTop: "10px" }}>
//           <input
//             type="text"
//             placeholder="YouTube video link"
//             value={link}
//             onChange={(e) => handleLinkChange(e, index)}
//             onKeyDown={(e) => handleLinkKeyDown(e, index)}
//             style={{ width: "100%" }}
//           />
//         </div>
//       ))}
//     </>
//   );
// }

// function FileSelector(selectedFiles, setSelectedFiles) {
//   const fileInputRef = useRef(null);

//   const filesSelectedText =
//     selectedFiles.length === 1
//       ? "1 file selected"
//       : `${selectedFiles.length} files selected`;

//   const handleFileSelect = () => {
//     fileInputRef.current.click();
//   };

//   const handleFileChange = (e) => {
//     setSelectedFiles(Array.from(e.target.files));
//   };

//   return (
//     <>
//       <div className="mb-3">
//         <Button variant="primary" onClick={handleFileSelect}>
//           {selectedFiles.length > 0 ? "Re-Select File" : "Select File"}
//         </Button>
//         <span style={{ color: "gray", marginLeft: "10px" }}>
//           {filesSelectedText}
//         </span>
//         {selectedFiles.length > 0 && (
//           <Button
//             variant="danger"
//             onClick={() => setSelectedFiles([])}
//             style={{ marginLeft: "10px" }}
//           >
//             <i className="bi bi-x"></i>
//           </Button>
//         )}
//         <input
//           type="file"
//           accept=".txt, .docx, .mp3, .pdf, .mp4, video/*"
//           style={{ display: "none" }}
//           ref={fileInputRef}
//           onChange={handleFileChange}
//           multiple
//         />
//       </div>
//     </>
//   );
// }

// function UploadModal({ showModal, setShowModal, title, availableOptions }) {
//   const [selectedFiles, setSelectedFiles] = useState([]);
//   const [outputOption, setOutputOption] = useState("English");
//   const [selectedLinks, setSelectedLinks] = useState([""]);

//   function appendSelectedFilesToForm(formData) {
//     for (let i = 0; i < selectedFiles.length; i++) {
//       formData.append("file", selectedFiles[i]);
//     }
//   }

//   function appendLinksToForm(formData) {
//     const nonEmptyLinks = selectedLinks.filter((link) => link.trim() !== "");
//     for (let i = 0; i < nonEmptyLinks.length; i++) {
//       formData.append("webLink", nonEmptyLinks[i]);
//     }
//   }

//   function handleUploadClick() {
//     setShowModal(false);

//     const formData = new FormData();
//     appendSelectedFilesToForm(formData);
//     appendLinksToForm(formData);
//   }

//   function DropdownSelector() {
//     const handleOptionSelect = (option) => {
//       setOutputOption(option);
//     };

//     return (
//       <DropdownButton id="dropdown-basic-button" title={outputOption}>
//         {availableOptions.options.map((opt, index) => (
//           <Dropdown.Item
//             key={index}
//             onClick={() => handleOptionSelect(Object.values(opt)[0])}
//           >
//             {Object.values(opt)[0]}
//           </Dropdown.Item>
//         ))}
//       </DropdownButton>
//     );
//   }

//   return (
//     <Modal show={showModal} onHide={() => setShowModal(false)}>
//       <Modal.Header closeButton>
//         <Modal.Title>{title}</Modal.Title>
//       </Modal.Header>
//       <div className="mb-3"></div>
//       <Modal.Body>
//         {FileSelector(selectedFiles, setSelectedFiles)}
//         {LinkSelector(selectedLinks, setSelectedLinks)}
//       </Modal.Body>
//       <div className="mb-3"></div>
//       <Modal.Footer>
//         {availableOptions && <DropdownSelector />}
//         <Button
//           variant="primary"
//           onClick={handleUploadClick}
//           disabled={
//             selectedFiles.length === 0 &&
//             selectedLinks.every((link) => link.trim() === "")
//           }
//         >
//           Upload
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

// function ToolCard({
//   outputContentType,
//   bootstrapIconName,
//   title,
//   description,
//   isDisabled,
//   availableOptions,
// }) {
//   const [uploadStatus, setUploadStatus] = useState("Upload");
//   const [uploadStatusResult, setUploadStatusResult] = useState(null);
//   const [showModal, setShowModal] = useState(false);

//   const resetToolCard = () => {
//     setUploadStatus("Upload");
//     setUploadStatusResult(null);
//     setShowModal(false);
//   };

//   const handleRedirect = (link) => {
//     window.open(link, "_blank");
//     resetToolCard();
//   };

//   let buttonOnClick = () => setShowModal(true);

//   if (uploadStatus === "Upload") {
//     buttonOnClick = () => {
//       resetToolCard();
//       setShowModal(true);
//     };
//   } else if (uploadStatus === "Download") {
//     buttonOnClick = () => handleRedirect(uploadStatusResult);
//   } else {
//     buttonOnClick = () => setShowModal(true);
//   }

//   return (
//     <>
//       <Card
//         variant="link"
//         style={{
//           border: "dark",
//           height: "95%",
//           background: "transparent",
//           opacity: isDisabled ? "0.5" : "1",
//           filter: isDisabled ? "grayscale(100%)" : "none",
//           textDecoration: "none",
//         }}
//         className="mb-3"
//       >
//         <Card.Body style={{ textAlign: "left" }}>
//           <i className={`bi bi-${bootstrapIconName} fs-2`}></i>

//           <Card.Title>
//             <h5>{title}</h5>
//           </Card.Title>

//           <Card.Text style={{ color: "gray" }}>{description}</Card.Text>

//           {(uploadStatus === "Upload" ||
//             uploadStatus === "Download" ||
//             uploadStatus.includes("error")) && (
//             <Button
//               variant={
//                 uploadStatus === "Upload" || uploadStatus.includes("error")
//                   ? "primary"
//                   : "success"
//               }
//               onClick={buttonOnClick}
//               style={{ position: "absolute", bottom: "14px", left: "14px" }}
//               disabled={isDisabled || !buttonOnClick} // Disable the button when processing
//             >
//               {isDisabled
//                 ? "Soon..."
//                 : uploadStatus === "Download"
//                 ? "Download"
//                 : "Upload"}
//             </Button>
//           )}

//           {/* {uploadStatus.includes("error") && (
//             <Badge
//               bg="danger"
//               style={{ position: "absolute", bottom: "14px", left: "14px" }}
//             >
//               {uploadStatus}
//             </Badge>
//           )} */}

//           {(uploadStatus === "waiting" || uploadStatus === "Uploading...") && (
//             <Badge
//               bg="secondary"
//               style={{ position: "absolute", bottom: "14px", left: "14px" }}
//             >
//               {uploadStatus === "waiting" ? "Processsing..." : "Uploading..."}
//               <Spinner
//                 as="span"
//                 animation="border"
//                 size="sm"
//                 role="status"
//                 aria-hidden="true"
//               />
//             </Badge>
//           )}
//         </Card.Body>
//       </Card>

//       {UploadModal(
//         showModal,
//         setShowModal,
//         title,
//         outputContentType,
//         availableOptions
//       )}
//     </>
//   );
// }

// export default ToolCard;

// // try {
// //   const response = await uploadFiles(formData, outputContentType);
// //   console.log("-------------------", response);
// //   const result = await response.json();
// //   console.log(result); // Process the server response as needed

// //   setUploadStatus("Files Uploaded!"); // Update status after successful upload

// //   // Periodically update the second modal with the status
// //   const intervalId = setInterval(async () => {
// //     try {
// //       const statusResponse = await fetch(`/status?id=${result.result}`, {
// //         method: "POST",
// //       });
// //       const statusResult = await statusResponse.json();
// //       // Update the second modal with the response
// //       if (statusResult.status === "success") {
// //         setUploadStatus("Download");
// //         clearInterval(intervalId);
// //       } else setUploadStatus(statusResult.status);
// //       setUploadStatusResult(statusResult.result);
// //     } catch (error) {
// //       console.error("Error checking upload status:", error);
// //       setUploadStatus("Error checking upload status");
// //       clearInterval(intervalId);
// //     }
// //   }, 5000); // Update every 5 seconds

// //   // Save the interval ID to clear it later
// //   setUploadIntervalId(intervalId);
// // } catch (error) {
// //   console.error("Error uploading files:", error);
// //   setUploadStatus("Error uploading files"); // Update status in case of an error
// // }

// // // Add state for the interval ID
// // const [uploadIntervalId, setUploadIntervalId] = useState(null);

// // // Add useEffect to clear the interval when the component unmounts
// // useEffect(() => {
// //   return () => {
// //     if (uploadIntervalId) {
// //       clearInterval(uploadIntervalId);
// //     }
// //   };
// // }, [uploadIntervalId]);

// // const uploadFiles = async (formData, contentType) => {
// //   let url = `/upload?outputContentType=${contentType}`;

// //   if (outputOption) {
// //     url += `&outputLanguage=${outputOption}`;
// //   }

// //   return await fetch(url, {
// //     method: "POST",
// //     body: formData,
// //   });
// // };
