// import React,{ useState } from 'react'
// import { Col, Container, Row } from 'react-bootstrap';
// import ImageRatio from './ImageRatio';
// import ImageModel from './ImageModel';
// import ImageFormat from './ImageFormat';

// const ImageSettings = () => {
//     const [openImgSet, setOpenImgSet] = useState(false);
//     const handleClick = () => {
//       console.log("Synthesia Settings Clicked");
//       setOpenImgSet(!openImgSet);
//     };
  
//     return (
//       <Container fluid className="m-0 p-0">
//         <Row
//           className="m-0 p-0"
//           style={{ cursor: "pointer" }}
//           onClick={handleClick}
//         >
//           <Col xs={10} className="m-0 px-0 pb-1">
//             <strong className="text-secondary" style={{fontSize:'.95rem'}}>Image Settings</strong>
//           </Col>
//           <Col
//             xs={2}
//             className="m-0 p-0 d-flex align-items-center justify-content-center"
//           >
//             {!openImgSet ? (
//               <i className="bi bi-caret-down" />
//             ) : (
//               <i className="bi bi-caret-up" />
//             )}
//           </Col>
//         </Row>
//         {openImgSet && (
//           <Row className="m-0 p-0" style={{display:'flex',flexDirection:'column',gap:'10px'}}>
//             {/* Use AvatarSettings component here */}
//             <ImageRatio />
//             <ImageModel />
//             <ImageFormat />
//           </Row>
//         )}
//       </Container>
//     );
// }

// export default ImageSettings


import React, { useState, useEffect } from "react";
import { Modal, Tabs, Tab, Box, Typography, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ImageRatio from "./ImageRatio";
import ImageModel from "./ImageModel";
import ImageFormat from "./ImageFormat";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { button_bg_color_for_New_Project, hoveredSettings_Nav_bg_dm, selecetedSettings_Nav_bg_dm, settingsModal_bg_color_dm, uploadFiles_button_default_bg_color, uploadFiles_button_hovered_bg_color } from "../../../../constants/colors";
import { SettingsAdjustment } from "../../../../config/analytics";
import CommonToast from "../../../Common_Components/CommonToast";

const ImageSettings = () => {
  const [activeTab, setActiveTab] = useState('ratio');
  const { showImageSettings, setShowImageSettings, imageFormat, imageModel,imageRatio  } = useWorkspace();
  const theme = localStorage.getItem('theme');
  const [imageSettingsSaved,setImageSettingsSaved] = useState(false);

  useEffect(() => {
    if (showImageSettings) {
      setActiveTab('ratio'); // Set default tab
    }
  }, [showImageSettings]);

  const handleClose = () => {
    setShowImageSettings(false); // Close the modal by updating the context state
  };

  const handleSave = () => {
    // Implement save functionality here
    SettingsAdjustment("Image_settings", {imageFormat:imageFormat,imageModel: imageModel, imageRatio: imageRatio});
    console.log('Settings saved');
    setImageSettingsSaved(true);
    setShowImageSettings(false);
  };

  const handleCloseToast = () => {
    setImageSettingsSaved(false);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'ratio':
        return <ImageRatio />;
      case 'model':
        return <ImageModel />;
      case 'format':
        return <ImageFormat />;
      default:
        return <ImageRatio />;
    }
  };

  return (
    <div>
      <CommonToast 
        open={imageSettingsSaved}
        onClose={handleCloseToast}
        content="Image settings saved"
        bgColor="#282D51"
        fontColor="#fff"
        fontSize="14px"
        fontWeight="500"
        autoHideDuration={3000}
        iconType = 'success'
      />
      <Modal
      open={showImageSettings}
      onClose={handleClose}
      aria-labelledby="image-settings-title"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
       
      }}
    >
      <Box
        sx={{
          width: '720px',
          height: '636px',
          backgroundColor: 'background.paper',
          boxShadow: 24,
          // p: 4,
          display: 'flex',
          flexDirection: 'column',
          bgcolor: theme === 'dark' ? settingsModal_bg_color_dm : '',
          borderRadius:'8px',
          
        }}
      >
        <Typography
          id="image-settings-title"
          variant="h6"
          component="h2"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            // mb: 2,
            borderBottom: `1px solid ${theme === 'dark' ? '#535774' : ''}`,
            padding:'20px',
            fontFamily: 'Inter, sans-serif',
          }}
          style={{fontSize:'20px',fontWeight:'600',color:theme === 'dark'?'#FFFFFF':''}}
        >
          Image Settings
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            sx={{
              borderRight: 1,
              borderColor: theme === 'dark' ? '#535774' : '',
              minWidth: '150px',
              display: 'flex',
              flexDirection: 'column',
              width:'223px'
            }}
            TabIndicatorProps={{
              style: {
                display: 'none', // Hide the default indicator (blue underline)
              },
            }}
          >
            <Tab
              label="Image Ratio"
              value="ratio"
              sx={{
                backgroundColor: activeTab === 'ratio' ? selecetedSettings_Nav_bg_dm : 'transparent', // Grey background for selected tab
                '&:hover': {
                  backgroundColor: theme === 'dark' ? hoveredSettings_Nav_bg_dm : '', // Light grey on hover
                },
                color: theme === 'dark' ? 'white' : 'black',
                textTransform: 'none',
                fontSize: '14px',
                margin:'20px 20px',
                borderRadius:'8px'
              }}
              style={{color:'#FFFF'}}
            />
            <Tab
              label="Image Style"
              value="model"
              sx={{
                backgroundColor: activeTab === 'model' ? selecetedSettings_Nav_bg_dm : 'transparent', // Grey background for selected tab
                '&:hover': {
                  backgroundColor: theme === 'dark' ? hoveredSettings_Nav_bg_dm : '', // Light grey on hover
                },
                color: theme === 'dark' ? 'white' : 'black',
                textTransform: 'none',
                fontSize: '14px',
                margin:'0 20px',
                borderRadius:'8px'
              }}
              style={{color:'#FFFF'}}
            />
            <Tab
              label="Image Format"
              value="format"
              sx={{
                backgroundColor: activeTab === 'format' ? selecetedSettings_Nav_bg_dm : 'transparent', // Grey background for selected tab
                '&:hover': {
                  backgroundColor: theme === 'dark' ? hoveredSettings_Nav_bg_dm : '', // Light grey on hover
                },
                color: theme === 'dark' ? 'white' : 'black',
                textTransform: 'none',
                fontSize: '14px',
                margin:'0 20px',
                borderRadius:'8px'
              }}
              style={{color:'#FFFF'}}
            />
          </Tabs>
          <Box
            sx={{
              flexGrow: 1,
              pl: 3,
              overflowY: 'auto',
              maxHeight: '430px',
              width:'457px'
            }}
          >
            {renderContent()}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            // mt: 2,
            p: 2,
            borderTop: `1px solid ${theme === 'dark' ? '#444' : '#ccc'}`, // Border top added
          }}
        >
          <Button
            onClick={handleClose}
            sx={{
              mr: 1,
              backgroundColor: theme === 'dark' ? uploadFiles_button_default_bg_color : '',
              '&:hover': {
                backgroundColor: uploadFiles_button_hovered_bg_color,
              },
              color: '#17182C',
              fontSize: '16px',
              fontWeight: '600',
              textTransform: 'none',
              fontFamily: 'Inter, sans-serif',
            }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{
              backgroundColor: theme === 'dark' ? button_bg_color_for_New_Project : '',
              '&:hover': {
                backgroundColor: '#FFD9AA',
              },
              color: '#17182C',
              fontSize: '16px',
              fontWeight: '600',
              textTransform: 'none',
              fontFamily: 'Inter, sans-serif',
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
    </div>
    
  );
};

export default ImageSettings;