import React, { useState, useEffect, useRef } from "react";
import { Col } from "react-bootstrap";
import { useUI } from "../../../contexts/UIContext";
import Uploader from "./FileUploader";
import LinkUploader from "./LinkUploader";
import SynthesiaSettings from "./synthesiaSettings/SynthesiaSettings";
import Services from "./Services";
import ViewThreadFiles from "./ViewThreadFiles";
import PptTemplates from "./PptTemplates";
import ImageSettings from "./ImageSettings/ImageSettings";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import * as Sentry from "@sentry/react";
import {
  darkTheme_bg_color,
  fileUploader_bg_color_for_dm,
} from "../../../constants/colors";
import { useMediaQuery, Box, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import HelpButtons from "./HelpButton/HelpButtons";

const SettingContent = () => {
  const { currentTheme } = useUI();
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const {
    currentThread,
    threadFiles,
    setShowSettingBar,
    setShowRightsideBar,
    showSettingBar,
  } = useWorkspace();
  const [currThreadLen, setCurrThreadLen] = useState(0);
  const isBelow1500 = useMediaQuery("(max-width:1500px)");
  const mobileView = useMediaQuery("(max-width:992px)");
  const [showHelpButton, setShowHelpButton] = useState(false);
  // const dropdownRef = useRef(null);
  const toggleButtonRef = useRef(null);
  const helpButtonsRef = useRef(null);

  useEffect(() => {
    setCurrThreadLen(currentThread.length);
  }, [currentThread]);

  const getSettingBarStyle = (theme) => {
    return theme === "dark"
      ? { backgroundColor: darkTheme_bg_color, color: "white" }
      : { backgroundColor: "var(--bs-gray-300)", color: "black" };
  };

  const toggleSettingBar = () => {
    setShowSettingBar(!showSettingBar);
    setShowRightsideBar(!showSettingBar);
  };

  console.log("2222222222222222222222222222222222222222", showHelpButton);
  // const toggleHelpButton = () =>{
  //   setShowHelpButton(!showHelpButton)
  // }

  // const handleClickOutside = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //     setShowHelpButton(false); // Close the dropdown if clicked outside
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('mousedown', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  const toggleHelpButton = () => {
    setShowHelpButton((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    // Check if the click is outside both the toggle button and the modal
    if (
      toggleButtonRef.current &&
      helpButtonsRef.current &&
      !toggleButtonRef.current.contains(event.target) &&
      !helpButtonsRef.current.contains(event.target)
    ) {
      setShowHelpButton(false); // Close the modal if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      style={{
        width: "282px",
        // height: '100vh',
        height: "100%",
        overflowY: "auto",
        display: "inline-block",
        ...getSettingBarStyle(currentTheme),
        // padding: '20px'
      }}
    >
      <Col className="py-3">
        <div
          onClick={toggleSettingBar}
          style={{
            display: mobileView ? "block" : "none",
            width: "24px",
            height: "24px",
            marginLeft: "225px",
          }}
        >
          {" "}
          <CloseIcon />
        </div>
        <Sentry.ErrorBoundary
          fallback={<div>Something went wrong in the workspace</div>}
        >
          <div
            style={{
              // margin : '20px',
              margin: mobileView ? "20px 0px 20px 10px" : "20px",
              height: mobileView ? "" : "281px",
              width: "242px",
              border: mobileView ? "" : "2px dotted",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // backgroundColor:theme === 'dark'?fileUploader_bg_color_for_dm:'',
              backgroundColor: mobileView ? "" : fileUploader_bg_color_for_dm,
            }}
          >
            <Uploader />
          </div>
        </Sentry.ErrorBoundary>

        <div className="mb-3" style={{ backgroundColor: "red" }} />
        {/* <div 
        style={{
            margin : mobileView ? '20px 5px 20px 10px' : '20px',
            border: '2px dotted',
            borderRadius: '10px',
            backgroundColor:theme === 'dark'?fileUploader_bg_color_for_dm:''
        }}>
          <LinkUploader />
        </div> */}
        <hr style={{ marginTop: "28px" }}></hr>
        <div>
          <ViewThreadFiles />
        </div>

        {/* <hr style={{ margin: "10px 0", borderTop: "2px solid black" }} /> */}
        {/* <ViewThreadFiles />
        <hr style={{ margin: "10px 0", borderTop: "2px solid black" }} />
        <SynthesiaSettings />
        <hr style={{ margin: "10px 0", borderTop: "2px solid black" }} />
        <ImageSettings />
        <PptTemplates />
        {currThreadLen > 0 &&
        <div style={{ marginTop: '50px' }}>
          <Services />
        </div>
        } */}
      </Col>
      {/* <Box 
      ref={dropdownRef}
      sx={ {
        display : showHelpButton ? 'block' : "none",
        marginLeft : '20px',
        marginTop : '-15px'
      }}>
      <HelpButtons/>
      </Box>     
      <Box 
      onClick={toggleHelpButton}
      sx={{
        // marginTop : showHelpButton ? "" : '240px',
        marginTop : showHelpButton ? "35.3%" : '80%',
        marginLeft : '224px',
        width : '36px',
        height : '36px',
        border : '2px solid rgba(255, 255, 255, 0.1)',
        borderRadius : '50%',
        display : "flex",
        justifyContent : "center",
        alignItems : 'center',
        cursor : 'pointer',
        paddingTop:'-36px'
      }}><QuestionMarkIcon/>

      </Box> */}

      <Box
        ref={helpButtonsRef}
        sx={{
          display: showHelpButton ? "block" : "none",
          marginLeft: "20px",
          // marginTop: '-15px',
          position: "fixed",
          bottom: {
            xs: "7%", // Extra-small screens (0px to 600px)
            sm: "7%", // Small screens (600px to 960px)
            md: "7%", // Medium screens (960px to 1280px)
            lg: "7%", // Large screens (1280px to 1920px)
            xl: "7%", // Extra-large screens (1920px and up)
          },
        }}
      >
        <HelpButtons />
      </Box>
      <Tooltip
        title={showHelpButton ? null : "Help"}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#E6E0E9",
              color: "#17182C",
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 500,
              padding: "4px 8px 4px 8px",
              borderRadius: "4px",
              maxWidth: "104px",
              textAlign: "left",
            },
          },
        }}
      >
        <Box
          ref={toggleButtonRef}
          onClick={toggleHelpButton}
          sx={{
            // marginTop: showHelpButton ? '35.3%' : '80%',
            marginLeft: "224px",
            position: "fixed",
            bottom: {
              xs: "2%", // Extra-small screens (0px to 600px)
              sm: "2%", // Small screens (600px to 960px)
              md: "2%", // Medium screens (960px to 1280px)
              lg: "2%", // Large screens (1280px to 1920px)
              xl: "2%", // Extra-large screens (1920px and up)
            },

            width: "36px",
            height: "36px",
            border: "2px solid rgba(255, 255, 255, 0.1)",
            width: "36px",
            height: "36px",
            border: "2px solid rgba(255, 255, 255, 0.1)",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <QuestionMarkIcon />
        </Box>
      </Tooltip>
    </div>
  );
};

export default SettingContent;

// import React from "react";
// import { Col } from "react-bootstrap";
// import { useUI } from "../../../contexts/UIContext";
// import Uploader from "./FileUploader";
// import LinkUploader from "./LinkUploader";
// import SynthesiaSettings from "./synthesiaSettings/SynthesiaSettings";
// import Services from "./Services";
// import ViewThreadFiles from "./ViewThreadFiles";

// const SettingContent = () => {
//   const { currentTheme } = useUI();

//   const getSettingBarStyle = (theme) => {
//     return theme === "dark"
//       ? { backgroundColor: "var(--bs-gray-dark)", color: "white" }
//       : { backgroundColor: "var(--bs-gray-300)", color: "black" };
//   };

//   return (
//     <Col
//       style={{
//         maxWidth: "300px",
//         minWidth: "300px",
//         overflowY: "auto",
//         height:'100vh',
//         display: "flex",
//         flexDirection: "column",
//         ...getSettingBarStyle(currentTheme),
//         padding:'20px'
//       }}
//       className="py-3"
//     >
//       <Uploader />
//       <div className="mb-3" />
//       <LinkUploader />
//       <hr style={{ margin: "10px 0", borderTop: "2px solid black" }} />
//       <ViewThreadFiles />
//       <hr style={{ margin: "10px 0", borderTop: "2px solid black" }} />
//       <SynthesiaSettings />
//       <div style={{marginTop:'50px'}}>
//         <Services />
//       </div>
//     </Col>
//   );
// };

// export default SettingContent;
