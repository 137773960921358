import React, { useEffect, useState } from "react";
import {
  Modal,
  Tabs,
  Tab,
  Box,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  button_bg_color_for_New_Project,
  hoveredSettings_Nav_bg_dm,
  selecetedSettings_Nav_bg_dm,
  settingsModal_bg_color_dm,
  uploadFiles_button_default_bg_color,
  uploadFiles_button_hovered_bg_color,
} from "../../../../constants/colors";
import LanguageSettings from "./LanguageSettings";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import CaptionSettings from "./CaptionSettings";
import VoiceSettings from "./VoiceSettings";
import VideoResolution from "./VideoResolution";
import VideoRatioSettings from "./VideoRatioSettings";
import CommonToast from "../../../Common_Components/CommonToast";

const VideoGenSettings = () => {
  const [activeTab, setActiveTab] = useState("voice");
  const {
    showVideoGenSettings,
    setShowVideoGenSettings,
  } = useWorkspace();
  const theme = localStorage.getItem("theme");
  const [videoSettingsSaved,setVideoSettingsSaved] = useState(false);

  useEffect(() => {
    if (showVideoGenSettings) {
      setActiveTab("voice"); 
    }
  }, [showVideoGenSettings]);

  const handleClose = () => {
    setShowVideoGenSettings(false); 
  };

  const handleSave = () => {
    console.log("VideoGen settings saved");
    setVideoSettingsSaved(true);
    setShowVideoGenSettings(false);
  };

  const handleCloseToast = () => {
    setVideoSettingsSaved(false);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "voice":
        return <VoiceSettings />;
      case "language":
        return <LanguageSettings />;
      // case "music":
      //   return <MusicSettings />;
      case "caption":
        return <CaptionSettings />;
      case "videoAspectRatio":
        return <VideoRatioSettings />;
      case "videoResolution":
        return <VideoResolution />;
      default:
        return <LanguageSettings />;
    }
  };

  return (
    <div>
      <CommonToast 
        open={videoSettingsSaved}
        onClose={handleCloseToast}
        content="Video settings saved."
        bgColor="#282D51"
        fontColor="#fff"
        fontSize="14px"
        fontWeight="500"
        autoHideDuration={3000}
        iconType = 'success'
    />
    <Modal
      open={showVideoGenSettings}
      onClose={handleClose}
      aria-labelledby="audio-settings-title"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "900px",
          height: "636px",
          backgroundColor: "background.paper",
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
          bgcolor: theme === "dark" ? settingsModal_bg_color_dm : "",
          borderRadius: "8px",
        }}
      >
        <Typography
          id="videoGen-settings-title"
          variant="h6"
          component="h2"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            // mb: 2,
            borderBottom: `1px solid ${theme === "dark" ? "#535774" : ""}`,
            padding: "20px",
          }}
          style={{
            fontSize: "20px",
            fontWeight: "600",
            color: theme === "dark" ? "#FFFFFF" : "",
          }}
        >
          Video Settings
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            sx={{
              borderRight: 1,
              borderColor: theme === "dark" ? "#535774" : "",
              minWidth: "150px",
              display: "flex",
              flexDirection: "column",
              width: "223px",
              paddingTop: "20px",
            }}
            TabIndicatorProps={{
              style: {
                display: "none", // Hide the default indicator (blue underline)
              },
            }}
          >
            <Tab
              label="Voice"
              value="voice"
              sx={{
                backgroundColor:
                  activeTab === "voice"
                    ? selecetedSettings_Nav_bg_dm
                    : "transparent", // Grey background for selected tab
                "&:hover": {
                  backgroundColor:
                    theme === "dark" ? hoveredSettings_Nav_bg_dm : "", // Light grey on hover
                },
                color: theme === "dark" ? "white" : "black",
                textTransform: "none",
                fontSize: "14px",
                margin: "0 20px",
                borderRadius: "8px",
              }}
              style={{ color: "#FFFF" }}
            />
            <Tab
              label="Language"
              value="language"
              sx={{
                backgroundColor:
                  activeTab === "language"
                    ? selecetedSettings_Nav_bg_dm
                    : "transparent", // Grey background for selected tab
                "&:hover": {
                  backgroundColor:
                    theme === "dark" ? hoveredSettings_Nav_bg_dm : "", // Light grey on hover
                },
                color: theme === "dark" ? "white" : "black",
                textTransform: "none",
                fontSize: "14px",
                margin: "0 20px",
                borderRadius: "8px",
              }}
              style={{ color: "#FFFF" }}
            />
            {/* <Tab label="Music"
              value="music"
              sx={{
                backgroundColor:
                  activeTab === "music"
                    ? selecetedSettings_Nav_bg_dm
                    : "transparent", // Grey background for selected tab
                "&:hover": {
                  backgroundColor:
                    theme === "dark" ? hoveredSettings_Nav_bg_dm : "", // Light grey on hover
                },
                color: theme === "dark" ? "white" : "black",
                textTransform: "none",
                fontSize: "14px",
                margin: "0 20px",
                borderRadius: "8px",
              }}
              style={{ color: "#FFFF" }}
            /> */}
            <Tab
              label="Caption"
              value="caption"
              sx={{
                backgroundColor:
                  activeTab === "caption"
                    ? selecetedSettings_Nav_bg_dm
                    : "transparent", // Grey background for selected tab
                "&:hover": {
                  backgroundColor:
                    theme === "dark" ? hoveredSettings_Nav_bg_dm : "", // Light grey on hover
                },
                color: theme === "dark" ? "white" : "black",
                textTransform: "none",
                fontSize: "14px",
                margin: "0 20px",
                borderRadius: "8px",
              }}
              style={{ color: "#FFFF" }}
            />
            <Tab
              label="Aspect Ratio"
              value="videoAspectRatio"
              sx={{
                backgroundColor:
                  activeTab === "videoAspectRatio"
                    ? selecetedSettings_Nav_bg_dm
                    : "transparent", // Grey background for selected tab
                "&:hover": {
                  backgroundColor:
                    theme === "dark" ? hoveredSettings_Nav_bg_dm : "", // Light grey on hover
                },
                color: theme === "dark" ? "white" : "black",
                textTransform: "none",
                fontSize: "14px",
                margin: "0 20px",
                borderRadius: "8px",
              }}
              style={{ color: "#FFFF" }}
            />
            <Tab
              label="Resolution"
              value="videoResolution"
              sx={{
                backgroundColor:
                  activeTab === "videoResolution"
                    ? selecetedSettings_Nav_bg_dm
                    : "transparent", // Grey background for selected tab
                "&:hover": {
                  backgroundColor:
                    theme === "dark" ? hoveredSettings_Nav_bg_dm : "", // Light grey on hover
                },
                color: theme === "dark" ? "white" : "black",
                textTransform: "none",
                fontSize: "14px",
                margin: "0 20px",
                borderRadius: "8px",
              }}
              style={{ color: "#FFFF" }}
            />
          </Tabs>
          <Box
            sx={{
              flexGrow: 1,
              pl: 3,
              overflowY: "auto",
              maxHeight: "430px",
              width: "457px",
            }}
          >
            {renderContent()}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            // mt: 2,
            p: 2,
            borderTop: `1px solid ${theme === "dark" ? "#444" : "#ccc"}`, // Border top added
          }}
        >
          <Button
            onClick={handleClose}
            sx={{
              mr: 1,
              backgroundColor:
                theme === "dark" ? uploadFiles_button_default_bg_color : "",
              "&:hover": {
                backgroundColor: uploadFiles_button_hovered_bg_color,
              },
              color: "#17182C",
              fontSize: "16px",
              fontWeight: "600",
              textTransform: "none",
              fontFamily: "Inter, sans-serif",
            }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{
              backgroundColor:
                theme === "dark" ? button_bg_color_for_New_Project : "",
              "&:hover": {
                backgroundColor: "#FFD9AA",
              },
              color: "#17182C",
              fontSize: "16px",
              fontWeight: "600",
              textTransform: "none",
              fontFamily: "Inter, sans-serif",
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
    </div>
    
  );
};

export default VideoGenSettings;
