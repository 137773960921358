import React from "react";
import { darkTheme_bg_color } from "../../../constants/colors";
import Image from "../../../assets/landingPageActionImage.png";
import Image990 from "../../../assets/LandingPage_Items/Landingpage_Modalx_FlowDiagram_below_990.png";
import { Typography, useMediaQuery, CardMedia, Box } from "@mui/material";

const Actionable = () => {
  const isXsOrSm = useMediaQuery("(max-width:1340px)");
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: darkTheme_bg_color,
        textAlign: "center",
      }}
    >
      {/* <p style={{textAlign:'center',color:'#F6BE6B'}}>Actionable</p> */}
      <div style={{ maxWidth: "1072px", margin: "auto", textAlign: "center" }}>
        <Typography
          sx={{
            fontSize: "48px",
            marginTop: "80px",
            "@media (max-width : 991px)": {
              opacity: "80%",
              fontFamily: "Inter",
              fontSize: "28px",
              fontWeight: 500,
              letterSpacing: "-0.32",
              textAlign: "center",
              margin: "40px auto",
              marginBottom: "40px",
              padding : '0px 10px 0px 10px',
            },
          }}
        >
          Stop just dreaming about growth. ModalX ties into the best AI tools on
          the market to put your plan into action, so you can see results faster
        </Typography>
      </div>
      {/* width: 1167px;
height: 260px;
gap: 0px;
opacity: 0px; */}

      <Box
        sx={{
          maxWidth: "1167px",
          position: "relative",
          margin: "80px auto",
          overflow: "hidden",
          "@media (max-width : 991px)": {
            margin: "40px auto",         
          },
        }}
      >
        {/* <img src={Image} style={{ maxWidth: "90%" }} /> */}
        <CardMedia
          component="img"
          image={Image}
          alt="Your Image Description"
          sx={{
            maxWidth: "100%",
            "@media (max-width : 991px)": {
              display: "none",
            },
          }}
        />

        <Box
          sx={{
            "@media (max-width : 991px)": {
              display: "block",
            },
            "@media (min-width : 991px)": {
              display: "none",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              maxWidth: "990px",
  
            }}
          >

            <CardMedia
              component="img"
              image={Image990}
              alt="Your Image Description"
              sx={{
                maxWidth: "65%",
                marginBottom: "10px",
 
              }}
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Actionable;
