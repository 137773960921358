// import React, { useState,useEffect } from "react";
// import { Col, Container, Row } from "react-bootstrap";
// import AvatarSettings from "./AvatarSettings"; // Import AvatarSettings component
// import VoiceSettings from "./VoiceSetting";
// import BackgroundSettings from "./BackgroundSettings";
// import LogoSettings from "./LogoSettings";
// import { useWorkspace } from "../../../../contexts/WorkspaceContext";

// const SynthesiaSettings = () => {
//   const [openSynSet, setOpenSynSet] = useState(false);
//   const {showVideoSettings} = useWorkspace();

//   const handleClick = () => {
//     console.log("Synthesia Settings Clicked");
//     setOpenSynSet(!openSynSet);
//   };

//   useEffect(() => {
//     if(showVideoSettings){
//       setOpenSynSet(true)
//     }
    
//   }, [showVideoSettings])
  

//   return (
//     <Container fluid className="m-0 p-0">
//       <Row
//         className="m-0 p-0"
//         style={{ cursor: "pointer" }}
//         onClick={handleClick}
//       >
//         <Col xs={10} className="m-0 px-0 pb-1">
//           <strong className="text-secondary" style={{fontSize:'.95rem'}}>Video Settings</strong>
//         </Col>
//         <Col
//           xs={2}
//           className="m-0 p-0 d-flex align-items-center justify-content-center"
//         >
//           {!openSynSet ? (
//             <i className="bi bi-caret-down" />
//           ) : (
//             <i className="bi bi-caret-up" />
//           )}
//         </Col>
//       </Row>
//       {openSynSet && (
//         <Row className="m-0 p-0">
//           {/* Use AvatarSettings component here */}
//           <BackgroundSettings />
//           <AvatarSettings />
//           <VoiceSettings />
//           {/* <LogoSettings /> */}
//         </Row>
//       )}
//     </Container>
//   );
// };

// export default SynthesiaSettings;

import React, { useState, useEffect } from "react";
import { Modal, Tabs, Tab, Box, Typography, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AvatarSettings from "./AvatarSettings";
import VoiceSettings from "./VoiceSetting";
import BackgroundSettings from "./BackgroundSettings";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { button_bg_color_for_New_Project, hoveredSettings_Nav_bg_dm, selecetedSettings_Nav_bg_dm, settingsModal_bg_color_dm, uploadFiles_button_default_bg_color, uploadFiles_button_hovered_bg_color } from "../../../../constants/colors";
import VideoSettingsAPI from "./videoSettingsAPI";
import { SettingsAdjustment } from "../../../../config/analytics";

const SynthesiaSettings = () => {
  const [activeTab, setActiveTab] = useState('background');
  const [selectedBackground, setSelectedBackground] = useState(null);
  const { showVideoSettings, setShowVideoSettings,setCategories,setBackgrounds,setSelectedCategory ,setAvatars, setLanguages,setVoices, avatarsloading, setAvatarsLoading, voiceloading, setVoiceLoading, videoAvatar, videoBackground, videoAvatarLanguage,  } = useWorkspace();
  const theme = localStorage.getItem('theme');

  useEffect(() => {

    if (showVideoSettings) {
      setAvatarsLoading(true)
      setVoiceLoading(true)
      setActiveTab('background'); // Set default tab
      VideoSettingsAPI(setCategories,setBackgrounds,setSelectedCategory ,setAvatars, setLanguages,setVoices,  avatarsloading, setAvatarsLoading, voiceloading, setVoiceLoading,   )
    }
  }, [showVideoSettings]);

  const handleBackgroundSelect = (background) => {
    setSelectedBackground(background);
  };

  const handleClose = () => {
    setShowVideoSettings(false); // Close the modal by updating the context state
  };

  const handleSave = () => {
    // Implement save functionality here
    SettingsAdjustment("video_settings", {videoAvatar:videoAvatar,videoBackground: videoBackground,videoAvatarLanguage:videoAvatarLanguage});
    console.log('Settings saved');
    setShowVideoSettings(false);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'background':
        return <BackgroundSettings onBackgroundSelect={handleBackgroundSelect} />;
      case 'avatar':
        return <AvatarSettings />;
      case 'voice':
        return <VoiceSettings />;
      default:
        return <BackgroundSettings onBackgroundSelect={handleBackgroundSelect} />;
    }
  };

  return (
    <Modal
      open={showVideoSettings}
      onClose={handleClose}
      aria-labelledby="video-settings-title"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: '720px',
          height: '636px',
          backgroundColor: 'background.paper',
          boxShadow: 24,
          // p: 4,
          display: 'flex',
          flexDirection: 'column',
          bgcolor: theme === 'dark' ? settingsModal_bg_color_dm : '',
          borderRadius:'8px',
        }}
      >
        <Typography
          id="video-settings-title"
          variant="h6"
          component="h2"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            // mb: 2,
            borderBottom: `1px solid ${theme === 'dark' ? '#535774' : ''}`,
            padding:'20px'
          }}
          style={{fontSize:'20px',fontWeight:'600',color:theme === 'dark'?'#FFFFFF':''}}
        >
          Video Settings
          {activeTab === 'background' && selectedBackground ? (
            <span> ({selectedBackground.name})</span>
          ) : null}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            sx={{
              borderRight: 1,
              borderColor: theme === 'dark' ? '#535774' : '',
              minWidth: '150px',
              display: 'flex',
              flexDirection: 'column',
              width:'223px',
              paddingTop : '20px',
              
            }}
            TabIndicatorProps={{
              style: {
                display: 'none', // Hide the default indicator (blue underline)
              },
            }}
          >
            <Tab
              label="Background"
              value="background"
              sx={{
                backgroundColor: activeTab === 'background' ? selecetedSettings_Nav_bg_dm : 'transparent', // Grey background for selected tab
                '&:hover': {
                  backgroundColor: theme === 'dark' ? hoveredSettings_Nav_bg_dm : '', // Light grey on hover
                },
                color: theme === 'dark' ? 'white' : 'black',
                textTransform: 'none',
                fontSize:'14px',
                margin:'0 20px',
                borderRadius:'8px',
             
              }}
              style={{color:'#FFFF'}}
            />
            <Tab
              label="Avatar"
              value="avatar"
              sx={{
                backgroundColor: activeTab === 'avatar' ? selecetedSettings_Nav_bg_dm : 'transparent', // Grey background for selected tab
                '&:hover': {
                  backgroundColor: theme === 'dark' ? hoveredSettings_Nav_bg_dm : '', // Light grey on hover
                },
                color: theme === 'dark' ? 'white' : 'black',
                textTransform: 'none',
                fontSize:'14px',
                margin:'0 20px',
                borderRadius:'8px'
                
              }}
              style={{color:'#FFFF'}}
            />
            <Tab
              label="Voice"
              value="voice"
              sx={{
                backgroundColor: activeTab === 'voice' ? selecetedSettings_Nav_bg_dm : 'transparent', // Grey background for selected tab
                '&:hover': {
                  backgroundColor: theme === 'dark' ? hoveredSettings_Nav_bg_dm : '', // Light grey on hover
                },
                color: theme === 'dark' ? 'white' : 'black',
                textTransform: 'none',
                fontSize:'14px',
                margin:'0 20px',
                borderRadius:'8px'
              }}
              style={{color:'#FFFF'}}
            />
          </Tabs>
          <Box
            sx={{
              flexGrow: 1,
              pl: 3,
              overflowY: 'auto',
              maxHeight: '430px',
              width:'457px',
              paddingTop : '20px'
            }}
          >
            {renderContent()}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            p: 2,
            borderTop: `1px solid ${theme === 'dark' ? '#444' : '#ccc'}`, // Border top added
          }}
        >
          <Button onClick={handleClose} sx={{ mr: 1, backgroundColor:theme === 'dark'?uploadFiles_button_default_bg_color:'',
          '&:hover': {
            backgroundColor: uploadFiles_button_hovered_bg_color,
          },
          color:'#17182C',
          fontSize:'16px',
          fontWeight:'600',
          textTransform:'none',
          fontFamily: 'Inter, sans-serif',
          }}>
            Close
          </Button>
          <Button variant="contained" onClick={handleSave} sx={{backgroundColor:theme === 'dark'? button_bg_color_for_New_Project:'',
          '&:hover': {
            backgroundColor:'#FFD9AA',
          },
          color:'#17182C',
          fontSize:'16px',
          fontWeight:'600',
          textTransform:'none',
          fontFamily: 'Inter, sans-serif',
          }}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SynthesiaSettings;



