// import React, { useState } from 'react';
// import { Box, Button, IconButton } from '@mui/material';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import { New_Project_Button_bg_color, SelectedChat_bg_color } from '../../Provider/Color_Provider';
// import Linkidin_Image from "../../assets/LandingPage_Items/Testimonials/linkedin.png";
// import Avatar_Image from "../../assets/LandingPage_Items/Testimonials/Avatar.png";
// import { hovered_chat_bg_color_dm, selected_chat_bg_color_dm } from '../../constants/colors';

// const Testimonials = () => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const totalBoxes = 5; // Total number of boxes in the carousel
//   const visibleBoxes = 3; // Number of boxes visible at once
//   const boxWidth = 386; // Width of each box in pixels (including margin)

//   const handlePrevClick = () => {
//     if (currentIndex > 0) {
//       setCurrentIndex((prevIndex) => prevIndex - 1);
//     }
//   };

//   const handleNextClick = () => {
//     if (currentIndex < totalBoxes - visibleBoxes) {
//       setCurrentIndex((prevIndex) => prevIndex + 1);
//     }
//   };

//   const features = [
//     {
//       id: '1',
//       name: 'Cameron Williamson',
//       description: '“We have increased our web presence through videos with amazing quality. It has freed up hours for my employees and has allowed them to focus on our core business”.',
//     },
//     {
//       id: '2',
//       name: 'Cameron Williamson',
//       description: '“We have increased our web presence through videos with amazing quality. It has freed up hours for my employees and has allowed them to focus on our core business”.',
//     },
//     {
//       id: '3',
//       name: 'Cameron Williamson',
//       description: '“We have increased our web presence through videos with amazing quality. It has freed up hours for my employees and has allowed them to focus on our core business”.',
//     },
//     {
//       id: '4',
//       name: 'Cameron Williamson',
//       description: '“We have increased our web presence through videos with amazing quality. It has freed up hours for my employees and has allowed them to focus on our core business”.',
//     },
//     {
//       id: '5',
//       name: 'Cameron Williamson',
//       description: '“We have increased our web presence through videos with amazing quality. It has freed up hours for my employees and has allowed them to focus on our core business”.',
//     }
//   ];

//   return (
//     <div>
//       <p style={{ textAlign: 'center', color: '#F6BE6B' }}>Testimonials</p>
//       <div style={{ maxWidth: '1072px', margin: 'auto', textAlign: 'center' }}>
//         <p style={{ fontSize: '48px', marginTop: '80px' }}>
//             Don’t just take our word for it        
//         </p>
//         <div style={{width:'704px',height:'48px',margin:'auto',color:'rgba(255,255,255,0.8)'}}>
//             <p>Our diverse clients share a common theme: success. They’ve used our tools to streamline workflows, enhance creativity, and achieve remarkable results</p>
//         </div>
        
//       </div>
//       <Box display="flex" alignItems="center" justifyContent="center" mt={4} sx={{ marginTop: '138px' }}>
//         <Box
//           overflow="hidden"
//           width={`${visibleBoxes * boxWidth}px`}
//           display="flex"
//           position="relative"
//         >
//           <Box
//             display="flex"
//             transition="transform 0.5s ease"
//             style={{
//               transform: `translateX(-${currentIndex * boxWidth}px)`
//             }}
//           >
//             {features.map((feature, index) => (
//               <Box
//                 key={feature.id}
//                 width="386px"
//                 height="262px"
//                 bgcolor={SelectedChat_bg_color}
//                 borderRadius="12px"
//                 marginRight="30px"
//                 boxShadow={2}
//                 flexShrink={0}
//                 display="flex"
//                 justifyContent="center"
//                 alignItems="center"
//                 flexDirection='column'
//                 p={2}
//               >
//                 <div style={{width:'318px',height:'194px',display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
//                     <div style={{height:'40px',display:'flex',justifyContent:'space-between'}}>
//                         <div style={{display:'flex',height:'40px',maxWidth:'210px',justifyContent:'space-between'}}>
//                             <img src={Avatar_Image}/>
//                             <div style={{height:'40px',lineHeight:'10px',marginLeft:'10px'}}>
//                                 <p>{feature.name}</p>
//                                 <p style={{color:'#8F9BB7'}}>COO @ ShareWise</p>
//                             </div>
//                         </div>
//                         <img src={Linkidin_Image} style={{height:'24px',width:'24px'}}/>
//                     </div>
//                     <p>{feature.description}</p>
//                 </div>
//               </Box>
//             ))}
//           </Box>
//         </Box>
//       </Box>
//       <Box display="flex" justifyContent="center" sx={{maxWidth:'112px',height:'48px',display:'flex',justifyContent:'space-between',margin:'48px auto'}}>
//           <IconButton
//             onClick={handlePrevClick}
//             disabled={currentIndex === 0}
//             disableRipple
//             sx={{
//               bgcolor: '#1F233E',
//               color: 'white', // Set the icon color
//               '&:hover': {
//                 backgroundColor: '#1B1E36',
//               },
//               '&:disabled': {
//                 bgcolor: '#1F233E', // Maintain the same background color when disabled
//                 color: 'grey', // Change color to indicate disabled state
//               },
//               width:'48px',
//               height:'49px'
//             }}
//           >
//             <ChevronLeftIcon />
//           </IconButton>
//           <IconButton
//             onClick={handleNextClick}
//             disabled={currentIndex >= totalBoxes - visibleBoxes}
//             disableRipple
//             sx={{
//               bgcolor: '#1F233E',
//               color: 'white', // Set the icon color
//               '&:hover': {
//                 backgroundColor: '#1B1E36',
//               },
//               '&:disabled': {
//                 bgcolor: '#1F233E', // Maintain the same background color when disabled
//                 color: 'grey', // Change color to indicate disabled state
//               },
//               width:'48px',
//               height:'49px'
//             }}
//           >
//             <KeyboardArrowRightIcon />
//           </IconButton>
//         </Box>

//     </div>
//   );
// };

// export default Testimonials;


import React, { useState } from 'react';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { SelectedChat_bg_color } from '../../Provider/Color_Provider';
import Linkidin_Image from "../../assets/LandingPage_Items/Testimonials/linkedin.png";
import Avatar_Image from "../../assets/LandingPage_Items/Testimonials/Avatar.png";
import { darkTheme_bg_color, selected_chat_bg_color_dm } from '../../constants/colors';

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalBoxes = 6; // Total number of boxes in the carousel
  const visibleBoxes = 3; // Number of boxes visible at once
  const boxWidth = 386; // Width of each box in pixels (including margin)
  const isBelow991 = useMediaQuery("(max-width:991px)");

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < totalBoxes - visibleBoxes) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const features = [
    {
      id: '1',
      name: 'Cameron Williamson',
      description: '“We have increased our web presence through videos with amazing quality. It has freed up hours for my employees and has allowed them to focus on our core business”.',
    },
    {
      id: '2',
      name: 'Cameron Williamson',
      description: '“We have increased our web presence through videos with amazing quality. It has freed up hours for my employees and has allowed them to focus on our core business”.',
    },
    {
      id: '3',
      name: 'Cameron Williamson',
      description: '“We have increased our web presence through videos with amazing quality. It has freed up hours for my employees and has allowed them to focus on our core business”.',
    },
    {
      id: '4',
      name: 'Cameron Williamson',
      description: '“We have increased our web presence through videos with amazing quality. It has freed up hours for my employees and has allowed them to focus on our core business”.',
    },
    {
      id: '5',
      name: 'Cameron Williamson',
      description: '“We have increased our web presence through videos with amazing quality. It has freed up hours for my employees and has allowed them to focus on our core business”.',
    }
  ];

  return (
    <Box style={{paddingTop:isBelow991?'125px':'176px',background: `linear-gradient(to bottom, #1e223d, ${darkTheme_bg_color})`}}>
        <Typography variant="h6" align="center" color="#F6BE6B" sx={{fontSize: isBelow991?'12px':"16px"}}>
            Testimonials
        </Typography>
        <Box maxWidth={isBelow991?'90%':"1072px"} margin="auto" marginTop='-42px' textAlign="center">
            <Typography variant="h3" mt={10} fontSize={isBelow991?'28px':"48px"} marginBottom="16px">
            Don’t just take our word for it        
            </Typography>
            <Box maxWidth="704px" height="48px" mx="auto" color="rgba(255,255,255,0.8)">
            <Typography fontSize={isBelow991?'14px':'18px'}>
                Our diverse clients share a common theme: success. They’ve used our tools to streamline workflows, enhance creativity, and achieve remarkable results
            </Typography>
            </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt={4} sx={{ marginTop: isBelow991?'88px':'138px' }}>
            <Box
            overflow="hidden"
            width={`${visibleBoxes * boxWidth+162}px`}
            display="flex"
            position="relative"
            >
            <Box
                display="flex"
                transition="transform 0.5s ease-in-out" // Changed the transition to ease-in-out
                sx={{
                transform: `translateX(-${currentIndex * boxWidth}px)`,
                transitionProperty: 'transform',
                transitionDuration: '0.5s',
                transitionTimingFunction: 'ease-in-out', // Smooth transition
                
                }}
            >
                {features.map((feature) => (
                <Box
                    key={feature.id}
                    width={isBelow991?'358px':"386px"}
                    height={isBelow991?'226px':"262px"}
                    bgcolor={selected_chat_bg_color_dm}
                    borderRadius="12px"
                    marginRight={isBelow991?'40px':"81px"}
                    boxShadow={2}
                    flexShrink={0}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection='column'
                    p={2}
                    sx={{
                      "&:hover": {
                      backgroundColor: "#282D51CC",
                      cursor: "pointer",
                    },
    
                    }}
                >
                    <Box width="318px" height="194px" display="flex" flexDirection="column" justifyContent="space-between">
                    <Box height="40px" display="flex" justifyContent="space-between">
                        <Box display="flex" height="40px" maxWidth="210px" justifyContent="space-between">
                        <img src={Avatar_Image} alt="avatar"/>
                        <Box height="40px" lineHeight="10px" ml={1} >
                            <Typography>{feature.name}</Typography>
                            <Typography color="#8F9BB7">COO @ ShareWise</Typography>
                        </Box>
                        </Box>
                        <img src={Linkidin_Image} alt="LinkedIn" style={{ height: '24px', width: '24px' }} />
                    </Box>
                    <Typography>{feature.description}</Typography>
                    </Box>
                </Box>
                ))}
            </Box>
            </Box>
        </Box>
        <Box display="flex" justifyContent="center" sx={{maxWidth:'112px',height:'48px',display:'flex',justifyContent:'space-between',margin:'48px auto'}}>
            <IconButton
            onClick={handlePrevClick}
            disabled={currentIndex === 0}
            disableRipple
            sx={{
                bgcolor: '#1F233E',
                color: 'white',
                '&:hover': {
                backgroundColor: '#1B1E36',
                },
                '&:disabled': {
                bgcolor: '#1F233E',
                color: 'grey',
                },
                width: '48px',
                height: '49px',
            }}
            >
            <ChevronLeftIcon />
            </IconButton>
            <IconButton
            onClick={handleNextClick}
            disabled={currentIndex >= totalBoxes - visibleBoxes}
            disableRipple
            sx={{
                bgcolor: '#1F233E',
                color: 'white',
                '&:hover': {
                backgroundColor: '#1B1E36',
                },
                '&:disabled': {
                bgcolor: '#1F233E',
                color: 'grey',
                },
                width: '48px',
                height: '49px',
            }}
            >
            <KeyboardArrowRightIcon />
            </IconButton>
        </Box>
    </Box>
  );
};

export default Testimonials;
