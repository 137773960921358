// import React, { useState, useEffect } from "react";
// import { Col, Button, Offcanvas, Row } from "react-bootstrap";
// import { useMediaQuery } from "react-responsive";
// import SettingContent from "./SettingContent"; // Import the SettingBarContent component
// import { useWorkspace } from "../../../contexts/WorkspaceContext";
// import * as Sentry from "@sentry/react";

// const SettingBar = () => {
//   const {setShowRightsideBar} = useWorkspace();
//   const [showSettingBar, setShowSettingBar] = useState(true);
//   const [userToggled, setUserToggled] = useState(false);
//   const isXsOrSm = useMediaQuery({ maxWidth: 767 });

//   useEffect(() => {
//     if (!userToggled) {
//       setShowSettingBar(!isXsOrSm);
//       setShowRightsideBar(!isXsOrSm);
//     }
//   }, [isXsOrSm, userToggled]);

//   const toggleSettingBar = () => {
//     setShowSettingBar(!showSettingBar);
//     setShowRightsideBar(!showSettingBar);
//     setUserToggled(true);
//   };

//   const SettingBarToggle = (
//     <Col
//     style={{
//       maxWidth: "1px",
//       minWidth: "1px",
//       backgroundColor:'#FFFFFF',
//       opacity:'10%'

//     }}
//       className="m-0 p-0 d-flex justify-content-start"
//     >
//       <Button
//         variant="Secondary"
//         className="p-0 m-0 border-0 bg-transparent"
//         onClick={toggleSettingBar}
//       >
//         {showSettingBar ? (
//           // <div
//           //   style={{
//           //     width: "25px",
//           //     minWidth: "25px",
//           //     maxWidth: "25px",
//           //     display: "flex",
//           //     alignItems: "center",
//           //     justifyContent: "center",
//           //   }}
//           // >
//           <i className="bi bi-chevron-compact-right p-0 m-0 fs-4" />
//         ) : (
//           // </div>
//           // <div
//           //   style={{
//           //     width: "25px",
//           //     minWidth: "25px",
//           //     maxWidth: "25px",
//           //     display: "flex",
//           //     alignItems: "center",
//           //     justifyContent: "center",
//           //   }}
//           // >
//           <i className="bi bi-chevron-compact-left p-0 m-0 fs-4" />
//           // </div>
//         )}
//       </Button>
//     </Col>
//   );

//   return (
//     <>
//       {SettingBarToggle}
//       {isXsOrSm ? (
//         <Offcanvas
//           show={showSettingBar}
//           onHide={toggleSettingBar}
//           placement="end"
//           style={{
//             maxWidth: "325px",
//             minWidth: "325px",
//             overflowY: "auto",
//             display: "flex",
//             border: "none",
//             flexDirection: "column",
//           }}
//           className="p-0 m-0"
//         >
//           <Offcanvas.Body className="p-0 m-0">
//             <Row style={{ height: "100vh" }} className="p-0 m-0">
//               <>
//                 {showSettingBar && SettingBarToggle}
//                 <Sentry.ErrorBoundary fallback={<div>Something went wrong in the workspace</div>}>
//                   <SettingContent />
//                 </Sentry.ErrorBoundary>
                
//               </>
//             </Row>
//           </Offcanvas.Body>
//         </Offcanvas>
//       ) : (
//         <>{showSettingBar && <Sentry.ErrorBoundary fallback={<div>Something went wrong in the workspace</div>}><SettingContent /></Sentry.ErrorBoundary>}</>
//       )}
//     </>
//   );
// };

// export default SettingBar;


import React, { useState, useEffect } from "react";
import { Col, Button, Offcanvas, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import SettingContent from "./SettingContent";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import * as Sentry from "@sentry/react";
import { Box } from "@mui/material";
import { hovered_chat_bg_color_dm, selected_chat_bg_color_dm } from "../../../constants/colors";

const SettingBar = () => {
  
  const { setShowRightsideBar,showSettingBar,setShowSettingBar,iswebTrackClicked } = useWorkspace();
  // const [showSettingBar, setShowSettingBar] = useState(true);
  const [userToggled, setUserToggled] = useState(false);
  // const isXsOrSm = useMediaQuery({ maxWidth: 991 });
  const isXsOrSm = useMediaQuery({ maxWidth: 992 });

  useEffect(() => {
    if(iswebTrackClicked){
      setShowSettingBar(false);
    }
    else{
      setShowSettingBar(true);
    }
  }, [iswebTrackClicked])
  

  useEffect(() => {
    if (!userToggled) {
      setShowSettingBar(!isXsOrSm);
      setShowRightsideBar(!isXsOrSm);
    }
  }, [isXsOrSm, userToggled]);

  const toggleSettingBar = () => {
    setShowSettingBar(!showSettingBar);
    setShowRightsideBar(!showSettingBar);
    setUserToggled(true);
  };

  const SettingBarToggle = (
    <>
      <Button
        variant="Secondary"
        className="p-0 m-0 border-0 bg-transparent"
        onClick={toggleSettingBar}
        style={{display : 'none'}}
      >
        {showSettingBar ? (
          // <i className="bi bi-chevron-compact-right p-0 m-0 fs-4" />
          <></>
        ) : (
          <Box 
            sx={{
              width:'25px',
              display:'flex',
              alignItems: "center",
              justifyContent: "center",
              backgroundColor:selected_chat_bg_color_dm,
              color:'rgba(255,255,255,0.8)',
              '&:hover': {
                bgcolor: hovered_chat_bg_color_dm,
                color:'#FFFFFF'
              }
            }}>
            <i className="bi bi-chevron-compact-left p-0 m-0 fs-4" />
          </Box>
        )}
      </Button>
      <Col
        style={{
          maxWidth: "1px",
          minWidth: "1px",
          backgroundColor: "#FFFFFF",
          opacity: "10%",
        }}
        className="m-0 p-0 d-flex justify-content-start"
      />
    </>
  );

  return (
    <>
      {SettingBarToggle}
      {isXsOrSm ? (
        <Offcanvas
          show={showSettingBar}
          onHide={toggleSettingBar}
          placement="end"
          style={{
            // maxWidth: "325px",
            // minWidth: "325px",
            width:'282px',
            overflowY: "auto",
            display: "flex",
            border: "none",
            flexDirection: "column",
          }}
          className="p-0 m-0"
        >
          <Offcanvas.Body className="p-0 m-0">
            <Row style={{ height: "100vh"}} className="p-0 m-0">
              <>
                {showSettingBar && SettingBarToggle}
                <Sentry.ErrorBoundary fallback={<div>Something went wrong in the workspace</div>}>
                  <SettingContent />
                </Sentry.ErrorBoundary>
              </>
            </Row>
          </Offcanvas.Body>
        </Offcanvas>
      ) : (
        <>{showSettingBar && <Sentry.ErrorBoundary fallback={<div>Something went wrong in the workspace</div>}><SettingContent /></Sentry.ErrorBoundary>}</>
      )}
    </>
  );
};

export default SettingBar;
