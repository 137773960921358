// import React, { useState } from "react";
// import { Col, Button, Spinner, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
// import PreviewMedia from "./PreviewMedia"; // Import the PreviewMedia component

// const MessageActions = ({ message, handleDownload }) => {
//   // console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkkk",message)
//   const [showPreviewModal, setShowPreviewModal] = useState(false);
//   const [previewUrl, setPreviewUrl] = useState("");

//   const handlePreview = () => {
//     setShowPreviewModal(true);
//     setPreviewUrl(message.file.url);
//   };

//   const handleClosePreviewModal = () => {
//     setShowPreviewModal(false);
//     setPreviewUrl("");
//   };

//   const renderTooltip = (props) => (
//     <Tooltip id="button-tooltip" {...props}>
//       Error occurred while generating!
//     </Tooltip>
//   );

//   return (
//     <>
//       <Col style={{ display: "flex", flexDirection: "column" }}>
//         <OverlayTrigger
//           placement="bottom"
//           overlay={message.file.status === "failed" ? renderTooltip : <></>}
//         >
//           <span>
//             <Button
//               variant="outline-secondary"
//               onClick={() => handleDownload(message.file.url)}
//               disabled={message.file.status === "processing" || message.file.status === "failed"}
//               style={{ fontWeight:'400',borderColor:message.file.status === "failed" ? 'red' : ''}}
//               className="my-2 d-flex align-items-center justify-content-start"
//             >
//               {!message.file.url.endsWith(".mp3") &&
//               !message.file.url.endsWith(".mp4") &&
//               !message.file.url.endsWith(".jpg") ? (
//                 <>
//                   <i className="bi bi-cloud-arrow-down pe-3" />
//                   Download
//                 </>
//               ) : (
//                 <>
//                   <i className="bi bi-arrow-up-right-circle pe-3" />
//                   Open
//                 </>
//               )}
//               {message.file.status === "processing" && (
//                 <Spinner
//                   animation="border"
//                   role="status"
//                   size="sm"
//                   className="ms-2"
//                 />
//               )}
//             </Button>
//           </span>
//         </OverlayTrigger>
//       </Col>
//       <Col style={{ display: "flex", flexDirection: "column" }}>
//         <OverlayTrigger
//           placement="bottom"
//           overlay={message.file.status === "failed" ? renderTooltip : <></>}
//         >
//           <span>
//             <Button
//               variant="outline-secondary"
//               disabled={message.file.status === "processing" || message.file.status === "failed"}
//               className="my-2 d-flex align-items-center justify-content-start"
//               onClick={handlePreview}
//               style={{fontWeight:'400',borderColor:message.file.status === "failed" ? 'red' : '' }}
//             >
//               <i className="bi bi-easel2 pe-3" />
//               Preview
//               {message.file.status === "processing" && (
//                 <Spinner
//                   animation="border"
//                   role="status"
//                   size="sm"
//                   className="ms-2"
//                 />
//               )}
//             </Button>
//           </span>
//         </OverlayTrigger>
//       </Col>
//       {/* Render Preview modal */}
//       <Modal show={showPreviewModal} onHide={handleClosePreviewModal} size="lg">
//         <Modal.Header closeButton>
//           <Modal.Title>Preview</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <PreviewMedia url={previewUrl} />
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// };

// export default MessageActions;

import React, {useEffect} from "react";
import { Col, Spinner, Row } from "react-bootstrap";
import PreviewMedia from "./PreviewMedia"; // Import the PreviewMedia component
import { agent_conversation_bg_color } from "../../../constants/colors";

const MessageActions = ({ message }) => {
  console.log("Inside message actionsssssssssssssssssssssssssssssssssssssssssssssssssssssssss", message);

//   return (
//     <Col style={{ display: "flex", flexDirection: "column",marginBottom : '24px', marginTop : '16px' }}>
//     {message.file.status === "processing" ? (
//       <Spinner animation="border" role="status" className="my-2">
//         <span className="visually-hidden">Loading...</span>
//       </Spinner>
//     ) : message.file.status === "failed" ? (
//       <span style={{ color: "red" }}>Error occurred while generating!</span>
//       // message.file.status === null  ---> this one i have added  below for reading file from public links
//     ) : (message.file.status === "processed" || message.file.status === "completed" || message.file.status === null) ? (
//       <PreviewMedia url={message.file.url} />
//     ) : null}
//   </Col>
// );

  return (  
  <>
    { message.file.length > 0  ? 
      message.file.map((msg)=>{
       return <Col style={{ display: "flex", flexDirection: "column" }}>
        {msg.status === "processing" ? (
          <Spinner animation="border" role="status" className="my-2">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : msg.status === "failed" ? (
          <span style={{ color: "red" }}>Error occurred while generating!</span>
        ) : (msg.status === "processed" || msg.status === "completed" || msg.status === null) ? (
               <Col xs={11} width="auto"  className="text-start" style={{ margin: 'auto', marginBottom:"30px",
              backgroundColor : agent_conversation_bg_color, marginLeft : '10px',borderRadius:'8px'}}>
            <PreviewMedia url={msg.url} /></Col>
        ) : null}
      </Col>
      })
     :
      <Col style={{ display: "flex", flexDirection: "column" }}>
      {message.file.status === "processing" ? (
        <Spinner animation="border" role="status" className="my-2">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : message.file.status === "failed" ? (
        <span style={{ color: "red" }}>Error occurred while generating!</span>
        // message.file.status === null  ---> this one i have added  below for reading file from public links
      ) : (message.file.status === "processed" || message.file.status === "completed" || message.file.status === null) ? (
        <PreviewMedia url={message.file.url} />
      ) : null}
    </Col>
    }
    </>
  );
};

export default MessageActions;


// import React, { useEffect, useState } from "react";
// import { Col, Button, Spinner, Modal } from "react-bootstrap";
// import PreviewMedia from "./PreviewMedia"; // Import the PreviewMedia component
// import { useWorkspace } from "../../../contexts/WorkspaceContext";

// const MessageActions = ({ message, handleDownload }) => {
//   const {checkUrlStatus} = useWorkspace()
//   const [showPreviewModal, setShowPreviewModal] = useState(false);
//   const [previewUrl, setPreviewUrl] = useState("");
//   const [progress,setProgress] = useState(true);
//   const url = message.file.url || '' ;

//   useEffect(() => {
//     let intervalId;
  
//     const checkStatus = () => {
//       if (checkUrlStatus(url)) {
//         clearInterval(intervalId);
//         setProgress(false);
//       }
//     };

//     intervalId = setInterval(checkStatus, 3000);
    
  
//     return () => {
//       clearInterval(intervalId);
//     };
//   }, [url, checkUrlStatus, progress]);
  

//   const handlePreview = () => {
//     setShowPreviewModal(true);
//     setPreviewUrl(message.file.url);
//   };

//   const handleClosePreviewModal = () => {
//     setShowPreviewModal(false);
//     setPreviewUrl("");
//   };

//   return (
//     <>
//       <Col style={{ display: "flex", flexDirection: "column" }}>
//         <Button
//           variant="outline-secondary"
//           onClick={() => handleDownload(message.file.url)}
//           disabled={message.file.status === "processing"}
//           className="my-2 d-flex align-items-center justify-content-start"
//         >
//           {!message.file.url.endsWith(".mp3") &&
//           !message.file.url.endsWith(".mp4") &&
//           !message.file.url.endsWith(".jpg") ? (
//             <>
//               <i className="bi bi-cloud-arrow-down pe-3" />
//               Download
//             </>
//           ) : (
//             <>
//               <i className="bi bi-arrow-up-right-circle pe-3" />
//               Open
//             </>
//           )}
//           {progress && (
//             <Spinner
//               animation="border"
//               role="status"
//               size="sm"
//               className="ms-2"
//             />
//           )}
//         </Button>
//       </Col>
//       <Col style={{ display: "flex", flexDirection: "column" }}>
//         <Button
//           variant="outline-secondary"
//           disabled={message.file.status === "processing"}
//           className="my-2 d-flex align-items-center justify-content-start"
//           onClick={handlePreview} // Add onClick handler to trigger preview
//         >
//           <i className="bi bi-easel2 pe-3" />
//           Preview
//           {progress && (
//             <Spinner
//               animation="border"
//               role="status"
//               size="sm"
//               className="ms-2"
//             />
//           )}
//         </Button>
//       </Col>
//       {/* Render Preview modal */}
//       <Modal show={showPreviewModal} onHide={handleClosePreviewModal} size="lg">
//         <Modal.Header closeButton>
//           <Modal.Title>Preview</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <PreviewMedia url={previewUrl} />
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// };

// export default MessageActions;







// import React from "react";
// import { Col, Button, Spinner } from "react-bootstrap";

// const MessageActions = ({ message, handleDownload, handlePreview }) => {
//   console.log("here is the message actions",message);
//   return (
//     <>
//       <Col style={{ display: "flex", flexDirection: "column" }}>
//         <Button
//           variant="outline-secondary"
//           onClick={() => handleDownload(message.file.url)}
//           disabled={message.file.status === "processing"}
//           className="my-2 d-flex align-items-center justify-content-start"
//         >
//           {!message.file.url.endsWith(".mp3") &&
//           !message.file.url.endsWith(".mp4") &&
//           !message.file.url.endsWith(".jpg") ? (
//             <>
//               <i className="bi bi-cloud-arrow-down pe-3" />
//               Download
//             </>
//           ) : (
//             <>
//               <i className="bi bi-arrow-up-right-circle pe-3" />
//               Open
//             </>
//           )}
//           {message.file.status === "processing" && (
//             <Spinner
//               animation="border"
//               role="status"
//               size="sm"
//               className="ms-2"
//             />
//           )}
//         </Button>
//       </Col>
//       <Col style={{ display: "flex", flexDirection: "column" }}>
//         <Button
//           variant="outline-secondary"
//           onClick={() => handlePreview(message.file.url)}
//           disabled={message.file.status === "processing"}
//           className="my-2 d-flex align-items-center justify-content-start"
//         >
//           <i className="bi bi-easel2 pe-3" />
//           Preview
//           {message.file.status === "processing" && (
//             <Spinner
//               animation="border"
//               role="status"
//               size="sm"
//               className="ms-2"
//             />
//           )}
//         </Button>
//       </Col>
//     </>
//   );
// };

// export default MessageActions;
