import React from "react";
import { Box, Typography, CardMedia } from "@mui/material";
import modalxIcon from "../../../assets/modalxIcon.png";
import { useMediaQuery } from "react-responsive";

function GetModalXFlowDiagramForMobileResponsive() {
  const userEmail = localStorage.getItem("userEmail");
  const isHeightAbove610 = useMediaQuery({ minHeight: 610 });

  function removeAfterAtSymbol(userEmail) {
    // Use split to divide the string at the '@' symbol and return the first part
    return userEmail.split('@')[0];
  }
  
  const name = removeAfterAtSymbol(userEmail);
  
  return (
    <>
      <Box sx={{}}>
        <Box
          sx={{
            width: "100%",
            height: "80px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "80px",
              height: "80px",
            }}
          >
           {
            isHeightAbove610 ? <CardMedia component="img" image={modalxIcon} alt="loading" /> : null
           }  
            {/* <CardMedia component="img" image={modalxIcon} alt="loading" /> */}
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: 600,
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            Hi {name} <br /> How can I assist you today?
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default GetModalXFlowDiagramForMobileResponsive;
