import React from "react";
import "./CSS/BlogArticle.css";
import { Box, Typography, CardMedia, Card, Button } from "@mui/material";
import log from "../assets/PresentationGallery/ModalxLogo.png";
import image from "../assets/PresentationGallery/blog1.png";
import PageLayout from "../components/navFoot/PageLayout";
import RequestDemoNearFooter from "../components/LandingPage/RequestDemoNearFooter";

function BlogArticle() {
  return (
    <>
      <PageLayout>
        {/* section 1 */}

        <Box
          sx={{
            width: "100%",
            marginTop: "226px",
            gap: "48px",
            opacity: "0px",
            '@media (max-width : 991px)' :{
              marginTop: "156px",
            }
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "24px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontWeight: "600px",
                lineHeight: "24px",
                textAlign: "center",
                color: "rgba(246, 190, 107, 1)",
                "@media(max-width : 991px)": {
                  fontSize: "12px",
                },
              }}
            >
              Blog
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              marginTop: "48px",
              gap: "28px",
              opacity: "0px",
            }}
          >
            {" "}
            <Typography
              sx={{
                // width: "50%",
                fontFamily: "Inter",
                fontWeight: "500px",
                fontSize: "48px",
                lineHeight: "57.6px",
                textAlign: "center",
                letterSpacing: "-0.32px",
                color: "rgba(255, 255, 255, 1)",
                margin: "auto",
                "@media(max-width : 991px)": {
                  textAlign: "center",
                  fontSize: "28px",
                  padding: "0px, 2.2% 0px 2.2%",
                },
              }}
            >
              Unleashing Tomorrow’s Potential: Multimodal AI
            </Typography>
          </Box>
        </Box>

        {/* section 1 */}

        {/* section 2 */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "691px",
              marginTop: "60px",
              gap: "160px",
              opacity: "0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "#FFFFFF",
              opacity: "80%",
              "@media (max-width : 991px )": {
                width: "350px",
                gap: "0px",
              },
            }}
          >
            <Box
              sx={{
                width: "122px",
                gap: "12px",
                opacity: "0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "@media (max-width : 991px)": {
                  width: "97px",
                },
              }}
            >
              <Box
                sx={{
                  width: "40px",
                  gap: "10px",
                  padding: "10px",
                  bgcolor : 'green',
                  backgroundColor: "rgba(40, 45, 81, 1)",
                  borderRadius: "20px",
                }}
              >
                <Box
                  sx={{
                    width: "20px",
                    height: "20px",
                  }}
                >
                  <CardMedia
                    sx={{
                      backgroundColor: "rgba(40, 45, 81, 1)",
                    }}
                    component="img"
                    image={log}
                    alt="log"
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  // width: "70px",
                  height: "24px",
                }}
              >
                {" "}
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontWeight: "400px",
                    fontSize: "18px",
                    lineHeight: "24px",
                    textAlign: "center",
                    letterSpacing: "-.32px",
                    color: "rgba(255, 255, 255, 0.8)",
                    "@media (max-width : 991px)": {
                      fontSize: "16px",
                    },
                  }}
                >
                  ModalX
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                // width: "127px",
                height: "24px",
                // gap : '12px',
                opacity: "0px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: "400px",
                  fontSize: "18px",
                  lineHeight: "24px",
                  textAlign: "center",
                  letterSpacing: "-.32px",
                  color: "rgba(255, 255, 255, 0.8)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                July 27, 2024
              </Typography>
            </Box>

            <Box
              sx={{
                // width: "127px",
                height: "24px",
                // gap : '12px',
                opacity: "0px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: "400px",
                  fontSize: "18px",
                  lineHeight: "24px",
                  textAlign: "center",
                  letterSpacing: "-.32px",
                  color: "rgba(255, 255, 255, 0.8)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                3 minute read
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* section 2 */}

        {/* section 3 */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              maxWidth: "100%",
              margin: "80px 20px 0px 20px",
            }}
          >
            <Card>
              <CardMedia sx={{}} component="img" image={image} alt="log" />
            </Card>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "155px",
              height: "24px",
              marginTop: "20px",
              opacity: "0px",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "400px",
                lineHeight: "24px",
                letterSpacing: "-0.32",
                textAlign: "center",
                color: "rgba(255, 255, 255, 0.8)",
              }}
            >
              Made by ModalX
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              marginTop: "64px",
              gap: "0px",
              opacity: "0px",
              "@media(max-width : 901px)": {
                width: "100%",
              },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "20px",
                fontWeight: "400px",
                lineHeight: "36px",
                letterSpacing: "-0.32",
                textAlign: "left",
                padding: "0px 4.4% 0px 4.4%",
                color: "rgba(255, 255, 255, 0.8)",
                "@media(max-width : 901px)": {
                  fontSize: "16px",
                },
              }}
            >
              Imagine asking your AI assistant to draft an email, create a
              presentation, compose a jingle, and design a logo—all in one go!
              It sounds like you're setting up an extreme obstacle course for
              your AI, right? Welcome to the world of multimodal generative AI,
              where such requests don’t drive your AI to an existential crisis
              but rather showcase its superpowers! <br />
              <br />
              Remember the legendary King Midas who turned everything he touched
              into gold? He would be green with envy over modern multimodal AI.
              Today’s AI doesn’t turn stuff into gold, but it pretty much does
              everything else—from text to speech, images to videos, all in one
              breath.
            </Typography>
          </Box>
        </Box>

        {/* section 3 */}

        <Box
          sx={{
            marginTop: "100px",
          }}
        >
          <RequestDemoNearFooter />
        </Box>
      </PageLayout>
    </>
  );
}

export default BlogArticle;
