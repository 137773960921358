import React from "react";
import PageLayout from "../components/navFoot/PageLayout";

const Privacy = () => {
  return (
    <PageLayout>
      <h1 className="mb-4">Privacy Policy</h1>
      <h2 className="pt-3 pb-2 border-top">Introduction</h2>
      <p style={{ color: "gray" }}>
        Welcome to the Privacy Policy for Modal X, provided by Brightspot. This
        Privacy Policy outlines how we collect, use, and protect your personal
        information when you access or use Modal X. By using Modal X, you agree
        to the terms outlined in this Privacy Policy.
      </p>
      <h2 className="pt-3 pb-2 border-top">
        Collection and Use of Information
      </h2>
      <p style={{ color: "gray" }}>
        Modal X employs encryption techniques to securely store user passwords
        and ensure the protection of user accounts. All communication between
        users and our servers is encrypted to safeguard data integrity and
        confidentiality.
      </p>
      <p style={{ color: "gray" }}>
        Any files or data uploaded to Modal X are kept secure and are deleted in
        a timely manner when no longer needed. We do not share user information
        with any third parties without explicit consent.
      </p>
      <h2 className="pt-3 pb-2 border-top">User Consent</h2>
      <p style={{ color: "gray" }}>
        We respect your privacy and will not disclose your personal information
        to any third parties without your consent, except as required by law or
        as necessary to provide the services offered by Modal X.
      </p>
      <h2 className="pt-3 pb-2 border-top">Security Measures</h2>
      <p style={{ color: "gray" }}>
        Modal X implements robust security measures to protect your personal
        information from unauthorized access, disclosure, alteration, or
        destruction. We continuously monitor and update our systems to maintain
        the highest standards of security.
      </p>
      <h2 className="pt-3 pb-2 border-top">Children's Privacy</h2>
      <p style={{ color: "gray" }}>
        Modal X is not intended for use by children under the age of 13. We do
        not knowingly collect personal information from children under 13 years
        of age. If you are a parent or guardian and believe that your child has
        provided us with personal information, please contact us so that we can
        take appropriate action.
      </p>
      <h2 className="pt-3 pb-2 border-top">Changes to Privacy Policy</h2>
      <p style={{ color: "gray" }}>
        Brightspot reserves the right to update or modify this Privacy Policy at
        any time without prior notice. Any changes will be effective immediately
        upon posting the revised Privacy Policy on Modal X. Your continued use
        of Modal X after any such changes constitutes your acceptance of the new
        Privacy Policy.
      </p>
      <h2 className="pt-3 pb-2 border-top">Contact Information</h2>
      <p style={{ color: "gray" }}>
        If you have any questions or concerns about our Privacy Policy, please
        contact us at{" "}
        <a href="mailto:privacy@getbrightspot.com">privacy@getbrightspot.com</a>
        .
      </p>
    </PageLayout>
  );
};

export default Privacy;
