import React from "react";
import PageLayout from "../components/navFoot/PageLayout";
import { Box, Typography, Card, CardMedia, Grid, Button, useMediaQuery } from "@mui/material";
// import bolgImage1 from "../assets/BlogImage/blog1.png";
// import bolgImage2 from "../assets/BlogImage/2.png";
// import bolgImage3 from "../assets/BlogImage/blogImage3.png";
// import bolgImage4 from "../assets/BlogImage/4.png";
import blog01 from "../assets/BlogImage/blog01.png";
import blog02 from "../assets/BlogImage/blog02.jpg";
import blog03 from "../assets/BlogImage/blog03.png";
import blog04 from "../assets/BlogImage/blog04.png";
import blog05 from "../assets/BlogImage/blog05.png";
import blog06 from "../assets/BlogImage/blog06.png";
import blog07 from "../assets/BlogImage/blog07.png";
import blog08 from "../assets/BlogImage/blog08.png";
import blog09 from "../assets/BlogImage/blog09.png";

import RequestDemoNearFooter from "../components/LandingPage/RequestDemoNearFooter";

// const data = [
//   {
//     image: bolgImage1,
//     id: 1,
//     title1: "Unleashing Tomorrow’s Potential: Multimodal AI",
//     title2: "July 27, 2024",
//     title3: "3 min read",
//   },
//   {
//     image: bolgImage2,
//     id: 2,
//     title1: "Top AI Trends in 2024: Where Corporate Magic Begins",
//     title2: "July 24, 2024",
//     title3: "4 min read",
//   },
//   {
//     image: bolgImage3,
//     id: 3,
//     title1: "From Nerdy Novels to Everyday Marvels",
//     title2: "July 20, 2024",
//     title3: "4 min read",
//   },
//   {
//     image: bolgImage4,
//     id: 4,
//     title1: "Clocked In, Kicked Back: AI at Work",
//     title2: "July 17, 2024",
//     title3: "3 min read",
//   },
// ];

const BlogData = [
  {
    id: 1,
    image: blog01,
    title1: "4 Multimodal AI Tips to Level Up Your Social Media Game",
    title2: "Published",
    title3: "Aug 28, 2024",
    link: "https://www.linkedin.com/pulse/4-multimodal-ai-tips-level-up-your-social-media-game-modalx-hhq3e/?trackingId=Mb0DBaf9Rf2kVI7PjwSIKw%3D%3D",
  },
  {
    id: 2,
    image: blog02,
    title1:
      "Dynamic Duo: The Fusion of IoT and Multimodal AI for Your Smart Future",
    title2: "Published",
    title3: "Aug 24, 2024",
    link: "https://www.linkedin.com/pulse/dynamic-duo-fusion-iot-multimodal-ai-your-smart-future-modalx-agfee/?trackingId=8zKZAmpzRH2psz6OzSKYbg%3D%3D",
  },
  {
    id: 3,
    image: blog03,
    title1: `Next-Gen Olympics: AI's Impact on the 2024 Paris Games`,
    title2: "Published",
    title3: "Aug 15, 2024",
    link: "https://www.linkedin.com/pulse/next-gen-olympics-ais-impact-2024-paris-games-modalx-50mff/?trackingId=%2BGqtANMJR063hKEyB2y9jQ%3D%3D",
  },
  {
    id: 4,
    image: blog04,
    title1:
      "Multimodal AI for Competitive Advantage: Strategies for Business Leaders",
    title2: "Published",
    title3: "Aug 7, 2024",
    link: "https://www.linkedin.com/pulse/multimodal-ai-competitive-advantage-strategies-business-leaders-50xne/?trackingId=qpxmmJEYTzWD%2FoqPPDNbJA%3D%3D",
  },
  {
    id: 5,
    image: blog05,
    title1: `Meta's AI Team Drops SAM 2: The Sequel That's Slicing and Dicing Videos`,
    title2: "Published",
    title3: "Jul 31, 2024",
    link: "https://www.linkedin.com/pulse/metas-ai-team-drops-sam-2-sequel-thats-slicing-dicing-videos-modalx-weq1e/?trackingId=HJjQf6IZSL%2BenwD0d3WDUQ%3D%3D",
  },
  {
    id: 6,
    image: blog06,
    title1: "Unleashing Tomorrow’s Potential - Multimodal AI",
    title2: "Published",
    title3: "Jul 27, 2024",
    link: "https://www.linkedin.com/pulse/unleashing-tomorrows-potential-multimodal-ai-modalx-osh1e/?trackingId=csseRN4ITaGWaFpuuURWow%3D%3D",
  },
  {
    id: 7,
    image: blog07,
    title1: "Top AI Trends in 2024: Where Corporate Magic Begins",
    title2: "Published",
    title3: "Jul 23, 2024",
    link: "https://www.linkedin.com/pulse/top-ai-trends-2024-where-corporate-magic-begins-modalx-t6abc/?trackingId=6dwz%2FrqGTViheZG6mTwwkQ%3D%3D",
  },
  {
    id: 8,
    image: blog08,
    title1: "From Nerdy Novels to Everyday Marvels",
    title2: "Published",
    title3: "Jul 19, 2024",
    link: "https://www.linkedin.com/pulse/from-nerdy-novels-everyday-marvels-modalx-x6bhe/?trackingId=VwkATbAVTuipxXqYpnCe1g%3D%3D",
  },
  {
    id: 9,
    image: blog09,
    title1: "Clocked In, Kicked Back: AI at Work",
    title2: "Published",
    title3: "Jul 16, 2024",
    link: "https://www.linkedin.com/pulse/clocked-kicked-back-ai-work-modalx-rqspe/?trackingId=mojbDzcUTxidgjPeaBCApA%3D%3D",
  },
];

function Blog() {
  
  const handleRedirect = (link) => {
    window.open(link, "_blank"); // Opens the link in a new tab
    
  };
  const isMobile = useMediaQuery('(max-width:991px)');
  return (
    <PageLayout>
      {/* section 1 */}

      <Box
        sx={{
          marginTop: "206px",
          width: "100%",
          maxHeight: "600px",
          "@media (max-width : 991px)": {
            marginTop: "156px",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "24px",
            opacity: "0px",
            margin: "auto",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: "600px",
              lineHeight: "24px",
              textAlign: "center",
              color: "rgba(246, 190, 107, 1)",
            }}
          >
            Blog
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "134px",
            marginTop: "72px",
            "@media (max-width : 991px)": {
              marginTop: "18px",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "58px",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "48px",
                fontWeight: "500px",
                lineHeight: "57.6px",
                letterSpacing: "-0.3px",
                color: "rgba(255, 255, 255, 1)",
                textAlign: "center",
                padding: "0px 2.5% 0px 2.5%",
                "@media (max-width: 991px)": {
                  fontSize: "28px",
                },
              }}
            >
              Elevate with ModalX
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              maxHeight: "48px",
              margin: "auto",
              marginTop: "28px",
              "@media (max-width : 991px)": {
                marginTop: "16px",
              },
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Inter",
                fontSize: "18px",
                fontWeight: "500px",
                lineHeight: "24px",
                letterSpacing: "0.1px",
                padding: "0px 2.5% 0px 2.5%",
                color: "rgba(255, 255, 255, 0.8)",
                "@media (max-width: 991px)": {
                  fontSize: "14px",
                },
              }}
            >
              Your go-to newsletter for AI breakthroughs and industry trends,
              crafted to keep you informed and inspired.
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* section 2 */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          // marginTop: "74px",
          // bgcolor: "red",
          // padding : '0px 2.5% 0px 2.5%',
          borderRadius: "12px ",
          "@media (max-width: 991px)": {
            marginTop: "14px",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            minHeight: "987px",
            display: "flex",
            flexWrap: "wrap", // Ensures that the items wrap to the next line
            justifyContent: "space-evenly",
            "@media (max-width : 991px)": {
              display: "grid",
              gridTemplateColumns: "repeat(1 , 1fr)",
              margin: "auto",
              justifyContent: "center",
            },
          }}
        >
          {BlogData.map((e) => (
            <Grid

              onClick={() => handleRedirect(e.link)}
              sx={{
                
              }}
              item
              key={e.id}
            >
              <Box
                sx={{
                  margin: "auto",
                  marginTop: "60px",
                  cursor: 'pointer',
                  '&:hover': {
                    
                   
                  },
                  
                }}
              >
                <Box
                  sx={{
                    margin: "auto",
                    maxWidth: "384px",
                    maxHeight: "384px",

                    "@media (max-width : 991px)": {
                      margin: "auto",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    image={e.image}
                    alt="Example"
                    sx={{
                      width: isMobile ?  "100%" : '384px',
                      height: isMobile ?  "100%" : '384px',
                      "@media (max-width : 991px)": {
                        padding: "0px 5% 0px 5%",

                      },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    maxWidth: "384px",
                    position: "relative",
                    top: "20px",
                    margin: "auto",
                    "@media (max-width : 991px)": {
                      margin: "auto",
                      maxWidth: "100%",
                      minHeight: "70px",
                      padding: "0px 5% 0px 5%",
                    },
                  }}
                >
                  <Box sx={{}}>
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontWeight: "500px",
                        fontSize: "16px",
                        lineHeight: "20.8px",
                        textAlign: "left",
                        color: "rgba(255, 255, 255, 1)",
                      }}
                    >
                      {e.title1}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "155px",
                      height: "16px",
                      // position: "absolute",
                      // top: "33px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        width: "80px",
                        height: "16px",
                        marginTop: "12px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 400,
                          lineHeight: "15.6px",
                          textAlign: "left",
                          color: "rgba(255, 255, 255, 0.8)",
                        }}
                      >
                        {e.title2}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        minWidth: "90px",
                        height: "16px",
                        marginTop: "12px",

                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: "400px",
                          lineHeight: "15.6px",
                          textAlign: "left",
                          color: "rgba(255, 255, 255, 0.8)",
                        }}
                      >
                        {e.title3}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: "188px",
        }}
      >
        <RequestDemoNearFooter />
      </Box>
    </PageLayout>
  );
}

export default Blog;
