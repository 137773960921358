import React, { useState } from "react";
import { Box, Button, Grid, Typography, IconButton } from "@mui/material";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";

const VideoResolution = () => {
    const {minDimensionPixels, setMinDImensionPixels, selectedAspectRatioForVideoGen, setSelectedAspectRatioForVideoGen} = useWorkspace()
    const theme = localStorage.getItem("theme");
  
    const handleVideoRatio = (ratio) => {
      setSelectedAspectRatioForVideoGen(ratio)
      switch (ratio){
        case "1080p":
          setMinDImensionPixels(1080);
          break;
        case "720p":
          setMinDImensionPixels(720);
          break;
        case "480p":
          setMinDImensionPixels(480);
          break;
        case "360p":
          setMinDImensionPixels(360);
          break;
        case "240p":
          setMinDImensionPixels(240);
          break;
        case "144p":
          setMinDImensionPixels(144);
          break;
        default:
          setMinDImensionPixels(1080);
          break; // Default to 1080p if none of the options are selected.
      }
    };
  
    const VideoRation = ["1080p", "720p", "480p", "360p", "240p", "144p"];
  return (
    <>
    <Box>
      <Box sx={{ p: 2 }}>
        <Typography
          variant="h6"
          gutterBottom
          style={{
            fontSize: "16px",
            fontWeight: "500",
            height: "20px",
            marginBottom: "20px",
          }}
        >
          Select Video Resolution
        </Typography>
        <Grid container spacing={1} justifyContent="start">
          {VideoRation.map((videoratio) => (
            <Grid item key={videoratio}>
              <Button
                variant="outlined"
                onClick={() => handleVideoRatio(videoratio)}
                sx={{
                  // width: "54px",
                  // height: "32px",
                  textTransform:"none",
                  backgroundColor:
                  selectedAspectRatioForVideoGen === videoratio ? "#3a3f69" : "#30355f",
                  // backgroundColor: theme === 'dark'? '#30355f' : '',
                  color: theme === "dark" ? "white" : "black",
                  "&:hover": {
                    backgroundColor:
                    selectedAspectRatioForVideoGen === videoratio ? "#3a3f69" : "",
                  },
                  borderColor:
                  selectedAspectRatioForVideoGen === videoratio
                      ? "#cac4d0"
                      : "transparent",
                  mx: 0.5, // Add horizontal margin for spacing
                }}
              >
                {videoratio}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  </>
  )
}

export default VideoResolution