import React, { useState } from 'react';
import { Box, Button, IconButton, useMediaQuery } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { New_Project_Button_bg_color, SelectedChat_bg_color } from '../../Provider/Color_Provider';
import Image_1 from "../../assets/LandingPage_Items/1_image.png";
import Image_2 from "../../assets/LandingPage_Items/2_Video.png";
import Image_3 from "../../assets/LandingPage_Items/3_audio.png";
import Image_4 from "../../assets/LandingPage_Items/4_Presentation.png";
import Image_5 from "../../assets/LandingPage_Items/5_Blog.png";
import { button_bg_color_for_New_Project_dm, hovered_chat_bg_color_dm, selected_chat_bg_color_dm } from '../../constants/colors';
import { useNavigate } from 'react-router-dom';

const Features = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalBoxes = 5; // Total number of boxes in the carousel
  const visibleBoxes = 2.5; // Number of boxes visible at once
  const boxWidth = 547; // Width of each box in pixels (including margin)
  const isBelow991 = useMediaQuery("(max-width:991px)");

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < totalBoxes - visibleBoxes) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };
   
  const navigate = useNavigate()
  const handleImageGallery = () =>{
    window.scrollTo(0, 0);
    navigate('/imagegallery')
  }
  const handleVideoGallery = () =>{
    window.scrollTo(0, 0);
    navigate('/videogallery')
    
  }
  const handlePresentationGallery = () =>{
    window.scrollTo(0, 0);
    navigate('/presentationgallery')
    
  }

  const handleSpeechGallery = () =>{
    window.scrollTo(0, 0);
    navigate('/audiogallery')
    
  }

  const handleBlogGallery = () =>{
    window.scrollTo(0, 0);
    navigate('/blog');
    
  }

  const features = [
    {
      id: '1',
      title: 'Image Generation',
      description: 'Generate copyright-free images that effectively communicate your ideas.',
      image: Image_1,
      handleRoute : handleImageGallery,
    },
    {
      id: '2',
      title: 'Video Generation',
      description: 'Generate video at scale for diverse audiences.',
      image: Image_2,
      handleRoute : handleVideoGallery,
    },
    {
      id: '3',
      title: 'Speech Generation',
      description: 'Generate realistic speech in multiple languages seamlessly.',
      image: Image_3,
      handleRoute : handleSpeechGallery,
    },
    {
      id: '4',
      title: 'Presentation Generation',
      description: 'Generate impactful presentations that deliver value.',
      image: Image_4,
      handleRoute : handlePresentationGallery,
    },
    {
      id: '5',
      title: 'Blog Generation',
      description: 'Generate engaging blogs that deliver value.',
      image: Image_5,
      handleRoute : handleBlogGallery,
    }
  ];

  return (
    <div>
      <p style={{ textAlign: 'center', color: '#F6BE6B',fontSize: isBelow991?'12px':"16px" }}>Features</p>
      <div style={{ maxWidth: '1072px', margin: 'auto', textAlign: 'center' }}>
        <p style={{ fontSize: isBelow991?'28px':'48px', marginTop: isBelow991?'24px':'-0px' }}>
          The AI toolkit for every business need
        </p>
      </div>
      <Box display="flex" alignItems="center" justifyContent="center" mt={4} sx={{ marginTop: isBelow991?'100px':'138px' }}>
        <Box
          overflow="hidden"
          width={`${visibleBoxes * boxWidth}px`}
          display="flex"
          position="relative"
        >
          <Box
            display="flex"
            transition="transform 0.5s ease"
            style={{
              transform: `translateX(-${currentIndex * boxWidth}px)`
            }}
          >
            {features.map((feature, index) => (
              
              <Box
                key={feature.id}
                width={isBelow991?'358px':"547px"}
                height={isBelow991?'356px':"447px"}
                bgcolor={selected_chat_bg_color_dm}
                borderRadius="8px"
                marginRight="30px"
                boxShadow={2}
                flexShrink={0}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection='column'
                p={2}
                sx={{
                  "&:hover": {
                  backgroundColor: "#282D51CC",
                  cursor: "pointer",
                },

                }}


              >
                <div style={{ textAlign: 'start', width: isBelow991?'318px':'480px' }}>
                  <p style={{ fontSize: isBelow991?'20px':'24px', marginBottom: '16px' }}>{feature.title}</p>
                  <p style={{ marginBottom: isBelow991?'14px':'16px' }}>{feature.description}</p>
                  <img src={feature.image} alt={feature.title} style={{ width: '100%', borderRadius: '8px' }} />
                </div>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  width="100%"
                  pr={2}
                  mt={2}
                >
                  <Button 
                    onClick={feature.handleRoute}
                    
                    sx={{textTransform:'none',
                      fontSize:isBelow991?'14px':'16px',
                      color:'#17182C',
                      bgcolor:button_bg_color_for_New_Project_dm,
                      width:isBelow991?'109px':'145px',
                      height:'40px',
                      fontWeight:'600',
                      '&:hover': {
                        backgroundColor: '#ffd9aa',
                      },}}
                  >
                    View Gallery
                  </Button>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" sx={{maxWidth:'112px',height:'48px',display:'flex',justifyContent:'space-between',margin:'48px auto'}}>
          <IconButton
            onClick={handlePrevClick}
            disabled={currentIndex === 0}
            disableRipple
            sx={{
              bgcolor: '#1F233E',
              color: 'white', // Set the icon color
              '&:hover': {
                backgroundColor: '#1B1E36',
              },
              '&:disabled': {
                bgcolor: '#1F233E', // Maintain the same background color when disabled
                color: 'grey', // Change color to indicate disabled state
              },
              width:'48px',
              height:'49px'
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
          <IconButton
            onClick={handleNextClick}
            disabled={currentIndex >= totalBoxes - visibleBoxes}
            disableRipple
            sx={{
              bgcolor: '#1F233E',
              color: 'white', // Set the icon color
              '&:hover': {
                backgroundColor: '#1B1E36',
              },
              '&:disabled': {
                bgcolor: '#1F233E', // Maintain the same background color when disabled
                color: 'grey', // Change color to indicate disabled state
              },
              width:'48px',
              height:'49px'
            }}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Box>

    </div>
  );
};

export default Features;


