// import { Button } from '@mui/material'
// import React from 'react'
// import { LandingPage_MainTitleText_FontColor } from '../Provider/Color_Provider'
// import VideoPreview from "../assets/ModalX_Video_Preview.mp4";
// import LandingPage_Logos from "../assets/LandingPage_Logos.png";
// import { selected_chat_bg_color, selected_chat_bg_color_dm } from '../constants/colors';
// import ModalX_Advantages from './LandingPage/ModalX_Advantages';
// import HowModalX_Works from './LandingPage/HowModalX_Works';
// import Features from './LandingPage/Features';
// import ModalXInAction from './LandingPage/ModalXInAction';
// import Testimonials from './LandingPage/Testimonials';
// import CaseStudies from './LandingPage/CaseStudies';

// const LandingPageContents = () => {
//     const theme = localStorage.getItem('theme')
//   return (
//     <div>
//         <div style={{maxWidth:'',fontSize:'80px',textAlign:'center',color:theme === 'dark'?'#F6BE6B':'',marginTop:'166px'}}>
//             <p style={{}}>
//                 Business Growth,
//             </p>
//             <p >
//                 Without the Headaches
//             </p>
//         </div>
//         <p style={{margin:'auto',textAlign:'center',fontSize:'18px',color:'#FFFFFF'}}>Your AI-powered Growth Engine, Made Simple</p>
//         <div style={{textAlign:'center',width:'370px',display:'flex',justifyContent:'space-between',margin:'auto',marginTop:'30px'}}>
//             <Button style={{width:'161px',height:'40px',border:`1px solid #F6BE6A`,color:'#F6BE6A'}} sx={{textTransform:'none'}}>Request demo</Button>
//             <Button style={{width:'173px',height:'40px',backgroundColor:'#F6BE6A',color:'#17182C',fontWeight:'600'}} sx={{textTransform:'none'}}>Get started free</Button>
//         </div>
//         <div style={{maxWidth:'1200px',maxHeight:'675px',margin:'120px auto 0 auto',borderRadius:'10px',overflow:'hidden'}}>
//             <video
//                 id="introVideo"
//                 width="100%"
//                 controls
//                 autoPlay
//             >
//                 <source src={VideoPreview} type="video/mp4" />
//                 Your browser does not support the video tag.
//             </video>
//         </div>
//         <div style={{height:'210px',margin:'120px auto',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',backgroundColor:'#1d213c'}}>
//             <p style={{textAlign:'center',fontSize:'20px'}}>Be among the first to experience our innovative solutions designed to drive success and creativity</p>
//             <div style={{overflow:'hidden'}}>
//                 <img src={LandingPage_Logos} style={{overflow:'hidden'}}/>
//             </div>
//         </div>
//         <ModalX_Advantages />
//         <HowModalX_Works />
//         <Features />
//         <ModalXInAction />
//         <Testimonials />
//         <CaseStudies />
//     </div>
//   )
// }

// export default LandingPageContents

import React ,{useState,useEffect}from "react";
import { Box, Button, Typography,useMediaQuery } from "@mui/material";
// import VideoPreview from "../assets/ModalX_Video_Preview.mp4";
// import LandingPage_Video from "../assets/ModalX_landingPage_Video.mp4";
import LandingPage_Video from "../assets/UpdatedModalxLandingPageVideo.mp4";
import LandingPage_Logos from "../assets/LandingPage_Logos.png";
import ModalX_Advantages from "./LandingPage/ModalX_Advantages";
import Features from "./LandingPage/Features";
import ModalXInAction from "./LandingPage/ModalXInAction";
import Testimonials from "./LandingPage/Testimonials";
import CaseStudies from "./LandingPage/CaseStudies";
import Capabilities from "./LandingPage/Capabilities";
import RequestDemoNearFooter from "./LandingPage/RequestDemoNearFooter";
import HowItWorks from "./LandingPage/HowItWorks";
import Logo_1 from "../assets/LandingPage_Items/Logo_1.png";
import Logo_2 from "../assets/LandingPage_Items/Logo_2.png";
import Logo_3 from "../assets/LandingPage_Items/Logo_3.png";
import Logo_4 from "../assets/LandingPage_Items/Logo_4.png";
import Logo_5 from "../assets/LandingPage_Items/Logo_5.png";
import Logo_6 from "../assets/LandingPage_Items/Logo_6.png";
import Logo_7 from "../assets/LandingPage_Items/Logo_7.png";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { ButtonClick  } from "../config/analytics";


const LandingPageContents = () => {
  const theme = localStorage.getItem("theme");
  const isBelow991 = useMediaQuery("(max-width:991px)");
  const logos = [Logo_1, Logo_2, Logo_3, Logo_4, Logo_5, Logo_6, Logo_7];
  
  const handleRedirect = (link) => {
    window.open(link, '_blank'); // Opens the link in a new tab
  };
  const {setShowSignUpModal,showSignUpModal,showLoginModal, setMenuOpen} =useAuth()
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const navigate = useNavigate();
  const handleGetStarted = () =>{

    if (isLoggedIn){
      navigate('/workspace') 
    }
    else {
      setMenuOpen(true)
      setShowSignUpModal(true) 

    }   
  }

  
  return (
    <Box>
      <Box
        sx={{
          fontSize: "80px",
          textAlign: "center",
          color: theme === "dark" ? "#F6BE6B" : "",
          marginTop: isBelow991?'73px':"166px",
          background:
            "linear-gradient(90deg, #F6BE6B 0%, rgba(246, 190, 107, 0.60) 100%)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text", 
          WebkitTextFillColor: "transparent", 
        }}
      >
        <Typography variant="h1" component="p" sx={{fontSize:isBelow991?'33px':'80px'}}>
          Business Growth,
        </Typography>
        <Typography variant="h1" component="p" sx={{fontSize:isBelow991?'33px':'80px'}}>
          Without the Headaches
        </Typography>
      </Box>
      <Typography
        sx={{
          margin: "27px auto",
          textAlign: "center",
          fontSize: isBelow991?'14px':"18px",
          color: "rgba(255,255,255,0.8)",
        }}
      >
        Your AI-powered Growth Engine, Made Simple
      </Typography>
      <Box
        sx={{
          textAlign: "center",
          width: isBelow991?'350px':"370px",
          display: "flex",
          justifyContent: "space-between",
          margin: "auto",
          marginTop: "30px",
        }}
      >
        <Button  
          // onClick={() => handleRedirect('mailto:noreply@ModalX.ai')}
          onClick={() =>{  ButtonClick("Request Demo Button Click", "Request Demo");
          window.open('https://calendly.com/makarand-modalx', '_blank')}}
          variant="outlined"
          sx={{
            width: "161px",
            height: "40px",
            borderColor: "#F6BE6A",
            color: "#F6BE6A",
            textTransform: "none",
            fontSize: isBelow991?'14px':"16px",
            "&:hover": {
              borderColor: "#F6BE6A",
              backgroundColor: "transparent", // No border color changes on hover
            },
            fontFamily: "Inter, sans-serif",
          }}
        >
          Request demo
        </Button>
        <Button  
        onClick={handleGetStarted}
       
          variant="contained"
          sx={{
            width: "173px",
            height: "40px",
            backgroundColor: "#F6BE6A",
            color: "#17182C",
            fontWeight: "600",
            textTransform: "none",
            fontSize: isBelow991?'14px':"16px",
            "&:hover": {
              backgroundColor: "#ffd9aa", // Changes background color on hover
            },
            fontFamily: "Inter, sans-serif",
          }}
        >
          {isLoggedIn?"Go to warroom":"Get started free"}
        </Button>
      </Box>
      <Box
        sx={{
          maxWidth: "1200px",
          maxHeight: "675px",
          margin: isBelow991?'48px 16px 0 16px':"120px auto 0 auto",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <video id="introVideo" width="100%" controls >
          <source src={LandingPage_Video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box>
      <Box
        sx={{
          height: "210px",
          margin: isBelow991?'48px auto':"120px auto",
          display: "flex",
          flexDirection: "column",
          // justifyContent: 'center',
          // alignItems: 'center',
          backgroundColor: "#1d213c",
          // bgcolor : 'blue'
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "26px",
            marginTop: "60px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: 400,
              lineHeight: "26px",
              textAlign: "center",
              fontSize: isBelow991?'14px':"20px",
              color: "#FFFFFF",
              opacity: "80%",
            }}
          >
            Be among the first to experience our innovative solutions designed
            to drive success and creativity
          </Typography>
        </Box>

        <Box
          sx={{
            overflow: "hidden",
            width: "100%",
            maxWidth: "100%",
            marginTop: "40px",
          }}
        >
          {isBelow991 ? (
            <Box
              sx={{
                display: "flex",
                // overflow: "hidden",
                padding: "20px",
                whiteSpace: "nowrap",
                animation: "scrollLogos 20s linear infinite",
                "@keyframes scrollLogos": {
                  "0%": { transform: "translateX(100%)" },
                  "100%": { transform: "translateX(-160%)" },
                },
              }}
            >
              {logos.concat(logos).map((logo, index) => (
                <Box
                  key={index}
                  component="img"
                  src={logo}
                  alt={`Logo ${index + 1}`}
                  sx={{
                    height: '25px',
                    margin: '0 10px',
                    display: "inline-block",
                  }}
                />
              ))}
            </Box>
          ) : (
            <img
              src={LandingPage_Logos}
              alt="Landing Page Logos"
              style={{ width: "100%", height: "auto", display: "block" }}
            />
          )}
        </Box>
      </Box>
      <ModalXInAction />
      <CaseStudies />
      <ModalX_Advantages />
      <HowItWorks />
      <Capabilities />
      <Features />
      <Testimonials />
      <RequestDemoNearFooter />

    </Box>
  );
};

export default LandingPageContents;
