import React, { useEffect } from "react";
import { Modal, Alert } from "react-bootstrap";

const Notification = ({ showNotif, setShowNotif, variant, children }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNotif(false);
    }, 3000);

    return () => clearTimeout(timer);
  });

  const handleClose = () => setShowNotif(false);

  return (
    <Modal show={showNotif} onHide={handleClose} className="p-0 m-0">
      <Modal.Body onClick={handleClose} className="p-0 m-0">
        <Alert variant={variant} className="m-0">
          {children}
        </Alert>
      </Modal.Body>
    </Modal>
  );
};

export default Notification;
