import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import Notification from "../Notification";

import { auth, createUserWithEmailAndPassword, sendEmailVerification } from "../../config/firebase.config";
import SignUp from "./Signup";
import Login from "./Login";
import NavigationBar from "./NavigationBar";
import LandingPageContents from "../LandingPageContents";
function SignUpLoginForTesting() {
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [emailSent,setEmailSent] = useState(false);
  return (
    <> 
      {/* <Button
        variant="primary"
        className="me-2 mb-2"
        onClick={() => setShowSignUpModal(true)} // Show signup modal
      >
        Sign up
      </Button>
      <Button
        variant="secondary"
        className="mb-2 me-2"
        onClick={() => setShowLoginModal(true)} // Show login modal
      >
        Login
      </Button> */}
      <NavigationBar />
      <SignUp
        showSignUpModal={showSignUpModal}
        setShowSignUpModal={setShowSignUpModal}
        setShowLoginModal={setShowLoginModal}
        showLoginModal={showLoginModal}
        setEmailSent={setEmailSent}
      />
      <Login
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        setShowSignUpModal={setShowSignUpModal}
        emailSent={emailSent}
      />
      
      
    </>
  );
}

export default SignUpLoginForTesting;
