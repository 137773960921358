export const disposableEmailProviders = [
  "10minutemail.com",
  "20minutemail.com",
  "anonymbox.com",
  "beefmilk.com",
  "binkmail.com",
  "bobmail.info",
  "bootmail.com",
  "boun.cr",
  "burnermail.io",
  "byom.de",
  "chammy.info",
  "chogmail.com",
  "dayrep.com",
  "deadaddress.com",
  "despam.it",
  "discard.email",
  "discardmail.com",
  "disposableemailaddresses.com",
  "dispostable.com",
  "divermail.com",
  "dump-email.info",
  "email60.com",
  "emailigo.de",
  "emailondeck.com",
  "emailtemporanee.com",
  "emailwarden.com",
  "fakeinbox.com",
  "fake-mail.pp.ua",
  "fakemailgenerator.com",
  "filzmail.com",
  "fleckens.hu",
  "frapmail.com",
  "garlicmail.com",
  "gishpuppy.com",
  "guerrillamail.com",
  "guerrillamailblock.com",
  "haltospam.com",
  "harakirimail.com",
  "hochsitze.com",
  "hulapla.de",
  "incognitomail.com",
  "insorg-mail.info",
  "jetable.org",
  "jourrapide.com",
  "kasmail.com",
  "klassmaster.com",
  "klzlk.com",
  "koszmail.pl",
  "kurzepost.de",
  "lackmail.net",
  "letthemeatspam.com",
  "lhsdv.com",
  "lifebyfood.com",
  "link2mail.net",
  "litedrop.com",
  "mail2trash.com",
  "mail7.io",
  "mailandftp.com",
  "mailbox92.com",
  "mailcatch.com",
  "maildrop.cc",
  "maildx.com",
  "mailhazard.com",
  "mailinator.com",
  "mailinator2.com",
  "mailismagic.com",
  "mailjunk.top",
  "mailmoat.com",
  "mailnesia.com",
  "mailnull.com",
  "mailtemp.net",
  "mailtrash.net",
  "mailup.net",
  "mailzi.ru",
  "moakt.com",
  "my10minutemail.com",
  "mytrashmail.com",
  "neomailbox.com",
  "noclickemail.com",
  "nowmymail.com",
  "nullbox.email",
  "oneoffemail.com",
  "pjjkp.com",
  "pookmail.com",
  "proxymail.eu",
  "qube.in",
  "quickinbox.com",
  "rhyta.com",
  "safersignup.de",
  "slopsbox.com",
  "smashmail.de",
  "snkmail.com",
  "sofort-mail.de",
  "squizzy.de",
  "tagyourself.com",
  "temp-mail.io",
  "temp-mail.org",
  "tempail.com",
  "tempinbox.com",
  "tempomail.fr",
  "temporaryemail.net",
  "temporaryinbox.com",
  "tempr.email",
  "trashmail.com",
  "trashmail.de",
  "trashmails.com",
  "trash-me.com",
  "trbvm.com",
  "tryalert.com",
  "twinmail.de",
  "wegwerfmail.de",
  "wegwerfmail.net",
  "wegwerfmail.org",
  "wimsg.com",
  "yepmail.net",
  "yopmail.com",
  "yopmail.net",
  "yopmail.org",
  "zmail.com",
  "zoemail.com",
  // Add more domains as they become known
];
