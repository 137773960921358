// import React from "react";
// import { Container } from "react-bootstrap";
// import NavigationBar from "./NavigationBar";
// import Footer from "./Footer";

// const PageLayout = ({ showLogin, children }) => {
//   return (
//     <>
//       <div
//         style={{ display: "flex", flexDirection: "column", minHeight: "92vh" }} //this div is just to push footer to the bottom, remove if there is enough content on the page
//       >
//         <NavigationBar showLogin={showLogin} />
//         <div className="mb-5"></div>
//         {/* <Container>{children}</Container> */}
//         <div>
//           {children}
//         </div>
//         <div className="mb-5"></div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default PageLayout;


import React from "react";
import { Box } from "@mui/material"; // Import Box from Material-UI
import NavigationBar from "./NavigationBar";
import Footer from "./Footer";

const PageLayout = ({ showLogin, children }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh", // Set minHeight to 100vh to ensure it occupies the full viewport height
          backgroundColor : 'rgba(23, 26, 45, 1)'
        }}
      >
        <NavigationBar showLogin={showLogin} />
        <Box sx={{ flex: 1 }}>{children}</Box> {/* Ensure this grows to fill the available space */}
        <Footer  />
      </Box>
    </>
  );
};

export default PageLayout;
