// import React, { useState } from "react";
// import { Modal, Button, Container, Row, Col, Image } from "react-bootstrap";
// import sampleImage from "../../../../assets/homeSlide6.webp"; // Adjust the import as needed
// import { useWorkspace } from "../../../../contexts/WorkspaceContext";

// const ImageRatios = ['1:1', '16:9', '21:9', '2:3', '3:2', '4:5', '5:4', '9:16', '9:21'];

// const getRatioStyle = (ratio) => {
//   const [widthRatio, heightRatio] = ratio.split(':').map(Number);
//   return {
//     width: '100%',
//     paddingTop: `${(heightRatio / widthRatio) * 100}%`,
//     position: 'relative',
//     overflow: 'hidden'
//   };
// };

// const ImageSettings = () => {
//   const {imageRatio,setImageRatio} = useWorkspace();
//   // const [currRatio, setCurrRatio] = useState('1:1');
//   const [showModal, setShowModal] = useState(false);

//   const handleRatioSelect = (ratio) => {
//     setImageRatio(ratio);
//     // setShowModal(false);
//   };

//   const handleModalToggle = () => {
//     setShowModal(!showModal);
//   };

//   return (
//     <>
//       <Button variant="outline-primary" onClick={handleModalToggle} style={{textAlign:'start'}}>
//         Image Ratio ({imageRatio})
//       </Button>

//       <Modal show={showModal} onHide={handleModalToggle} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>Select Image Ratio</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Container>
//             <Row className="mb-4" style={{display:'grid',gridTemplateColumns:'repeat(5,1fr)'}}>
//               {ImageRatios.map((ratio) => (
//                 <Col key={ratio} xs={6} md={4} className="mb-3" >
//                   <Button
//                     variant="outline-primary"
//                     onClick={() => handleRatioSelect(ratio)}
//                     block
//                     style={{
//                       backgroundColor: imageRatio === ratio ? 'blue' : '',
//                       color: imageRatio === ratio ? 'white' : ''
//                     }}
//                   >
//                     {ratio}
//                   </Button>
//                 </Col>
//               ))}
//             </Row>
//             <div style={getRatioStyle(imageRatio)}>
//               <Image src={sampleImage} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }} />
//             </div>
//           </Container>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleModalToggle} style={{backgroundColor:'blue'}}>
//             Save
//           </Button>
//         </Modal.Footer>
//       </Modal>

      
//     </>
//   );
// };

// export default ImageSettings;

import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import sampleImage from "../../../../assets/homeSlide6.webp"; // Adjust the import as needed

const ImageRatios = ['1:1', '16:9', '21:9', '2:3', '3:2', '4:5', '5:4', '9:16', '9:21'];

const getRatioStyle = (ratio) => {
  const [widthRatio, heightRatio] = ratio.split(':').map(Number);
  
  return {
    width: '100%',
    paddingTop: `${(heightRatio / widthRatio) * 100}%`,
    position: 'relative',
    overflow: 'hidden'
  };
};

const ImageRatio = () => {
  const { imageRatio, setImageRatio } = useWorkspace();
  const theme = localStorage.getItem('theme');
  const handleRatioSelect = (ratio) => {
    setImageRatio(ratio);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom style={{fontSize:'16px',fontWeight:'500',width:'143px',height:'20px',marginBottom:'20px'}}>
        Select Image Ratio
      </Typography>
      <Grid container spacing={1} justifyContent="start">
        {ImageRatios.map((ratio) => (
          <Grid item key={ratio}>
            <Button
              variant="outlined"
              onClick={() => handleRatioSelect(ratio)}
              sx={{
                width: '54px',
                height: '32px',
                backgroundColor: imageRatio === ratio ? '#3a3f69' : '#30355f',
                // backgroundColor: theme === 'dark'? '#30355f' : '',
                color: theme === 'dark' ? 'white' : 'black',
                '&:hover': {
                  backgroundColor: imageRatio === ratio ? '#3a3f69' : '',
                },
                borderColor: imageRatio === ratio ? '#cac4d0' : 'transparent',
                mx: 0.5, // Add horizontal margin for spacing
              }}
            >
              {ratio}
            </Button>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ mt: 2, position: 'relative', width: '100%', height: 'auto', display: 'flex', justifyContent: 'center' }}>
        <Box sx={getRatioStyle(imageRatio)}>
          <img
            src={sampleImage}
            alt="Sample"
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ImageRatio;
