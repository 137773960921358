import axios from "axios";
import React, { useState, useEffect } from "react";
import CurrentUsers from "./CurrentUsers";
import WaitListedUsers from "./WaitListedUsers";
import InviteUser from "./InviteUser";
import "bootstrap/dist/css/bootstrap.min.css";
import sourceUrl from "../../api/Source.api";
import EnableSubscription from "./EnableSubscription";
import Subscription from "./Subscription";

const ClientLandingPage = () => {
  const [selected, setSelected] = useState("currentuser");
  const token = localStorage.getItem("token");
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [users, setUsers] = useState([]);

  const fetchAllUsers = async () => {
    try {
      const res = await axios.get(`${sourceUrl}/admin/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data) {
        setUsers(res.data.data.data);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        setError(true);
        setLoading(false);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  if (!token || !isLoggedIn) {
    return (
      <div className="container text-center my-4">
        <h1>You are not logged in yet</h1>
        <p>Please log in to access the admin page.</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container text-center my-4">
        <h1>Not Authorized to access the admin page.</h1>
      </div>
    );
  }

  const sidebarStyle = {
    paddingTop: "20px",
    borderRight: "1px solid",
  };

  const navLinkStyle = {
    cursor: "pointer",
    display: "block",
    padding: "10px 15px",
  };

  const activeNavLinkStyle = {
    backgroundColor: "#007bff", // Bootstrap primary color
    color: "white",
    width: "100%",
    textAlign: "start",
    borderRadius: "10px",
  };

  return (
    <div>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%" }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div
            style={{
              textAlign: "center",
              padding: "20px",
              borderBottom: "1px solid",
            }}
          >
            <h1>ModalX Admin Space</h1>
          </div>
          <div className="row" style={{ top: "0" }}>
            <div className="col-md-2" style={sidebarStyle}>
              <ul className="nav flex-column">
                <li className="nav-item">
                  <button
                    className="nav-link"
                    style={
                      selected === "currentuser"
                        ? { ...navLinkStyle, ...activeNavLinkStyle }
                        : navLinkStyle
                    }
                    onClick={() => setSelected("currentuser")}
                  >
                    Current Users
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    style={
                      selected === "waitlisted"
                        ? { ...navLinkStyle, ...activeNavLinkStyle }
                        : navLinkStyle
                    }
                    onClick={() => setSelected("waitlisted")}
                  >
                    Waitlisted Requests
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    style={
                      selected === "invite"
                        ? { ...navLinkStyle, ...activeNavLinkStyle }
                        : navLinkStyle
                    }
                    onClick={() => setSelected("invite")}
                  >
                    Invite
                  </button>
                </li>
                {/* <li className="nav-item">
                  <button
                    className="nav-link"
                    style={
                      selected === "enableSubscription"
                        ? { ...navLinkStyle, ...activeNavLinkStyle }
                        : navLinkStyle
                    }
                    onClick={() => setSelected("enableSubscription")}
                  >
                    Grant Excess
                  </button>
                </li> */}
                <li className="nav-item">
                  <button
                    className="nav-link"
                    style={
                      selected === "subscription"
                        ? { ...navLinkStyle, ...activeNavLinkStyle }
                        : navLinkStyle
                    }
                    onClick={() => setSelected("subscription")}
                  >
                    Subscription
                  </button>
                </li>
              </ul>
            </div>
            <div className="col-md-10">
              <div style={{ width: "100%", margin: "auto", padding: "30px" }}>
                {selected === "currentuser" && (
                  <CurrentUsers
                    error={error}
                    setError={setError}
                    users={users}
                    setUser={setUsers}
                    loading={loading}
                  />
                )}
                {selected === "waitlisted" && <WaitListedUsers />}
                {selected === "invite" && <InviteUser />}
                {/* {selected === "enableSubscription" && <EnableSubscription/>} */}
                {selected === "subscription" && <Subscription/>}
                
                
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientLandingPage;
