import React, {useState} from "react";
import { Box, Button, IconButton, keyframes, Typography, useMediaQuery, Divider } from "@mui/material";
import PlayCircleIcon  from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PageLayout from "../components/navFoot/PageLayout";
import RequestDemoNearFooter from "../components/LandingPage/RequestDemoNearFooter";


let buttonList = [
  "All",
  "English",
  "Spanish",
  "French",
  "German",
  "Hindi",
  "Arabic",
  "Portuguese",
  "Mandarin",
  "Cantonese",
  "Korean",
  "Japanese",
];

let audioList = [
  {id:1, title:"Podcast intro for The Book Review Show" ,button1:"English", button2:"echo", disc :"“Generate a cheerful introduction to a podcast about reviewing fiction novels called The Book Review Show”"},
  {id:2, title:"Introduction speech for launch keynote" ,button1:"Spanish", button2:"nova", disc :"“Generate an introduction speech for the new ShareWise product launch keynote in a way that generates excitement”"},
  {id:3, title:"Voice-over for ShareWise promo adw" ,button1:"French", button2:"alloy", disc :"“Generate a 30 second voice-over for a promotional ad for ShareWise for YT shorts/Tiktok/Reels highlighting the key features”"},
  {id:4, title:"Voice-over for ShareWise promo ad" ,button1:"Japanese", button2:"alloy", disc :"“Generate a 30 second voice-over for a promotional ad for ShareWise for YT shorts/Tiktok/Reels highlighting the key features”"},
  {id:5, title:"Voice-over for ShareWise promo ad" ,button1:"Korean", button2:"alloy", disc :"“Generate a 30 second voice-over for a promotional ad for ShareWise for YT shorts/Tiktok/Reels highlighting the key features”"},
]


const AudioGallery = () => {

  const [selected, setSelected] = useState(0);
  const [selectedPlayButton, setSelectedPlayButton] = useState();
  const [showPauseButton, setShowPauseButton] = useState(false);
  const isBelow991 = useMediaQuery("(max-width:991px)");
  const isBelow1272 = useMediaQuery("(max-width:1272px)");

  const scrollLogos = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const handlePlayButtonClick = (index) =>{
  setSelectedPlayButton(index)
  setShowPauseButton(!showPauseButton)
}

  return <>
  <PageLayout>
  <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignSelf: "center",
            padding: "163px 84px 601px 84px",
            "@media (max-width: 991px)": { padding: "120px 16px 60px 16px" },
          }}
        >
          {/* Page Name Heading */}

          <Box>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "24px",
                textAlign: "center",
                color: "#F6BE6B",
                "@media (max-width: 991px)": {
                  fontSize: "12px",
                },
              }}
            >   
              Audio Gallery
            </Typography>
          </Box>

          {/* Second Main Heading \  */}
           <Box
            sx={{
              maxWidth: "1080px",
              margin: "auto",
              textAlign: "center",
              marginTop: "48px",
              "@media (max-width: 991px)": { marginTop: "24px" },
            }}
          >
            <Typography
              sx={{
                maxWidth: "1080px",
                fontFamily: "Inter",
                fontSize: "48px",
                fontWeight: 500,
                lineHeight: "57.6px",
                textAlign: "center",
                letterSpacing: "-0.32",
                color: "#FFFFFF",
                opacity: "80%",
                "@media (max-width: 991px)": {
                  fontFamily: "Inter",
                  fontSize: "28px",
                  fontWeight: 500,
                  lineHeight: "33.6px",
                  textAlign: "center",
                  letterSpacing: "-0.32",
                  color: "#FFFFFF",
                  opacity: "80%",
                  minWidth: "358px",
                  margin: "auto",
                  whiteSpace: "wrap",
                },
              }}
            >
              Generate realistic speech in multiple languages seamlessly
            </Typography>
          </Box>

          {/* All Button Code */}
          <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                overflow: "hidden",
                position: "relative",
                marginTop:"72px",
                "@media(max-width: 900px)": {marginTop:"60px",}
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "16px",
                  height: "32px",
                  overflowX: "scroll", // Enables horizontal scrolling
                  overflowY: "hidden", // Prevents vertical scrolling
                  scrollbarWidth: "none", // Hides scrollbar (Firefox)
                  msOverflowStyle: "none", // Hides scrollbar (Internet Explorer)
                  "&::-webkit-scrollbar": {
                    display: "none", // Hides scrollbar (WebKit)
                  },
                  scrollBehavior: "smooth", // Smooth scrolling effect

                  "@media(max-width: 900px)": {
                    gap: "8px", // Reduces gap on smaller screens
                  },
                   animation: isBelow1272 ? `${scrollLogos} 20s linear infinite` : "none"
                }}
              >
                {buttonList.map((title, index) => (
                  <Button
                    key={index}
                    onClick={() => setSelected(index)}
                    sx={{
                      flex: "0 0 auto", // Prevents buttons from shrinking
                      minWidth: "auto", // Allows button width to adjust to content
                      height: "32px",
                      backgroundColor:
                        selected === index
                          ? "rgba(202, 196, 208, 1)"
                          : "rgba(31, 35, 62, 1)",
                      "&:hover": {
                        backgroundColor:
                          selected === index
                            ? "rgba(202, 196, 208, 1)"
                            : "rgba(31, 35, 62, 1)",
                      },
                      borderRadius: "4px",
                    }}
                    variant="contained"
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter, sans-serif",
                        fontSize: "10px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        textAlign: "center",
                        color:
                          selected === index
                            ? "rgba(23, 24, 44, 1)"
                            : "rgba(202, 196, 208, 1)",
                        whiteSpace: "nowrap", // Prevents text from wrapping
                        overflow: "hidden", // Ensures text does not overflow the button
                        textOverflow: "ellipsis", // Adds ellipsis if text overflows
                      }}
                    >
                      {title}
                    </Typography>
                  </Button>
                ))}
              </Box>
            </Box>

          {/* Audio Gallery */}

            <Box sx={{ marginTop: isBelow991 ? "60px" : "100px", }}>
              {
                audioList.map((audioObject, index)=>{
                  return <Box>  <Box sx={{display:"flex", flexDirection: isBelow991 ? "column" : "row", alignItems:"center", paddingTop: isBelow991 ? (index===0 ? "0px" : "30px") : (index===0 ? "0px" : "36px"), paddingBottom: isBelow991 ? (index=== audioList.length - 1 ? "0px" : "30px") : (index=== audioList.length - 1 ? "0px" : "36px"),}}>
                    <Box sx={{display:"flex", justifyContent:"space-between", textAlign:"cenetr", flexDirection: isBelow991 ? "column" : "row", alignItems:"center", width: isBelow991 ? "auto" : "512px", maxWidth:"512px"}}>
                   
                    <Box sx={{display:"flex",  alignItems:"center", justifyContent:"center",}}>
                    <Button sx={{ display:"flex", flexDirection:"row", alignItems:"center", padding:"0px, 0px",}} onClick={(e)=>{handlePlayButtonClick(index)}}>
                      <IconButton sx={{padding:"0px, 0px", }}>
                        {
                          index===selectedPlayButton && showPauseButton ?  <PauseCircleIcon style={{ color: "#ffffff", padding:"0px, 0px",}} sx={{padding:"0px, 0px"}}/> :  <PlayCircleIcon style={{ color: "#ffffff", padding:"0px, 0px",}} sx={{padding:"0px, 0px"}} /> 
                        }
                      </IconButton>
                    </Button>
                    <Box sx={{  fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "16.94px",
                          textAlign: "left",
                          color: "rgba(255, 255, 255, 0.80)",
                          opacity: 0.8,
                          fontFeatureSettings: "'liga' off, 'clig' off",
                      }}>{audioObject.title}
                      </Box>
                      </Box>
                    
                      <Box sx={{display:"flex", gap : isBelow991 ? "8px" : "12px", textAlign:"center"}}>
                    <Button
                    key={audioObject.id}
                    // onClick={() => setSelected(index)}
                    sx={{
                      flex: "0 0 auto", // Prevents buttons from shrinking
                      minWidth: "auto", // Allows button width to adjust to content
                      height: "32px",
                      backgroundColor:"rgba(31, 35, 62, 1)",
                        // selected === index
                        //   ? "rgba(202, 196, 208, 1)"
                        //   : "rgba(31, 35, 62, 1)",
                      marginLeft : isBelow991 ? "0px" : "25px", 
                      "&:hover": {
                        backgroundColor:"rgba(31, 35, 62, 1)",
                          // selected === index
                            // ? "rgba(202, 196, 208, 1)"
                            // : "rgba(31, 35, 62, 1)",
                      },
                      borderRadius: "4px",
                    }}
                    variant="contained"
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter, sans-serif",
                        fontSize: "10px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        textAlign: "center",
                        color:  "rgba(202, 196, 208, 1)",
                          // selected === index
                          //   ? "rgba(23, 24, 44, 1)"
                          //   : "rgba(202, 196, 208, 1)",
                        whiteSpace: "nowrap", // Prevents text from wrapping
                        overflow: "hidden", // Ensures text does not overflow the button
                        textOverflow: "ellipsis", // Adds ellipsis if text overflows
                      }}
                    >
                      {audioObject.button1}
                    </Typography>
                  </Button>

                  <Button
                    key={audioObject.id}
                    // onClick={() => setSelected(index)}
                    sx={{
                      flex: "0 0 auto", // Prevents buttons from shrinking
                      minWidth: "auto", // Allows button width to adjust to content
                      height: "32px",
                      backgroundColor:"rgba(31, 35, 62, 1)",
                        // selected === index
                        //   ? "rgba(202, 196, 208, 1)"
                        //   : "rgba(31, 35, 62, 1)",
                      "&:hover": {
                        backgroundColor:"rgba(31, 35, 62, 1)",
                          // selected === index
                            // ? "rgba(202, 196, 208, 1)"
                            // : "rgba(31, 35, 62, 1)",
                      },
                      borderRadius: "4px",
                    }}
                    variant="contained"
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter, sans-serif",
                        fontSize: "10px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        textAlign: "center",
                        color:  "rgba(202, 196, 208, 1)",
                          // selected === index
                          //   ? "rgba(23, 24, 44, 1)"
                          //   : "rgba(202, 196, 208, 1)",
                        whiteSpace: "nowrap", // Prevents text from wrapping
                        overflow: "hidden", // Ensures text does not overflow the button
                        textOverflow: "ellipsis", // Adds ellipsis if text overflows
                      }}
                    >
                      {audioObject.button2}
                    </Typography>
                  </Button>
                  </Box>

                      </Box>
                    

                  

                  <Box sx={{display : isBelow991 ? "flex" : "flex", marginLeft : isBelow991 ? "0px" : "50px", maxWidth:"536px", marginTop : isBelow991 ? "24px" : "0px",}}>
                    <Typography sx={{ fontFamily: "Inter",fontSize: "16px",fontWeight: 400,lineHeight: "20.8px",  color:"rgba(255, 255, 255, 0.80)", opacity: 0.8, fontFeatureSettings: "'liga' off, 'clig' off", textAlign : isBelow991 ? "center" : "left",}}>{audioObject.disc}</Typography>
                  </Box>

                  <Box>
                    
                  </Box> 
                  
                    </Box > 
                    {
                      index !== audioList.length - 1 && <Divider sx={{bgcolor:"rgba(255, 255, 255, 0.30)"}}/>
                      
                    } </Box>
                    
                })
              }
            </Box>


</Box>
<RequestDemoNearFooter />
</PageLayout>
  </>;
};

export default AudioGallery;
