import React, { createContext, useContext, useState } from "react";

// Create the context
const CreditRechargeContext = createContext();

// Create a provider component
export const CreditRechargeProvider = ({ children }) => {
  const [showCreditRecharge, setShowCreditRecharge] = useState(false);
  const upgradePlan = () =>{
    setShowCreditRecharge(true)
  }

  return (
    <CreditRechargeContext.Provider value={{ showCreditRecharge, setShowCreditRecharge,upgradePlan }}>
      {children}
    </CreditRechargeContext.Provider>
  );
};

// Custom hook to use the CreditRecharge context
export const useCreditRecharge = () => useContext(CreditRechargeContext);

