import React, { useState } from 'react';
// import Confetti from "react-confetti";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { AiOutlineMail, AiOutlineInstagram } from 'react-icons/ai';

function AddToWaitList() {
    const [email, setEmail] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [isConfettiActive, setIsConfettiActive] = useState(false);
    const [isAlreadyInWaitlist,setIsAlreadyInWaitlist] = useState(false);

    const ModalXLightSVG = require("../assets/ModalX_-_Black_Gradient-removebg.png")

    const handleSubmit = (event) => {
        event.preventDefault();
        fetch('https://www.modalx.ai/earlyAccess', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: email })
        })
            .then(response => response.json())
            .then(data => {
                // Assuming your response structure contains a 'status' field indicating success
                if (data.status) {
                    setShowSuccessMessage(true);
                    setIsConfettiActive(true);
                } else {
                    // 
                }
            })
            .catch(error => {
                console.error('Error:', error);
                // Check if it's a network error or a server error
                if (error instanceof TypeError || error.message === 'db insert error') {
                    // Network error or CORS issue
                    console.error('Network error occurred.');
                    // Handle accordingly, maybe show a message to the user
                } else {
                    // Server error or other error
                    console.error('Server error occurred.');
                    setIsAlreadyInWaitlist(true);
                }
            });
            
    }
    
    
    const closeSuccessMessage = () => {
        setShowSuccessMessage(false);
        setIsConfettiActive(false);

        resetForm();
    }

    const resetForm = () => {
        setEmail('');
    }

    return (
        <div className="container">
            {!showSuccessMessage && !isAlreadyInWaitlist && (
            <div className="main-content">
                <div className="logo">
                    <img src={ModalXLightSVG} alt="AlignUI Logo" />
                </div>
                <h1 className="title"><strong>Join The Waitlist for ModalX Today!</strong></h1>
                <p className="subtitle">Discover an array of new resources on the horizon. Sign up to be notified when we launch!</p>
                <form onSubmit={handleSubmit} className="form">
                    <input
                        type="email"
                        placeholder="Your Email Address"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="input-field"
                    />
                    <button type="submit" className="submit-button">Join Waitlist!</button>
                </form>
            </div>
        )}
        {showSuccessMessage && (
            <div className="success-message">
                <h2>Congratulations!</h2>
                <p>You've successfully joined the waitlist for ModalX! 🎉 We're thrilled to have you onboard. Keep an eye on your inbox for exciting updates and announcements. Thank you for being a part of our community.</p>
                <div className="social-icons">
                    <a href="https://www.linkedin.com/company/modalx/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
                    <a href="mailto:contact@modalx.ai"><AiOutlineMail /></a>
                    <a href="https://www.instagram.com/modalx.ai/" target="_blank" rel="noopener noreferrer"><AiOutlineInstagram /></a>
                </div>
            </div>
        )}
        {isAlreadyInWaitlist && (
            <div className="success-message">
            <h2>You're Already In!..</h2>
            <p>You've already joined the waitlist for ModalX! 🎉 We're thrilled to have you onboard. Keep an eye on your inbox for exciting updates and announcements. Thank you for being a part of our community.</p>
            <div className="social-icons">
                <a href="https://www.linkedin.com/company/modalx/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
                <a href="mailto:contact@modalx.ai"><AiOutlineMail /></a>
                <a href="https://www.instagram.com/modalx.ai/" target="_blank" rel="noopener noreferrer"><AiOutlineInstagram /></a>
            </div>
        </div>
        )}
            <style>{`
                .container {
                    font-family: Arial, sans-serif;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    min-height: 100vh;
                    background: linear-gradient(135deg, #000000, #000000);
                    color: white;
                    min-width:100vw;
                }

                .main-content, .success-message {
                    text-align: center;
                    margin-bottom: 50px;
                    max-width: 500px; /* Reduced width */

                }

                .logo img {
                    width: 150px;
                    height: auto;
                }

                .title {
                    font-size: 32px;
                    margin-bottom: 20px;
                }

                .subtitle {
                    font-size: 18px;
                    margin-bottom: 30px;
                    opacity: 0.9;
                }

                .form {
                    display: flex;
                    justify-content: center;
                    gap: 10px;
                    margin-top: 20px;

                }

                .input-field {
                    padding: 10px;
                    width: 300px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    margin-bottom: 10px;
                    height: 40px; /* Set the height */
                }

                .submit-button {
                    height: 40px; /* Set the height */
                    padding: 10px 15px;
                    background-color: #0078d7;
                    color: white;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                }

                .success-message {
                    background-color: #000000;
                    padding: 20px;
                    text-align: center;
                    max-width: 400px;
                    border-radius: 8px;
                }

                .close-button {
                    padding: 8px 16px;
                    background-color: transparent;
                    border: 1px solid white;
                    color: white;
                    border-radius: 4px;
                    cursor: pointer;
                    margin-top: 10px;
                }

                .hidden {
                    display: none;
                }
                .social-icons {
                    margin-top: 20px;
                }
                
                .social-icons a {
                    display: inline-block;
                    margin-right: 10px;
                    font-size: 24px;
                    color: white;
                    text-decoration: none;
                }
                
                .social-icons a:hover {
                    color: #0078d7; /* Change color on hover */
                }
            `}</style>
        </div>
    );
}

export default AddToWaitList;
