import React, { useState } from 'react';
import sourceUrl from "../../api/Source.api";

function EnableSubscription({fetchSubscriptionData}) {
  const [email, setEmail] = useState('');
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const token = localStorage.getItem('token');
  const [hover, setHover] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  
  // const handleSubscription = async () => {
  //   try {
  //     const res = await fetch(`${sourceUrl}/admin/enableSubscription`, {
  //       method: 'POST',
  //       headers: {
  //         'accept': '*/*',
  //         'content-type': 'application/json',
  //         'authorization': `Bearer ${token}`, 
  //       },
  //       body: JSON.stringify({ email }),
  //     });

  //     const data = await res.json();

  //     if (res.ok) {
  //       setResponse(data);
  //       fetchSubscriptionData()
  //       setError(null);
  //               // Hide the response after 2 seconds
  //               setTimeout(() => setResponse(null), 2000);
  //     } else {
  //       setError(data.message || 'Something went wrong!');
  //       setResponse(null);
  //     }
  //   } catch (err) {
  //     setError('Failed to connect to the API');
  //     setResponse(null);
  //   }
    
  // };

  const handleSubscription = async () => {
    try {
      const response = await fetch(`${sourceUrl}/admin/enableSubscription`, {
        method: 'POST',
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ email }), // Include 'name' if needed
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.message || `Request failed with status ${response.status}`);
      }
  
      // Handle success case
      setResponse(data);
      fetchSubscriptionData(); // Refresh subscription data
      setError(null);
  
      // Hide response message after 2 seconds
      setTimeout(() => setResponse(null), 2000);
    } catch (error) {
      console.error('Subscription error:', error.message); // Log the error
      setError(error.message || 'Failed to connect to the API');
      setResponse(null);
    }
  };
  
  return (
    <div>
      <h5>Enable Subscription</h5>
      <input
        type="email"
        placeholder="Enter email"
        value={email}
        onChange={handleEmailChange}
        style={{ padding: '10px', minWidth: '400px', marginBottom: '10px',height : '50px' ,borderRadius : '10px',
            border : 'none'
        }}
      />
      <br />
      <button onClick={handleSubscription} 
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
      
      style={{ padding: '5px 20px', cursor: 'pointer',
      border : 'none' , borderRadius : '5px' ,
      backgroundColor: hover ? 'darkgreen' : 'green',
        
       }}>
        Submit
      </button>
      {response && (
        <div style={{ marginTop: '20px', textAlign: 'left' }}>
          <h3>{response.message}</h3>
          {/* <pre>{JSON.stringify(response, null, 2)}</pre> */}
        </div>
      )}

      {error && (
        <div style={{ color: 'red', marginTop: '20px' }}>
            <h3><strong>Error:</strong> {error}</h3>
          
        </div>
      )}
      
    </div>
  )
}

export default EnableSubscription
