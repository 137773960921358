// import React, { useState } from 'react';
// import { Button, Col, Container, Row, Form } from 'react-bootstrap';
// import { useWorkspace } from "../../../../contexts/WorkspaceContext";

// const ImageFormat = () => {
//   const {threadSettings,setThreadSettings,imageFormat,setImageFormat} = useWorkspace();
//   const [openImgSet, setOpenImgSet] = useState(false);
//   // const [currImageFormat, setCurrImageFormat] = useState('png');
//   const theme = localStorage.getItem('theme');
//   const handleClick = () => {
//     console.log("Synthesia Settings Clicked");
//     setOpenImgSet(!openImgSet);
//   };

//   const handleFormatChange = (e) => {
//     const newFormat = e.target.value ;
//     setImageFormat(newFormat);
//     setThreadSettings((prevSettings) => ({
//       ...prevSettings,
//       imageFormat: newFormat,
//     }));

//   };

//   return (
//     <Container fluid className="m-0 p-0">
//       <Row
//         className="m-0 p-0"
//         style={{ cursor: "pointer" }}
//         onClick={handleClick}
//       >
//         <Button variant="outline-primary" style={{ textAlign: 'start', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
//           Image Format ({imageFormat.toUpperCase()})
//           <Col
//             xs={2}
//             className="m-0 p-0 d-flex align-items-center justify-content-center"
//           >
//             {!openImgSet ? (
//               <i className="bi bi-caret-down" />
//             ) : (
//               <i className="bi bi-caret-up" />
//             )}
//           </Col>
//         </Button>
//       </Row>
//       {openImgSet && (
//         <Row className="m-0 p-0" style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px'}}>
//           <Form value={imageFormat} style={{width:'90%',margin:'auto',border:'1px solid',borderBottomLeftRadius:'10px',borderBottomRightRadius:'10px',borderTop:'none',borderColor:theme ==='dark'?'grey':''}}>
//           <Form.Check
//               type="radio"
//               label="PNG"
//               name="imageFormat"
//               value="png"
//               checked={imageFormat === 'png'}
//               onChange={handleFormatChange}
//             />

//             <Form.Check
//               type="radio"
//               label="JPEG"
//               name="imageFormat"
//               value="jpeg"
//               checked={imageFormat === 'jpeg'}
//               onChange={handleFormatChange}
//             />

//             <Form.Check
//               type="radio"
//               label="WEBP"
//               name="imageFormat"
//               value="webp"
//               checked={imageFormat === 'webp'}
//               onChange={handleFormatChange}
//             />
//           </Form>
//         </Row>
//       )}
//     </Container>
//   );
// }

// export default ImageFormat;

import React from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";

const ImageFormats = ["png", "jpeg", "webp"];

// const ImageFormat = () => {
//   const { threadSettings, setThreadSettings, imageFormat, setImageFormat } =
//     useWorkspace();
//   const theme = localStorage.getItem("theme");

//   const handleFormatChange = (e) => {
//     const newFormat = e.target.value;
//     setImageFormat(newFormat);
//     setThreadSettings((prevSettings) => ({
//       ...prevSettings,
//       imageFormat: newFormat,
//     }));
//   };

//   return (
//     <FormControl component="fieldset">
//       <RadioGroup value={imageFormat} onChange={handleFormatChange}>
//         <FormControlLabel
//           value="png"
//           control={<Radio />}
//           label="PNG"
//           sx={{
//             backgroundColor: imageFormat === "png" ? "#3a3f69" : "transparent",
//             color: theme === "dark" ? "white" : "black",
//             "& .MuiRadio-root": {
//               color: theme === "dark" ? "white" : "black",
//             },
//             "&.Mui-checked": {
//               color: "white",
//             },
//             "&:hover": {
//               backgroundColor: imageFormat === "png" ? "#3a3f69" : "lightgrey",
//             },
//           }}
//         />
//         <FormControlLabel
//           value="jpeg"
//           control={<Radio />}
//           label="JPEG"
//           sx={{
//             backgroundColor: imageFormat === "jpeg" ? "#3a3f69" : "transparent",
//             color: theme === "dark" ? "white" : "black",
//             "& .MuiRadio-root": {
//               color: theme === "dark" ? "white" : "black",
//             },
//             "&.Mui-checked": {
//               color: "white",
//             },
//             "&:hover": {
//               backgroundColor: imageFormat === "jpeg" ? "#3a3f69" : "lightgrey",
//             },
//           }}
//         />
//         <FormControlLabel
//           value="webp"
//           control={<Radio />}
//           label="WEBP"
//           sx={{
//             backgroundColor: imageFormat === "webp" ? "#3a3f69" : "transparent",
//             color: theme === "dark" ? "white" : "black",
//             "& .MuiRadio-root": {
//               color: theme === "dark" ? "white" : "black",
//             },
//             "&.Mui-checked": {
//               color: "white",
//             },
//             "&:hover": {
//               backgroundColor: imageFormat === "webp" ? "#3a3f69" : "lightgrey",
//             },
//           }}
//         />
//       </RadioGroup>
//     </FormControl>
//   );
// };

function ImageFormat() {
  const { threadSettings, setThreadSettings, imageFormat, setImageFormat } =
    useWorkspace();
  const theme = localStorage.getItem("theme");

  const handleImageFormatChange = (format) => {
    setImageFormat(format);
    setThreadSettings((prevSettings) => ({
      ...prevSettings,
      imageFormat: format,
    }));
  };
  return (
    <Box sx={{ p: 2 }}>
      <Typography
        variant="h6"
        gutterBottom
        style={{
          fontSize: "16px",
          fontWeight: "500",
          width: "158px",
          height: "20px",
          marginBottom: "20px",
        }}
      >
        Select Image Format
      </Typography>
      <Grid container spacing={1} justifyContent="start">
        {ImageFormats.map((format) => (
          <Grid item key={format}>
            <Button
              variant="outlined"
              onClick={() => handleImageFormatChange(format)}
              sx={{
                width: "54px",
                height: "32px",
                backgroundColor: imageFormat === format ? "#3a3f69" : "#30355f",
                // backgroundColor: theme === 'dark'? '#30355f' : '',
                color: theme === "dark" ? "white" : "black",
                "&:hover": {
                  backgroundColor: imageFormat === format ? "#3a3f69" : "",
                },
                borderColor: imageFormat === format ? "#cac4d0" : "transparent",
                mx: 0.5, // Add horizontal margin for spacing
              }}
            >
              {format}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default ImageFormat;
