// import { Button } from '@mui/material';
// import React, { useState, useEffect, useRef } from 'react';
// import vector_icon from '../../assets/Button/landingPage_vector_icon.png';
// import user_icon from '../../assets/Button/landingPage_user_icon.png.png';
// import checked_icon from '../../assets/Button/landingPage_checked_icon.png';
// import { selected_chat_bg_color_dm } from '../../constants/colors';
// import Simplified from './ModalX_Advatages_Button_Components/Simplified';
// import Actionable from './ModalX_Advatages_Button_Components/Actionable';
// import Personalized from './ModalX_Advatages_Button_Components/Personalized';

// const ModalX_Advantages = () => {
//   const [activeSection, setActiveSection] = useState('simplified');
//   const sectionRefs = {
//     simplified: useRef(null),
//     personalized: useRef(null),
//     actionable: useRef(null),
//   };

//   // Update the active section based on scroll
//   useEffect(() => {
//     const handleScroll = () => {
//       const { simplified, personalized, actionable } = sectionRefs;
//       const simplifiedOffset = simplified.current.offsetTop;
//       const personalizedOffset = personalized.current.offsetTop;
//       const actionableOffset = actionable.current.offsetTop;
//       const scrollPosition = window.scrollY + 100; // Adjust as needed for sticky header

//       if (scrollPosition >= actionableOffset) {
//         setActiveSection('actionable');
//       } else if (scrollPosition >= personalizedOffset) {
//         setActiveSection('personalized');
//       } else if (scrollPosition >= simplifiedOffset) {
//         setActiveSection('simplified');
//       }
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, [sectionRefs]);

//   return (
//     <div>
//       <div
//         style={{
//           position: 'sticky',
//           top: '50px',
//           zIndex: 1000,
//           // backgroundColor: '#fff', // Match the background color of the page
//           paddingTop: '20px',
//           paddingBottom: '20px',
//         }}
//       >
//         <div
//           style={{
//             display: 'flex',
//             maxWidth: '880px',
//             height: '64px',
//             margin: 'auto',
//             justifyContent: 'space-between',
//             marginTop: '20px',
//           }}
//         >
//           <Button
//             style={{
//               display: 'flex',
//               width: '240px',
//               height: '64px',
//               borderRadius: '12px',
//               padding: '24px 18px',
//               justifyContent: 'space-evenly',
//               fontSize: '24px',
//               border: activeSection === 'simplified' ? '2px solid #CAC4D0' : 'none',
//             }}
//             sx={{ textTransform: 'none', color: '#FFFFFF', bgcolor: selected_chat_bg_color_dm }}
//           >
//             <img src={vector_icon} alt="Simplified" />
//             Simplified
//           </Button>
//           <Button
//             style={{
//               display: 'flex',
//               width: '240px',
//               height: '64px',
//               borderRadius: '12px',
//               padding: '24px 18px',
//               justifyContent: 'space-evenly',
//               fontSize: '24px',
//               border: activeSection === 'personalized' ? '2px solid #CAC4D0' : 'none',
//             }}
//             sx={{ textTransform: 'none', color: '#FFFFFF', bgcolor: selected_chat_bg_color_dm }}
//           >
//             <img src={user_icon} alt="Personalized" />
//             Personalized
//           </Button>
//           <Button
//             style={{
//               display: 'flex',
//               width: '240px',
//               height: '64px',
//               borderRadius: '12px',
//               padding: '24px 18px',
//               justifyContent: 'space-evenly',
//               fontSize: '24px',
//               border: activeSection === 'actionable' ? '2px solid #CAC4D0' : 'none',
//             }}
//             sx={{ textTransform: 'none', color: '#FFFFFF', bgcolor: selected_chat_bg_color_dm }}
//           >
//             <img src={checked_icon} alt="Actionable" />
//             Actionable
//           </Button>
//         </div>
//       </div>

//       <div ref={sectionRefs.simplified}>
//         <Simplified />
//       </div>
//       <div ref={sectionRefs.personalized}>
//         <Personalized />
//       </div>
//       <div ref={sectionRefs.actionable}>
//         <Actionable />
//       </div>
//     </div>
//   );
// };

// export default ModalX_Advantages;

import { Button, Typography, Box, CardMedia } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import vector_icon from "../../assets/Button/landingPage_vector_icon.png";
import user_icon from "../../assets/Button/landingPage_user_icon.png.png";
import checked_icon from "../../assets/Button/landingPage_checked_icon.png";
import { selected_chat_bg_color_dm } from "../../constants/colors";
import Simplified from "./ModalX_Advatages_Button_Components/Simplified";
import Actionable from "./ModalX_Advatages_Button_Components/Actionable";
import Personalized from "./ModalX_Advatages_Button_Components/Personalized";

const ModalX_Advantages = () => {
  const [activeSection, setActiveSection] = useState("simplified");
  const sectionRefs = {
    simplified: useRef(null),
    personalized: useRef(null),
    actionable: useRef(null),
  };

  // Update the active section based on scroll
  useEffect(() => {
    const handleScroll = () => {
      const { simplified, personalized, actionable } = sectionRefs;
      const simplifiedOffset = simplified.current.offsetTop;
      const personalizedOffset = personalized.current.offsetTop;
      const actionableOffset = actionable.current.offsetTop;
      const scrollPosition = window.scrollY + 100; // Adjust as needed for sticky header

      if (scrollPosition >= actionableOffset) {
        setActiveSection("actionable");
      } else if (scrollPosition >= personalizedOffset) {
        setActiveSection("personalized");
      } else if (scrollPosition >= simplifiedOffset) {
        setActiveSection("simplified");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [sectionRefs]);

  // Function to handle button click and scroll to the corresponding section
  const scrollToSection = (section) => {
    sectionRefs[section].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    setActiveSection(section); // Update active section
  };

  return (
    <div>
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "56px",
          marginBottom: "100px",
          "@media (max-width : 991px)": {
            // width: "358px",
            // height: "72px",
            gap: "0px",
            opacity: "80%",
            fontFamily: "Inter",
            fontSize: "30px",
            fontWeight: 500,
            lineHeight: "36px",
            letterSpacing: "-0.32",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            paddingLeft : '10px',
            paddingRight : '10px',
            
            // marginBottom: "px",
          },
        }}
      >
        Stop Guessing, Start Growing
      </Typography>

      {/* <p style={{}}>Stop Guessing, Start Growing</p> */}
      <Box
        sx={{
          position: "sticky",
          top: "50px",
          zIndex: 1000,
          // backgroundColor: '#fff', // Match the background color of the page
          paddingTop: "20px",
          paddingBottom: "20px",
          "@media (max-width : 991px)": {
            height: "72px",
            paddingTop: "10px",
            paddingBottom: "70px",
            
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            maxWidth: "880px",
            height: "64px",
            margin: "auto",
            justifyContent: "space-between",
            marginTop: "20px",
            "@media (max-width : 991px)": {
              maxWidth: "368px",
              display: "flex",
              alignItems: "center",
              paddingLeft : '10px',
              paddingRight : '10px'
            },
          }}
        >
          <Button
            // onClick={() => scrollToSection('simplified')}
            // style={{
            //   display: 'flex',
            //   width: '240px',
            //   height: '64px',
            //   borderRadius: '12px',
            //   padding: '24px 18px',
            //   justifyContent: 'space-evenly',
            //   fontSize: '24px',
            //   bgcolor : 'blue',
            //   border: activeSection === 'simplified' ? '2px solid #CAC4D0' : 'none',

            // }}
            // sx={{
            //   // textTransform: 'none',
            //   color: '#FFFFFF',
            //   bgcolor: selected_chat_bg_color_dm,
            //   '&:hover': {
            //     bgcolor: selected_chat_bg_color_dm, // Keeps the background color the same on hover
            //     '@media (max-width : 991px)':{
            //       backgroundColor : 'red',
            //       width : '140px'

            //      }

            //   },
            // }}
            onClick={() => scrollToSection("simplified")}
            sx={{
              display: "flex",
              textTransform: "none",
              width: "240px",
              height: "64px",
              borderRadius: "12px",
              padding: "24px 18px",
              justifyContent: "space-evenly",
              fontSize: "24px",
              bgcolor: selected_chat_bg_color_dm,
              color: "#FFFFFF",
              border:
                activeSection === "simplified" ? "2px solid #CAC4D0" : "none",
              "&:hover": {
                bgcolor: selected_chat_bg_color_dm, // Keeps the background color the same on hover
              },
              "@media (max-width: 991px)": {
                width: "104px",
                height: "40px",
                padding: "8px",
                gap: "4px",
                opacity: "0px",
                fontFamily: "Inter",
                fontSize: "13px",
                fontWeight: 500,
                letterSpacing: "0.1",
                textAlign: "center",
              },
            }}
          >
            <CardMedia
              component="img"
              image={vector_icon}
              alt="Simplified"
              sx={{
                width: "23px",
                height: "18px",
                "@media (max-width : 991px)": {
                  width: "16px",
                  height: "14px",
                },
              }}
            />
            {/* <img src={vector_icon} alt="Simplified" /> */}
            Simplified
          </Button>
          <Button
            onClick={() => scrollToSection("personalized")}
            // style={{
            //   display: 'flex',
            //   width: '240px',
            //   height: '64px',
            //   borderRadius: '12px',
            //   padding: '24px 18px',
            //   justifyContent: 'space-evenly',
            //   fontSize: '24px',
            //   border: activeSection === 'personalized' ? '2px solid #CAC4D0' : 'none',
            // }}
            // sx={{
            //   textTransform: 'none',
            //   color: '#FFFFFF',
            //   bgcolor: selected_chat_bg_color_dm,
            //   '&:hover': {
            //     bgcolor: selected_chat_bg_color_dm, // Keeps the background color the same on hover
            //   },
            // }}
            sx={{
              display: "flex",
              textTransform: "none",
              width: "240px",
              height: "64px",
              borderRadius: "12px",
              padding: "24px 18px",
              justifyContent: "space-evenly",
              fontSize: "24px",
              bgcolor: selected_chat_bg_color_dm,
              color: "#FFFFFF",
              border:
                activeSection === "personalized" ? "2px solid #CAC4D0" : "none",
              "&:hover": {
                bgcolor: selected_chat_bg_color_dm, // Keeps the background color the same on hover
              },
              "@media (max-width: 991px)": {
                width: "122px",
                height: "40px",
                padding: "8px",
                gap: "4px",
                opacity: "0px",
                fontFamily: "Inter",
                fontSize: "13px",
                fontWeight: 500,
                letterSpacing: "0.1",
                textAlign: "center",
              },
            }}
          >
            <CardMedia
              component="img"
              image={user_icon}
              alt="Simplified"
              sx={{
                width: "23px",
                height: "18px",
                "@media (max-width : 991px)": {
                  width: "16px",
                  height: "14px",
                },
              }}
            />
            {/* <img src={user_icon} alt="Personalized" /> */}
            Personalized
          </Button>
          <Button
            onClick={() => scrollToSection("actionable")}
            // style={{
            //   display: 'flex',
            //   width: '240px',
            //   height: '64px',
            //   borderRadius: '12px',
            //   padding: '24px 18px',
            //   justifyContent: 'space-evenly',
            //   fontSize: '24px',
            //   border: activeSection === 'actionable' ? '2px solid #CAC4D0' : 'none',
            // }}
            // sx={{
            //   textTransform: 'none',
            //   color: '#FFFFFF',
            //   bgcolor: selected_chat_bg_color_dm,
            //   '&:hover': {
            //     bgcolor: selected_chat_bg_color_dm, // Keeps the background color the same on hover
            //   },
            // }}
            sx={{
              display: "flex",
              textTransform: "none",
              width: "240px",
              height: "64px",
              borderRadius: "12px",
              padding: "24px 18px",
              justifyContent: "space-evenly",
              fontSize: "24px",
              bgcolor: selected_chat_bg_color_dm,
              color: "#FFFFFF",
              border:
                activeSection === "actionable" ? "2px solid #CAC4D0" : "none",
              "&:hover": {
                bgcolor: selected_chat_bg_color_dm, // Keeps the background color the same on hover
              },
              "@media (max-width: 991px)": {
                width: "108px",
                height: "40px",
                padding: "8px",
                gap: "4px",
                opacity: "0px",
                fontFamily: "Inter",
                fontSize: "13px",
                fontWeight: 500,
                letterSpacing: "0.1",
                textAlign: "center",
              },
            }}
          >
            <CardMedia
              component="img"
              image={checked_icon}
              alt="Actionable"
              sx={{
                width: "23px",
                height: "18px",
                "@media (max-width : 991px)": {
                  width: "16px",
                  height: "14px",
                },
              }}
            />
            {/* <img src={checked_icon} alt="Actionable" /> */}
            Actionable
          </Button>
        </Box>
      </Box>

      <div ref={sectionRefs.simplified}>
        <Simplified />
      </div>
      <div ref={sectionRefs.personalized}>
        <Personalized />
      </div>
      <div ref={sectionRefs.actionable}>
        <Actionable />
      </div>
    </div>
  );
};

export default ModalX_Advantages;

{/* <CardMedia
component="img"
image={image_blow_990}
alt="Your Image Description"
sx={{
  minWidth: "358px",
  marginBottom: "10px",
  paddingLeft : '40px',
  paddingRight : '40px',
  "@media (max-width : 991px)": {
    display: "block",
    margin: "auto",
    // maxWidth: "338px",
    // height: "240px",
    marginTop: "37px",
    marginBottom: "60px",

  },

  "@media (min-width : 991px)": {
    display: "none",
  },
  

}}/> */}