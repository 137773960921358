// CircularProgressExample.js
import React from 'react';
import FacebookCircularProgress from './FacebookCircularProgress';

export default function CircularProgress() {
  return (
    <div>
      
      {/* Pass custom props to FacebookCircularProgress */}
      <FacebookCircularProgress
        size={20}
        thickness={4}
        // border: 3px solid 
        color="#4A5076"          // Custom color for the spinner
        indeterminateColor="green" // Dark mode color
        value={80}              // Static value for determinate progress
      />
    </div>
  );
}
