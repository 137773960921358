export const avatarVoiceLanguages = [
  "Afrikaans (South Africa)",
  "Albanian (Albania)",
  "Amharic (Ethiopia)",
  "Arabic",
  "Arabic (Algeria)",
  "Arabic (Bahrain)",
  "Arabic (Egypt)",
  "Arabic (Iraq)",
  "Arabic (Jordan)",
  "Arabic (Kuwait)",
  "Arabic (Lebanon)",
  "Arabic (Libya)",
  "Arabic (Morocco)",
  "Arabic (Oman)",
  "Arabic (Qatar)",
  "Arabic (Saudi Arabia)",
  "Arabic (Syria)",
  "Arabic (Tunisia)",
  "Arabic (United Arab Emirates)",
  "Arabic (Yemen)",
  "Armenian (Armenia)",
  "Azerbaijani (Latin, Azerbaijan)",
  "Bangla (Bangladesh)",
  "Basque",
  "Bengali (India)",
  "Bosnian (Bosnia and Herzegovina)",
  "Bulgarian (Bulgaria)",
  "Burmese (Myanmar)",
  "Catalan (Spain)",
  "Chinese",
  "Chinese (Cantonese, Simplified)",
  "Chinese (Cantonese, Traditional)",
  "Chinese (Jilu Mandarin, Simplified)",
  "Chinese (Mandarin, Simplified)",
  "Chinese (Northeastern Mandarin, Simplified)",
  "Chinese (Southwestern Mandarin, Simplified)",
  "Chinese (Taiwanese Mandarin, Traditional)",
  "Chinese (Wu, Simplified)",
  "Chinese (Zhongyuan Mandarin Henan, Simplified)",
  "Chinese (Zhongyuan Mandarin Shaanxi, Simplified)",
  "Croatian (Croatia)",
  "Czech (Czechia)",
  "Danish (Denmark)",
  "Dutch (Belgium)",
  "Dutch (Netherlands)",
  "English",
  "English (Australia)",
  "English (Canada)",
  "English (Hong Kong SAR)",
  "English (India)",
  "English (Ireland)",
  "English (Kenya)",
  "English (Nigeria)",
  "English (New Zealand)",
  "English (Philippines)",
  "English (Singapore)",
  "English (South Africa)",
  "English (Tanzania)",
  "English (United Kingdom)",
  "English (United States)",
  "English (US)",
  "English (IN)",
  "English (IE)",
  "English (GB)",
  "English (AU)",
  "English (ZA)",
  "English (FI)",
  "English (FR)",
  "English (IT)",
  "English (MX)",
  "English (NZ)",
  "Estonian (Estonia)",
  "Filipino (Philippines)",
  "Finnish (Finland)",
  "French (Belgium)",
  "French (Canada)",
  "French (France)",
  "French (Switzerland)",
  "Galician",
  "Georgian (Georgia)",
  "German (Austria)",
  "German (Germany)",
  "German (Switzerland)",
  "Greek (Greece)",
  "Gujarati (India)",
  "Hebrew (Israel)",
  "Hindi (India)",
  "Hungarian (Hungary)",
  "Icelandic (Iceland)",
  "Indonesian (Indonesia)",
  "Irish (Ireland)",
  "Italian (Italy)",
  "Japanese (Japan)",
  "Javanese (Latin, Indonesia)",
  "Kannada (India)",
  "Kazakh (Kazakhstan)",
  "Khmer (Cambodia)",
  "Korean (Korea)",
  "Lao (Laos)",
  "Latvian (Latvia)",
  "Lithuanian (Lithuania)",
  "Macedonian (North Macedonia)",
  "Malay (Malaysia)",
  "Malayalam (India)",
  "Maltese (Malta)",
  "Marathi (India)",
  "Mongolian (Mongolia)",
  "Nepali (Nepal)",
  "Norwegian Bokmål (Norway)",
  "Pashto (Afghanistan)",
  "Persian (Iran)",
  "Polish (Poland)",
  "Portuguese (Brazil)",
  "Portuguese (Portugal)",
  "Punjabi (India)",
  "Romanian (Romania)",
  "Russian (Russia)",
  "Serbian (Cyrillic, Serbia)",
  "Serbian (Latin, Serbia)",
  "Sinhala (Sri Lanka)",
  "Slovak (Slovakia)",
  "Slovenian (Slovenia)",
  "Somali (Somalia)",
  "Spanish (Argentina)",
  "Spanish (Bolivia)",
  "Spanish (Chile)",
  "Spanish (Colombia)",
  "Spanish (Costa Rica)",
  "Spanish (Cuba)",
  "Spanish (Dominican Republic)",
  "Spanish (Ecuador)",
  "Spanish (El Salvador)",
  "Spanish (Equatorial Guinea)",
  "Spanish (Guatemala)",
  "Spanish (Honduras)",
  "Spanish (Mexico)",
  "Spanish (Nicaragua)",
  "Spanish (Panama)",
  "Spanish (Paraguay)",
  "Spanish (Peru)",
  "Spanish (Puerto Rico)",
  "Spanish (Spain)",
  "Spanish (United States)",
  "Spanish (Uruguay)",
  "Spanish (Venezuela)",
  "Swahili (Kenya)",
  "Swahili (Tanzania)",
  "Swedish (Sweden)",
  "Tamil",
  "Tamil (India)",
  "Tamil (Malaysia)",
  "Tamil (Singapore)",
  "Tamil (Sri Lanka)",
  "Telugu (India)",
  "Thai (Thailand)",
  "Turkish (Turkey)",
  "Ukrainian (Ukraine)",
  "Urdu (India)",
  "Urdu (Pakistan)",
  "Uzbek (Latin, Uzbekistan)",
  "Vietnamese (Vietnam)",
  "Welsh (United Kingdom)",
  "Zulu (South Africa)"
];


// export const avatarVoiceLanguages = [
//     "Chinese",
//     "Korean (Korea)",
//     "Dutch (Netherlands)",
//     "Turkish (Turkey)",
//     "Swedish (Sweden)",
//     "Indonesian (Indonesia)",
//     "Filipino (Philippines)",
//     "Japanese (Japan)",
//     "Ukrainian (Ukraine)",
//     "Greek (Greece)",
//     "Czech (Czechia)",
//     "Finnish (Finland)",
//     "Romanian (Romania)",
//     "Russian (Russia)",
//     "Danish (Denmark)",
//     "Bulgarian (Bulgaria)",
//     "Malay (Malaysia)",
//     "Slovak (Slovakia)",
//     "Croatian (Croatia)",
//     "Arabic",
//     "Tamil",
//     "English",
//     "Polish (Poland)",
//     "German (Germany)",
//     "Spanish (Spain)",
//     "French (France)",
//     "Italian (Italy)",
//     "Hindi (India)",
//     "Portuguese (Portugal)",
//     "Afrikaans (South Africa)",
//     "Amharic (Ethiopia)",
//     "Arabic (United Arab Emirates)",
//     "Arabic (Bahrain)",
//     "Arabic (Algeria)",
//     "Arabic (Egypt)",
//     "Arabic (Iraq)",
//     "Arabic (Jordan)",
//     "Arabic (Kuwait)",
//     "Arabic (Lebanon)",
//     "Arabic (Libya)",
//     "Arabic (Morocco)",
//     "Arabic (Oman)",
//     "Arabic (Qatar)",
//     "Arabic (Saudi Arabia)",
//     "Arabic (Syria)",
//     "Arabic (Tunisia)",
//     "Arabic (Yemen)",
//     "Azerbaijani (Latin, Azerbaijan)",
//     "Bangla (Bangladesh)",
//     "Bengali (India)",
//     "Bosnian (Bosnia and Herzegovina)",
//     "Catalan (Spain)",
//     "Welsh (United Kingdom)",
//     "German (Austria)",
//     "German (Switzerland)",
//     "English (Australia)",
//     "English (Canada)",
//     "English (United Kingdom)",
//     "English (Hong Kong SAR)",
//     "English (Ireland)",
//     "English (India)",
//     "English (Kenya)",
//     "English (Nigeria)",
//     "English (New Zealand)",
//     "English (Philippines)",
//     "English (Singapore)",
//     "English (Tanzania)",
//     "English (United States)",
//     "English (South Africa)",
//     "Spanish (Argentina)",
//     "Spanish (Bolivia)",
//     "Spanish (Chile)",
//     "Spanish (Colombia)",
//     "Spanish (Costa Rica)",
//     "Spanish (Cuba)",
//     "Spanish (Dominican Republic)",
//     "Spanish (Ecuador)",
//     "Spanish (Equatorial Guinea)",
//     "Spanish (Guatemala)",
//     "Spanish (Honduras)",
//     "Spanish (Mexico)",
//     "Spanish (Nicaragua)",
//     "Spanish (Panama)",
//     "Spanish (Peru)",
//     "Spanish (Puerto Rico)",
//     "Spanish (Paraguay)",
//     "Spanish (El Salvador)",
//     "Spanish (United States)",
//     "Spanish (Uruguay)",
//     "Spanish (Venezuela)",
//     "Estonian (Estonia)",
//     "Basque",
//     "Persian (Iran)",
//     "French (Belgium)",
//     "French (Canada)",
//     "French (Switzerland)",
//     "Irish (Ireland)",
//     "Galician",
//     "Gujarati (India)",
//     "Hebrew (Israel)",
//     "Hungarian (Hungary)",
//     "Armenian (Armenia)",
//     "Icelandic (Iceland)",
//     "Javanese (Latin, Indonesia)",
//     "Georgian (Georgia)",
//     "Kazakh (Kazakhstan)",
//     "Khmer (Cambodia)",
//     "Kannada (India)",
//     "Lao (Laos)",
//     "Lithuanian (Lithuania)",
//     "Latvian (Latvia)",
//     "Macedonian (North Macedonia)",
//     "Malayalam (India)",
//     "Mongolian (Mongolia)",
//     "Marathi (India)",
//     "Maltese (Malta)",
//     "Burmese (Myanmar)",
//     "Norwegian Bokmål (Norway)",
//     "Nepali (Nepal)",
//     "Dutch (Belgium)",
//     "Pashto (Afghanistan)",
//     "Portuguese (Brazil)",
//     "Sinhala (Sri Lanka)",
//     "Slovenian (Slovenia)",
//     "Somali (Somalia)",
//     "Albanian (Albania)",
//     "Serbian (Latin, Serbia)",
//     "Serbian (Cyrillic, Serbia)",
//     "Sundanese (Indonesia)",
//     "Swahili (Kenya)",
//     "Swahili (Tanzania)",
//     "Tamil (India)",
//     "Tamil (Sri Lanka)",
//     "Tamil (Malaysia)",
//     "Tamil (Singapore)",
//     "Telugu (India)",
//     "Thai (Thailand)",
//     "Urdu (India)",
//     "Urdu (Pakistan)",
//     "Uzbek (Latin, Uzbekistan)",
//     "Vietnamese (Vietnam)",
//     "Chinese (Wu, Simplified)",
//     "Chinese (Cantonese, Simplified)",
//     "Chinese (Mandarin, Simplified)",
//     "Chinese (Zhongyuan Mandarin Henan, Simplified)",
//     "Chinese (Northeastern Mandarin, Simplified)",
//     "Chinese (Zhongyuan Mandarin Shaanxi, Simplified)",
//     "Chinese (Jilu Mandarin, Simplified)",
//     "Chinese (Southwestern Mandarin, Simplified)",
//     "Chinese (Cantonese, Traditional)",
//     "Chinese (Taiwanese Mandarin, Traditional)",
//     "Zulu (South Africa)",
//     "English (US)",
//     "English (IN)",
//     "English (IE)",
//     "English (GB)",
//     "English (AU)",
//     "English (ZA)",
//     "English (FI)",
//     "English (FR)",
//     "English (IT)",
//     "English (MX)",
//     "English (NZ)",
//     "Punjabi (India)"
//   ];