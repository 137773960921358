// FacebookCircularProgress.js
import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { circularProgressClasses } from '@mui/material/CircularProgress';

function FacebookCircularProgress({ size = 40, thickness = 4, color = 'red', value = 10, indeterminateColor = 'green' }) {
  return (
    <Box sx={{ position: 'relative' }}>
      {/* Determinate Circular Progress */}
      <CircularProgress
        variant="determinate"
        sx={(theme) => ({
          color: theme.palette.grey[200], // Light mode background color
          ...theme.applyStyles('dark', {
            color: theme.palette.grey[800], // Dark mode background color
          }),
        })}
        size={size}
        thickness={thickness}
        value={value}
      />
      {/* Indeterminate Circular Progress */}
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={(theme) => ({
          color: color, // Custom color for the spinner
          animationDuration: '550ms', // Animation speed
          position: 'absolute', // Overlay position
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round', // Rounded stroke
          },
          ...theme.applyStyles('dark', {
            color: indeterminateColor, // Dark mode spinner color
          }),
        })}
        size={size}
        thickness={thickness}
      />
    </Box>
  );
}

export default FacebookCircularProgress;
