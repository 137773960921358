import { Box, IconButton, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SmsFailedSharpIcon from "@mui/icons-material/SmsFailedSharp";
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";

import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import MessageWindow from "../components/workspace/message/MessageWindow";
import Sidebar from "../components/workspace/sideBar/Sidebar";
import SettingBar from "../components/workspace/settingBar/SettingBar";
import { Navigate, useNavigate } from "react-router-dom";
// import { useWorkspace } from "../contexts/WorkspaceContext";
import CreditRechargeModal from "../components/CreditRechargeModal";
import VideoPreview from "../assets/ModalX_Video_Preview.mp4";
import VideoModal from "../components/VideoModal";
import * as Sentry from "@sentry/react";
import { useWorkspace } from "../contexts/WorkspaceContext";

const Check = () => {
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const [showVideoModal, setShowVideoModal] = useState(false);
  const navigate = useNavigate();
  const {
    threadId,
    currentThread,
    currentHistoryId,
    showFirstVisitModal,
    setShowFirstVisitModal,
  } = useWorkspace;

  useEffect(() => {
    if (!localStorage.getItem("threadId")) {
      setShowVideoModal(true);
    }

    const handleBeforeUnload = (event) => {
      if (currentThread.length > 0) {
        localStorage.setItem("current_thread_id", threadId);
        localStorage.setItem("current_history_id", currentHistoryId);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [currentThread, threadId, currentHistoryId]);

  const handleNavigation = () => {
    navigate("/workspace");
  };

  return (
    // isLoggedIn ?
    <div>
      <div
        style={{
          opacity: "0.4",
          display: "flex",
          width: "100vw",
          height: "100vh",
        }}
      >
        <Sidebar />
        <MessageWindow />
        <Sentry.ErrorBoundary
          fallback={<div>Something went wrong in the workspace</div>}
        >
          <SettingBar />
        </Sentry.ErrorBoundary>
        <CreditRechargeModal />
        {showFirstVisitModal && (
          <VideoModal
            show={showVideoModal}
            handleClose={() => setShowVideoModal(false)}
            videoSrc={VideoPreview}
          />
        )}
      </div>

      <Box
        sx={{
          zIndex: "50px",
          width: "480px",
          height: "300px",
          backgroundColor: "rgba(40, 45, 81, 1)",
          top: "200px",
          left: "480px",
          position: "absolute",
          borderRadius: "8px",
          border: "1px solid rgba(202, 196, 208, 1)",
        }}
      >
        {/* section1  */}

        <Box
          sx={{
            width: "480px",
            height: "68px",
            Border: "0px, 0px, 1px, 0px",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <Box
            sx={{
              width: "440px",
              height: "28px",

              display: "flex",
              justifyContent: "end",
            }}
          >
            <Box
              sx={{
                width: "28px",
                height: "28px",
              }}
            >
              <IconButton
                aria-label="close"
                onClick={handleNavigation}
                sx={{
                  color: "rgba(255, 255, 255, 1)",
                }}
              >
                <CloseIcon
                  sx={{
                    width: "16.33px",
                    height: "16.33px",
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        </Box>

        {/* section 2 */}

        <Box
          sx={{
            width: "400px",
            height: "160px",
            position: "absolute",
            top: "40px",
            left: "40px",
            gap: "20px",
            opacity: "0px",
          }}
        >
          <Box
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "20px",
              backgroundColor: "white",
            }}
          >
            {" "}
            <CheckCircleSharpIcon
              sx={{
                color: "rgba(35, 165, 101, 1)",
                width: "40px",
                height: "40px",
              }}
            />
          </Box>

          <Box
            sx={{
              width: "400px",
              height: "100px",

              gap: "20px",
            }}
          >
            <Box
              sx={{
                marginTop: "20px",
                width: "400px",
                height: "20px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  textAlign: "left",
                }}
              >
                Payment Successful
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: "20px",
                width: "400px",
                height: "50px",
              }}
            >
              <Typography
                sx={{
                  headingLarge: {
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    textAlign: "left",
                  },
                }}
              >
                You have been upgraded to ModalX Pro. Enjoy unlimited access to
                ModalX features!
              </Typography>
            </Box>
          </Box>
        </Box>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "rgba(246, 190, 107, 1)",
            color: "rgba(23, 24, 44, 1)",
            width: "400px",
            height: "40px",
            position: "absolute",
            top: "220px",
            left: "40px",
            padding: "10px 24px 10px 24px",
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "20px",
            letterSpacing: "0.1px",
            textAlign: "center",
            textTransform: "none",
            "&:hover": {
              bgcolor: "#ffd9aa",
            },
          }}
          onClick={handleNavigation}
        >
          Continue
        </Button>
      </Box>
    </div>
  );
};

export default Check;
