// import React, { useState, useEffect } from "react";
// import { Modal, Button, Form, Row, Col } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../contexts/AuthContext";
// import Notification from "../Notification";

// import { auth, createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword } from "../../config/firebase.config";

// function SignUpLogInModal({
//   showSignUpModal,
//   setShowSignUpModal,
//   showLoginModal,
//   setShowLoginModal,
// }) {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [showNotif, setShowNotif] = useState(false);
//   const [notifVariant, setNotifVariant] = useState("");
//   const [notifMsg, setNotifMsg] = useState("");
//   const { setUser } = useAuth();
//   const navigate = useNavigate();

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//       if (user) {
//         // User is signed in, handle account creation
//         handleAccountCreation(user);
//       }
//     });

//     return () => unsubscribe();
//   }, []);

//   const handleAccountCreation = async (user) => {
//     try {
//       const unsubscribe = auth.onAuthStateChanged((user) => {
//         if (user && user.emailVerified) {
//           createUser();
//           unsubscribe(); // Unsubscribe from onAuthStateChanged
//         }
//       });
//     } catch (error) {
//       console.error("Error completing account creation:", error);
//       setNotifMsg(`Error completing account creation: ${error.message}`);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const createUser = async () => {
//     try {
//       await setUser(auth.currentUser);
//       setShowSignUpModal(false);
//       setShowLoginModal(false);
//       navigate("/workspace");
//     } catch (error) {
//       console.error("Error creating user:", error);
//       setNotifMsg(`Error creating user: ${error.message}`);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const handleSignUp = async () => {
//     try {
//       await createUserWithEmailAndPassword(auth, email, password);
//       await sendVerificationEmail();
//       setNotifMsg("Verification email sent. Please verify your email address.");
//       setNotifVariant("info");
//       setShowNotif(true);
//     } catch (error) {
//       console.error("Error signing up:", error);
//       setNotifMsg(`Error signing up: ${error.message}`);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const sendVerificationEmail = async () => {
//     const user = auth.currentUser;
//     if (user) {
//       await sendEmailVerification(user);
//     } else {
//       throw new Error("User not signed in.");
//     }
//   };

//   const handleLogin = async () => {
//     try {
//       const userCredential = await signInWithEmailAndPassword(auth, email, password);
//       setUser(userCredential.user);
//       setShowLoginModal(false);
//       navigate("/workspace");
//     } catch (error) {
//       console.error("Error logging in:", error);
//       setNotifMsg(`Error logging in: ${error.message}`);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const handleFormSubmit = (e) => {
//     e.preventDefault();
//     if (showSignUpModal) {
//       if (password === confirmPassword) {
//         handleSignUp();
//       } else {
//         setNotifMsg("Passwords do not match.");
//         setNotifVariant("danger");
//         setShowNotif(true);
//       }
//     } else {
//       handleLogin();
//     }
//   };

//   return (
//     <>
//       <Notification
//         showNotif={showNotif}
//         setShowNotif={setShowNotif}
//         variant={notifVariant}
//       >
//         {notifMsg}
//       </Notification>

//       <Modal
//         centered
//         show={showSignUpModal || showLoginModal}
//         onHide={() => {
//           setShowSignUpModal(false);
//           setShowLoginModal(false);
//         }}
//       >
//         <Modal.Header className="justify-content-center">
//           <Modal.Title>{showSignUpModal ? "Sign up" : "Login"}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="my-3">
//           <Row className="justify-content-center">
//             <Col md={10}>
//               <Form onSubmit={handleFormSubmit}>
//                 <Form.Group controlId="formBasicEmail" className="mb-3">
//                   <Form.Label>Email address</Form.Label>
//                   <Form.Control
//                     type="email"
//                     placeholder="Enter email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     required
//                   />
//                 </Form.Group>
//                 <Form.Group controlId="formBasicPassword" className="mb-3">
//                   <Form.Label>Password</Form.Label>
//                   <Form.Control
//                     type="password"
//                     placeholder="Password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     required
//                   />
//                 </Form.Group>
//                 {showSignUpModal && (
//                   <Form.Group controlId="formBasicConfirmPassword" className="mb-3">
//                     <Form.Label>Confirm Password</Form.Label>
//                     <Form.Control
//                       type="password"
//                       placeholder="Confirm Password"
//                       value={confirmPassword}
//                       onChange={(e) => setConfirmPassword(e.target.value)}
//                       required
//                     />
//                   </Form.Group>
//                 )}
//                 <Button variant="primary" type="submit" className="w-100">
//                   {showSignUpModal ? "Sign up" : "Login"}
//                 </Button>
//               </Form>
//             </Col>
//           </Row>
//         </Modal.Body>
//         <Modal.Footer className="justify-content-center">
//           {showSignUpModal ? (
//             <p>
//               Already a member?{" "}
//               <Button variant="link" onClick={() => setShowLoginModal(true)}>
//                 Login
//               </Button>
//             </p>
//           ) : (
//             <p>
//               New user?{" "}
//               <Button variant="link" onClick={() => setShowSignUpModal(true)}>
//                 Sign up
//               </Button>
//             </p>
//           )}
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }

// function SignUpLogIn() {
//   const [showSignUpModal, setShowSignUpModal] = useState(false);
//   const [showLoginModal, setShowLoginModal] = useState(false);

//   return (
//     <>
//       <Button
//         variant="primary"
//         className="me-2 mb-2"
//         onClick={() => setShowSignUpModal(true)} // Show signup modal
//       >
//         Sign up
//       </Button>
//       <Button
//         variant="secondary"
//         className="mb-2 me-2"
//         onClick={() => setShowLoginModal(true)} // Show login modal
//       >
//         Login
//       </Button>
//       <SignUpLogInModal
//         showSignUpModal={showSignUpModal}
//         setShowSignUpModal={setShowSignUpModal}
//         showLoginModal={showLoginModal}
//         setShowLoginModal={setShowLoginModal}
//       />
//     </>
//   );
// }

// export default SignUpLogIn;

// ===========================================================
// import React, { useState } from "react";
// import { Modal, Button, Form, Row, Col } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../contexts/AuthContext";
// import Notification from "../Notification";

// import { auth, createUserWithEmailAndPassword, sendEmailVerification } from "../../config/firebase.config";
// import SignUp from "./Signup";
// import Login from "./Login";

// function SignUpLogIn() {
//   const [showSignUpModal, setShowSignUpModal] = useState(false);
//   const [showLoginModal, setShowLoginModal] = useState(false);
//   const [emailSent,setEmailSent] = useState(false);
//   return (
//     <>
//       <Button
//         variant="primary"
//         className="me-2 mb-2"
//         onClick={() => setShowSignUpModal(true)} // Show signup modal
//       >
//         Sign up
//       </Button>
//       <Button
//         variant="secondary"
//         className="mb-2 me-2"
//         onClick={() => setShowLoginModal(true)} // Show login modal
//       >
//         Login
//       </Button>
//       <SignUp
//         showSignUpModal={showSignUpModal}
//         setShowSignUpModal={setShowSignUpModal}
//         setShowLoginModal={setShowLoginModal}
//         showLoginModal={showLoginModal}
//         setEmailSent={setEmailSent}
//       />
//       <Login
//         showLoginModal={showLoginModal}
//         setShowLoginModal={setShowLoginModal}
//         setShowSignUpModal={setShowSignUpModal}
//         emailSent={emailSent}
//       />
//     </>
//   );
// }

// export default SignUpLogIn;

// Above one is the latest on 12-06-2024

import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import {Box} from '@mui/material'
import SignUp from "./Signup";
import Login from "./Login";
import { button_bg_color_for_New_Project_dm } from "../../constants/colors";
import Image1 from "../../assets/signup/Group 174.png";
import Image2 from "../../assets/signup/Frame 142.png";
import ResetPassword from "./ResetPassword";
import ResendEmailmodal from "./ResendEmailmodal";
import PasswordChangedmodal from "./PasswordChangedmodal";
import EnterNewPasswordModal from "./EnterNewPasswordModal";
import { useMediaQuery } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";

function SignUpLogIn({
  showSignUpModal,
  setShowSignUpModal,
  showLoginModal,
  setShowLoginModal,
  setEmailSent,
  showVerificationModal,
  setShowVerificationModal
}) {
  const [email, setEmail] = useState("");
  const [currentIndex, setCurrentIndex] = useState(1);
  // const [showResendModal, setShowResendModal] = useState(false);
  const [showEnterNewPasswordModal, setShowEnterNewPasswordModal] = useState(false);
  const [showPasswordChangedModal, setShowPasswordChangedModal] = useState(false);
  const isBelow991 = useMediaQuery("(max-width:991px)");
  const {showResetPasswordModal, showResendModal} =useAuth()
  let images = [
    {
      image: Image1,
      title: "Personalized Solutions, Custom Outputs",
      disc: "Get a custom AI playbook designed for your needs, not a one-size-fits-all solution. Generate customized solutions for all your business goals.",
    },
    {
      image: Image2,
      title: "Simplified ",
      disc: "We cut through the AI clutter so you can focus on what matters - growing your business",
    },
    {
      image: Image1,
      title: "Personalized Solutions, Custom Outputs",
      disc: "Get a custom AI playbook designed for your needs, not a one-size-fits-all solution. Generate customized solutions for all your business goals.",
    },
    {
      image: Image1,
      title: "Personalized Solutions, Custom Outputs",
      disc: "Get a custom AI playbook designed for your needs, not a one-size-fits-all solution. Generate customized solutions for all your business goals.",
    },
    {
      image: Image2,
      title: "Simplified ",
      disc: "We cut through the AI clutter so you can focus on what matters - growing your business",
    },
  ];

  useEffect(() => {
    document.body.style.overflow =
      showSignUpModal ||
      showLoginModal ||
      showResetPasswordModal ||
      showResendModal
        ? "hidden"
        : "auto";
    return () => {
      document.body.style.overflow = "auto"; // Ensure scrollbars are re-enabled on unmount
    };
  }, [
    showSignUpModal,
    showLoginModal,
    showResetPasswordModal,
    showResendModal,
  ]);

  const handleRedirect = (link) => {
    window.open(link, '_blank'); // Opens the link in a new tab
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "10px",
        flexDirection:isBelow991?'column':'row'
      }}
    >
    <div style={{display:"flex", flexDirection: isBelow991 ? "column" : "row", alignSelf:"center", justifyContent:"center", textAlign:"center"}}>
    {!showLoginModal && !showSignUpModal && !showVerificationModal && !showResetPasswordModal && !showResendModal && <Button
        className="mb-2 me-2"
        onClick={()=>{setShowLoginModal(true)}} // Show login modal
        style={{
          display:"flex",
          alignSelf:"center",
          maxWidth: "100px",
          backgroundColor: "transparent",
          border: "none",
          fontFamily : 'Inter',
          fontWeight : 500,
          color : '#FFFFFFCC',
          opacity : '80%',
          fontSize : isBelow991 ? '20px' : '16px',          
        }}
      >
        Login

      
      </Button>}
      <Box sx={{
        display : showSignUpModal ? 'none' : 'block'
      }}>
      <Button
       onClick={() => handleRedirect('mailto:noreply@ModalX.ai')}
       variant="outlined"
        style={{
        display : isBelow991 ? 'block' : 'none',
        marginTop : '36px',
        width: '310px',
        height: '40px',
        borderColor: '#F6BE6A',
        color: '#F6BE6A',
        textTransform: 'none',
        fontSize: isBelow991?'14px':'16px',
        '&:hover': {
          borderColor: '#F6BE6A',
          backgroundColor: 'transparent', // No border color changes on hover
        },
        fontFamily: 'Inter, sans-serif',
       }} >Request demo</Button>
      </Box>

      {!showLoginModal && !showSignUpModal &&  !showVerificationModal && !showResetPasswordModal && !showResendModal && <Button
        variant="primary"
        className="me-2 mb-2"
        onClick={()=>{setShowSignUpModal(true)}} // Show signup modal
        style={{
          backgroundColor: button_bg_color_for_New_Project_dm,
          color: "black",
          fontWeight: "600",
          border: "none",
          marginTop :  isBelow991 ? '24px' : '',
          width : isBelow991 ? '310px' : ''
        }}
      >
        Get Started
      </Button>} 
      </div>
        <SignUp
         setEmailSent={setEmailSent}
         images={images}
         currentIndex={currentIndex}
         setCurrentIndex={setCurrentIndex}
       />
      <Login
        images={images}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex} 
      />
       {/* {showResetPasswordModal && (
          <ResetPassword
            setShowSignUpModal={setShowSignUpModal}
            showLoginModal={showLoginModal}
            setShowLoginModal={setShowLoginModal}
            showResetPasswordModal={showResetPasswordModal}
            setShowResetPasswordModal={setShowResetPasswordModal}
            setShowResendModal={setShowResendModal}
            setEmail={setEmail}
            email={email}
            
          />)}
        {showResendModal && (
          <ResendEmailmodal
            setShowSignUpModal={setShowSignUpModal}
            showLoginModal={showLoginModal}
            setShowLoginModal={setShowLoginModal}
            showResetPasswordModal={showResetPasswordModal}
            setShowResetPasswordModal={setShowResetPasswordModal}
            setShowResendModal={setShowResendModal}
            showResendModal={showResendModal}
            setEmail={setEmail}
            email={email}
          />)} */}
  
            {/* {showEnterNewPasswordModal && (
        <div>
          <EnterNewPasswordModal
            setShowSignUpModal={setShowSignUpModal}
            showLoginModal={showLoginModal}
            setShowLoginModal={setShowLoginModal}
            showResetPasswordModal={showResetPasswordModal}
            setShowResetPasswordModal={setShowResetPasswordModal}
            setShowResendModal={setShowResendModal}
            showPasswordChangedModal={showPasswordChangedModal}
            setShowPasswordChangedModal={setShowPasswordChangedModal}
          />
        </div>
      )}
          {showPasswordChangedModal && (
        <div>
          <PasswordChangedmodal
            showLoginModal={showLoginModal}
            setShowLoginModal={setShowLoginModal}
            showPasswordChangedModal={showPasswordChangedModal}
            setShowPasswordChangedModal={setShowPasswordChangedModal}
          />
        </div>
      )} */}

      </div>
  );
}

export default SignUpLogIn;

// import React, { useState } from "react";
// import { Modal, Button, Form, Row, Col } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../contexts/AuthContext";
// import Notification from "../Notification";
// import { useUI } from "../../contexts/UIContext";

// function SignUpLogInModal({
//   email,
//   setEmail,
//   password,
//   setPassword,
//   showSignUpModal,
//   setShowSignUpModal,
//   showLoginModal,
//   setShowLoginModal,
// }) {
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [inFocus, setInFocus] = useState("");
//   const [visiblePass, setVisiblePass] = useState("");
//   const [emailValid, setEmailValid] = useState(false);
//   const [passwordsMatch, setPasswordsMatch] = useState(false);

//   const [showNotif, setShowNotif] = useState(false);
//   const [notifVariant, setNotifVariant] = useState("");
//   const [notifMsg, setNotifMsg] = useState("");

//   const navigate = useNavigate();
//   const { setUser } = useAuth();

//   const isFormValid = emailValid && passwordsMatch;

//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//     setEmailValid(e.target.checkValidity());
//   };

//   const handlePasswordChange = (e) => {
//     setPassword(e.target.value);
//     if (showSignUpModal) {
//       setPasswordsMatch(
//         e.target.value.length > 0 && e.target.value === confirmPassword
//       );
//     }
//     if (showLoginModal) {
//       setPasswordsMatch(e.target.value.length > 0);
//     }
//   };

//   const handleConfirmPasswordChange = (e) => {
//     setConfirmPassword(e.target.value);
//     setPasswordsMatch(e.target.value.length > 0 && password === e.target.value);
//   };

//   async function getUser() {
//     try {
//       const userResponse = await fetch("https://modalx.ai/profile", {
//         method: "GET",
//         credentials: "include",
//       });
//       const responseData = await userResponse.json();
//       const user = await responseData.result;
//       console.log(user);
//       if (!user) setUser(null);
//       else {
//         setUser(user);
//       }
//     } catch (err) {
//       console.log("error getting userData");
//       setUser(null);
//     }
//   }

//   const handleSubmit = async (event) => {
//     event.preventDefault(); // Prevent default form submission
//     const url = showSignUpModal ? "https://modalx.ai/signup" : "https://modalx.ai/login";
//     try {
//       const response = await fetch(url, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ email, password }),
//       });

//       if (response.ok) {
//         if (showSignUpModal) {
//           setNotifMsg(
//             showSignUpModal
//               ? "User signed up successfully!"
//               : "User logged in successfully!"
//           );
//         }

//         setNotifVariant("success");
//         setShowNotif(true);

//         if (showLoginModal) {
//           await getUser();
//           navigate("/workspace");
//         }

//         // Optionally close the modal after successful signup
//         resetSignInLogInModal(showSignUpModal);
//       } else {
//         // Handle error response
//         setNotifMsg(
//           "Error " +
//             (showSignUpModal ? "signing" : "loging") +
//             " up: " +
//             response.statusText
//         );
//         setNotifVariant("danger");
//         setShowNotif(true);

//         console.error(
//           "Error",
//           showSignUpModal ? "signing" : "loging",
//           "up:",
//           response.statusText
//         );
//       }
//     } catch (error) {
//       setNotifMsg(
//         "Error " +
//           (showSignUpModal ? "signing" : "loging") +
//           " up: " +
//           error.message
//       );
//       setNotifVariant("danger");
//       setShowNotif(true);
//       console.error(
//         "Error ",
//         showSignUpModal ? "signing" : "loging",
//         " up: ",
//         error.message
//       );
//     }
//   };

//   function resetSignInLogInModal(goToLogin = false) {
//     setEmail("");
//     setPassword("");
//     setConfirmPassword("");
//     setEmailValid(false);
//     setPasswordsMatch(false);
//     setShowSignUpModal(false);
//     setShowLoginModal(goToLogin);
//   }

//   const handleLoginClick = () => {
//     resetSignInLogInModal();
//     setShowLoginModal(true);
//   };

//   const handleSignUpClick = () => {
//     resetSignInLogInModal();
//     setShowSignUpModal(true);
//   };

//   return (
//     <>
//       <Notification
//         showNotif={showNotif}
//         setShowNotif={setShowNotif}
//         variant={notifVariant}
//       >
//         {notifMsg}
//       </Notification>

//       <Modal
//         centered
//         show={showSignUpModal || showLoginModal}
//         onHide={resetSignInLogInModal}
//       >
//         <Modal.Header className="justify-content-center">
//           <Modal.Title>{showSignUpModal ? "Sign up" : "Login"}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="my-3">
//           <Row className="justify-content-center">
//             <Col md={10}>
//               <Form onSubmit={handleSubmit}>
//                 <Form.Group controlId="formBasicEmail" className="mb-3">
//                   <Form.Label
//                     className={`mb-2 ${inFocus === "email" && "fw-bold"}`}
//                   >
//                     Email address
//                   </Form.Label>
//                   <Form.Control
//                     className="mb-1"
//                     type="email"
//                     autoComplete="email"
//                     placeholder="name@example.com"
//                     value={email}
//                     onChange={handleEmailChange}
//                     onFocus={() => setInFocus("email")}
//                     onBlur={() => setInFocus("")}
//                     required
//                     autoFocus
//                   />
//                   {!emailValid && email.length > 0 && (
//                     <p style={{ color: "red" }}>Please enter a valid email.</p>
//                   )}
//                 </Form.Group>
//                 <Form.Group controlId="formBasicPassword" className="mb-3">
//                   <Form.Label
//                     className={`mb-2 ${inFocus === "password" && "fw-bold"}`}
//                   >
//                     Password
//                   </Form.Label>
//                   <Row className="align-items-center">
//                     {/* <Col xs={1}>
//                     <i className="bi bi-shield-lock fs-4" />
//                   </Col> */}
//                     <Col xs={11} className="pe-0">
//                       <Form.Control
//                         className="mb-1"
//                         type={visiblePass === "password" ? "text" : "password"}
//                         placeholder="Password"
//                         autoComplete={
//                           showSignUpModal ? "new-password" : "current-password"
//                         }
//                         value={password}
//                         onChange={handlePasswordChange}
//                         onFocus={() => setInFocus("password")}
//                         onBlur={() => setInFocus("")}
//                         required
//                       />
//                     </Col>
//                     <Col xs={1}>
//                       {/* Eye icon to toggle password visibility */}
//                       {visiblePass === "password" ? (
//                         <i
//                           className="bi bi-eye-slash fs-5"
//                           onClick={() => setVisiblePass("")}
//                           style={{ cursor: "pointer" }}
//                         />
//                       ) : (
//                         <i
//                           className="bi bi-eye fs-5"
//                           onClick={() => setVisiblePass("password")}
//                           style={{ cursor: "pointer" }}
//                         />
//                       )}
//                     </Col>
//                   </Row>
//                 </Form.Group>

//                 {showSignUpModal && (
//                   <Form.Group
//                     controlId="formBasicConfirmPassword"
//                     className="mb-3"
//                   >
//                     <Form.Label
//                       className={`mb-2 ${
//                         inFocus === "confirmPassword" && "fw-bold"
//                       }`}
//                     >
//                       Confirm Password
//                     </Form.Label>
//                     <Row className="align-items-center">
//                       {/* <Col xs={1}>
//                     <i className="bi bi-shield-lock fs-4" />
//                   </Col> */}
//                       <Col xs={11} className="pe-0">
//                         <Form.Control
//                           className="mb-1"
//                           type={
//                             visiblePass === "confirmPassword"
//                               ? "text"
//                               : "password"
//                           }
//                           autoComplete="new-password"
//                           placeholder="Confirm Password"
//                           value={confirmPassword}
//                           onChange={handleConfirmPasswordChange}
//                           onFocus={() => setInFocus("confirmPassword")}
//                           onBlur={() => setInFocus("confirmPassword")}
//                           required
//                         />
//                       </Col>
//                       <Col xs={1}>
//                         {/* Eye icon to toggle password visibility */}
//                         {visiblePass === "confirmPassword" ? (
//                           <i
//                             className="bi bi-eye-slash fs-5"
//                             onClick={() => setVisiblePass("")}
//                             style={{ cursor: "pointer" }}
//                           />
//                         ) : (
//                           <i
//                             className="bi bi-eye fs-5"
//                             onClick={() => setVisiblePass("confirmPassword")}
//                             style={{ cursor: "pointer" }}
//                           />
//                         )}
//                       </Col>
//                     </Row>
//                     {!passwordsMatch && confirmPassword.length > 0 && (
//                       <p style={{ color: "red" }}>Passwords do not match.</p>
//                     )}
//                   </Form.Group>
//                 )}
//                 {showLoginModal && (
//                   <p className="mb-3 text-center">
//                     <Button variant="link">Forgot Password?</Button>
//                   </p>
//                 )}
//                 <Button
//                   variant="primary"
//                   type="submit"
//                   disabled={!isFormValid}
//                   className="mt-4 w-100"
//                 >
//                   {showSignUpModal ? "Sign up" : "Login"}
//                 </Button>
//               </Form>
//             </Col>
//           </Row>
//         </Modal.Body>
//         <Modal.Footer className="justify-content-center">
//           {showSignUpModal ? (
//             <p>
//               Already a member?{" "}
//               <Button variant="link" onClick={handleLoginClick}>
//                 Login
//               </Button>
//             </p>
//           ) : (
//             <p>
//               New user?{" "}
//               <Button variant="link" onClick={handleSignUpClick}>
//                 Sign up
//               </Button>
//             </p>
//           )}
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }

// function SignUpLogIn() {
//   const { showLoginModal, setShowLoginModal } = useUI();
//   const [showSignUpModal, setShowSignUpModal] = useState(false);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");

//   return (
//     <>
//       <Button
//         variant="primary"
//         className="me-2 mb-2"
//         onClick={() => setShowSignUpModal(true)}
//       >
//         Sign up
//       </Button>
//       <Button
//         variant="secondary"
//         className="mb-2 me-2"
//         onClick={() => setShowLoginModal(true)}
//       >
//         Login
//       </Button>
//       <SignUpLogInModal
//         email={email}
//         setEmail={setEmail}
//         password={password}
//         setPassword={setPassword}
//         showSignUpModal={showSignUpModal}
//         setShowSignUpModal={setShowSignUpModal}
//         showLoginModal={showLoginModal}
//         setShowLoginModal={setShowLoginModal}
//       />
//     </>
//   );
// }

// export default SignUpLogIn;
