import React, { useEffect, useState } from 'react';
import { Button as MuiButton, Dialog,Button, DialogActions, DialogContent, DialogTitle,useMediaQuery, LinearProgress, Tooltip,Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import FileUploader from '../settingBar/FileUploader';
import LinkUploader from '../settingBar/LinkUploader';
import videoIcon from "../../../assets/Button/videoIcon.png";
import settingIcon from "../../../assets/Button/settings.png";
import musicIcon from "../../../assets/Button/Music Library.png";
import pictureIcon from "../../../assets/Button/Picture.png";
import presentationIcon from "../../../assets/Button/presentation.png";
import { button_bg_color_for_New_Project, fileUploader_bg_color_inside_onclickModal, hoveredSettings_button_bg_color, oneClickModal_backgroundColor_dm, selecetedSettings_Nav_bg_dm, uploadFiles_button_default_bg_color, uploadFiles_button_hovered_bg_color } from '../../../constants/colors';
import ViewThreadFiles from "../settingBar/ViewThreadFiles";
import { trackContentGeneration, trackModalOpen } from '../../../config/analytics';
import { Navigate, useNavigate } from 'react-router-dom';
import { useCreditRecharge } from '../../../contexts/CreditRechageContext';
import { VideoGenAPISettings } from '../settingBar/VideoGenSettings/VideoGenAPISettings';
// import { Button } from 'react-bootstrap';

const ServicesForDefaultWindow = () => {
    const { threadFiles,setThreadFiles, sendMessage, setCurrentThread, setWaitThread, setSending, historyFiles,showVideoGenSettings,setShowVideoSettings,setShowImageSettings,setShowPptSettings,setShowAudioSettings,currentThread,waitThread, setShowVideoGenSettings,setGenVideoLanguage, setGenVideoAudioSettingsLoading,setGenVideoVoice,setGenVideoVoicesLoading,setCaptionFontForVideoGen, genVideoLanguage , genVideoVoice, setArrayForLinksAvalible, setHasLink } = useWorkspace();
    const theme = localStorage.getItem('theme');
    const [currText, setCurrText] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState(''); // State for modal title
    const [files, setFiles] = useState([]);
    const [filesLen,setFilesLen] = useState(0);
    const [fileProcessing, setFileProcessing] = useState(false);
    const [history_Files, setHistoryFiles] = useState([]);
    const token = localStorage.getItem('token');
    const isBelow1500 = useMediaQuery('(max-width:1500px)');
    const navigate = useNavigate()
    const [close , setClose] = useState(true)
    const { showCreditRecharge, setShowCreditRecharge } = useCreditRecharge();

    const onClose = () =>{
        setClose(false)
    }

 

    useEffect(() => {
        if (historyFiles) {
            setHistoryFiles(historyFiles);
        }
    }, [historyFiles]);

    const handleOneClick = async (text, title) => {
        setCurrText(text);
        setModalTitle(title); // Set the modal title
        setShowModal(true);
        trackModalOpen(title)
    };

    useEffect(() => {
        setFilesLen(files.length);
    }, [files]);

    useEffect(() => {
        setFiles([...threadFiles, ...historyFiles]);
        setFilesLen(files.length);
    }, [threadFiles,historyFiles]);

    // console.log(files, "okkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk");
    useEffect(() => {
        const isAnyFileProcessing = files.some(file => 
            file && file.status && (file.status !== 'completed' && file.status !== 'failed')
        );
        if(isAnyFileProcessing){
            setFileProcessing(true);
        }
        else{
            setFileProcessing(false);
        }
        
    }, [files]);
    

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSend = () => {
        handleSubmit(currText);
        setShowModal(false);
        console.log(currText)
        // trackContentGeneration("Image", currText)
    };

    const findUrl = (msg) => {
        const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
        const match = urlRegex.exec(msg);
        return match ? match[1] : null;
      };
    
      const getFileFormat = (url) => {
        // Extract the file extension from the URL
        const parts = url.split('.');
        if (parts.length > 1) {
          return parts[parts.length - 1]; // Return the last part as the file format
        } else {
          return ''; // Return empty string if no file extension found
        }
      };

      const extractLinks = async (inputString) => {
        setArrayForLinksAvalible([])
        // Regular expressions for each file type
        const pptRegex = /https?:\/\/[^\s]+\.pptx\b/g;
        const jpegRegex = /https?:\/\/[^\s]+\.jpeg\b/g;
        const mp4Regex = /https?:\/\/[^\s]+\.mp4\b/g;
        const mp3Regex = /https?:\/\/[^\s]+\.mp3\b/g;
        
        // Extract and push matches into their respective arrays
        const pptMatches = inputString?.match(pptRegex);
        if (pptMatches) {
          setArrayForLinksAvalible((prev)=> [...prev, "pptx"])
        }
        
        const jpegMatches = inputString?.match(jpegRegex);
        if (jpegMatches) {
          setArrayForLinksAvalible((prev)=> [...prev, "image"])
        }
        
        const mp4Matches = inputString?.match(mp4Regex);
        if (mp4Matches) {
          setArrayForLinksAvalible((prev)=> [...prev, "video"])
        }
        const mp3Matches = inputString?.match(mp3Regex);
        if (mp3Matches) {
          setArrayForLinksAvalible((prev)=> [...prev, "audio"])
        }
        
        return 
        };

    const handleSubmit = async (input) => {
        setSending(true);
        setWaitThread(true);
        if (input.trim() && !fileProcessing) {
            const latestEntry = { sender: "user", content: input };
            setCurrText("");
            setCurrentThread((prevThread) => [...prevThread, latestEntry]);
            const entryResponse = await sendMessage(latestEntry).catch(console.error);
            if(findUrl(entryResponse.content)){
                setHasLink(true)
                await extractLinks(entryResponse.content)
                }
            if (entryResponse) {
                // Check if entryResponse contains a daily limit exceeded message
                if (
                  entryResponse &&
                  entryResponse.content &&
                  findUrl(entryResponse.content) &&
                  findUrl(entryResponse.content).includes("dailylimitexceeded")
                ) {
                  // Determine the file format from the URL (assuming findUrl() returns the URL)
                  const fileFormat = getFileFormat(findUrl(entryResponse.content));
          
                  // Prepare the response message
                  const responseMessage = {
                    content: `Hi there,\n\nIt looks like you’ve reached your daily limit for generating `+ fileFormat +` files. We’re really sorry about that! We know how important uninterrupted access is for you.\n\nTo keep enjoying all the great benefits and have a seamless experience, we’d love for you to consider upgrading to our premium plan.\n\nThank you so much for your understanding and support. We truly value having you with us and are excited to continue providing you with the best service possible.\n\nWarm regards,\n\nThe ModalX Team`,
                    sender: 'modalX'
                  };
          
                  // Update the current thread with the response message
                  setCurrentThread((prevThread) => [...prevThread, responseMessage]);
                  setShowCreditRecharge(true);
                } else {
                  // If the response does not contain a daily limit exceeded message, proceed as usual
                  setCurrentThread((prevThread) => [...prevThread, entryResponse]);
                }
                setSending(false);
              } else {
                // If there's no entryResponse, indicate processing failure
                setCurrentThread((prevThread) => [...prevThread, {content:"Unable to process your query!..",sender:'modalX'}]);
                setSending(false);
              }
        }
        setWaitThread(false);
    };
    // fetchVideoSettings()
    useEffect(()=>{
        if(genVideoLanguage || genVideoVoice){
            VideoGenAPISettings(setGenVideoLanguage, setGenVideoAudioSettingsLoading,setGenVideoVoice,setGenVideoVoicesLoading,setCaptionFontForVideoGen)
        }
      },[])


    const handleSettingsClick = async(title) => {
        if(title === 'Video_Settings'){
            setShowVideoSettings(true);
            trackModalOpen(title)
        }
        else if(title === 'Image_Settings'){
            setShowImageSettings(true);
            trackModalOpen(title)
        }
        else if(title === 'Presentation_Settings'){
            setShowPptSettings(true);
            trackModalOpen(title)
        }
        else if(title  === 'Audio_Settings'){
            setShowAudioSettings(true);
            trackModalOpen(title)
        }
        else if(title  === 'VideoGen_Settings'){
            // console.log("VideoGen_Settings")
            setShowVideoGenSettings(true);
            trackModalOpen(title)
        }

    }

    const buttonStyle = {
        backgroundColor: '#1F233E',
        position: 'relative',
        fontSize:isBelow1500?'13px':'1rem',
        transition: 'transform 0.3s',
        padding: isBelow1500?'3px 0px 3px 0px':'3px 10px 3px 20px',
        gap: '10px',
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
        width:isBelow1500?'130px':'150px',
        height:'40px',
        color:theme ==='dark'?'white':''
    };

    const settingButtonStyle = {
        backgroundColor: '#1F233E',
        position: 'relative',
        fontSize:'1rem',
        transition: 'transform 0.3s',
        padding: '9px',
        gap: '10px',
        borderLeft: '1px solid #4C4F65',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        // width:'36px',
        height:'40px'
    };

    const handleMouseEnter = (e) => {
        // e.currentTarget.style.color = 'blue';
        e.currentTarget.style.backgroundColor = '#282D51';
    };

    const handleMouseLeave = (e) => {
        // e.currentTarget.style.transform = 'scale(1)';
        // e.currentTarget.style.color = theme === 'light' ? 'black' : 'white'; // Adjust color here
        e.currentTarget.style.backgroundColor = '#1F233E'
    };

    const handleMouseLeaveForAudioSettings = (e) => {
        // e.currentTarget.style.transform = 'scale(1)';
        // e.currentTarget.style.color = theme === 'light' ? 'black' : 'white'; // Adjust color here
        e.currentTarget.style.backgroundColor = '#282D51'
    };

    const datas = [
        {id : 1 , title1 : 'An image that speaks', style1 : buttonStyle , handleOneClick : {text : 'Shrink the transcribed content from various sources, such as videos, audio recordings, and PDFs, to within 3000 words. Keep the points concise and generate an image from the summary accordingly.' ,title : 'Generate Image'}, handleMouseEnter : handleMouseEnter, handleMouseLeave : handleMouseLeave , image1 :pictureIcon , image1Title : 'Picture', style2 : settingButtonStyle, handleSettingsClick :'Image_Settings',image2 : settingIcon },

        {id : 5 , title1 : 'An videogen that connects', style1 : buttonStyle , handleOneClick : {text : 'Shrink the transcribed content from various sources, such as videos, audio recordings, and PDFs, to within 3000 words. Keep the points concise and generate an video from the summary accordingly.' ,title : 'Generate Video'},handleMouseEnter : handleMouseEnter, handleMouseLeave : handleMouseLeave , image1 :videoIcon , image1Title : 'Video', style2 : settingButtonStyle, handleSettingsClick :'VideoGen_Settings',image2 : settingIcon },
        
        // {id : 2 , title1 : 'A video that inspires', style1 : buttonStyle , handleOneClick : {text : 'Shrink the transcribed content from various sources, such as videos, audio recordings, and PDFs, to within 3000 words. Keep the points concise and generate an image from the summary accordingly.' ,title : 'Generate Video'}, handleMouseEnter : handleMouseEnter, handleMouseLeave : handleMouseLeave , image1 :videoIcon , image1Title : 'Video', style2 : settingButtonStyle, handleSettingsClick :'Video_Settings',image2 : settingIcon },

        {id : 3 , title1 : 'A presentation that delivers value', style1 : buttonStyle , handleOneClick : {text : 'Shrink the transcribed content from various sources, such as videos, audio recordings, and PDFs, to within 3000 words. Keep the points concise and generate an presentation from the summary accordingly.' ,title : 'Generate Presentation'}, handleMouseEnter : handleMouseEnter, handleMouseLeave : handleMouseLeave , image1 :presentationIcon , image1Title : 'Presentation', style2 : settingButtonStyle, handleSettingsClick :'Presentation_Settings',image2 : settingIcon },

        {id : 4 , title1 : 'An audio that connects', style1 : buttonStyle , handleOneClick : {text : 'Shrink the transcribed content from various sources, such as videos, audio recordings, and PDFs, to within 3000 words. Keep the points concise and generate an audio from the summary accordingly.' ,title : 'Generate Audio'},handleMouseEnter : handleMouseEnter, handleMouseLeave : handleMouseLeave , image1 :musicIcon , image1Title : 'Audio', style2 : settingButtonStyle, handleSettingsClick :'Audio_Settings',image2 : settingIcon },
    ]
 

    return (
        <div style={{ lineHeight: "13px", overflow: "hidden" }}>
        <div
        style={{
        display: "flex",
        justifyContent: "space-evenly",
        position: "relative",
        maxWidth: "930px",
        margin: "auto",
        }}
        >
        {datas.map((e) => (
        <div style={{ display: "flex" }} key={e.id}>
        <Tooltip title={e.title1} placement="bottom">
            <MuiButton
            style={buttonStyle}
            onClick={() => handleOneClick(e.handleOneClick.text,e.handleOneClick.title)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{ textTransform: "none" }}
            >
            <img src={e.image1} alt="Picture Icon" />
            {e.id === 3 ?<p style={{position:'absolute',fontSize:'8px',top:'2px',right:'3px',color:button_bg_color_for_New_Project,fontStyle: 'italic'}}>Beta</p> : ''}
            {e.image1Title}
            </MuiButton>
        </Tooltip>
        <MuiButton
            style={settingButtonStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleSettingsClick(e.handleSettingsClick)}
            sx={{ minWidth: isBelow1500 ? "40px" : "60px" }}
        >
            <img src={e.image2} alt="Settings Icon" />
            
        </MuiButton>
        </div>
        ))}

        </div>
        <Dialog
        open={showModal}
        onClose={handleCloseModal}
        maxWidth="lg"
        fullWidth
        PaperProps={{
        sx: {
            width: "720px",
            height: "615px",
            gap: "0px",
            borderRadius: "8px",
            border: "1px solid #CAC4D0",
            opacity: "0px",
            bgcolor: "#282D51",
        },
        }}
        >
        <div
        style={{
            backgroundColor:
            theme === "dark" ? oneClickModal_backgroundColor_dm : "",
            color: theme === "dark" ? "white" : "",
        }}
        >
        {/* section 1 */}
        <Box
            sx={{
            width: "100%",
            height: "68px",
            padding: "20px",
            gap: "10px",
            borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
            opacity: "0px",
            }}
        >
            <Box
            sx={{
                width: "680px",
                height: "28px",
                gap: "0px",
                opacity: "0px",
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
            }}
            >
            <Box
                sx={{
                width: "248px",
                height: "20px",
                gap: "0px",
                opacity: "0px",
                }}
            >
                
                <Typography
                sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    textAlign: "left",
                    color: "#FFFFFF",
                }}
                >
                {modalTitle}
                </Typography>
            </Box>

            <Box
                sx={{
                width: "28px",
                height: "28px",
                }}
            >
                <CloseIcon
                onClick={handleCloseModal}
                aria-label="close"
                sx={{
                    paddingTop: "5.83px",
                    paddingLeft: "5.83px",
                    gap: "0px",
                    opacity: "0px",
                    color: "#FFFFFF",
                    "&:hover": {
                    cursor: "pointer",
                    },
                }}
                />
            </Box>
            </Box>
        </Box>
        {/* section 1 */}
        <DialogContent>
            {/* section 2 */}

            <Box
            sx={{
                width: "680px",
                height: "96px",
                position: "absolute",
                top: "84px",
                gap: "16px",
                opacity: "0px",
            }}
            >
            <Box
                sx={{
                width: "680px",
                height: "20px",
                opacity: "0px",
                opacity: "0px",
                }}
            >
                <Typography
                sx={{
                    fontFamily: "Inter",
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    textAlign: "left",
                    color: "#FFFFFF",
                }}
                >
                Prompt
                </Typography>
            </Box>

            <Box
                sx={{
                width: "680px",
                height: "60px",
                position: "absolute",
                top: "36px",
                opacity: "0px",
                opacity: "0px",
                }}
            >
                <Typography
                sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    textAlign: "left",
                    color: "#FFFFFF",
                }}
                >
                {currText}
                </Typography>
            </Box>
            </Box>
            {/* section 2 */}

            {/* section 3 */}

            <Box
            sx={{
                width: "360px",
                height: "244px",
                position: "absolute",
                top: "216px",
                left: "20px",
                // paddingLeft : '50px',
                opacity: "0px",
                border: "2px dotted #FFFFFF",
                borderRadius: "10px",
                alignItems: "center",

                bgcolor: "#1F233E",
                display: "flex",
                // justifyContent : 'center',
                margin: "auto",
                textAlign: "center",
            }}
            >
            <FileUploader />
            </Box>
            {/* section 3 */}

            {/* section 4 */}

            <Box
            sx={{
                width: "360px",
                height: "60px",
                position: "absolute",
                top: "478px",
                left: "20px",
                // padding :'16px 24px 16px 24px',
                gap: "20px",
                opacity: "0px",
                border: "2px dotted #FFFFFF",
                borderRadius: "10px",
                alignItems: "center",
            }}
            >
            {" "}
            <Box
                sx={{
                // paddingBottom : '-100px'
                margin: "auto",
                }}
            >
                <LinkUploader />
            </Box>
            </Box>

            {/* section 4 */}

            {/* section 5 */}

            {/* section 6 */}
            <Box
            sx={{
                width: "280px",
                height: "108px",
                position: "absolute",
                top: "216px",
                left: "420px",
                gap: "0px",
                opacity: "0px",
            }}
            >
            <Box
                sx={{
                width: "260px",
                height: "40px",
                position: "absolute",
                left: "20px",
                }}
            >
                <Typography
                sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    textAlign: "left",
                }}
                >
                {filesLen == 0 ? (
                    <p>Upload at least one file to use this functionality </p>
                ) : (
                    <ViewThreadFiles />
                )}
                </Typography>
            </Box>
            </Box>

            {/* section 6 */}

            {/* section 7 button part */}
            <Box
            sx={{
                width: "100%",
                height: "72px",
                position: "absolute",
                top: "538px",
                left: "0px",
                gap: "10px",
                opacity: "0px",
                bgcolor: "#282D51",
            }}
            >
            <Box
                sx={{
                width: "230px",
                height: "40px",
                position: "absolute",
                right: "16px",
                top: "16px",
                gap: "16px",
                opacity: "0px",
                display: "flex",
                justifyContent: "space-between",
                }}
            >
                {/* <DialogActions> */}
                <Box
                onClick={handleCloseModal}
                variant="contained"
                sx={{
                    backgroundColor:
                    theme === "dark" ? uploadFiles_button_default_bg_color : "",
                    color: "black",
                    width: "93px",
                    height: "40px",
                    borderRadius: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    "&:hover": {
                    backgroundColor: uploadFiles_button_hovered_bg_color,
                    },
                }}
                >
                <Box
                    sx={{
                    width: "45px",
                    height: "20px",
                    }}
                >
                    <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        letterSpacing: "0.1",
                        textAlign: "center",
                        color: "#17182C",
                    }}
                    >
                    Close
                    </Typography>
                </Box>
                </Box>

                <Button
                onClick={handleSend}
                variant="contained"
                disabled={
                    fileProcessing ||
                    (files.length === 0 && history_Files.length === 0)
                }
                sx={{
                    backgroundColor:
                    files.length === 0 && history_Files.length === 0
                        ? "grey"
                        : button_bg_color_for_New_Project,
                    width: "121px",
                    height: "40px",
                    borderRadius: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    "&:hover": {
                    backgroundColor: "#FFD9AA",
                    },
                    color: "black",
                }}
                >
                <Typography
                    sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    textAlign: "center",
                    color: "#17182C",
                    textTransform: "none",
                    }}
                >
                    Generate
                </Typography>
                </Button>

                {/* </DialogActions> */}
            </Box>
            </Box>
        </DialogContent>
        </div>
        </Dialog>
        </div>
    );
};

export default ServicesForDefaultWindow;




// import React, { useEffect, useState } from 'react';
// import { OverlayTrigger, Button, Modal, ProgressBar } from 'react-bootstrap';
// import { useWorkspace } from "../../../contexts/WorkspaceContext";
// import FileUploader from '../settingBar/FileUploader';

// const ServicesForDefaultWindow = () => {
//     const { threadFiles, sendMessage, setCurrentThread, setWaitThread, checkUrlStatus, setSending,historyFiles } = useWorkspace();
//     const theme = localStorage.getItem('theme');
//     const [currText, setCurrText] = useState('');
//     const [showModal, setShowModal] = useState(false);
//     const [files, setFiles] = useState([]);
//     const [fileProcessing, setFileProcessing] = useState(false);
//     const [history_Files,setHistoryFiles] = useState([]);
//     const token = localStorage.getItem('token');

//     useEffect(() => {
//         if(historyFiles){
//           setHistoryFiles(historyFiles);
//         }
//       }, [historyFiles])

//     const handleOneClick = async (text) => {
//         setCurrText(text);
//         setShowModal(true);
//     };

//     useEffect(() => {
//         setFiles(threadFiles);
//     }, [threadFiles]);

//     useEffect(() => {
//         const isAnyFileProcessing = files.some(file => file && file.status === 'processing');
//         setFileProcessing(isAnyFileProcessing);
//     }, [files]);

//     const renderOverlay = (text) => (
//         <div style={{ position: 'absolute', top: '-30px', left: '50%', transform: 'translateX(-50%)', backgroundColor: 'blue', color: '#fff', padding: '5px', borderRadius: '5px', zIndex: '999', pointerEvents: 'none' }}>
//             {text}
//         </div>
//     );

//     const handleCloseModal = () => {
//         setShowModal(false);
//     };

//     const handleSend = () => {
//         handleSubmit(currText);
//         setShowModal(false);
//     };

//     const handleSubmit = async (input) => {
//         setSending(true);
//         setWaitThread(true);
//         if (input.trim() && !fileProcessing) {
//             const latestEntry = { sender: "user", content: input };
//             setCurrText("");
//             setCurrentThread((prevThread) => [...prevThread, latestEntry]);
//             const entryResponse = await sendMessage(latestEntry).catch(console.error);
//             console.log(entryResponse);
//             if (entryResponse) {
//                 setSending(false);
//                 setCurrentThread((prevThread) => [...prevThread, entryResponse]);
//             } else {
//                 setSending(false);
//                 setCurrentThread((prevThread) => [...prevThread, { content: "Unable to process your query!..", sender: 'modalX' }]);
//             }
//         }
//         setWaitThread(false);
//     };

//     return (
//         <div>
//             <p style={{ textAlign: 'center', fontSize: '1.1rem',color: theme === 'dark' ? '#6c757d' : '', fontWeight: '600' }}>One Click Output</p>
//             <hr></hr>
//             <div style={{ display: 'flex', justifyContent: 'space-evenly', position: 'relative' }}>
//                 <OverlayTrigger
//                     placement="bottom"
//                     overlay={renderOverlay('Generate Video')}
//                 >
//                     <Button
//                         style={{
//                             background: 'none',
//                             border: 'none',
//                             position: 'relative',
//                             fontSize:'1.8rem',
//                             transition: 'transform 0.3s'
//                         }}
//                         onClick={() => handleOneClick('Shrink the transcribed content from various sources, such as videos, audio recordings, and PDFs, to within 3000 words. Keep the points concise and generate a video summary accordingly.')}
//                         onMouseEnter={(e) => {
//                             e.currentTarget.style.transform = 'scale(.8)'
//                             e.currentTarget.style.color = 'blue'
//                         }}
//                         onMouseLeave={(e) => {
//                             e.currentTarget.style.transform = 'scale(1)'
//                             e.currentTarget.style.color = 'white'
//                         }}
//                     >
//                         <i className="bi bi-camera-reels" style={{ color: theme === 'light' ? 'black' : '' }} />
//                     </Button>
//                 </OverlayTrigger>

//                 <OverlayTrigger
//                     placement="bottom"
//                     overlay={renderOverlay('Generate Audio')}
//                 >
//                     <Button
//                         style={{
//                             background: 'none',
//                             border: 'none',
//                             position: 'relative',
//                             fontSize:'1.8rem',
//                             transition: 'transform 0.3s'
//                         }}
//                         onClick={() => handleOneClick('Shrink the transcribed content from various sources, such as videos, audio recordings, and PDFs, to within 3000 words. Keep the points concise and generate an audio summary accordingly.')}
//                         onMouseEnter={(e) => {
//                             e.currentTarget.style.transform = 'scale(.8)'
//                             e.currentTarget.style.color = 'blue'
//                         }}
//                         onMouseLeave={(e) => {
//                             e.currentTarget.style.transform = 'scale(1)'
//                             e.currentTarget.style.color = 'white'
//                         }}
//                     >
//                         <i className="bi bi-file-earmark-music" style={{ color: theme === 'light' ? 'black' : '' }} />
//                     </Button>
//                 </OverlayTrigger>

//                 <OverlayTrigger
//                     placement="bottom"
//                     overlay={renderOverlay('Generate PPT')}
//                 >
//                     <Button
//                         style={{
//                             background: 'none',
//                             border: 'none',
//                             position: 'relative',
//                             fontSize:'1.8rem',
//                             transition: 'transform 0.3s',
//                         }}
//                         onClick={() => handleOneClick('Shrink the transcribed content from various sources, such as videos, audio recordings, and PDFs, to within 3000 words. Keep the points concise and generate a ppt accordingly.')}
//                         onMouseEnter={(e) => {
//                             e.currentTarget.style.transform = 'scale(.8)'
//                             e.currentTarget.style.color = 'blue'
//                         }}
//                         onMouseLeave={(e) => {
//                             e.currentTarget.style.transform = 'scale(1)'
//                             e.currentTarget.style.color = 'white'
//                         }}
//                     >
//                         <i className="bi bi-journal-text" style={{ color: theme === 'light' ? 'black' : '' }} />
//                     </Button>
//                 </OverlayTrigger>

//                 <OverlayTrigger
//                     placement="bottom"
//                     overlay={renderOverlay('Generate Image')}
//                 >
//                     <Button
//                         style={{
//                             background: 'none',
//                             border: 'none',
//                             position: 'relative',
//                             fontSize:'1.8rem',
//                             transition: 'transform 0.3s'
//                         }}
//                         onClick={() => handleOneClick('Shrink the transcribed content from various sources, such as videos, audio recordings, and PDFs, to within 3000 words. Keep the points concise and generate an image from the summary accordingly.')}
//                         onMouseEnter={(e) => {
//                             e.currentTarget.style.transform = 'scale(.8)'
//                             e.currentTarget.style.color = 'blue'
//                         }}
//                         onMouseLeave={(e) => {
//                             e.currentTarget.style.transform = 'scale(1)'
//                             e.currentTarget.style.color = 'white'
//                         }}
//                     >
//                         <i className="bi bi-images" style={{ color: theme === 'light' ? 'black' : '' }} />
//                     </Button>
//                 </OverlayTrigger>
//             </div>
//             <Modal size="lg" show={showModal} onHide={handleCloseModal} centered>
//                 <Modal.Header closeButton />
//                 <Modal.Body style={{marginLeft:'10px'}}>
//                     <h6>Prompt :</h6>
//                     <p>{currText}</p>
//                     <h6 style={{ color: 'grey' }}>{files.length ? "Files :" : "No files uploaded yet"}</h6>
//                     {files.length ? (
//                         // Display files here
//                         <div>
//                             <ul>
//                                 {files.map((file, index) => (
//                                     <li key={index}>
//                                         {file.name}
//                                         {file.type === 'file' && file.status === 'uploading' && (
//                                             <ProgressBar animated now={100} label="Uploading..." />
//                                         )}
//                                         {file.type === 'file' && file.status === 'upload failed' && (
//                                             <p style={{ color: 'red' }}>Upload Failed</p>
//                                         )}
//                                     </li>
//                                 ))}
//                             </ul>
//                             <FileUploader />
//                         </div>
//                     ) : (
//                         // Input element for adding files

//                         <FileUploader />
//                     )}
//                     {files.length === 0 && history_Files.length === 0 ?<p style={{fontWeight:'600',fontStyle:'italic',color:'orange'}}>You have to upload at least one file to explore this functionality!</p>:<></>}
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
//                     <Button variant="primary" onClick={handleSend} style={{backgroundColor:(files.length === 0 && history_Files.length === 0 )?'grey':''}} disabled={fileProcessing || (files.length === 0 && history_Files.length === 0 )}>Generate</Button>
//                 </Modal.Footer>
//             </Modal>
//         </div>
//     );
// };

// export default ServicesForDefaultWindow;


