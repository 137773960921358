import React from "react";
import { useAuth } from "../contexts/AuthContext.jsx";
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoute = () => {
  const { user, setShowLoginModal } = useAuth();
  if (!user) {
    setShowLoginModal(true);
  }
  // const isLoggedIn = localStorage.getItem("isLoggedIn");

  return user ? <Outlet /> : <Navigate to="/home" replace />;
};

export default PrivateRoute;

// import React, { useEffect } from "react";
// import { useAuth } from "../contexts/AuthContext";
// import { Outlet, Navigate, useNavigate } from "react-router-dom";

// const PrivateRoute = () => {
//   const { user, setShowLoginModal } = useAuth();
//   const navigate = useNavigate();

//   useEffect(() => {
//     // Assuming `user` is null when not logged in and an object when logged in
//     if (user && !user.firstName) {
//       // Redirect users with no firstName (considered new users) to the user journey
//       navigate("/userJourney");
//     }
//   }, [user, navigate]);

//   if (!user) {
//     // Show login modal if not logged in
//     setShowLoginModal(true);
//     return <Navigate to="/home" replace />;
//   }

//   // At this point, the user is logged in, and if they have a firstName, they are not redirected
//   // So, render the child routes protected by this PrivateRoute
//   return <Outlet />;
//   // or
//   return user.firstName ? <Outlet />:<Navigate to="/userJourney" replace >;
// };

// export default PrivateRoute;
