// import React, { useState } from 'react';
// import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
// import { useWorkspace } from "../../../../contexts/WorkspaceContext";

// const stylePresets = [
//   '3d-model', 'analog-film', 'anime', 'cinematic', 'comic-book',
//   'digital-art', 'enhance', 'fantasy-art', 'isometric', 'line-art',
//   'low-poly', 'modeling-compound', 'neon-punk', 'origami',
//   'photographic', 'pixel-art', 'tile-texture'
// ];

// const ImageModel = () => {
//   const {imageModel,setImageModel} = useWorkspace();
//   // const [selectedStyle, setSelectedStyle] = useState('3d-model');
//   const [showModal, setShowModal] = useState(false);

//   const handleStyleSelect = (style) => {
//     setImageModel(style);
//   };

//   const handleModalToggle = () => {
//     setShowModal(!showModal);
//   };

//   return (
//     <>
//       <Button variant="outline-primary" onClick={handleModalToggle} style={{textAlign:'start'}}>
//         Image Model ({imageModel})
//       </Button>

//       <Modal show={showModal} onHide={handleModalToggle} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>Select Image Style</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Container>
//             <Row className="d-flex flex-wrap">
//               {stylePresets.map((style) => (
//                 <Col key={style} className="mb-3" xs="auto">
//                   <Button
//                     variant="outline-primary"
//                     onClick={() => handleStyleSelect(style)}
//                     style={{
//                       backgroundColor: imageModel === style ? 'blue' : '',
//                       color: imageModel === style ? 'white' : ''
//                     }}
//                   >
//                     {style}
//                   </Button>
//                 </Col>
//               ))}
//             </Row>
//           </Container>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleModalToggle} style={{backgroundColor:'blue'}}>
//             Save
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default ImageModel;


import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useWorkspace } from "../../../../contexts/WorkspaceContext";

const stylePresets = [
  '3d-model', 'analog-film', 'anime', 'cinematic', 'comic-book',
  'digital-art', 'enhance', 'fantasy-art', 'isometric', 'line-art',
  'low-poly', 'modeling-compound', 'neon-punk', 'origami',
  'photographic', 'pixel-art', 'tile-texture'
];

const ImageModel = () => {
  const { imageModel, setImageModel } = useWorkspace();
  const theme = localStorage.getItem('theme');
  const handleStyleSelect = (style) => {
    setImageModel(style);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom style={{fontSize:'16px',fontWeight:'500',width:'143px',height:'20px',marginBottom:'20px'}}>
        Select Image Style
      </Typography>
      <Grid container spacing={2}>
        {stylePresets.map((style) => (
          <Grid item key={style} xs="auto">
            <Button
              variant="outlined"
              onClick={() => handleStyleSelect(style)}
              sx={{
                textTransform: 'none',
                backgroundColor: imageModel === style ? '#3a3f69' : '#30355f',
                color: theme === 'dark' ? 'white' : '',
                '&:hover': {
                  backgroundColor: imageModel === style ? '#3a3f69' : '',
                },
                borderColor: imageModel === style ? '#cac4d0' : 'transparent',
                m: 0.5, // Add margin for spacing
              }}
            >
              {style}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ImageModel;
