import React from "react";
import { Box, Typography, } from "@mui/material";
import CaptionSettingOption from "./CaptionSettingOption";

const CaptionSettings = () => {

  return (
    <>
      <Box sx={{ pr: 3 }}>
        <Box sx={{ pt: 2.5 }}>
          <Typography
            variant="h6"
            style={{
              fontSize: "16px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "20px",
            }}
          >
            Caption settings
          </Typography>
        </Box>
        <Box>
          <CaptionSettingOption />
        </Box>
      </Box>
    </>
  );
};

export default CaptionSettings;
