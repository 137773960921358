import React from "react";
import PageLayout from "../components/navFoot/PageLayout";
import RequestDemoNearFooter from "../components/LandingPage/RequestDemoNearFooter";
import {
  Box,
  Typography,
  useMediaQuery,
  Button,
  CardMedia,
} from "@mui/material";
// import { useMediaQuery } from 'react-responsive'
import image01 from "../assets/LandingPage_Items/useCases/useCase01.png";
import image02 from "../assets/LandingPage_Items/useCases/useCase02.png";
import image03 from "../assets/LandingPage_Items/useCases/useCase03.png";
import image04 from "../assets/LandingPage_Items/useCases/useCase04.png";

function UseCases() {
  const isBelow991 = useMediaQuery("(max-width : 991px)");
  const datas1 = [
    {
      id: 1,
      image: image01,
      title1: "Boost Marketing  Scalability",
      title2:
        "Boost marketing scalability with AI-driven creative automation. Quickly produce on-brand content at scale. Experiment with different versions and dynamically update. Personalize brand interactions across touchpoints.",
      button1: "Request demo",
      button2: "Get started free",
    },
    {
      id: 2,
      image: image02,
      title1: "Manage Creative Operations",
      title2:
        "Administer creative operations with advanced AI tools in a unified, brand-compliant workspace. Easily access assets, guidelines, templates, and past campaigns. Ensure AI-driven brand compliance with pre-flight workflows.",
      button1: "Request demo",
      button2: "Get started free",
    },
    {
      id: 3,
      image: image03,
      title1: "Consistent, Crisp Outcomes",
      title2:
        "Achieve consistent, crisp outcomes while preserving the essence and subtleties of your initial message. Refine and perfect your voiceovers with Speech to Speech editing.",
      button1: "Request demo",
      button2: "Get started free",
    },
    {
      id: 4,
      image: image04,
      title1: "Organize Ideas, Execute Faster",
      title2:
        "Our tool streamlines idea organization, expediting project completion, whether managing small or large datasets. ModalX swiftly transitions from disorderly concepts to intended messages.",
      button1: "Request demo",
      button2: "Get started free",
    },
  ];
  return (
    <PageLayout>
      <Box
        sx={{
          marginTop: isBelow991 ? "110px" : "261px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: isBelow991 ? "12px" : "16px",
            fontWeight: 600,
            lineHeight: isBelow991 ? "18px" : "24px",
            textAlign: "center",
            color: "#F6BE6B",
          }}
        >
          Use Cases
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: isBelow991 ? "24px" : "48px",
          padding: "0px , 20px 0px , 20px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: isBelow991 ? "28px" : "48px",
            fontWeight: 500,
            lineHeight: isBelow991 ? "33.6px" : "57.6px",
            textAlign: "center",
            letterSpacing: "-0.3px",
            color: "#FFFFFF",
            opacity: "80%",
          }}
        >
          Leverage ModalX to Maximize your Growth
        </Typography>
      </Box>
      {/* section 2 */}
      <Box sx={{
        display : isBelow991 ? 'none' : 'block'
     }}>
     <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "0px 20px 0px 20px",
          marginTop: "180px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: "1272px",
            minHeight: "438px",
            borderRadius: "8px",
            bgcolor: "#1A1E34",
            border: "1px solid rgba(255, 255, 255, 0.1)",
          }}
        >
          <Box
            sx={{
              margin: "0px 48px 0px 48px",
              maxWidth: "600px",
              // height : '400px',
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                maxWidth: "600px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "48px",
                  fontWeight: 500,
                  lineHeight: "57.6px",
                  letterSpacing: "-0.32",
                  textAlign: "left",
                  color: "#F6BE6B",
                  opacity: "60%",
                }}
              >
                Boost marketing scalability
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: "600px",
                marginTop: "30px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontWeight: 500,
                  letterSpacing: "-0.32px",
                  lineHeight: "27px",
                  textAlign: "left",
                  color: "#FFFFFF",
                  opacity: "80%",
                }}
              >
                Boost marketing scalability with AI-driven creative automation.
                Quickly produce on-brand content at scale. Experiment with
                different versions and dynamically update. Personalize brand
                interactions across touchpoints.
              </Typography>
            </Box>
            <Box
              sx={{
                width: "370px",
                marginTop: "48px",
                // bgcolor: "green",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                  textTransform: "none",
                  width: "140px",
                  height: "40px",
                  border: "1px solid #F6BE6A",
                  color: "#F6BE6A",
                }}
              >
                Request demo
              </Button>

              <Button
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                  textTransform: "none",
                  width: "173px",
                  height: "40px",
                  // border : '1px solid #F6BE6A',
                  color: "#17182C",
                  bgcolor: "#F6BE6A",
                }}
              >
                Get started free
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              margin: "0px 48px 0px 48px",
              maxWidth: "487px",
              maxHeight: "222.5px",
              borderRadius: "4px",
            }}
          >
            <CardMedia component="img" image={image01} alt="image01" />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "0px 20px 0px 20px",
          marginTop: "100px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: "1272px",
            minHeight: "438px",
            borderRadius: "8px",
            bgcolor: "#1A1E34",
            border: "1px solid rgba(255, 255, 255, 0.1)",
          }}
        >
          <Box
            sx={{
              margin: "0px 48px 0px 48px",
              maxWidth: "480px",
              maxHeight: "270px",
              borderRadius: "4px",
            }}
          >
            <CardMedia component="img" image={image02} alt="image01" />
          </Box>

          <Box
            sx={{
              margin: "0px 48px 0px 48px",
              maxWidth: "600px",
              // height : '400px',
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                maxWidth: "600px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "48px",
                  fontWeight: 500,
                  lineHeight: "57.6px",
                  letterSpacing: "-0.32",
                  textAlign: "left",
                  color: "#F6BE6B",
                  opacity: "60%",
                }}
              >
                Manage Creative Operations
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: "600px",
                marginTop: "30px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontWeight: 500,
                  letterSpacing: "-0.32px",
                  lineHeight: "27px",
                  textAlign: "left",
                  color: "#FFFFFF",
                  opacity: "80%",
                }}
              >
                Administer creative operations with advanced AI tools in a
                unified, brand-compliant workspace. Easily access assets,
                guidelines, templates, and past campaigns. Ensure AI-driven
                brand compliance with pre-flight workflows.
              </Typography>
            </Box>
            <Box
              sx={{
                width: "370px",
                marginTop: "48px",
                // bgcolor: "green",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                  textTransform: "none",
                  width: "140px",
                  height: "40px",
                  border: "1px solid #F6BE6A",
                  color: "#F6BE6A",
                }}
              >
                Request demo
              </Button>

              <Button
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                  textTransform: "none",
                  width: "173px",
                  height: "40px",
                  // border : '1px solid #F6BE6A',
                  color: "#17182C",
                  bgcolor: "#F6BE6A",
                }}
              >
                Get started free
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "0px 20px 0px 20px",
          marginTop: "100px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: "1272px",
            minHeight: "438px",
            borderRadius: "8px",
            bgcolor: "#1A1E34",
            border: "1px solid rgba(255, 255, 255, 0.1)",
          }}
        >
          <Box
            sx={{
              margin: "0px 48px 0px 48px",
              maxWidth: "600px",
              // height : '400px',
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                maxWidth: "600px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "48px",
                  fontWeight: 500,
                  lineHeight: "57.6px",
                  letterSpacing: "-0.32",
                  textAlign: "left",
                  color: "#F6BE6B",
                  opacity: "60%",
                }}
              >
                Consistent, Crisp Outcomes
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: "600px",
                marginTop: "30px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontWeight: 500,
                  letterSpacing: "-0.32px",
                  lineHeight: "27px",
                  textAlign: "left",
                  color: "#FFFFFF",
                  opacity: "80%",
                }}
              >
                Achieve consistent, crisp outcomes while preserving the essence
                and subtleties of your initial message. Refine and perfect your
                voiceovers with Speech to Speech editing.
              </Typography>
            </Box>
            <Box
              sx={{
                width: "370px",
                marginTop: "48px",
                // bgcolor: "green",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                  textTransform: "none",
                  width: "140px",
                  height: "40px",
                  border: "1px solid #F6BE6A",
                  color: "#F6BE6A",
                }}
              >
                Request demo
              </Button>

              <Button
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                  textTransform: "none",
                  width: "173px",
                  height: "40px",
                  // border : '1px solid #F6BE6A',
                  color: "#17182C",
                  bgcolor: "#F6BE6A",
                }}
              >
                Get started free
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              margin: "0px 48px 0px 48px",
              maxWidth: "487px",
              maxHeight: "222.5px",
              borderRadius: "4px",
            }}
          >
            <CardMedia component="img" image={image03} alt="image01" />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "0px 20px 0px 20px",
          marginTop: "100px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: "1272px",
            minHeight: "438px",
            borderRadius: "8px",
            bgcolor: "#1A1E34",
            border: "1px solid rgba(255, 255, 255, 0.1)",
          }}
        >
          <Box
            sx={{
              margin: "0px 48px 0px 48px",
              maxWidth: "480px",
              maxHeight: "270px",
              borderRadius: "4px",
             
            }}
          >
            <CardMedia component="img" image={image04} alt="image01" />
          </Box>

          <Box
            sx={{
              margin: "0px 48px 0px 48px",
              maxWidth: "600px",
              // height : '400px',
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                maxWidth: "600px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "48px",
                  fontWeight: 500,
                  lineHeight: "57.6px",
                  letterSpacing: "-0.32",
                  textAlign: "left",
                  color: "#F6BE6B",
                  opacity: "60%",
                }}
              >
                Organize Ideas, Execute Faster
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: "600px",
                marginTop: "30px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontWeight: 500,
                  letterSpacing: "-0.32px",
                  lineHeight: "27px",
                  textAlign: "left",
                  color: "#FFFFFF",
                  opacity: "80%",
                }}
              >
                Our tool streamlines idea organization, expediting project
                completion, whether managing small or large datasets. ModalX
                swiftly transitions from disorderly concepts to intended
                messages.
              </Typography>
            </Box>
            <Box
              sx={{
                width: "370px",
                marginTop: "48px",
                // bgcolor: "green",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                  textTransform: "none",
                  width: "140px",
                  height: "40px",
                  border: "1px solid #F6BE6A",
                  color: "#F6BE6A",
                }}
              >
                Request demo
              </Button>

              <Button
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                  textTransform: "none",
                  width: "173px",
                  height: "40px",
                  // border : '1px solid #F6BE6A',
                  color: "#17182C",
                  bgcolor: "#F6BE6A",
                }}
              >
                Get started free
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

     </Box>

      {/* section 2 */}
      {/* for mobile view */}
      <Box
        sx={{
          marginTop: "60px",
          display: "flex",
          flexDirection : 'column',
          justifyContent: "center",
          display : isBelow991 ? 'block' : 'none'
        }}
      >
       
          {datas1.map((e) => (
            <Box key={e.id}
              sx={{
                minWidth: "358px",
                minHeight: "499px",
                borderRadius: "8px",
                border: "1px solid rgba(255, 255, 255, 0.1)",
                bgcolor: "#1A1E34",
                
                margin: "0px 3% 40px 3%",
              }}
            >
              <Box
                sx={{
                  maxWidth: "100%",
                  marginTop: "24px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "24px",
                    fontWeight: 500,
                    lineHeight: "28.8px",
                    letterSpacing: "-0.35",
                    textAlign: "left",
                    opacity: "80%",
                    color: "#F6BE6B",
                    padding: "0px 24px 0px 24px",
                  }}
                >
                  {e.title1}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: "40px",
                  width: "100%",
                  minHeight: "142px",
                }}
              >
                <CardMedia
                  sx={{
                    padding: "0px 24px 0px 24px",
                  }}
                  component="img"
                  image={e.image}
                  alt="image01"
                />
              </Box>
              <Box
                sx={{
                  marginTop: "40px",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 500,
                    // lineHeight: "21px",
                    // letterSpacing: "-0.35",
                    // textAlign: "left",
                    opacity: "80%",
                    color: "#FFFFFF",
                    padding: "0px 24px 0px 24px",
                  }}
                >
                 {e.title2}
                </Typography>
              </Box>
              <Box
                sx={{
                  maxWidth: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "25px 24px 25px 24px",
                }}
              >
                <Button
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    textTransform: "none",
                    width: "147px",
                    height: "40px",
                    border: "1px solid #F6BE6A",
                    color: "#F6BE6A",
                  }}
                >
                  {e.button1}
                </Button>

                <Button
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    textTransform: "none",
                    width: "147px",
                    height: "40px",
                    // border : '1px solid #F6BE6A',
                    color: "#17182C",
                    bgcolor: "#F6BE6A",
                  }}
                >
                  {e.button2}
                </Button>
              </Box>
            </Box>
          ))}
        
      </Box>

      {/* for mobile view */}
      <Box>
        <RequestDemoNearFooter />
      </Box>
    </PageLayout>
  );
}

export default UseCases;
