import React from "react";
import { Box, Typography, Button } from "@mui/material";
import PageLayout from "../components/navFoot/PageLayout";
import RequestDemoNearFooter from "../components/LandingPage/RequestDemoNearFooter";

function PrivacyPolicy() {
  return (
    <>
      <PageLayout>
        {/* section 1 */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              marginTop: "246px",
              gap: "48px",
              opacity: "0px",
              "@media(max-width : 991px)": {
                marginTop: "156px",
              },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "48px",
                fontWeight: "500px",
                lineHeight: "57.6px",
                letterSpacing: "-0.32",
                opacity: "0.8px",
                textAlign: "center",
                color: "rgba(255, 255, 255, 0.8)",
                "@media(max-width : 991px)": {
                  fontSize: "28px",
                },
              }}
            >
              Privacy Policy
            </Typography>
          </Box>
        </Box>

        {/* section 1 */}

        {/* section 2 */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "220px",
              marginTop: "40px",
              gap: "0px",
              opacity: "0px",
              "@media(max-width : 991px)": {
                marginTop: "36px",
              },
            }}
          >
            {" "}
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: "500px",
                lineHeight: "24px",
                textAlign: "center",
                color: "rgba(255, 255, 255, 1)",
                "@media(max-width : 991px)": {
                  fontSize: "14px",
                },
              }}
            >
              Last Updated: July 24, 2024{" "}
            </Typography>
          </Box>
        </Box>

        {/* section 2 */}

        {/* section 3 */}
        <Box
          display={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "100px",
              gap: "20px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "60px",
                minWidth: "350px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "600px",
                  lineHeight: "24px,",
                  textAlign: "left",
                  "@media (max-width : 991px)": {
                    fontSize: "14px",
                    paddingLeft: "4.4%",
                  },
                }}
              >
                Effective:{" "}
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "500px",
                    lineHeight: "24px,",
                    textAlign: "left",
                    "@media (max-width : 991px)": {
                      fontSize: "14px",
                    },
                  }}
                  component="span"
                >
                  August 1 2024
                </Typography>{" "}
              </Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
                marginTop: "20px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "14px",
                    padding: "0px 4.4% 0px 4.4%",
                  },
                }}
              >
                BRIGHTSPOT APP INC.  (“Company”, “we”, “us”, or “our”) is
                committed to protecting the user’s privacy. This Privacy Policy
                explains how we collect, use, disclose, and safeguard the user’s
                information when the user uses our AI services (“Services”). If
                the user does not agree with the terms of this Privacy Policy,
                please do not access the Services.
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* section 3 */}

        {/* section 4 */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              top: "664px",
              gap: "40px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                gap: "36px",
                opacity: "0px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "24px",
                  fontWeight: "600px",
                  lineHeight: "36px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "20px",
                    paddingLeft: "4.4%",
                  },
                }}
              >
                1. Information We Collect
              </Typography>
            </Box>

            <Box
              sx={{
                width: "900px",
                marginTop: "40px",
                gap: "20px",
                opacity: "0px",
                "@media (max-width : 991px)": {
                  marginTop: "28px",
                  width: "100%",
                },

                // backgroundColor : 'black'
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    paddingLeft: "4.4%",
                    fontSize: "16px",
                  },
                }}
              >
                1.1 Personal Data
              </Typography>

              <Box
                sx={{
                  width: "900px",
                  gap: "0px",
                  opacity: "0px",
                  marginTop: "20px",
                  "@media (max-width : 991px)": {
                    marginTop: "16px",
                    padding: "0px 4.4% 0px 4.4%",
                    width: "100%",
                  },
                }}
              >
                <Box component="ul" sx={{ paddingLeft: 2 }}>
                  <Box component="li">
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400px",
                        lineHeight: "24px",
                        textAlign: "left",
                        "@media (max-width : 991px)": {
                          fontSize: "14px",
                        },
                      }}
                    >
                      We collect the information that the user provides when
                      creating an account, setting preferences, or contacting
                      us. We also gather data about the user's device and how
                      the user interacts with our Services.
                    </Typography>
                  </Box>
                  <Box component="li">
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400px",
                        lineHeight: "24px",
                        textAlign: "left",
                        "@media (max-width : 991px)": {
                          fontSize: "14px",
                        },
                      }}
                    >
                      We may obtain various information about the user or their
                      devices from multiple sources, as detailed below: 
                    </Typography>
                  </Box>
                  <Box component="li" sx={{ marginLeft: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400px",
                        lineHeight: "24px",
                        textAlign: "left",
                        "@media (max-width : 991px)": {
                          fontSize: "14px",
                        },
                      }}
                    >
                      IP address.
                    </Typography>
                  </Box>

                  <Box component="li" sx={{ marginLeft: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400px",
                        lineHeight: "24px",
                        textAlign: "left",
                        "@media (max-width : 991px)": {
                          fontSize: "14px",
                        },
                      }}
                    >
                      Browser type and version.
                    </Typography>
                  </Box>
                  <Box component="li" sx={{ marginLeft: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400px",
                        lineHeight: "24px",
                        textAlign: "left",
                        "@media (max-width : 991px)": {
                          fontSize: "14px",
                        },
                      }}
                    >
                      Time zone setting.
                    </Typography>
                  </Box>
                  <Box component="li" sx={{ marginLeft: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400px",
                        lineHeight: "24px",
                        textAlign: "left",
                        "@media (max-width : 991px)": {
                          fontSize: "14px",
                        },
                      }}
                    >
                      Browser plug-in types and versions.
                    </Typography>
                  </Box>

                  <Box component="li" sx={{ marginLeft: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400px",
                        lineHeight: "24px",
                        textAlign: "left",
                        "@media (max-width : 991px)": {
                          fontSize: "14px",
                        },
                      }}
                    >
                      Operating system and platform.
                    </Typography>
                  </Box>

                  <Box component="li" sx={{ marginLeft: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400px",
                        lineHeight: "24px",
                        textAlign: "left",
                        "@media (max-width : 991px)": {
                          fontSize: "14px",
                        },
                      }}
                    >
                      Device information.
                    </Typography>
                  </Box>

                  <Box component="li" sx={{ marginLeft: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400px",
                        lineHeight: "24px",
                        textAlign: "left",
                        "@media (max-width : 991px)": {
                          fontSize: "14px",
                        },
                      }}
                    >
                      Pages the user views.
                    </Typography>
                  </Box>

                  <Box component="li" sx={{ marginLeft: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400px",
                        lineHeight: "24px",
                        textAlign: "left",
                        "@media (max-width : 991px)": {
                          fontSize: "14px",
                        },
                      }}
                    >
                      How the user interacts with links on the Services.
                    </Typography>
                  </Box>

                  <Box component="li">
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400px",
                        lineHeight: "24px",
                        textAlign: "left",
                        "@media (max-width : 991px)": {
                          fontSize: "14px",
                        },
                      }}
                    >
                      If the user does not provide the requested information,
                      they may be unable to use our Services if it is necessary
                      for their provision or if legally required.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* section 4 */}

        {/* section 5 */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "40px",
              gap: "20px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                minWidth: "350px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                gap: "0px",
                opacity: "0px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    padding: "0px 4.4% 0px 4.4%",
                    fontSize: "16px",
                  },
                }}
              >
                1.2 Registration and Profile Information
              </Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
                marginTop: "20px",
                gap: "0px",
                opacity: "0px",
                padding: "0px 2.2% 0px 2.2%",
                "@media (max-width : 991px)": {
                  minWidth: "350px",
                  marginTop: "16px",
                  padding: "0px 6.4% 0px 6.4%",
                },
              }}
            >
              <Box component="ul" sx={{ paddingLeft: 2 }}>
                <Box component="li">
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    When the user registers for an account, we ask for their
                    name and email address.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* section 5 */}

        {/* section 6 */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "40px",
              gap: "20px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                minWidth: "350px",
                marginBottom: "411px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "30px",
                gap: "0px",
                opacity: "0px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    padding: "0px 4.4% 0px 4.4%",
                    fontSize: "16px",
                  },
                }}
              >
                1.3 Information from Third Parties
              </Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
                marginTop: "20px",
                gap: "0px",
                opacity: "0px",
                padding: "0px 2.2% 0px 2.2%",
                "@media (max-width : 991px)": {
                  padding: "0px 6.2%x 0px 6.2%",
                  marginTop: "16px",
                },
              }}
            >
              <Box component="ul" sx={{ paddingLeft: 2 }}>
                <Box component="li">
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    If the user connects a third-party account, like Google, to
                    our Services, we receive information from that account, such
                    as their username, profile picture, and other profile
                    details.
                  </Typography>
                </Box>

                <Box component="li">
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    The user can view Google’s privacy policy{" "}
                    <Typography
                      sx={{ textDecoration: "underline" }}
                      component="span"
                    >
                      {" "}
                      here.
                    </Typography>
                  </Typography>
                </Box>

                <Box component="li">
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    We may enable other log-in services in the future, sharing
                    log-in credentials with the provider and receiving
                    information that the user has shared with them.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* section 6 */}

        {/* section 7 */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            "@media (max-width : 991px)": {
              display: "none",
            },
          }}
        >
          <Box
            sx={{
              width: "900px",
              height: "24px",
              marginTop: "207px",
              marginBottom: "171px",
              gap: "0px",
              opacity: "0px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: "500px",
                lineHeight: "24px,",
                textAlign: "left",
              }}
            >
              For any questions regarding these terms, please contact us at:{" "}
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "500px",
                  lineHeight: "24px,",
                  textAlign: "left",
                }}
                component="span"
              >
                support@modalx.ai
              </Typography>{" "}
            </Typography>
          </Box>
        </Box>

        {/* section 7 */}
        <Box>
          <RequestDemoNearFooter />
        </Box>
      </PageLayout>
    </>
  );
}

export default PrivacyPolicy;
