import React, { useEffect, useState } from "react";
import {
  Toolbar,
  IconButton,
  Select,
  MenuItem,
  Typography,
  FormControl,
  Box,
  Switch,
  styled,
  Tooltip,
} from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import { fetchCaptionFont } from "./VideoGenAPISettings";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import ColorPalette from "./ColorPalette";

const CaptionSettingOption = () => {
  // const [font, setFont] = React.useState("Arial");
  // const [fontSize, setFontSize] = useState("50");
  // const [isBold, setIsBold] = useState(false);
  // const [alignment, setAlignment] = useState("center");
  // const [valignment, setVAlignment] = useState("end");
  const [showcolorpalette, setShowcolorpalette] = useState(false);
  const [
    showcolorpaletteForBackgroundColor,
    setShowcolorpaletteForBackgroundColor,
  ] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElOne, setAnchorElOne] = useState(null);
  const [checked, setChecked] = useState(true);
  // const [captionBackgoundColor, setCaptionBackgoundColor] = useState({ r: 0,g: 0, b: 0, a: 0,});
  // const [selectedColorForCaptionText, setSeletedColorForCaptionText] = useState("#ffffff");
  // const [
  //   selectedColorForCaptionTextBackground,
  //   setSelectedColorForCaptionTextBackground,
  // ] = useState("FFFFFF");
  const {
    selectedCaptionFontForVideoGen,
    setSelectedCaptionFontForVideoGen,
    CaptionFontForVideoGen,
    setCaptionFontForVideoGen,
    showCaption,
    setShowCaption,
    color,
    setColor,
    selectedColorForCaptionText,
    setSeletedColorForCaptionText,
    selectedColorForCaptionTextBackground,
    setSelectedColorForCaptionTextBackground,
    isBold,
    setIsBold,
    alignment,
    setAlignment,
    valignment,
    setVAlignment,
    captionBackgoundColor,
    setCaptionBackgoundColor,
    fontSize,
    setFontSize,
  } = useWorkspace();
  const theme = localStorage.getItem("theme");

  const handleFontChange = (event) => {
    setSelectedCaptionFontForVideoGen(event.target.value);
  };

  const handleFontSizeChange = (event) => {
    setFontSize(event.target.value);
  };

  const toggleBold = () => {
    setIsBold(!isBold);
  };

  const handleAlignment = (align) => {
    setAlignment(align);
  };

  const handleFontHorizontalAlignmentChange = (event) => {
    setAlignment(event.target.value);
  };

  const handleFontVarticalAlignmentChange = (event) => {
    if (event.target.value === "start") {
      setVAlignment("TOP");
    } else if (event.target.value === "end") {
      setVAlignment("BOTTOM");
    } else {
      setVAlignment("MIDDLE");
    }
  };
  const getData = async () => {
    const data = await fetchCaptionFont(setCaptionFontForVideoGen);
    setCaptionFontForVideoGen(data);
  };

  const handleCloseColorPalette = () => {
    setAnchorEl(null);
    setShowcolorpalette(false);
  };

  const handleCloseColorPaletteForBackgroundColor = () => {
    setAnchorElOne(null);
    setShowcolorpaletteForBackgroundColor(false);
  };

  useEffect(() => {
    if (CaptionFontForVideoGen == null) {
      getData();
    }
  }, [selectedCaptionFontForVideoGen]);

  const rgbaToHex = ({ r, g, b, a }) => {
    const red = r.toString(16).padStart(2, "0");
    const green = g.toString(16).padStart(2, "0");
    const blue = b.toString(16).padStart(2, "0");
    const alpha = Math.round(a * 255)
      .toString(16)
      .padStart(2, "0");
    return `#${red}${green}${blue}${alpha}`;
  };

  useEffect(() => {
    setSeletedColorForCaptionText(rgbaToHex(color));
  }, [color]);

  useEffect(() => {
    setSelectedColorForCaptionTextBackground(rgbaToHex(captionBackgoundColor));
  }, [captionBackgoundColor]);

  const handleShowCaptionButtonClick = (event) => {
    setShowCaption(!showCaption);
    setChecked(event.target.checked);
  };
  const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 40,
    height: 24,
    padding: 0,
    display: "flex",
    "& .MuiSwitch-switchBase": {
      padding: 2,
      color: "#fff",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#34C759", // Green color for the checked state
        },
      },
    },
    "& .MuiSwitch-track": {
      borderRadius: 14,
      opacity: 1,
      backgroundColor: "#bdbdbd", // Default gray color
    },
  }));

  const menuProps = {
    PaperProps: {
      sx: {
        backgroundColor: "#2F355F",
        color: "#2F355F",
        padding: 0, // Remove padding at top and bottom
        borderRadius: "4px",
        // Hide scrollbar while allowing scrolling
        "&::-webkit-scrollbar": {
          display: "none",
        },
        overflowY: "auto", // Enable scrolling without scrollbar
        scrollbarWidth: "none", // Hide scrollbar in Firefox
        // Remove padding and spacing for each item in the dropdown
        "& .MuiMenuItem-root": {
          padding: "8px 16px", // Adjust padding as needed
        },
      },
    },
  };

  return (
    <Box sx={{ pt: 3, backgroundColor: "" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#2F355F",
          padding: "12px 12px",
          borderRadius: "8px",
          color: "white",
          width: "100%",
          mb: "40px",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "20px",
          }}
        >
          Show caption
        </Typography>
        <CustomSwitch
          checked={checked}
          onChange={handleShowCaptionButtonClick}
          inputProps={{ "aria-label": "custom switch" }}
        />
      </Box>

       {showCaption && (
        <Box>
          <Toolbar
            sx={{
              backgroundColor: "#2F355F",
              color: "white",
              borderRadius: 1,
              padding: 1,
              display: "flex",
              justifyContent: "space-between",
              paddingLeft:"0px",
              paddingRight:"0px",
              paddingTop:"0px",
              paddingBottom:"0px",
            }}
          >
            <FormControl
              variant="standard"
              sx={{
                minWidth: 100,
                "& .MuiInput-underline:before": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:after": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottom: "none",
                },
              }}
            >
              <Select
                value={selectedCaptionFontForVideoGen}
                onChange={handleFontChange}
                displayEmpty
                width="120px"
                sx={{
                  padding: "0px",
                  color: "white",
                  opacity: "80%",
                  borderRadius: "8px",
                  height:"48px",
                  textAlign: "center",
                  "&:hover": {
                    backgroundColor: "#373E6D", 
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2F355F", 
                  },
                  "& .MuiSelect-icon": {
                    color: "#FFFFFF", 
                  },
                  backgroundColor: "#2F355F",
                }}
                MenuProps={{
                  ...menuProps,
                  style: {
                    maxHeight: 64 * 4.5, 
                  },
                }}
              >
                {CaptionFontForVideoGen?.map((font, i) => {
                  return (
                    <MenuItem
                      height={4.5}
                      sx={{
                        background: "#2F355F",
                        padding: 1,
                        "&.Mui-selected": {
                          backgroundColor: "#373E6D", 
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: "#373E6D", 
                        },
                        "&:hover": {
                          backgroundColor: "#373E6D", 
                        },

                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "20px",
                        fontFamily: { font },
                      }}
                      value={font}
                    >
                      {font}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              variant="standard"
              sx={{
                minWidth: 100,
                // mr: 2,
                "& .MuiInput-underline:before": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:after": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottom: "none",
                },
                "& .MuiSelect-icon": {
                  color: "#FFFFFF", // Set the icon color here
                },
              }}
            >
              <Select
                value={fontSize}
                onChange={handleFontSizeChange}
                displayEmpty
                sx={{
                  padding: "0px",
                  color: "white",
                  opacity: "80%",
                  borderRadius: "8px",
                  height:"48px",
                  textAlign: "center",
                  "&:hover": {
                    backgroundColor: "#373E6D", // Change this color as desired
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2F355F", // Border color
                  },
                  "& .MuiSelect-icon": {
                    color: "#FFFFFF", // Set the icon color here
                  },
                  backgroundColor: "#2F355F",
                }}
                MenuProps={{
                  ...menuProps,
                }}
              >
                <MenuItem
                  sx={{
                    background: "#2F355F",
                    padding: 1,
                    "&.Mui-selected": {
                      backgroundColor: "#373E6D", // Background color when selected
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "#373E6D", // Background color on hover when selected
                    },
                    "&:hover": {
                      backgroundColor: "#373E6D", // Background color on hover
                    },

                    color: "#FFFFFF",
                    fontSize: "7px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20px",
                    fontFamily: "Inter",
                  }}
                  height={4.5}
                  value="35"
                >
                  Tiny
                </MenuItem>
                <MenuItem
                  sx={{
                    background: "#2F355F",
                    padding: 1,
                    "&.Mui-selected": {
                      backgroundColor: "#373E6D", // Background color when selected
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "#373E6D", // Background color on hover when selected
                    },
                    "&:hover": {
                      backgroundColor: "#373E6D", // Background color on hover
                    },

                    color: "#FFFFFF",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20px",
                    fontFamily: "Inter",
                  }}
                  height={4.5}
                  value="50"
                >
                  Small
                </MenuItem>
                <MenuItem
                  sx={{
                    background: "#2F355F",
                    padding: 1,
                    "&.Mui-selected": {
                      backgroundColor: "#373E6D", // Background color when selected
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "#373E6D", // Background color on hover when selected
                    },
                    "&:hover": {
                      backgroundColor: "#373E6D", // Background color on hover
                    },

                    color: "#FFFFFF",
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20px",
                    fontFamily: "Inter",
                  }}
                  height={4.5}
                  value="75"
                >
                  Medium
                </MenuItem>
                <MenuItem
                  sx={{
                    background: "#2F355F",
                    padding: 1,
                    "&.Mui-selected": {
                      backgroundColor: "#373E6D", // Background color when selected
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "#373E6D", // Background color on hover when selected
                    },
                    "&:hover": {
                      backgroundColor: "#373E6D", // Background color on hover
                    },

                    color: "#FFFFFF",
                    fontSize: "20",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20px",
                    fontFamily: "Inter",
                  }}
                  height={4.5}
                  value="100"
                >
                  Large
                </MenuItem>
              </Select>
            </FormControl>
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#E6E0E9",
                    color: "#17182C",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontWeight: 500,
                    padding: "4px 8px 4px 8px",
                    borderRadius: "4px",
                    maxWidth: "104px",
                    textAlign: "left",
                  },
                },
              }}
              title="Text bold"
            >
              <IconButton
                sx={{
                  color: "white",
                  opacity: "80%",
                  width: "48px",
                  height: "48px",
                  bgcolor: isBold ? "#373E6D" : "",
                  borderRadius: "8px",
                  "&:hover": {
                  backgroundColor: "#373E6D", // Change this color as desired
                }
                }}
                onClick={toggleBold}

              >
                <FormatBoldIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#E6E0E9",
                    color: "#17182C",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontWeight: 500,
                    padding: "4px 8px 4px 8px",
                    borderRadius: "4px",
                    maxWidth: "104px",
                    textAlign: "left",
                  },
                },
              }}
              title="Text color"
            >
              <IconButton
                onClick={(event) => {
                  setShowcolorpalette(!showcolorpalette);
                  setAnchorEl(event.currentTarget);
                }}
                sx={{
                  width: "48px",
                  height: "48px",
                  borderRadius:"8px",
                  "&:hover": {
                    backgroundColor: "#373E6D",
                  },
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M2 20H22V24H2V20ZM5.49 17H7.91L9.18 13.42H14.83L16.09 17H18.51L13.25 3H10.75L5.49 17ZM9.91 11.39L11.94 5.6H12.06L14.09 11.39H9.91Z"
                    fill="white"
                  />
                  <rect
                    x="2"
                    y="20"
                    width="20"
                    height="4"
                    fill={selectedColorForCaptionText}
                  />
                </svg>
              </IconButton>
            </Tooltip>
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#E6E0E9",
                    color: "#17182C",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontWeight: 500,
                    padding: "4px 8px 4px 8px",
                    borderRadius: "4px",
                    textAlign: "left",
                  },
                },
              }}
              title="Text backgound color"
            >
              <IconButton
                sx={{ width: "48px", height: "48px", borderRadius:"8px",  "&:hover": {
                  backgroundColor: "#373E6D", 
                }, }}
                onClick={(event) => {
                  setShowcolorpaletteForBackgroundColor(
                    !showcolorpaletteForBackgroundColor
                  );
                  setAnchorElOne(event.currentTarget);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M16.893 8.94L7.95301 0L6.54301 1.41L8.92301 3.79L3.77301 8.94C3.63272 9.07859 3.52133 9.24364 3.44531 9.4256C3.36929 9.60756 3.33015 9.8028 3.33015 10C3.33015 10.1972 3.36929 10.3924 3.44531 10.5744C3.52133 10.7564 3.63272 10.9214 3.77301 11.06L9.27301 16.56C9.56301 16.85 9.95301 17 10.333 17C10.713 17 11.103 16.85 11.393 16.56L16.893 11.06C17.483 10.48 17.483 9.53 16.893 8.94ZM5.54301 10L10.333 5.21L15.123 10H5.54301ZM19.333 11.5C19.333 11.5 17.333 13.67 17.333 15C17.333 16.1 18.233 17 19.333 17C20.433 17 21.333 16.1 21.333 15C21.333 13.67 19.333 11.5 19.333 11.5ZM2.33301 20H22.333V24H2.33301V20Z"
                    fill="#CAC4D0"
                  />
                  <rect
                    x="2.33301"
                    y="20"
                    width="20"
                    height="4"
                    fill={selectedColorForCaptionTextBackground}
                  />
                </svg>
              </IconButton>
            </Tooltip>
            <FormControl
              variant="standard"
              sx={{
                minWidth: 60,
                mr: 2,
                "& .MuiInput-underline:before": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:after": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottom: "none",
                },
                "& .MuiSelect-icon": {
                  color: "#FFFFFF", 
                },
              }}
            >
              <Select
                value={alignment}
                onChange={handleFontHorizontalAlignmentChange}
                displayEmpty
                sx={{
                  padding: "0px",
                  color: "white",
                  opacity: "80%",
                  borderRadius: "8px",
                  height:"48px",
                  textAlign: "center",
                  "&:hover": {
                    backgroundColor: "#373E6D", 
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2F355F", 
                  },
                  "& .MuiSelect-icon": {
                    color: "#FFFFFF",
                  },
                  backgroundColor: "#2F355F",
                }}
                MenuProps={{ ...menuProps }}
              >
                <MenuItem
                  sx={{
                    background: "#2F355F",
                    padding: 1,
                    "&.Mui-selected": {
                      backgroundColor: "#373E6D", // Background color when selected
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "#373E6D", // Background color on hover when selected
                    },
                    "&:hover": {
                      backgroundColor: "#373E6D", // Background color on hover
                    },

                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20px",
                    fontFamily: "Inter",
                  }}
                  value="left"
                >
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#E6E0E9",
                          color: "#17182C",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 500,
                          padding: "4px 8px 4px 8px",
                          borderRadius: "4px",
                          maxWidth: "104px",
                          textAlign: "center",
                        },
                      },
                    }}
                    title="Left-align"
                  >
                    <FormatAlignLeftIcon />
                  </Tooltip>
                </MenuItem>

                <MenuItem
                  sx={{
                    background: "#2F355F",
                    padding: 1,
                    "&.Mui-selected": {
                      backgroundColor: "#373E6D", // Background color when selected
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "#373E6D", // Background color on hover when selected
                    },
                    "&:hover": {
                      backgroundColor: "#373E6D", // Background color on hover
                    },

                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20px",
                    fontFamily: "Inter",
                  }}
                  value="center"
                >
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#E6E0E9",
                          color: "#17182C",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 500,
                          padding: "4px 8px 4px 8px",
                          borderRadius: "4px",
                          maxWidth: "104px",
                          textAlign: "left",
                        },
                      },
                    }}
                    title="Center-align"
                  >
                    <FormatAlignCenterIcon />
                  </Tooltip>
                </MenuItem>

                <MenuItem
                  sx={{
                    background: "#2F355F",
                    padding: 1,
                    "&.Mui-selected": {
                      backgroundColor: "#373E6D", 
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "#373E6D", 
                    },
                    "&:hover": {
                      backgroundColor: "#373E6D", 
                    },

                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20px",
                    fontFamily: "Inter",
                  }}
                  value="right"
                >
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#E6E0E9",
                          color: "#17182C",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 500,
                          padding: "4px 8px 4px 8px",
                          borderRadius: "4px",
                          maxWidth: "104px",
                          textAlign: "left",
                        },
                      },
                    }}
                    title="Right-align"
                  >
                    <FormatAlignRightIcon />
                  </Tooltip>
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant="standard"
              sx={{
                minWidth: 60,
                mr: 2,
                "& .MuiInput-underline:before": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:after": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottom: "none",
                },
                "& .MuiSelect-icon": {
                  color: "#FFFFFF", 
                },
              }}
            >
              <Select
                value={
                  valignment === "BOTTOM"
                    ? "end"
                    : valignment === "TOP"
                    ? "start"
                    : "center"
                }
                onChange={handleFontVarticalAlignmentChange}
                displayEmpty
                sx={{
                  padding: "0px",
                  color: "white",
                  opacity: "80%",
                  borderRadius: "8px",
                  height:"48px",
                  textAlign: "center",
                  "&:hover": {
                    backgroundColor: "#373E6D", 
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2F355F", 
                  },
                  "& .MuiSelect-icon": {
                    color: "#FFFFFF", 
                  },
                  backgroundColor: "#2F355F",
                }}
                MenuProps={{
                  ...menuProps,
                }}
              >
                <MenuItem
                  sx={{
                    background: "#2F355F",
                    padding: 1,
                    "&.Mui-selected": {
                      backgroundColor: "#373E6D", 
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "#373E6D", 
                    },
                    "&:hover": {
                      backgroundColor: "#373E6D", 
                    },

                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20px",
                    fontFamily: "Inter",
                  }}
                  value="end"
                >
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#E6E0E9",
                          color: "#17182C",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 500,
                          padding: "4px 8px 4px 8px",
                          borderRadius: "4px",
                          maxWidth: "104px",
                          textAlign: "left",
                        },
                      },
                    }}
                    title="Bottom-align"
                  >
                    <VerticalAlignBottomIcon />
                  </Tooltip>
                </MenuItem>

                <MenuItem
                  sx={{
                    background: "#2F355F",
                    padding: 1,
                    "&.Mui-selected": {
                      backgroundColor: "#373E6D", 
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "#373E6D", 
                    },
                    "&:hover": {
                      backgroundColor: "#373E6D", 
                    },

                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20px",
                    fontFamily: "Inter",
                  }}
                  value="center"
                >
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#E6E0E9",
                          color: "#17182C",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 500,
                          padding: "4px 8px 4px 8px",
                          borderRadius: "4px",
                          maxWidth: "104px",
                          textAlign: "left",
                        },
                      },
                    }}
                    title="Middle-align"
                  >
                    <VerticalAlignCenterIcon />
                  </Tooltip>
                </MenuItem>

                <MenuItem
                  sx={{
                    background: "#2F355F",
                    padding: 1,
                    "&.Mui-selected": {
                      backgroundColor: "#373E6D", 
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "#373E6D", 
                    },
                    "&:hover": {
                      backgroundColor: "#373E6D", 
                    },

                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20px",
                    fontFamily: "Inter",
                  }}
                  value="start"
                >
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#E6E0E9",
                          color: "#17182C",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 500,
                          padding: "4px 8px 4px 8px",
                          borderRadius: "4px",
                          maxWidth: "104px",
                          textAlign: "left",
                        },
                      },
                    }}
                    title="Top-align"
                  >
                    <VerticalAlignTopIcon />
                  </Tooltip>
                </MenuItem>
              </Select>
            </FormControl>
          </Toolbar> 


          {/* Preview Section */}
          <Box
            sx={{
              display: "flex",
              mt: 8,
              p: 2,
              height: "102px",
              backgroundColor: "#171A2D",
              borderRadius: 1,
              color: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
              fontFamily: selectedCaptionFontForVideoGen,
              fontSize: `${fontSize / 2.5}px`,
              fontWeight: isBold ? 700 : 400,
              alignItems:
                valignment === "BOTTOM"
                  ? "end"
                  : valignment === "TOP"
                  ? "start"
                  : "center",
              justifyContent: alignment,
            }}
          >
            <Box
              sx={{
                backgroundColor: `rgba(${captionBackgoundColor.r}, ${captionBackgoundColor.g}, ${captionBackgoundColor.b}, ${captionBackgoundColor.a})`,
              }}
            >
              {fontSize == 35
                ? "This is extremely tiny caption"
                : fontSize == 50
                ? "This is Small captions"
                : fontSize == 75
                ? "Medium captions"
                : fontSize == 100
                ? "Large"
                : "This is Small captions"}
            </Box>
          </Box>
        </Box>
   
      )}
      {showcolorpalette ? (
        <ColorPalette
          anchorEl={anchorEl}
          handleCloseColorPalette={handleCloseColorPalette}
          color={color}
          setColor={setColor}
          selectedcolor={selectedColorForCaptionText}
        />
      ) : null}
      {showcolorpaletteForBackgroundColor ? (
        <ColorPalette
          anchorEl={anchorElOne}
          handleCloseColorPalette={handleCloseColorPaletteForBackgroundColor}
          color={captionBackgoundColor}
          setColor={setCaptionBackgoundColor}
          selectedcolor={selectedColorForCaptionTextBackground}
        />
      ) : null}
    </Box>
  );
};

export default CaptionSettingOption;
