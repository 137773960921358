import React, { useState, useEffect } from "react";
import { Modal, Tabs, Tab, Box, Typography, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { button_bg_color_for_New_Project, hoveredSettings_Nav_bg_dm, selecetedSettings_Nav_bg_dm, settingsModal_bg_color_dm, uploadFiles_button_default_bg_color, uploadFiles_button_hovered_bg_color } from "../../../../constants/colors";
import VoiceSettings from "./VoiceSettings";
import LanguageSettings from "./LanguageSettings";
import { AudioSettingsAPI } from "./AudioSettingsAPI";
import { SettingsAdjustment } from "../../../../config/analytics";
import CommonToast from "../../../Common_Components/CommonToast";

const AudioSettings = () => {
  const [activeTab, setActiveTab] = useState('voice');
  const { showAudioSettings, setShowAudioSettings,Audiolanguages, setAudioLanguages,audioVoice,audioSettingVoices, setAudioVoice,setAudioSettingVoicesLoading,setAudioSettingLanguagesLoading,setAudioSettingVoices, audioLanguage } = useWorkspace();
  const theme = localStorage.getItem('theme');
  const [audioSettingsSaved,setAudioSettingsSaved] = useState(false);

  useEffect(() => {
    if (showAudioSettings) {
      setActiveTab('voice'); // Set default tab
      setAudioSettingVoicesLoading(true)
      setAudioSettingLanguagesLoading(true)
      AudioSettingsAPI(setAudioLanguages,setAudioSettingVoices,setAudioVoice, audioVoice, setAudioSettingVoicesLoading,setAudioSettingLanguagesLoading  )
    }
  }, [showAudioSettings]);

  const handleClose = () => {
    setShowAudioSettings(false); // Close the modal by updating the context state
  };

  const handleSave = () => {
    // Implement save functionality here
    SettingsAdjustment("audio_settings", {audioLanguage:audioLanguage,audioVoice: audioVoice});
    console.log('Audio settings saved');
    setAudioSettingsSaved(true);
    setShowAudioSettings(false);
  };

  const handleCloseToast = () => {
    setAudioSettingsSaved(false);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'voice':
        return <VoiceSettings />;
      case 'language':
        return <LanguageSettings />;
      default:
        return <VoiceSettings />;
    }
  };

  return (
    <div>
      <CommonToast 
        open={audioSettingsSaved}
        onClose={handleCloseToast}
        content="Audio settings saved"
        bgColor="#282D51"
        fontColor="#fff"
        fontSize="14px"
        fontWeight="500"
        autoHideDuration={3000}
        iconType = 'success'
      />
      <Modal
      open={showAudioSettings}
      onClose={handleClose}
      aria-labelledby="audio-settings-title"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: '720px',
          height: '636px',
          backgroundColor: 'background.paper',
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          bgcolor: theme === 'dark' ? settingsModal_bg_color_dm : '',
          borderRadius: '8px',
        }}
      >
        <Typography
          id="audio-settings-title"
          variant="h6"
          component="h2"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            // mb: 2,
            borderBottom: `1px solid ${theme === 'dark' ? '#535774' : ''}`,
            padding: '20px',
          }}
          style={{ fontSize: '20px', fontWeight: '600', color: theme === 'dark' ? '#FFFFFF' : '' }}
        >
          Audio Settings
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            sx={{
              borderRight: 1,
              borderColor: theme === 'dark' ? '#535774' : '',
              minWidth: '150px',
              display: 'flex',
              flexDirection: 'column',
              width: '223px',
              paddingTop : '20px'
            }}
            TabIndicatorProps={{
              style: {
                display: 'none', // Hide the default indicator (blue underline)
              },
            }}
          >
            <Tab
              label="Voice"
              value="voice"
              sx={{
                backgroundColor: activeTab === 'voice' ? selecetedSettings_Nav_bg_dm : 'transparent', // Grey background for selected tab
                '&:hover': {
                  backgroundColor: theme === 'dark' ? hoveredSettings_Nav_bg_dm : '', // Light grey on hover
                },
                color: theme === 'dark' ? 'white' : 'black',
                textTransform: 'none',
                fontSize: '14px',
                margin: '0 20px',
                borderRadius: '8px',
              }}
              style={{ color: '#FFFF' }}
            />
            <Tab
              label="Language"
              value="language"
              sx={{
                backgroundColor: activeTab === 'language' ? selecetedSettings_Nav_bg_dm : 'transparent', // Grey background for selected tab
                '&:hover': {
                  backgroundColor: theme === 'dark' ? hoveredSettings_Nav_bg_dm : '', // Light grey on hover
                },
                color: theme === 'dark' ? 'white' : 'black',
                textTransform: 'none',
                fontSize: '14px',
                margin: '0 20px',
                borderRadius: '8px',
              }}
              style={{ color: '#FFFF' }}
            />
          </Tabs>
          <Box
            sx={{
              flexGrow: 1,
              pl: 3,
              overflowY: 'auto',
              maxHeight: '430px',
              width: '457px',
            }}
          >
            {renderContent()}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            // mt: 2,
            p: 2,
            borderTop: `1px solid ${theme === 'dark' ? '#444' : '#ccc'}`, // Border top added
          }}
        >
          <Button
            onClick={handleClose}
            sx={{
              mr: 1,
              backgroundColor: theme === 'dark' ? uploadFiles_button_default_bg_color : '',
              '&:hover': {
                backgroundColor: uploadFiles_button_hovered_bg_color,
              },
              color: '#17182C',
              fontSize: '16px',
              fontWeight: '600',
              textTransform: 'none',
              fontFamily: 'Inter, sans-serif',
            }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{
              backgroundColor: theme === 'dark' ? button_bg_color_for_New_Project : '',
              '&:hover': {
                backgroundColor: '#FFD9AA',
              },
              color: '#17182C',
              fontSize: '16px',
              fontWeight: '600',
              textTransform: 'none',
              fontFamily: 'Inter, sans-serif',
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
    </div>
    
  );
};

export default AudioSettings;
