import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Image_1 from '../assets/Safety/Image_1.png';
import PageLayout from '../components/navFoot/PageLayout';
import RequestDemoNearFooter from '../components/LandingPage/RequestDemoNearFooter';

const SafetyMeasures = [
  {
    id: '1',
    content:
      'We explicitly prohibit the wrongful use of our AI for unlawful or detrimental intents and actively protect our platform by consistently testing and identifying weaknesses.',
  },
  {
    id: '2',
    content:
      'We collaborate with specialists to devise and execute strategies for preventing harm. Harnessing collective knowledge for enhanced and dependable results, mitigating potential risks.',
  },
  {
    id: '3',
    content:
      'We uphold data integrity by rigorously vetting training data, ensuring exclusion of illicit content to maintain safe and ethical standards across our products.',
  },
  {
    id: '4',
    content:
      'Transparent AI-generated content is pivotal for building trust. We stress the significance of discerning between human and AI creations, empowering users to make informed decisions and use technology responsibly.',
  },
  {
    id: '5',
    content:
      'While we cannot govern all behaviors, we offer solutions and resources to empower developers in responsible AI usage. We cultivate accountability to encourage conscientious AI utilization.',
  },
  {
    id: '6',
    content:
      'We adhere to SOC 2 and GDPR standards. Independent audits have certified our data management practices, systems, and procedures.',
  },
];

const Safety = () => {
  const isBelow991 = useMediaQuery("(max-width:991px)")
  return (
    <PageLayout>
      <Box sx={{ marginTop: isBelow991?'68px':'156px' }}>
        <Box
          sx={{
            maxWidth: '1072px',
            height: '130px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            textAlign: 'center',
            margin: 'auto',
          }}
        >
          <Typography sx={{ textAlign: 'center', color: '#F6BE6B',fontSize:isBelow991?'12px':'16px' }}>Safety</Typography>
          <Typography sx={{ fontSize: isBelow991?'28px':'48px',padding:isBelow991?'0 20px':'' }}>
            The Guiding Principles of Our Safety
          </Typography>
        </Box>
        <Box sx={{ maxWidth: '512px', maxHeight: '512px', margin: isBelow991?'60px auto':'80px auto',width:isBelow991?'80%':'100%' }}>
          <img src={Image_1} alt="Safety" style={{ width: '100%', height: 'auto' }} />
        </Box>
        <Box sx={{ maxWidth: '900px', fontSize: isBelow991?'16px':'20px', margin: 'auto',width:isBelow991?'80%':'100%' }}>
          <ol>
            {SafetyMeasures.map((item) => (
              <li key={item.id}>
                <Typography
                  sx={{
                    fontSize: isBelow991?'16px':'20px',
                    marginBottom: '20px',
                    color: 'rgba(255,255,255,0.8)',
                  }}
                >
                  {item.content}
                </Typography>
              </li>
            ))}
          </ol>
        </Box>
        <Box
          sx={{
            color: 'rgba(255,255,255,0.8)',
            maxWidth: '873px',
            margin: 'auto',
            paddingLeft: '20px',
            width:isBelow991?'80%':'100%',
            paddingLeft:isBelow991?'30px':''
          }}
        >
          <Typography paragraph sx={{fontSize: isBelow991?'14px':'16px',}}>
            Service Organization Control (SOC) 2, created by the American Institute of
            CPAs (AICPA), sets the standard for evaluating a company's internal controls
            and data protection.
          </Typography>
          <Typography paragraph sx={{fontSize: isBelow991?'14px':'16px',}}>
            Achieving SOC 2 certification requires full company commitment to security
            through each audit step. Since obtaining our Type II certification, we've
            successfully re-certified annually. At ModalX, safeguarding user data and
            content is a top priority, and we continuously uphold best-in-class security
            practices year-round.
          </Typography>
        </Box>
        <Box sx={{ margin: isBelow991?'60px auto 274px auto':'100px auto 406px auto', maxWidth: '900px',width:isBelow991?'90%':'100%' }}>
          <Typography sx={{ marginBottom: '20px', fontSize: isBelow991?'20px':'24px', fontWeight: '600' }}>
            Curious to dive deeper?
          </Typography>
          <Typography sx={{ marginBottom: '20px', fontSize: isBelow991?'16px':'20px' }}>
            If you're keen to discover more about ModalX's approach to security and our
            vision for the future with multi-modal generative AI, we'd love to connect
            with you.
          </Typography>
          <Typography sx={{ marginBottom: '20px', fontSize: isBelow991?'16px':'20px' }}>
            For a personalized demo of ModalX for you or your team, don't hesitate to
            contact our enterprise sales team.
          </Typography>
        </Box>
        <Box>
          <RequestDemoNearFooter />
        </Box>
      </Box>
    </PageLayout>
  );
};

export default Safety;

