import React, { useState, useEffect} from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import mailIcon from "../../assets/signup/mail.png";
import { Box, Button, IconButton, Typography, useMediaQuery } from "@mui/material";
import { New_Project_Button_bg_color } from "../../Provider/Color_Provider";
import sourceUrl from "../../api/Source.api";
import axios from "axios";
import { CustomizedSnackbars }from "./Signup";
import { createUserWithEmailAndPassword } from "firebase/auth";

const VerifyEmailModal = ({
    showVerificationModal,
    setShowVerificationModal,
    setShowSignUpModal,
    email,
    password,
    auth,
    open,
    setOpen, 
    notifMsg,
    notifVariant,
    setShowNotif,
    setNotifMsg,
    setNotifVariant,
    setEmail,
    setPassword,
    setConfirmPassword,
    setMaskedPassword,
    setMaskedConfirmPassword

  }) => {

  const isBelow991 = useMediaQuery("(max-width:991px)");

  // useEffect(()=>{
  //   setShowVerificationModal(true);
  // },[])

  useEffect(() => {
    // Add or remove the class from body
    
    document.body.style.overflow =  showVerificationModal ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto'; // Ensure scrollbars are re-enabled on unmount
    };
  }, [showVerificationModal,]);

  const clearStates = () =>{
    setEmail("");
    setPassword("");
    setConfirmPassword("")
    setMaskedPassword("")
    setMaskedConfirmPassword("")
  }

  const handleNavigation = () => {
        clearStates()
        setShowSignUpModal(true);
        setShowVerificationModal(false);
  };

  const handleCloseResendEmailModal = () => {
    clearStates()
    setShowVerificationModal(false);
    setShowSignUpModal(false);
  };

  const handleResendEmailClick = async () =>{
    try {
      const userCredential = await createUserWithEmailAndPassword (
        auth,
        email,
        password
      );
      await axios.post(
        `${sourceUrl}/profile`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userCredential._tokenResponse.idToken}`,
          },
        }
      );
      // clearStates()
    } catch (error) {
      console.error("Error signing up:", error);
      setNotifMsg(`Error signing up: ${error.message}`);
      setNotifVariant("error");
      setShowNotif(true);
      setOpen(true);
    }
  }

  return (
    <> 
    <div
    style={{
      zIndex: 70,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: isBelow991 ? "" : "100vw",
      height: isBelow991 ? "" : "100vh",
      left: "0%",
      top: "0%",
    }}
  >       
      <div
        onClick={handleCloseResendEmailModal}
        style={{
          width: "100%",
          height: isBelow991 ? "" : "100%",
          position: isBelow991 ? "static" : "absolute",
        //   transform: "translate(-50%, -50%)",
          backgroundColor: "rgba(23,26,45,0.8)",
        //   left: "50%",
        //   top: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          onClick={(event) => event.stopPropagation()}
          sx={{
            // width: "600px",
            // height: "488px",
            backgroundColor:  isBelow991 ? "#171A2D" : "rgba(40, 45, 81, 1)",
            borderRadius: "8px",
            border: isBelow991 ? null :"1px solid rgba(202, 196, 208, 1)",
            alignItems:"center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "68px",
              display: "flex",
              justifyContent: "start",
              padding: isBelow991 ?  "36px 20px 0px 20px" : "20px" ,
              alignItems: "center",

            }}
          >
            <Box
              sx={{
                width: "28px",
                height: "28px",
              }}
            >
              <IconButton
                aria-label="back"
                onClick={handleNavigation}
                sx={{
                  color: "#CDCDCD",
                  padding: "0px 0px",
                }}
              >
                <ArrowBackIcon
                  sx={{
                    width: "28px",
                    height: "28px",
                    padding: "0px 0px",
                  }}
                />
              </IconButton>
            </Box>
          </Box>
  
          <Box sx={{ padding: isBelow991 ? "60px 24px 40px 24px" : "20px 150px 40px 150px", }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: isBelow991 ? "left" : "center",
              }}
            >
              <Box
                style={{
                  width: "80px",
                  height: "80px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  backgroundColor: "rgba(255, 255, 255,0.05)",
                  opacity: 0.8,
                }}
              >
                <img src={mailIcon} alt="mailIcon" />
              </Box>
            </Box>
  
            <Box
              sx={{
                width: "100%",
                marginTop: "40px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  alignSelf: "stretch",
                  textAlign: isBelow991 ? "left" :  "center",
                  margin: "0px 0px",
                }}
              >
                Verify your Email address
              </Typography>
            </Box>
  
            <Box
              sx={{
                marginTop: "24px",
                width: "300px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  textAlign: isBelow991 ? "left" : "center",
                }}
              >
                ${`We have sent you a verification link on ${email}. If you haven’t received it momentarily, please click on resend mail.`}
              </Typography>
            </Box>
  
            <Box
              sx={{
                marginTop: "48px",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  bgcolor: New_Project_Button_bg_color,
                  color: "rgba(23, 24, 44, 1)",
                  padding: "10px 24px 10px 24px",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                  textAlign: "center",
                  width: "100%",
                  "&:hover": {
                    bgcolor: "#ffd9aa",
                  },
                }}
                onClick={handleResendEmailClick}
              >
                Resend Email
              </Button>
            </Box>

            <Box
          onClick={handleNavigation}
          sx={{
            width: "100%",
            marginTop: "24px",
            cursor: "pointer",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "20px",
              alignSelf: "stretch",
              textAlign: "center",
            }}
          >
            Use a different email address
          </Typography>
        </Box>

          </Box>
        </Box>
        <CustomizedSnackbars
          open={open}
          setOpen={setOpen}
          notifMsg={notifMsg}
          notifVariant={notifVariant}
          setShowNotif={setShowNotif}
        />
      </div>
      </div></>
  )
}

export default VerifyEmailModal