import React from 'react';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const RequestDemoNearFooter = () => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const isBelow991 = useMediaQuery("(max-width:991px)");
  const {setShowSignUpModal,showSignUpModal, setMenuOpen} = useAuth()
  const handleRedirect = (link) => {
    window.open(link, '_blank'); // Opens the link in a new tab
  };
  
  const handleGetStarted = () =>{
    if (isLoggedIn){
      navigate('/workspace') 
    }
    else {
      setShowSignUpModal(true) 
      setMenuOpen(true)

    }  
   }
 
  return (
    <Box sx={{ width: '100%', backgroundColor: '#1c2039', height: '358px', margin: 'auto' }}>
      <Box
        sx={{
          maxWidth: '1072px',
          height: '358px', // Match the height of the parent Box to ensure centering
          margin: 'auto',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center', // Centers content vertically
          alignItems: 'center', // Centers content horizontally
          width: isBelow991?'90%':'100%'
        }}
      >
        <Typography sx={{ fontSize: isBelow991?'28px':'48px', color: 'white', marginBottom: '16px' }}>
          Ready to speed up your workflow using AI?
        </Typography>
        <Typography sx={{ fontSize: isBelow991?'14px':'18px', color: 'rgba(255,255,255,0.8)', marginBottom: '30px' }}>
          Start exploring ModalX! Request a free demo today
        </Typography>
        <Box
          sx={{
            width: isBelow991?'350px':'370px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            // onClick={() => handleRedirect('mailto:noreply@ModalX.ai')}
            onClick={() => window.open('https://calendly.com/makarand-modalx', '_blank')}
            variant="outlined"
            sx={{
              width: '161px',
              height: '40px',
              borderColor: '#F6BE6A',
              color: '#F6BE6A',
              textTransform: 'none',
              fontSize: isBelow991?'14px':'16px',
              '&:hover': {
                borderColor: '#F6BE6A',
                backgroundColor: 'transparent', // No border color changes on hover
              },
              fontFamily: 'Inter, sans-serif',
            }}
          >
            Request demo
          </Button>
          <Button
          onClick={handleGetStarted}
            variant="contained"
            sx={{
              width: '173px',
              height: '40px',
              backgroundColor: '#F6BE6A',
              color: '#17182C',
              fontWeight: '600',
              textTransform: 'none',
              fontSize: isBelow991?'14px':'16px',
              '&:hover': {
                backgroundColor: '#ffd9aa', // Changes background color on hover
              },
              fontFamily: 'Inter, sans-serif',
            }}
          >
            {isLoggedIn?"Go to warroom":"Get started free"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default RequestDemoNearFooter;
