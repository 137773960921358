import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';

const CommonToast = ({
  open,
  onClose,
  content,
  iconType = 'success', // New prop to control the icon type
  bgColor = '#4caf50',
  fontColor = '#ffffff',
  fontSize = '16px',
  fontWeight = 'bold',
  autoHideDuration = 3000,
}) => {
  const icon = iconType === 'success' ? (
    <CheckCircleIcon fontSize="inherit" style={{ color: 'green' }} />
  ) : (
    <ErrorRoundedIcon fontSize="inherit" style={{ color: 'red' }} />
  );

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={onClose}
        severity={iconType === 'success' ? 'success' : 'error'}
        variant="filled"
        icon={icon} // Use dynamic icon based on iconType
        sx={{
          bgcolor: bgColor,
          color: fontColor,
          fontSize: fontSize,
          fontWeight: fontWeight,
          minWidth: '350px',
          width: 'auto',
          maxWidth: '100%',
        }}
      >
        {content}
      </Alert>
    </Snackbar>
  );
};

export default CommonToast;

