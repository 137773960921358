import React from "react";
import { Row, Col } from "react-bootstrap";

import ToolCard from "./ToolCard";
import toolsData from "../constants/tools.json";

function ToolSelector({ selectedTools }) {
  // Filter tools based on selected tool names
  const filteredTools = selectedTools
    ? toolsData.filter((tool) => selectedTools.includes(tool.title))
    : toolsData;

  return (
    <Row xs={2} sm={2} md={3} lg={4} xl={5} className="align-items-stretch">
      {filteredTools.map((tool, idx) => {
        return (
          <Col key={idx} className="py-2">
            <ToolCard {...tool} />
          </Col>
        );
      })}
    </Row>
  );
}

export default ToolSelector;
