import React from "react";
import PageLayout from "../components/navFoot/PageLayout";

const Terms = () => {
  return (
    <PageLayout>
      <h1 className="mb-4">Terms of Use</h1>
      <h2 className="pt-3 pb-2 border-top">Overview</h2>
      <p style={{ color: "gray" }}>
        Welcome to Modal X, provided by Brightspot. By accessing or using Modal
        X, you agree to be bound by these Terms of Use. If you disagree with any
        part of these terms, you may not access Modal X.
      </p>
      <h2 className="pt-3 pb-2 border-top">Description of Service</h2>
      <p style={{ color: "gray" }}>
        Modal X, provided by Brightspot, offers AI tools to convert user files
        into various formats such as presentations, video explanations, and
        more.
      </p>
      <h2 className="pt-3 pb-2 border-top">Acceptance of Terms</h2>
      <p style={{ color: "gray" }}>
        By accessing or using Modal X, you agree to these Terms of Use. If you
        do not agree with any part of these terms, you may not access the
        service.
      </p>
      <h2 className="pt-3 pb-2 border-top">Use of Service</h2>
      <p style={{ color: "gray" }}>
        You agree to use Modal X only for lawful purposes and in accordance with
        these Terms of Use and all applicable laws and regulations. You agree
        not to use Modal X in any way that violates the rights of others or that
        could damage, disable, overburden, or impair the service.
      </p>
      <h2 className="pt-3 pb-2 border-top">Third-Party Software</h2>
      <p style={{ color: "gray" }}>
        Modal X may use third-party software tools or services. You acknowledge
        and agree that your use of such third-party tools or services is subject
        to their respective terms of use and privacy policies. Brightspot does
        not endorse, control, or assume any responsibility for any third-party
        software or services.
      </p>
      <h2 className="pt-3 pb-2 border-top">Intellectual Property</h2>
      <p style={{ color: "gray" }}>
        All content and materials available on Modal X, including but not
        limited to text, graphics, logos, button icons, images, audio clips, and
        software, are the property of Brightspot or its licensors and are
        protected by Canadian and international copyright, trademark, and other
        intellectual property laws.
      </p>
      <h2 className="pt-3 pb-2 border-top">User Content</h2>
      <p style={{ color: "gray" }}>
        You retain ownership of any content you upload, submit, or transmit
        through Modal X. By submitting content, you grant Brightspot a
        worldwide, non-exclusive, royalty-free, sublicensable, and transferable
        license to use, reproduce, distribute, prepare derivative works of,
        display, and perform such content in connection with the operation of
        Modal X.
      </p>
      <h2 className="pt-3 pb-2 border-top">Privacy Policy</h2>
      <p style={{ color: "gray" }}>
        Your use of Modal X is subject to Brightspot's Privacy Policy. By using
        Modal X, you consent to the collection, use, and disclosure of your
        information as described in the Privacy Policy.
      </p>
      <h2 className="pt-3 pb-2 border-top">Disclaimers</h2>
      <p style={{ color: "gray" }}>
        Modal X is provided "as is" and "as available" without warranties of any
        kind, either express or implied. Brightspot does not warrant that the
        service will be uninterrupted, error-free, or free of viruses or other
        harmful components.
      </p>
      <h2 className="pt-3 pb-2 border-top">Limitation of Liability</h2>
      <p style={{ color: "gray" }}>
        In no event shall Brightspot be liable for any indirect, incidental,
        special, consequential, or punitive damages, including without
        limitation, loss of profits, data, use, goodwill, or other intangible
        losses, arising out of or in connection with your use of Modal X.
      </p>
      <h2 className="pt-3 pb-2 border-top">Governing Law</h2>
      <p style={{ color: "gray" }}>
        These Terms of Use shall be governed by and construed in accordance with
        the laws of Canada, without regard to its conflict of law provisions.
      </p>
      <h2 className="pt-3 pb-2 border-top">Contact Information</h2>
      <p style={{ color: "gray" }}>
        If you have any questions about these Terms of Use, please contact us at{" "}
        <a href="mailto:help@getbrightspot.com">help@getbrightspot.com</a>.
      </p>
      <h2 className="pt-3 pb-2 border-top">Changes to Terms</h2>
      <p style={{ color: "gray" }}>
        Brightspot reserves the right to modify or replace these Terms of Use at
        any time. Any changes will be effective immediately upon posting the
        revised Terms of Use on Modal X. Your continued use of Modal X after any
        such changes constitutes your acceptance of the new Terms of Use.
      </p>
    </PageLayout>
  );
};

export default Terms;
