import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled, { keyframes } from "styled-components";
// import  black_logo  from "../../../assets/modalX_robot_icon_black.png";
import black_logo from "../../../assets/ModalX_-orange_Icon.png";
import  white_logo  from "../../../assets/modalX_robot_icon_white.png";
// Define the blinking animation
const blink = keyframes`
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
`;

// Styled component for the dot
const Dot = styled.div`
  width: 2px;
  height: 5px;
  border-radius: 50%;
  background-color: #343a40; /* Blue color, you can change it as needed */
  margin: 0 5px;
  animation: ${blink} 1s infinite alternate; /* Apply the blinking animation */
`;

const TypingIndicator = () => {
  const theme = localStorage.getItem('theme');
  return (
    <Container fluid >
      <Row style={{display:'flex',flexDirection:'row',justifyContent:'start',alignItems:'center',marginRight:'10px'}}>
      <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0">
          {/* <i className="bi bi-robot fs-4" /> */}
          <img style={{width:'48px',height:'48px', color : 'red'}} src={black_logo}/>
        </Col>
        {/* Three blinking dots */}
        <Dot style={{backgroundColor : '#282D51', width: '431px' , height : '28px',borderRadius : '8px'}} />
        {/* <Col><Dot /></Col>
        <Col><Dot /></Col>
        <Col><Dot /></Col> */}

      </Row>
    </Container>
    
  );
};

export default TypingIndicator;
