// components/modals/VideoModal.js
import React, { useEffect } from "react";
import { Modal, CloseButton } from "react-bootstrap";
import {Button, } from "@mui/material";
import { button_bg_color_for_New_Project, settingsModal_bg_color_dm } from "../constants/colors";

const VideoModal = ({ show, handleClose, videoSrc }) => {
  const theme = localStorage.getItem('theme');
  useEffect(() => {
    // Automatically start playing the video when the modal is shown
    const video = document.getElementById("introVideo");
    if (video) {
      video.play();
    }
  }, [show]);

  return (
    <div style={{}}>
    <Modal show={show} onHide={handleClose} centered size="lg" >
      <Modal.Header style={{backgroundColor : 'dark' ? settingsModal_bg_color_dm : ''}}>
        <Modal.Title >Introduction Video</Modal.Title>
        <CloseButton onClick={handleClose} />
      </Modal.Header>
      <Modal.Body style={{ textAlign: "center",backgroundColor : 'dark' ? settingsModal_bg_color_dm : '' }}>
        <video
          id="introVideo"
          width="100%"
          controls
          autoPlay
          muted
        >
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Modal.Body>
      <Modal.Footer style={{display: "flex", justifyContent: "center", textAlign: "center",backgroundColor : 'dark' ? settingsModal_bg_color_dm : '' }}>
      <Button variant="outlined"  onClick={handleClose} 
        sx={{backgroundColor:theme === 'dark'? button_bg_color_for_New_Project:'',
          '&:hover': {
            backgroundColor:'#FFD9AA',
          },
          color:'#17182C',
          fontSize:'16px',
          fontWeight:'600',
          textTransform:'none',
          fontFamily: 'Inter, sans-serif',}}>
          Get Started
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
  );
};

export default VideoModal;
