// import React, { useState, useEffect } from 'react';
// import { Box, Button, Grid, Input, InputAdornment, Typography } from '@mui/material';
// import { useWorkspace } from "../../../../contexts/WorkspaceContext";
// import sourceUrl from '../../../../api/Source.api';
// import { defaultSettings_button_bg_color, hoveredSettings_button_bg_color, selected_SettingsButton_bg_color } from '../../../../constants/colors';
// import SearchIcon from '@mui/icons-material/Search';

// const LanguageSettings = () => {
//   const [inputBoxValue, setInputBoxValue] = useState("");
//   const [debouncedValue, setDebouncedValue] = useState("");
//   const [filterLanguages, setFilterLanguages] = useState(null)
//   const { audioLanguage, setAudioLanguage, Audiolanguages, setAudioLanguages, setAudioSettingLanguagesLoading, audioSettinglanguagesloading, showAudioSettings } = useWorkspace();
  
//   const theme = localStorage.getItem('theme');
//   const token = localStorage.getItem('token');

//   const fetchLanguages = async () => {
//     try {
//       const response = await fetch(`${sourceUrl}/audio/language`, {
//         method: 'GET',
//         headers: {
//           'Authorization': `Bearer ${token}`,
//           'Content-Type': 'application/x-www-form-urlencoded',
//         }
//       });
//       const data = await response.json();
//       setAudioLanguages(data.data.data); // Assuming the response has a languages array
//     } catch (error) {
//       console.error('Error fetching languages:', error);
//     }
//     setAudioSettingLanguagesLoading(false)
//   };

//   // console.log(Audiolanguages.length, "=======================languages.length======================")
//   useEffect(() => {
//     // Fetch available languages from the API
//     if(!audioSettinglanguagesloading && Audiolanguages.length === 0){
//       setAudioSettingLanguagesLoading(true)
//       fetchLanguages();
//     }
//   }, []);

//   const handleLanguageSelect = (lang) => {
//     setAudioLanguage(lang.name);
//   };

//         const handleFilter = (value) => {
//           const filterValue = Audiolanguages?.filter((item, i)=> item?.name?.toLowerCase().includes(value.toLowerCase()))
//           setFilterLanguages(filterValue)
//           //  console.log(filterValue, "==============filterValue=============")
//         };
//  useEffect(() => {
//       if(showAudioSettings){
//         const handler = setTimeout(() => {
//           setDebouncedValue(inputBoxValue);
//           handleFilter(inputBoxValue);
//         }, []);
//         return () => {
//              clearTimeout(handler);
//       };
//     }      
//  }, [inputBoxValue]);

//   const customTextFieldStyles = {
//     "& .MuiInputLabel-root": {
//       fontFamily: "Inter",
//       fontSize: "16px",
//       fontStyle: "normal",
//       fontWeight: 400,
//       lineHeight: "24px",
//       letterSpacing: "0.5px",
//       color: "rgba(255, 255, 255, 0.80)",
//       "&.Mui-focused": {
//         color: "rgba(255, 255, 255, 0.80) !important",
//       },
//     },
//     "& .MuiInputBase-input": {
//       color: "#FFFFFF",
//     },
//     "& .MuiOutlinedInput-root": {
//       borderRadius: "8px",
//       backgroundColor: "#232746", 
//       "& fieldset": {
//         borderColor: "rgba(255, 255, 255, 0.80)",
//       },
//       "&:hover fieldset": {
//         borderColor: "rgba(255, 255, 255, 0.80)",
//       },
//       "&.Mui-focused fieldset": {
//         borderColor: "rgba(255, 255, 255, 0.80) !important",
//       },
//     },
//     "& .MuiOutlinedInput-notchedOutline": {
//       borderColor: "transparent) !important",
//     },
//   };

//   return (
//     <>
//     {
//       audioSettinglanguagesloading  ? 
//       <Box sx={{ p: 2 }}>
//       <Typography variant="h6" gutterBottom style={{ fontSize: '16px', fontWeight: '500', width: '143px', height: '20px', marginBottom: '20px' }}>
//        Loading Language
//       </Typography>
//       </Box>
//       :
//       <Box sx={{ p: 2 }}>
//       <Typography variant="h6" gutterBottom style={{ fontSize: '16px', fontWeight: '500', width: '143px', height: '20px', marginBottom: '20px' }}>
//         Select Language
//       </Typography>
//       <Box sx={{ marginBottom: "20px", marginTop: "20px" }}>
//       <Input
//         type="text"
//         disableUnderline={true}
//         placeholder="Search language"
//         variant="outlined"
//         sx={customTextFieldStyles}
//         onChange={(e)=>{setInputBoxValue(e.target.value)}}
//         value={inputBoxValue}
//         style={{
//           width: "100%",
//           maxWidth: "180px",
//           height: "48px",
//           color: "#FFFFFF",
//           backgroundColor: "#2F355F",
//           borderRadius: "8px",
//           padding:"0px 12px",
//           boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
//           '&:focus': {
//           outline: 'none', 
//          },
//         }}
//         startAdornment={
//           <InputAdornment position="start">
//             <SearchIcon style={{ color: "rgba(255, 255, 255, 0.80)" }}  />
//           </InputAdornment>
//         }
//       />
//     </Box>
//       <Grid container spacing={2}>
//         {
//           inputBoxValue == "" ?
//         Audiolanguages.map((lang) => (
//           <Grid item key={lang.id} xs="auto">
//             <Button
//               variant="outlined"
//               onClick={() => handleLanguageSelect(lang)}
//               sx={{
//                 textTransform: 'none',
//                 backgroundColor: audioLanguage?.id === lang.id ? selected_SettingsButton_bg_color : defaultSettings_button_bg_color,
//                 color: theme === 'dark' ? 'white' : 'black',
//                 '&:hover': {
//                   backgroundColor: hoveredSettings_button_bg_color,
//                   borderColor:'#cac4d0'
//                 },
//                 borderColor: audioLanguage?.id === lang.id ? '#cac4d0' : 'transparent',
//                 m: 0.5, // Add margin for spacing
//               }}
//             >
//               {lang.name}
//             </Button>
//           </Grid>
//         )) :
//         filterLanguages.map((lang) => (
//           <Grid item key={lang.id} xs="auto">
//             <Button
//               variant="outlined"
//               onClick={() => handleLanguageSelect(lang)}
//               sx={{
//                 textTransform: 'none',
//                 backgroundColor: audioLanguage?.id === lang.id ? selected_SettingsButton_bg_color : defaultSettings_button_bg_color,
//                 color: theme === 'dark' ? 'white' : 'black',
//                 '&:hover': {
//                   backgroundColor: hoveredSettings_button_bg_color,
//                   borderColor:'#cac4d0'
//                 },
//                 borderColor: audioLanguage?.id === lang.id ? '#cac4d0' : 'transparent',
//                 m: 0.5, // Add margin for spacing
//               }}
//             >
//               {lang.name}
//             </Button>
//           </Grid>
//         ))
//         }
//       </Grid>
//     </Box>
//     }
//     </>
   
//   );
// };

// export default LanguageSettings;


import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Input, InputAdornment, Typography } from '@mui/material';
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import sourceUrl from '../../../../api/Source.api';
import { defaultSettings_button_bg_color, hoveredSettings_button_bg_color, selected_SettingsButton_bg_color } from '../../../../constants/colors';
import SearchIcon from '@mui/icons-material/Search';

const LanguageSettings = () => {
  const [inputBoxValue, setInputBoxValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const [filterLanguages, setFilterLanguages] = useState(null);
  const { audioLanguage, setAudioLanguage, Audiolanguages, setAudioLanguages, setAudioSettingLanguagesLoading, audioSettinglanguagesloading, showAudioSettings } = useWorkspace();
  
  const theme = localStorage.getItem('theme');
  const token = localStorage.getItem('token');

  const fetchLanguages = async () => {
    try {
      const response = await fetch(`${sourceUrl}/audio/language`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      });
      const data = await response.json();
      setAudioLanguages(data.data.data);
    } catch (error) {
      console.error('Error fetching languages:', error);
    }
    setAudioSettingLanguagesLoading(false);
  };

  useEffect(() => {
    if (!audioSettinglanguagesloading && Audiolanguages.length === 0) {
      setAudioSettingLanguagesLoading(true);
      fetchLanguages();
    }
  }, []);

  const handleLanguageSelect = (lang) => {
    // Set the selected language at the top of the Audiolanguages list
    const reorderedLanguages = [lang, ...Audiolanguages.filter(l => l.id !== lang.id)];
    setAudioLanguages(reorderedLanguages);
    setAudioLanguage(lang.name);
  };

  const handleFilter = (value) => {
    const filterValue = Audiolanguages?.filter(item => item?.name?.toLowerCase().includes(value.toLowerCase()));
    setFilterLanguages(filterValue);
  };

  useEffect(() => {
    if (showAudioSettings) {
      const handler = setTimeout(() => {
        setDebouncedValue(inputBoxValue);
        handleFilter(inputBoxValue);
      }, 300); // Debounce delay to optimize filtering
      return () => clearTimeout(handler);
    }      
  }, [inputBoxValue]);

  const customTextFieldStyles = {
    "& .MuiInputLabel-root": {
      fontFamily: "Inter",
      fontSize: "16px",
      color: "rgba(255, 255, 255, 0.80)",
      "&.Mui-focused": {
        color: "rgba(255, 255, 255, 0.80) !important",
      },
    },
    "& .MuiInputBase-input": {
      color: "#FFFFFF",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      backgroundColor: "#232746",
      "& fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80) !important",
      },
    },
  };

  return (
    <>
      {audioSettinglanguagesloading ? (
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom style={{ fontSize: '16px', fontWeight: '500', marginBottom: '20px' }}>
            Loading Language
          </Typography>
        </Box>
      ) : (
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom style={{ fontSize: '16px', fontWeight: '500', marginBottom: '20px' }}>
            Select Language
          </Typography>
          <Box sx={{ marginBottom: "20px", marginTop: "20px" }}>
            <Input
              type="text"
              disableUnderline={true}
              placeholder="Search language"
              variant="outlined"
              sx={customTextFieldStyles}
              onChange={(e) => setInputBoxValue(e.target.value)}
              value={inputBoxValue}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "rgba(255, 255, 255, 0.80)" }} />
                </InputAdornment>
              }
              style={{
                width: "100%",
                maxWidth: "180px",
                height: "48px",
                color: "#FFFFFF",
                backgroundColor: "#2F355F",
                borderRadius: "8px",
                padding: "0px 12px",
                boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
              }}
            />
          </Box>
          <Grid container spacing={2}>
            {(inputBoxValue === "" ? Audiolanguages : filterLanguages || []).map((lang) => (
              <Grid item key={lang.id} xs="auto">
                <Button
                  variant="outlined"
                  onClick={() => handleLanguageSelect(lang)}
                  sx={{
                    textTransform: 'none',
                    backgroundColor: audioLanguage?.id === lang.id ? selected_SettingsButton_bg_color : defaultSettings_button_bg_color,
                    color: theme === 'dark' ? 'white' : 'black',
                    '&:hover': {
                      backgroundColor: hoveredSettings_button_bg_color,
                      borderColor: '#cac4d0',
                    },
                    borderColor: audioLanguage === lang?.name ? '#cac4d0' : 'transparent',
                    m: 0.5,
                  }}
                >
                  {lang.name}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default LanguageSettings;

