import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
export default function CustomizedSnackbars({
    open,
    setOpen,
    notifMsg,
    setShowNotif,
    notifVariant,
  }) {
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
  
      setOpen(false);
    };
  
    return (
      <div style={{ position: "relative", zIndex: 60 }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
          <Alert
            onClose={handleClose}
            severity={notifVariant}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {notifMsg}
          </Alert>
        </Snackbar>
      </div>
    );
  }