import { button_bg_color_for_New_Project_dm, button_bg_color_for_New_Project_lm, hovered_chat_bg_color_dm, hovered_chat_bg_color_lm, prompt_Input_bg_color_for_dm, prompt_Input_bg_color_for_lm, selected_chat_bg_color_dm, selected_chat_bg_color_lm,LandingPage_Main_Text_color_dm,LandingPage_Main_Text_color_lm, darkTheme_bg_color, landingPage_mainTitle_textColor_dm, landingPage_mainTitle_textColor_lm } from "../constants/colors";

const theme = localStorage.getItem('theme');

export const Application_bg_color_dm = () => {
    return theme === 'dark'?darkTheme_bg_color:'';
}

export const New_Project_Button_bg_color = () => {
    return (theme === 'dark'? button_bg_color_for_New_Project_dm : button_bg_color_for_New_Project_lm )
}

export const HoveredChat_bg_color = () => {
    return theme === 'dark'? hovered_chat_bg_color_dm : hovered_chat_bg_color_lm ;
}

export const SelectedChat_bg_color = () => {
    return theme === 'dark'? selected_chat_bg_color_dm : selected_chat_bg_color_lm;
}

export const LandingPage_MainTitleText_FontColor = () => {
    return theme === 'dark'?landingPage_mainTitle_textColor_dm:landingPage_mainTitle_textColor_lm;
}
