// import React, { useRef, useState, useEffect } from "react";
// import {
//   Button, Col, Row, InputGroup, FormControl, Container,
//   Spinner, ProgressBar, Tooltip, OverlayTrigger
// } from "react-bootstrap";
// import { useWorkspace } from "../../../contexts/WorkspaceContext";
// import sourceUrl from "../../../api/Source.api";

// const LinkUploader = () => {
//   const { setThreadFiles, uploadFiles, threadFiles, checkUrlStatus, setWaitThread, historyFiles } = useWorkspace();
//   const [linkInputs, setLinkInputs] = useState([{ id: 0, value: "" }]);
//   const [isValidLink, setIsValidLink] = useState(true);
//   const [isDuplicateLink, setIsDuplicateLink] = useState(false);
//   const [progressStatusChecking, setProgressStatusChecking] = useState({});
//   const [isHovering, setIsHovering] = useState(false);
//   const linksContainerRef = useRef(null);
//   const latestElementRef = useRef(null);
//   const token = localStorage.getItem('token');
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     if (latestElementRef.current) {
//       latestElementRef.current.scrollIntoView({ behavior: "smooth" });
//     }
//   }, [linkInputs, threadFiles]);

//   useEffect(() => {
//     setLinkInputs([{ id: 0, value: "" }]);
//   }, [historyFiles]);

//   const isValidUrl = (urlString) => {
//     try {
//       new URL(urlString);
//       return true;
//     } catch (e) {
//       return false;
//     }
//   };

//   const checkDuplicateLink = (urlString) => {
//     return threadFiles.some(file => file.type === "link" && file.link === urlString);
//   };

//   const handleLinkChange = (event, id) => {
//     const input = event.target.value;
//     const updatedLinkInputs = linkInputs.map(linkInput =>
//       linkInput.id === id ? { ...linkInput, value: input } : linkInput
//     );
//     setLinkInputs(updatedLinkInputs);
//     setIsValidLink(updatedLinkInputs.every(linkInput => isValidUrl(linkInput.value)));

//     const isDuplicate = updatedLinkInputs.some((linkInput, index) =>
//       index !== updatedLinkInputs.findIndex(item => item.value === linkInput.value)
//     );
//     setIsDuplicateLink(isDuplicate);

//     if (isDuplicate) {
//       event.target.setCustomValidity("This URL is already in use!");
//       event.target.reportValidity();
//     } else {
//       event.target.setCustomValidity("");
//     }
//   };

//   const addLinkInput = () => {
//     const newId = linkInputs[linkInputs.length - 1].id + 1;
//     setLinkInputs([...linkInputs, { id: newId, value: "" }]);
//   };

//   const removeLastInput = () => {
//     setLinkInputs(linkInputs.slice(0, -1));
//   };

//   const handleLinkUpload = async (id) => {
//     setLoading(true);
//     const linkInput = linkInputs.find(input => input.id === id);
//     if (linkInput && isValidUrl(linkInput.value) && !checkDuplicateLink(linkInput.value)) {
//       const linkObject = {
//         type: "link",
//         link: linkInput.value,
//         name: new URL(linkInput.value).hostname,
//         status: "uploading",
//         fileId: null,
//       };
//       setWaitThread(true);
//       setThreadFiles(prevThreadFiles => [...prevThreadFiles, linkObject]);
//       const response = await uploadFiles(linkObject);
//       const fileId = response.data.id;
//       const url = response.data.url;

//       setThreadFiles(prevThreadFiles =>
//         prevThreadFiles.map(prevFile =>
//           prevFile.link === linkObject.link
//             ? {
//                 ...prevFile,
//                 status: response.data.status,
//                 fileId: fileId,
//                 url: url,
//               }
//             : prevFile
//         )
//       );

//       const updatedLinkInputs = linkInputs.filter(input => input.id !== id);
//       setLinkInputs(updatedLinkInputs);
//       addLinkInput();

//       setProgressStatusChecking(prev => ({ ...prev, [url]: true }));

//       const interval = setInterval(async () => {
//         const status_resp = await fetch(`${sourceUrl}/status`, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             "Authorization": `Bearer ${token}`
//           },
//           body: JSON.stringify({ url }),
//         });
//         const responseData = await status_resp.json();

//         if (status_resp.status === 200 && responseData.data.status === "completed") {
//           setThreadFiles(prevThreadFiles =>
//             prevThreadFiles.map(prevFile =>
//               prevFile.link === linkObject.link
//                 ? {
//                     ...prevFile,
//                     status: "completed",
//                     fileId: fileId,
//                     url: url,
//                   }
//                 : prevFile
//             )
//           );
//           setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
//           clearInterval(interval);
//         } else if (status_resp.status === 200 && responseData.data.status === "failed") {
//           setThreadFiles(prevThreadFiles =>
//             prevThreadFiles.map(prevFile =>
//               prevFile.link === linkObject.link
//                 ? {
//                     ...prevFile,
//                     status: "failed",
//                     fileId: "",
//                     url: url,
//                   }
//                 : prevFile
//             )
//           );
//           setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
//           clearInterval(interval);
//         } else if (status_resp.status === 200 && responseData.data.status === "processing") {
//           setThreadFiles(prevThreadFiles =>
//             prevThreadFiles.map(prevFile =>
//               prevFile.link === linkObject.link
//                 ? {
//                     ...prevFile,
//                     status: "processing",
//                     fileId: "",
//                     url: url,
//                   }
//                 : prevFile
//             )
//           );
//           setProgressStatusChecking(prev => ({ ...prev, [url]: true }));
//         } else if (status_resp.status === 500) {
//           setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
//           clearInterval(interval);
//         }
//       }, 3000);
//     }
//     setLoading(false);
//   };

//   const handleCopyToClipboard = (text) => {
//     navigator.clipboard.writeText(text)
//       .then(() => {
//         console.log('Link copied to clipboard:', text);
//       })
//       .catch((error) => {
//         console.error('Error copying link to clipboard:', error);
//       });
//   };

//   const handleRetryFile = async (file) => {
//     const index = threadFiles.findIndex(f => f.link === file.link);

//     setThreadFiles(prevThreadFiles => {
//       const updatedFiles = [...prevThreadFiles];
//       updatedFiles[index].status = "uploading";
//       return updatedFiles;
//     });

//     const response = await uploadFiles(file);

//     setThreadFiles(prevThreadFiles => {
//       const updatedFiles = [...prevThreadFiles];
//       if (response.error && response.error.code === 'invalid') {
//         updatedFiles[index].status = 'failed';
//         updatedFiles[index].resp_msg = response.error.message || '';
//       } else {
//         updatedFiles[index].status = response.data?.status || '';
//         updatedFiles[index].fileId = response.data?.id || '';
//         updatedFiles[index].url = response.data?.url || '';
//       }
//       return updatedFiles;
//     });
//   };

//   return (
//     <Container fluid style={{ overflowX: "hidden" }}>
//       <div ref={linksContainerRef}>
//         {linkInputs.map((linkInput, index) => (
//           <Row key={linkInput.id} className="mb-2">
//             <Col>
//               <InputGroup>
//                 <FormControl
//                   style={{ fontSize: ".95rem" }}
//                   placeholder="Enter/Paste link here..."
//                   onChange={(event) => handleLinkChange(event, linkInput.id)}
//                   value={linkInput.value}
//                   isInvalid={linkInput.value && (!isValidUrl(linkInput.value))}
//                 />
//                 <Button
//                   variant="outline-primary"
//                   onClick={() => handleLinkUpload(linkInput.id)}
//                   disabled={
//                     !isValidUrl(linkInput.value) ||
//                     checkDuplicateLink(linkInput.value) ||
//                     progressStatusChecking[linkInput.value]
//                   }
//                   style={{
//                     backgroundColor: isHovering ? 'blue' : 'transparent',
//                     transition: 'background-color 0.3s ease',
//                   }}
//                   onMouseEnter={() => setIsHovering(true)}
//                   onMouseLeave={() => setIsHovering(false)}
//                 >
//                   <i className="bi bi-arrow-up" />
//                 </Button>
//               </InputGroup>
//             </Col>
//             {index === linkInputs.length - 1 && <div ref={latestElementRef} />}
//           </Row>
//         ))}
//         <Row>
//           <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
//             <Button variant="outline-primary" onClick={addLinkInput} style={{ fontSize: '.95rem', right: '0' }}>+</Button>
//           </Col>
//         </Row>
//       </div>
//       {!threadFiles.some(file => file.type === "link") ? (
//         <Row className="text-secondary mb-1" style={{ fontSize: '.95rem' }}>
//           Add youtube links to reference them in your conversation.
//         </Row>
//       ) : (
//         <div>
//           {threadFiles.map((file, index) => (
//             <div key={index}>
//               {file.type === "link" && (
//                 <Row className="mb-1 px-0 m-0 align-items-center">
//                   <Col
//                     xs="10"
//                     className={file.status === "upload failed" ? "text-danger p-0 m-0" : "p-0 m-0"}
//                     style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
//                   >
//                     <OverlayTrigger
//                       placement="top"
//                       overlay={<Tooltip id="tooltip">{file.resp_msg ? file.resp_msg : file.link}</Tooltip>}
//                     >
//                       <div style={{ display: 'flex', justifyContent: 'space-between', color: file.status === 'failed' ? 'red' : '' }}>
//                         <span>
//                           {file.link.length > 20 ? `${file.link.slice(0, 20)}...` : file.link}
//                         </span>
//                         <i className="bi bi-copy" style={{ cursor: 'pointer', marginRight: '5px' }} onClick={() => handleCopyToClipboard(file.link)}></i>
//                       </div>
//                     </OverlayTrigger>
//                   </Col>
//                   <Col xs="2" className="m-0 p-0" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
//                     {file.status === "uploading" ? (
//                       <Spinner animation="border" role="status" size="sm">
//                         <span className="visually-hidden">Loading...</span>
//                       </Spinner>
//                     ) : file.status === "failed" ? (
//                       <i className="bi bi-arrow-repeat" onClick={() => handleRetryFile(file)} style={{ cursor: "pointer", color: 'red' }}></i>
//                     ) : (
//                       <></>
//                     )}
//                   </Col>
//                   {progressStatusChecking[file.url] && (
//                     <div style={{ borderRadius: '5px' }}>
//                       <ProgressBar animated now={100} label="Processing the link...." />
//                     </div>
//                   )}
//                 </Row>
//               )}
//               {index === threadFiles.length - 1 && <div ref={latestElementRef} />}
//             </div>
//           ))}
//         </div>
//       )}
//     </Container>
//   );
// };

// export default LinkUploader;

// ======================= above one is the Updated linkuploader on 17-07-2024=========================================

import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Container,
  CircularProgress,
  Tooltip,
  IconButton,
  LinearProgress,
  Box
} from "@mui/material";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import sourceUrl from "../../../api/Source.api";
import Link_Icon from "../../../assets/Link_Icon.png";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import { linkUploader_bg_color } from "../../../constants/colors";
import { ErrorEvent, LinkPaste, trackLinkPaste } from "../../../config/analytics";

const LinkUploader = () => {
  const { setThreadFiles, uploadFiles, threadFiles, setWaitThread, historyFiles } = useWorkspace();
  const [linkInput, setLinkInput] = useState({ id: 0, value: "" });
  const [isValidLink, setIsValidLink] = useState(true);
  const [isDuplicateLink, setIsDuplicateLink] = useState(false);
  const [progressStatusChecking, setProgressStatusChecking] = useState({});
  const [isHovering, setIsHovering] = useState(false);
  const linksContainerRef = useRef(null);
  const latestElementRef = useRef(null);
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem('theme'));

  useEffect(() => {
    if (latestElementRef.current) {
      latestElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [threadFiles]);

  useEffect(() => {
    setLinkInput({ id: 0, value: "" });
  }, [historyFiles]);

  const isValidUrl = (urlString) => {
    try {
      new URL(urlString);
      return true;
    } catch (e) {
      return false;
    }
  };

  const checkDuplicateLink = (urlString) => {
    return threadFiles.some(file => file.type === "link" && file.link === urlString);
  };

  const handleLinkChange = (event) => {
    const input = event.target.value;
    setLinkInput({ ...linkInput, value: input });
    setIsValidLink(isValidUrl(input));

    const isDuplicate = threadFiles.some(file => file.type === "link" && file.link === input);
    setIsDuplicateLink(isDuplicate);

    if (isDuplicate) {
      event.target.setCustomValidity("This URL is already in use!");
      event.target.reportValidity();
    } else {
      event.target.setCustomValidity("");
    }
  };

  // const handleLinkUpload = async () => {
  //   setLoading(true);
  //   if (isValidUrl(linkInput.value) && !checkDuplicateLink(linkInput.value)) {
  //     const linkObject = {
  //       type: "link",
  //       link: linkInput.value,
  //       name: new URL(linkInput.value).hostname,
  //       status: "uploading",
  //       fileId: null,
  //     };
  //     setWaitThread(true);
  //     setThreadFiles(prevThreadFiles => [...prevThreadFiles, linkObject]);
  //     const response = await uploadFiles(linkObject);
  //     const fileId = response.data.id;
  //     const url = response.data.url;

  //     setThreadFiles(prevThreadFiles =>
  //       prevThreadFiles.map(prevFile =>
  //         prevFile.link === linkObject.link
  //           ? {
  //               ...prevFile,
  //               status: response.data.status,
  //               fileId: fileId,
  //               url: url,
  //             }
  //           : prevFile
  //       )
  //     );

  //     setLinkInput({ id: 0, value: "" });

  //     setProgressStatusChecking(prev => ({ ...prev, [url]: true }));

  //     const interval = setInterval(async () => {
  //       const status_resp = await fetch(`${sourceUrl}/status`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           "Authorization": `Bearer ${token}`
  //         },
  //         body: JSON.stringify({ url }),
  //       });
  //       const responseData = await status_resp.json();

  //       if (status_resp.status === 200 && responseData.data.status === "completed") {
  //         setThreadFiles(prevThreadFiles =>
  //           prevThreadFiles.map(prevFile =>
  //             prevFile.link === linkObject.link
  //               ? {
  //                   ...prevFile,
  //                   status: "completed",
  //                   fileId: fileId,
  //                   url: url,
  //                 }
  //               : prevFile
  //           )
  //         );
  //         setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
  //         clearInterval(interval);
  //       } else if (status_resp.status === 200 && responseData.data.status === "failed") {
  //         setThreadFiles(prevThreadFiles =>
  //           prevThreadFiles.map(prevFile =>
  //             prevFile.link === linkObject.link
  //               ? {
  //                   ...prevFile,
  //                   status: "failed",
  //                   fileId: "",
  //                   url: url,
  //                 }
  //               : prevFile
  //           )
  //         );
  //         setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
  //         clearInterval(interval);
  //       } else if (status_resp.status === 200 && responseData.data.status === "processing") {
  //         setThreadFiles(prevThreadFiles =>
  //           prevThreadFiles.map(prevFile =>
  //             prevFile.link === linkObject.link
  //               ? {
  //                   ...prevFile,
  //                   status: "processing",
  //                   fileId: "",
  //                   url: url,
  //                 }
  //               : prevFile
  //           )
  //         );
  //         setProgressStatusChecking(prev => ({ ...prev, [url]: true }));
  //       } else if (status_resp.status === 500) {
  //         setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
  //         clearInterval(interval);
  //       }
  //       else {
  //         setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
  //         clearInterval(interval);
  //       }
  //     }, 3000);
  //   }
  //   setLoading(false);
  //   setWaitThread(false)
  // };

  const handleLinkUpload = async () => {
    setLoading(true);
    if (isValidUrl(linkInput.value) && !checkDuplicateLink(linkInput.value)) {
      const linkObject = {
        type: "link",
        link: linkInput.value,
        name: new URL(linkInput.value).hostname,
        status: "uploading",
        fileId: null,
      };
      LinkPaste(linkInput.value);
      setWaitThread(true);
      setThreadFiles(prevThreadFiles => [...prevThreadFiles, linkObject]);
  
      try {
        const response = await uploadFiles(linkObject);
        if (response && response.data) {
          const { id: fileId, url, status } = response.data;
          setThreadFiles(prevThreadFiles =>
            prevThreadFiles.map(prevFile =>
              prevFile.link === linkObject.link
                ? { ...prevFile, status, fileId, url }
                : prevFile
            )
          );
  
          setLinkInput({ id: 0, value: "" });
          setProgressStatusChecking(prev => ({ ...prev, [url]: true }));
  
          const interval = setInterval(async () => {
            try {
              const status_resp = await fetch(`${sourceUrl}/status`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify({ url }),
              });
  
              const responseData = await status_resp.json();
  
              if (status_resp.ok && responseData.data) {
                const { status } = responseData.data;
                setThreadFiles(prevThreadFiles =>
                  prevThreadFiles.map(prevFile =>
                    prevFile.link === linkObject.link
                      ? { ...prevFile, status, fileId, url }
                      : prevFile
                  )
                );
  
                if (status === "completed" || status === "failed") {
                  setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
                  clearInterval(interval);
                }
              } else {
                setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
                clearInterval(interval);
              }
            } catch (error) {
              console.error('Error fetching status:', error);
              ErrorEvent(error, error.message);
              setProgressStatusChecking(prev => ({ ...prev, [url]: false }));
              clearInterval(interval);
            }
          }, 3000);
        } else {
          console.error('Invalid response from uploadFiles:', response);
          setThreadFiles(prevThreadFiles =>
            prevThreadFiles.map(prevFile =>
              prevFile.link === linkObject.link
                ? { ...prevFile, status: "failed", fileId: "", url: linkObject.link }
                : prevFile
            )
          );
          setLinkInput({ id: 0, value: "" });
        }
        trackLinkPaste( linkInput.value,);

      } catch (error) {
        setThreadFiles(prevThreadFiles =>
          prevThreadFiles.map(prevFile =>
            prevFile.link === linkObject.link
              ? { ...prevFile, status: "failed", fileId: "", url: linkObject.link }
              : prevFile
          )
        );
        ErrorEvent(error, error.message);
      }
    }
  
    setLoading(false);
    setWaitThread(false);
  };
  
  

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Link copied to clipboard:', text);
      })
      .catch((error) => {
        console.error('Error copying link to clipboard:', error);
      });
  };

  const handleRetryFile = async (file) => {
    const index = threadFiles.findIndex(f => f.link === file.link);

    setThreadFiles(prevThreadFiles => {
      const updatedFiles = [...prevThreadFiles];
      updatedFiles[index].status = "uploading";
      return updatedFiles;
    });

    const response = await uploadFiles(file);

    setThreadFiles(prevThreadFiles => {
      const updatedFiles = [...prevThreadFiles];
      if (response.error && response.error.code === 'invalid') {
        updatedFiles[index].status = 'failed';
        updatedFiles[index].resp_msg = response.error.message || '';
      } else {
        updatedFiles[index].status = response.data?.status || '';
        updatedFiles[index].fileId = response.data?.id || '';
        updatedFiles[index].url = response.data?.url || '';
      }
      return updatedFiles;
    });
  };

  return (
    <Container maxWidth="md" disableGutters style={{ overflowX: "hidden" }}>
      <div ref={linksContainerRef}>
        <div style={{
          backgroundColor: theme === 'dark' ? linkUploader_bg_color : '',
          borderRadius: '10px',
          width: '100%',
          height: '56px',
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0 16px', // Equivalent to Grid spacing
          gap:''
        }}>
          <div style={{
            display: 'flex',
            margin: 'auto',
            padding: '8px', // Padding to mimic spacing
          }}>
            <img
              src={Link_Icon}
              alt="Link Icon"
              style={{ width: '20px', height: '20px', verticalAlign: 'middle'}}
            />
          </div>
          <div style={{ flexGrow: 1, margin: '0 8px' }}>
            <TextField
              fullWidth
              placeholder="Paste any link here"
              onChange={handleLinkChange}
              value={linkInput.value}
              error={linkInput.value && !isValidUrl(linkInput.value)}
              helperText={linkInput.value && !isValidUrl(linkInput.value) ? "Invalid URL" : ""}
              variant="outlined"
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },
                  '& input': {
                    fontSize: '14px', // Adjust input text size here
                    color: theme === 'dark' ? 'white' : 'black',
                  },
                },
                '& .MuiInputLabel-root': {
                  fontSize: '11px', // Adjust placeholder text size here
                },
                margin: 'auto',
                width:'150px',
                marginLeft:'-10px',
                marginRight:'-10px',
                
              }}
              InputLabelProps={{
                sx: {
                  fontSize: '10px',
                },
              }}
              style={{fontSize:'7px'}}
            />
          </div>
          <div>
            <IconButton
              onClick={handleLinkUpload}
              disabled={
                !isValidUrl(linkInput.value) ||
                checkDuplicateLink(linkInput.value) ||
                progressStatusChecking[linkInput.value]
              }
              style={{
                color: isHovering ? 'blue' : '',
                transition: 'background-color 0.3s ease',
              }}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            >
              <CheckCircleIcon />
            </IconButton>
          </div>
          <div ref={latestElementRef} />
        </div>
      </div>
    </Container>

  );
};

export default LinkUploader;


// ======================================

{/* <Container maxWidth="md" disableGutters  style={{ overflowX: "hidden"}}>
      <div ref={linksContainerRef}>
        <Grid container spacing={2} alignItems="center" style={{backgroundColor: theme === 'dark' ? 'red' : '', borderRadius: '10px',width:'100%',height:'56px',margin:'auto',display:'flex',justifyContent:'center'}}>
          <Grid item style={{backgroundColor:'yellow',display:'flex',margin:'auto'}}>
            <img src={Link_Icon} alt="Link Icon" style={{ width: '20px', height: '20px', verticalAlign: 'middle',backgroundColor:'green'}} />
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              placeholder="Paste any link here"
              onChange={handleLinkChange}
              value={linkInput.value}
              error={linkInput.value && !isValidUrl(linkInput.value)}
              helperText={linkInput.value && !isValidUrl(linkInput.value) ? "Invalid URL" : ""}
              variant="outlined"
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },
                  color: 'white'
                },
                margin:'auto'
              }}
            />
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleLinkUpload}
              disabled={
                !isValidUrl(linkInput.value) ||
                checkDuplicateLink(linkInput.value) ||
                progressStatusChecking[linkInput.value]
              }
              style={{
                color: isHovering ? 'blue' : '',
                transition: 'background-color 0.3s ease',
              }}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            >
              <CheckCircleIcon />
            </IconButton>
          </Grid>
          <div ref={latestElementRef} />
        </Grid>
      </div>
    </Container> */}

// =======================================




// import React, { useState } from "react";
// import {
//   Button,
//   Col,
//   Row,
//   InputGroup,
//   FormControl,
//   Container,
//   Spinner,
// } from "react-bootstrap";
// import { useWorkspace } from "../../../contexts/WorkspaceContext";

// const LinkUploader = () => {
//   const { setThreadFiles, uploadFiles, threadFiles } = useWorkspace();
//   const [linkInput, setLinkInput] = useState("");
//   const [isValidLink, setIsValidLink] = useState(true);
//   const [isDuplicateLink, setIsDuplicateLink] = useState(false);

//   // Function to validate URL using a regex pattern
//   const isValidUrl = (urlString) => {
//     // const urlPattern = new RegExp(
//     //   "^(https?:\\/\\/)?" + // validate protocol
//     //     "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
//     //     "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
//     //     "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
//     //     "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
//     //     "(\\#[-a-z\\d_]*)?$",
//     //   "i" // fragment locator
//     // );
//     // return !!urlPattern.test(urlString);
//     // if (urlString !== "" && urlString !== null) {
//     //   return true;
//     // }
//     try {
//       new URL(urlString);
//       return true; // If successful, URL is valid
//     } catch (e) {
//       return false; // If an error occurs, URL is invalid
//     }
//   };

//   const checkDuplicateLink = (urlString) => {
//     return threadFiles.some(
//       (file) => file.type === "link" && file.link === urlString
//     );
//   };

//   const handleLinkChange = (event) => {
//     const input = event.target.value;
//     setLinkInput(input);
//     setIsValidLink(isValidUrl(input)); // Validate link and update state
//     setIsDuplicateLink(checkDuplicateLink(input)); // Check for duplicate
//   };

//   const handleLinkUpload = async () => {
//     const linkObject = {
//       type: "link",
//       link: linkInput,
//       name: new URL(linkInput).hostname, // Extract hostname as name
//       status: "uploading", // Mark as "uploading" initially
//       fileId: null,
//     };

//     // Update threadFiles with new link object
//     setThreadFiles((prevThreadFiles) => [...prevThreadFiles, linkObject]);

//     // Upload link
//     const fileId = await uploadFiles(linkObject);

//     // Update the file object with uploaded state or upload failed
//     setThreadFiles((prevThreadFiles) =>
//       prevThreadFiles.map((prevFile) =>
//         prevFile.link === linkObject.link
//           ? fileId
//             ? { ...prevFile, status: "uploaded", fileId: fileId }
//             : { ...prevFile, status: "upload failed" }
//           : prevFile
//       )
//     );

//     // Clear input
//     setLinkInput("");
//   };

//   const handleRemoveFile = (index) => {
//     const updatedFiles = threadFiles.filter(
//       (_, fileIndex) => fileIndex !== index
//     );
//     setThreadFiles(updatedFiles); // Update the context
//   };

//   return (
//     <Container fluid>
//       <Row>
//         <Col
//           style={{ display: "flex", flexDirection: "column" }}
//           className="m-0 p-0"
//         >
//           <InputGroup className="mb-2">
//             <FormControl
//               style={{fontSize:'.95rem'}}
//               placeholder="Enter/Paste link here..."
//               onChange={handleLinkChange}
//               value={linkInput}
//               isInvalid={linkInput && (!isValidLink || isDuplicateLink)}
//             />
//             <Button
//               variant="outline-primary"
//               onClick={handleLinkUpload}
//               id="button-addon2"
//               disabled={!isValidLink || isDuplicateLink}
//             >
//                <i className="bi bi-send" />
//             </Button>
//           </InputGroup>
//         </Col>
//       </Row>
//       {linkInput && !isValidLink && (
//         <Row className="text-danger mb-1" style={{fontSize:'.95rem'}}>Please enter a valid URL link.</Row>
//       )}
//       {isDuplicateLink && (
//         <Row className="text-danger mb-1" style={{fontSize:'.95rem'}}>
//           This link has already been added.
//         </Row>
//       )}
//       {!threadFiles.some((file) => file.type === "link") ? (
//         <Row className="text-secondary mb-1" style={{fontSize:'.95rem'}}>
//           Add youtube video, medium blog, or other web links to reference them
//           in your conversation.
//         </Row>
//       ) : (
//         threadFiles.map((file, index) => (
//           <div key={index}>
//             {file.type === "link" && (
//               <Row className="mb-1 px-0 m-0 align-items-center">
//                 <Col
//                   xs="10"
//                   className={
//                     file.status === "upload failed"
//                       ? "text-danger p-0 m-0"
//                       : "p-0 m-0"
//                   }
//                   style={{
//                     overflow: "hidden",
//                     textOverflow: "ellipsis",
//                     whiteSpace: "nowrap",
//                   }}
//                 >
//                   {file.name}
//                 </Col>
//                 <Col
//                   xs="2"
//                   className="m-0 p-0"
//                   style={{
//                     display: "flex",
//                     justifyContent: "end",
//                     alignItems: "center",
//                   }}
//                 >
//                   {file.status === "uploading" ? (
//                     <Spinner animation="border" role="status" size="sm">
//                       <span className="visually-hidden">Loading...</span>
//                     </Spinner>
//                   ) : file.status === "upload failed" ? (
//                     <i
//                       className="bi bi-exclamation-circle"
//                       onClick={() => handleRemoveFile(index)}
//                       style={{ cursor: "pointer" }}
//                     />
//                   ) : (
//                     <i
//                       className="bi bi-x-lg"
//                       onClick={() => handleRemoveFile(index)}
//                       style={{ cursor: "pointer" }}
//                     />
//                   )}
//                 </Col>
//               </Row>
//             )}
//           </div>
//         ))
//       )}
//     </Container>
//   );
// };

// export default LinkUploader;
